import React from 'react';

export const tourEnum = {
   /*Not Used*/
   PROFILE: [
    {
      selector: '[data-tut="tour_main_frame"]',
      content: () => (
        <p className="mr-2" style={{ fontSize: "18px" }}>
          Welcome to Rubiqe, The place to improve your Data & Analytics skills
          and be introduced to top-notch employers,
        </p>
      ),
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_profile_info_details"]',
      content: `Complete your profile details.`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_profile_bio"]',
      content: `Write your Bio too.`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_user_skills"]',
      content: `Click on any of the skills to self-claim your Data Literacy skills. By self-claiming, you will reach level 1 in each skill.`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_user_other-skills"]',
      content: `Add other skills you may have here.`,
      canInteract: false,
    },
    // {
    //     selector: '[data-tut="tour_user_certificates"]',
    //     content: `Certificates`
    // },
    {
      selector: '[data-tut="tour_user_experience"]',
      content: `Don’t forget to share your education and job experiences here.`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_assessments_link"]',
      content: () => (
        <p>
          Now the interesting part begins.
          <br />
          <br />
          Click on the Assessments assigned to me to continue.
        </p>
      ),
      canInteract: true,
    },
  ],

   /*Used*/
   DEMO_PROFILE: [
      {
         selector: '[data-tut="tour_main_frame"]',
         content: () => (
            <p className="mr-2" style={{ fontSize: "18px" }}>
               Welcome to Rubiqe, The place to improve your Data & Analytics skills
               and be introduced to top-notch employers,
            </p>
         ),
         canInteract: false,
         forQ3: true,
      },
      // {
      //    selector: '[data-tut="tour_profile_info_details"]',
      //    content: `Complete your profile details.`,
      //    canInteract: true,
      // },
      {
         selector: '[data-tut="tour_profile_bio"]',
         content: `Write your Bio too.`,
         canInteract: true,
      },
      {
         selector: '[data-tut="tour_user_skills"]',
         content: `Click on any of the skills to self-claim your Data Literacy skills. By self-claiming, you will reach level 1 in each skill.`,
         canInteract: true,
      },
      {
         selector: '[data-tut="tour_user_other-skills"]',
         content: `Add other skills you may have here.`,
         canInteract: true,
      },
      // {
      //    selector: '[data-tut="tour_user_experience"]',
      //    content: `Don’t forget to share your education and job experiences here.`,
      //    canInteract: false,
      // },
      {
         selector: '[data-tut="tour_ind_exprince_model_link"]',
         content: () => (
            <p>
               Now that you have the acquired skills, let’s explore opportunities to
               enhance your career.
               <br />
               <br />
               Click on Add Experience to continue
            </p>
         ),
         canInteract: true,
      }
            // {
      //    selector: '[data-tut="tour_ind_learn_link"]',
      //    content: () => (
      //       <p>
      //          Now that you have the acquired skills, let’s explore opportunities to
      //          enhance your career.
      //          <br />
      //          <br />
      //          Click on Learn to continue
      //       </p>
      //    ),
      //    canInteract: true,
      // },


      // {
      //    selector: '[data-tut="tour_assessments_link"]',
      //    content: () => (
      //       <p>
      //          Watch a video tutorial.
      //          <br />
      //          <br />
      //          <video id='video' controls="controls" preload='none' width="270" height="250" poster="http://media.w3.org/2010/05/sintel/poster.png">
      //             <source id='mp4' src="http://media.w3.org/2010/05/sintel/trailer.mp4" type='video/mp4' />
      //          </video>
      //       </p>
      //    ),
      //    canInteract: false,
      // },

      // {
      //    selector: "",
      //    content: () => (
      //       <h2 style={{textAlign:'center'}}>
      //          Thank you
      //       </h2>
      //    ),
      //    canInteract: false,
      // },
   ],
   DEMO_EXP:[{
      selector: '[data-tut="tour_exprince_model_work"]',
      content: () => (
         <p>
            Add your working experience here.
            <br />
         </p>
      ),
      canInteract: true,
      forQ3: true,
   }],

   ADD_EDU: [
      {
         selector: '[data-tut="tour_ind_exprince_model_link"]',
         content: () => (
            <p>
               Now that you have added the Experience,
               let's add your eduction.
               <br />
               <br />
               Click on Add Experience to continue
            </p>
         ),
         canInteract: true,
         forQ3: true,
      }
   ],

   DEMO_EDU: [
      {
         selector: '[data-tut="tour_exprince_model_edu_checkbox"]',
         content: () => (
            <p>
               Click on school
               <br />
            </p>
         ),
         canInteract: true,
         forQ3: true
      },
      {
         selector: '[data-tut="tour_exprince_model_work"]',
         content: () => (
            <p>
               Add your Educational experience here.
               <br />
            </p>
         ),
         canInteract: true,
      },
/*      {
         selector: '',
         content: () => (
            <>
               <h2 style={{ textAlign: 'center' }}>
                  That's it !
               </h2>
               <p>Now we will checkout learn page.</p>
            </>
         ),
         canInteract: false,
         last: true,
         onClick: () => window.location.href = '/individual/learn',
         btnText: 'Learn Page',
      },*/
      /*{
         selector: '',
         content: () => (
            <h2 style={{ textAlign: 'center' }}>
               Thank you
            </h2>
         ),
         canInteract: false,
         last: true,
      }*/],
   DEMO_LEARN:[
      {
         selector: '[data-tut="tour_ind_learn_link"]',
         content: () => (
            <p>
               Now that you have the acquired skills, let’s explore opportunities to
               enhance your career.
               <br />
               <br />
               Click on Learn to continue
            </p>
         ),
         canInteract: true,
         forQ3: true,
      },
      ],

   Add_THANKYOU: [
      {
         selector: "",
         content: () => (
            <h2 style={{textAlign:'center'}}>
               Thank you
            </h2>
         ),
         canInteract: false,
         last: true

      }
   ],

   EXPRINCE_MODEL:[ {
      selector: '[data-tut="tour_exprince_model_work"]',
      content: () => (
         <p>
            Here you can search suitable projects and job opportunities that interest you.
            <br />
         </p>
      ),
      canInteract: false,
   }, {
      selector: '[data-tut="tour_exprince_model_school"]',
      content: () => (
         <p>
            Here you can search suitable projects and job opportunities that interest you.
            <br />
         </p>
      ),
      last: true
   },],

   LEARN: [
         {
            selector: '[data-tut="tour_free_course"]',
            content: () => (
               <p style={{textAlign:'center'}}>
                  Select this course
               </p>
            ),
            nextStep: () => window.location.href = '/individual/karisma',
            canInteract: true,
            forQ3: true,
         },
/*      {
         selector: '[data-tut="tour_preferred_skills_1"]',
         content: () => (
            <p style={{textAlign:'center'}}>
               Please select the skills you want to learn.
            </p>
         ),
         canInteract: true,
      },*/
      {
         selector: '',
         content: () => (
            <p style={{textAlign:'center'}}>
               You have completed the on-boarding, now you are ready to explore on you own.
            </p>
         ),
         last: true
      }

   ],

   KARISHMA: [
      {
         selector: '[data-tut="karishma_to_q3cognito"]',
         content: () => (
            <p style={{textAlign:'center'}}>
               Click on this link to register to this program
            </p>
         ),
         canInteract: true,
         last: true,
         forQ3: true,
      }
   ],

  HOME: [{}],
  OPPORTUNITIES: [
    {
      selector: "",
      content: `Here you can search,apply and save suitable projects and job opportunities that interest you.`,
    },
     {
        selector: '[data-tut="tour_filtermenu"]',
        content: () => (
           <p>
              Here you can search suitable projects and job opportunities that interest you.
              <br />
           </p>
        ),
     },
    {
      selector: '[data-tut="tour_matchPercentage"]',
      content: () => (
        <p>
          Your match percentage shows how closely matched you are to a job;
          based on your skill level.
          <br />
          <br />
          The higher the better!
        </p>
      ),
    },
    //     {
    //       selector: '',
    //       content: () => (
    //           <p>
    //               Here you can get recommendations on Opportunities (job or project) that are a match to your skills. You can view details about a specific opportunity and Apply directly to the Hiring Employer.
    // <br/><br/>
    //               You can also save suitable projects and job opportunities that interest you for future reference.
    // <br/><br/>
    //               p/s Don’t forget that some employers require you to take specific assessments when applying for an opportunity
    //
    //           </p>
    //       ),
    //         canInteract: false
    //     },
    {
      selector: '[data-tut="tour_applyJob"]',
      content: `Don’t forget that some employers require you to take specific assessments when applying for a job.`,
      canInteract: false,
    },
    {
      selector: '',
      content: () => (
         <p style={{textAlign:'center'}}>
            You have completed the on-boarding, now you are ready to explore on you own.
         </p>
      ),
      last: true
   }
  ],
  // INVITE_IND: [
  //   {
  //     selector: '[data-tut="tour_invite_individual_link"]',
  //     content: `Invite your friends and  colleagues to join us to learn more about Data & Analytics and also to be introduced to top-notch employers.`,
  //     canInteract: true,
  //   },
  // ],

   LAST: [{
      selector: '',
      content: `Completed`,
      last: true,
   }],
  INVITE_IND_MODAL: [
    {
      selector: '[data-tut="tour_invite_individual_email"]',
      content: `You can invite many friends at the same time. Just separate their emails with a comma (,).`,
      last: true,
    },
  ],
  LEARNINGPATH: [{}],
  ASSESSMENTS_ASSIGNED: [
    {
      selector: "",
      content: `The assessments assigned to you will be listed here based on your self-claimed skills.`,
      canInteract: false,
    },
    {
      selector: "",
      content: () => (
        <p>
          If you achieve 50% to 80% in the assessment, your skill will be
          upgraded to level 2.
          <br />
          <br />
          If you achieve 80% or more, you will not only achieve level 3, but
          also unlock the certification assessment!
        </p>
      ),
      canInteract: false,
    },
    {
      selector: "",
      content: () => (
        <p>
          You can take any assessment at any time. <br />
          <br />
          Just be aware that there is a mandatory time gap of 7 days before you
          can retake the same assessment.
        </p>
      ),
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_completedAssessments_tab_link"]',
      content: `To view your assessment results, click on Completed Assessments.`,
      canInteract: true,
    },
  ],
  COMPLETED_ASSESSMENTS: [
    {
      selector: "",
      content: () => (
        <p>
          After completing your assessment, you can view the results here.
          <br />
          <br />
          Your assessment results affects your skill level in your profile.
          <br />
          <br />
          It can also increase your chances of being seen by top-notch
          employers.
        </p>
      ),
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_learningPaths_tab_link"]',
      content: `Next, see how Max, our AI assistant can help recommend your up-skilling choices.`,
      canInteract: false,
    },
    {
      selector: '',
      content: () => (
         <p style={{textAlign:'center'}}>
            You have completed the on-boarding, now you are ready to explore on you own.
         </p>
      ),
      last: true
   }
  ],


   GOALS: [
      // {
      //   selector: '',
      //   content: `Max, our AI assistant will recommend the best path for you to learn your favorite skills - based on the skills you have and the ones you want to learn`,
      //   canInteract: false,
      // },
      {
         selector: '[data-tut="tour_goals_skills"]',
         content: `Start by selecting the skills you want to learn, regardless of your current level or knowledge.`,
         canInteract: false,
      },
      {
         selector: '[data-tut="tour_goals_paths"]',
         content: `Max, our AI assistant will suggest relevant courses  in the form of a learning path, based on the skills you have and the ones you want to learn.`,
         canInteract: false,
      },
      {
         selector: '[data-tut="tour_ind_learning_link"]',
         content: () => (
            <p>
               Now that you have the acquired skills, let’s explore opportunities to
               enhance your career.
               <br />
               <br />
               Click on Learning to continue
            </p>
         ),
         canInteract: true,
      },
   ],




  LEARNING_PATH: [
    // {
    //   selector: '',
    //   content: `Max, our AI assistant will recommend the best path for you to learn your favorite skills - based on the skills you have and the ones you want to learn`,
    //   canInteract: false,
    // },
    {
      selector: '[data-tut="tour_preferred_skills"]',
      content: `Start by selecting the skills you want to learn, regardless of your current level or knowledge.`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_learning_paths"]',
      content: `Max, our AI assistant will suggest relevant courses  in the form of a learning path, based on the skills you have and the ones you want to learn.`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_ind_opportunities_link"]',
      content: () => (
        <p>
          Now that you have the acquired skills, let’s explore opportunities to
          enhance your career.
          <br />
          <br />
          Click on Opportunities to continue
        </p>
      ),
      canInteract: true,
    },
  ],
  ORG_PROFILE: [
    {
      selector: "",
      content: `Welcome to Rubiqe!
\n\n
The place to find AI, Analytics and Data Literate professionals.`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_complete_org_profile"]',
      content: `First you have to complete your company’s profile by clicking here.`,
      canInteract: true,
    },
  ],
  ORG_PROFILE_update: [
    {
      selector: '[data-tut="tour_complete_org_modal"]',
      content: `Update Your Organization information to continue.`,
      canInteract: true,
    },
  ],
  ORG_PROFILE1: [
    // {
    //     selector: '',
    //     content: `You can also add Photos and Benefits so that potential employees can get to know you better`,
    //     canInteract: false
    // },
    {
      selector: '[data-tut="tour_org_opportunities_link"]',
      content: () => (
        <p>
          Here you can post a new job or see your previous job posts and
          candidates.
        </p>
      ),
      canInteract: true,
    },
  ],
  ORGJOBS: [
    {
      selector: '[data-tut="tour_post_job_btn"]',
      content: `Click here to post a new job vacancy and discover the best candidates from Rubiqe, our AI and Data Science community.`,
      canInteract: true,
    },
  ],
  POST_JOB: [
    {
      selector: '[data-tut="tour_post_job_form"]',
      content: `Tell us about the requirements for your job posting here.`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_post_job_smar_skill"]',
      content: () => (
        <p>
          You can ask job applicants to take a Smart Skills Assessment, when
          applying for a job.
          <br />
          <br />
          The results of this assessment will help you to make sure that the
          candidates traits are in alignment with your organizations culture.
        </p>
      ),
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_post_job_required_skills"]',
      content: () => (
        <p>
          Select the required skills for this job.
          <br />
          <br />
          Max, our AI assistant will recommend candidates with the highest
          matching scores for your job posting.
        </p>
      ),
      canInteract: false,
    },
    {
      selector: "",
      content: `After posting a job, Max, the AI, will show you some suitable matches. Match (score) is formed based on your job post required skills, and the candidates skill levels.`,
      canInteract: true,
    },
    {
      selector: "",
      content: () => (
        <p>
          And here are the definition of levels and medals:
          <br />
          <b>Level 1:</b> a self-claimed skill.
          <br />
          <b>Level 2:</b> passing the first assessment with a 70% to 90% score.
          <br />
          <b>Level 3:</b> passing the first assessment with a +90% score.
          <br />
          Then applicants can take the 2nd assessment, which is certification
          assessment.
          <br />
          <b>Level4:</b> +70% in the 2nd assessment.
          <br />
          In level4, they can achieve medals based on their scores:
          <br />
          <b>Level 4, Bronze:</b> 70%-80% in the 2nd assessment.
          <br />
          <b>Level 4, Silver:</b> 80%-90% in the 2nd assessment.
          <br />
          <b>Level 4, Gold:</b> +90% in the 2nd assessment.
        </p>
      ),
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_messages_link"]',
      content: `Click here to see your chat history or send new messages.`,
      canInteract: true,
    },
  ],
  AFTER_JOB_POST: [
    {
      selector: '[data-tut=""]',
      content: `Suitable matches are shown here. Max, our AI assistant, has discovered these high potential candidates for you - based on their skills and certifications.`,
      canInteract: false,
    },
    {
      selector: '[data-tut=""]',
      content: `These are candidates who applied for your job posting.`,
      canInteract: false,
    },
    {
      selector: '[data-tut=""]',
      content: `click to see more details`,
      canInteract: true,
    },
  ],
  POSTED_JOB_DETAILS: [
    {
      selector: '[data-tut=""]',
      content: `By clicking on Max’ recommendations, or job applicant, you can view their professional profile`,
      canInteract: true,
    },
  ],
  APPLICANT_VISIT: [
    {
      selector: '[data-tut=""]',
      content: `An Applicant/Candidate skill level (LVL 1 - LVL5) demonstrates their depth of understanding and knowledge in a particular skill.`,
      canInteract: true,
    },
    {
      selector: '[data-tut=""]',
      content: () => (
        <p style={{ whiteSpace: "pre-wrap" }}>
          What do these Levels (LVL) mean to you ? Level 1 : The
          applicant/candidate has self-claimed a skill Level 2 : The
          applicant/candidate has taken a skill assessment and achieved a score
          of between XX% and XX% Level 3 : The applicant/candidate has taken a
          skill assessment and achieved a score of between XX% and XX% Level 4 :
          The applicant/candidate has taken a skill certification and attained a
          Bronze, Silver or Gold medal Level 5 : Not used at the moment.
        </p>
      ),
      canInteract: false,
    },
    {
      selector: '[data-tut=""]',
      content: `Applicants/Candidates with proven skills have passed certifications and achieved medals, which will be shown here.`,
      canInteract: false,
    },
    {
      selector: '[data-tut=""]',
      content: `the applicant/candidate is shortlisted, send them a message!`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_messages_link"]',
      content: `See your chat history or send new messages.`,
      canInteract: false,
    },
  ],
  ORG_MESSAGES: [
    {
      selector: "",
      content: `List of your all messages with Applicants will appear here.`,
      canInteract: false,
    },
    {
      selector: '[data-tut="tour_org_dashboard_link"]',
      content: `Click here to have an overall view of your activities.`,
      canInteract: true,
    },
  ],
  DASHBOARD: [
    {
      selector: '[data-tut="tour_dashboard_charts"]',
      content: `After Publishing a job/project, you can see the related stats here in your dashboard.`,
      canInteract: false,
      // last: true
    },
    {
      selector: '[data-tut="tour_settings_menu"]',
      content: `Click to open more options.`,
      canInteract: true,
      // last: true
    },
  ],
  ORG_INVITE: [
    {
      selector: '[data-tut=""]',
      content: `Don’t forget to invite your friends and colleagues.`,
      canInteract: false,
    },
  ],
};
