import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import React, { Component } from "react";
import AddProfileModal from "./AddProfileModal";
import CompareEmployees from "./CompareEmployees";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { Col, Container, Row } from 'reactstrap';
import { getSpecificJobsSeekerDetails } from '../../../../../../api/Organization/compareProfile.api';
import { showErrorNotification } from '../../../../../../shared/components/toastMessage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import LoadingIcon from 'mdi-react/LoadingIcon';

export class Overview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddProfileModalOpen: false,
      jobId:'',
      recommendation:[],
      JobsSeekerdata:[],
      recommendationDetails:[],
      jobSeekerIds: [],
      employeId:'',
      isFlag:true,
      isAddProfile: true
    };
  }

  componentDidMount() {
    let ids = window.location.pathname.split('/');
    let jobSeekerId = ids[ids.length - 1]
    let jobSeekerIds = [];
    jobSeekerIds.push(jobSeekerId);
    let jobId = ids[ids.length - 2]
    this.setState({
      jobSeekerId: jobSeekerId,
      jobId: jobId,
      jobSeekerIds: jobSeekerIds
    },() => {
      this.getJobsSeekerDetails(jobId,jobSeekerIds)
    })

  }

  toggleAddProfileModal = (id,isAddProfile) => {
    this.setState((prevState) => {
      return {
        isAddProfileModalOpen: !prevState.isAddProfileModalOpen,
        jobSeekerId:null,
        isAddProfile: isAddProfile
      };
    });
  };

  toggleAddProfileModalForRefresh = (id,isAddProfile) => {
    this.setState((prevState) => {
      return {
        isAddProfileModalOpen: !prevState.isAddProfileModalOpen,
        jobSeekerId:id,
        isAddProfile: isAddProfile
      };
    });
  }

  closeAddProfileModal = () => {
    this.setState((prevState) => {
      return {
        isAddProfileModalOpen: !prevState.isAddProfileModalOpen,
      }
    })
  }

  employeeDelete=(id)=>{
    const items = this.state.JobsSeekerdata.filter(item => item?.jobSeeker?.id !== id);
    let jobIds = [];
    for(let i=0;i<items.length;i++) {
      jobIds.push(items[i]?.jobSeeker?.id)
    }
    this.setState({ JobsSeekerdata: items, jobSeekerIds:jobIds });
  }

  compareJobSeekers = (selectedEmployees) => {
    this.getJobsSeekerDetails(this.state.jobId,selectedEmployees)
  }

  getJobsSeekerDetails = (jobId,jobSeekerIds) => {
    this.setState({
      JobsSeekerdata: [],
      isLoading:true
    })
    let params = new URLSearchParams();
    for(let i=0;i<jobSeekerIds.length;i++) {
      params.append('jobSeekerId',jobSeekerIds[i])
    }

    getSpecificJobsSeekerDetails(jobId,params).then((response) => {
      if (response && response.status === 200) {
        let jobSeekerIds = []
        for(let i=0;i<response.data.length;i++) {
          jobSeekerIds.push(response.data[i].jobSeeker.id);
        }
        this.setState({
          JobsSeekerdata: response.data,
          isLoading: false,
          jobSeekerIds: jobSeekerIds
        });
      }
    }).catch((error) => {
      console.log('getOrganizationDetails() error : ', error);
      showErrorNotification(error);
      this.setState({
        isLoading: false,
      });
    });
  };

  routeChange = () => {
    let path = `/organization/opportunities`;
    let { history } = this.props;
    history.push({
      pathname: path,
    });
  };


  render() {
    const {
      isAddProfileModalOpen,
      JobsSeekerdata,
    } = this.state;
    return (
        <Container>
            {this.state.isLoading ? (
                <div className="panel__refresh">
                  <LoadingIcon />
                </div>
             ) :
             null}
            <Row className="dashboard__container border-bottom-1 p-2" style={{borderBottom: '3px solid #d8dadb'}}>
              <Col md={6} className="assessment-header-mobile">
                <div className="dashboard__info">
                  <button
                     className="dashboard__info-icon mt-2 ml-2"
                     onClick={this.routeChange}
                  >
                    <ArrowLeftIcon size={'30'} />
                  </button>
                  <div className="dashboard__info-title">
                    <h3 className="page-title mt-2 assessmentDetailTitleMobile">
                      Compare profile
                    </h3>
                    <div className="mb-3 breadcrumbs">
                       <span className="bold">
                              Opportunities / Compare Profile
                       </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="compare-employees-overview">
              <div className="compare-employees-add-profile-container">
                <div
                  className="compare-employees-add-profile"
                  onClick={() => this.toggleAddProfileModal(this.state.jobSeekerId,true)}>
                  <PlusCircleOutlineIcon />
                  <span>Add Profile</span>
                </div>
              </div>

              <div className="compare-employees-data">
                <CompareEmployees
                    JobsSeekerdata={JobsSeekerdata}
                    employeeDelete={this.employeeDelete}
                    toggleAddProfileModal={this.toggleAddProfileModal}
                    toggleAddProfileModalForRefresh={this.toggleAddProfileModalForRefresh}
                />
              </div>
            <AddProfileModal
              isAddProfile={this.state.isAddProfile}
              isAddProfileModalOpen={isAddProfileModalOpen}
              jobSeekerId={this.state.jobSeekerId}
              jobId={this.state.jobId}
              selectedEmployees={this.state.jobSeekerIds}
              employeeDelete={this.employeeDelete}
              compareJobSeekers={this.compareJobSeekers}
              closeAddProfileModal={this.closeAddProfileModal}
            />
          </div>
        </Container>
    );
  }
}

export default connect((state) => ({ themeName: state.theme.className }))(
   withRouter(withTranslation("common")(Overview))
);
