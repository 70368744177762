import React, { Component, Fragment } from 'react';
import { getSuggestCountry } from '../../api/country.api';
import { getSuggestIndustry } from '../../api/industry.api';
import { showErrorNotification, showNotification } from '../components/toastMessage';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { registerOrganization } from '../../api/Individual/individual.organization.api';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { hookAuth0 } from '../components/auth/withAuth0';
import { switchOrg } from '../../api/profiles.api';


const employeeSizeOptions = [
   { value: '0', label: '1 - 100' },
   { value: '1', label: '101 - 1000' },
   { value: '2', label: '1001 - 10000' },
   { value: '3', label: '10000 +' },
];


class CreateOrgIndividualModal extends Component {
   constructor(props) {
      super(props);

      this.state = {
         industry: null,
         location: '',
         country: '',
         employeeSize: 0,
         description: '',
         profilePic: null,
         fileName: 'Upload Profile Photo',
         name: '',
         countries: [],
         industries: [],
      };
   }

   componentDidMount() {
      this.setState({ name: '' });
      this.getIndustriesByName({ target: { value: 'a' } });
      this.getCountriesByName({ target: { value: 'a' } });
      // this.props.dispatch(setTourConfig(tourEnum.O))
   }

   clearData = () => {
      let data = {
         industry: null,
         location: '',
         country: '',
         employeeSize: null,
         description: '',
         profilePic: null,
         fileName: 'Upload Profile Photo',
         name: '',
         phoneNumber: ''
      };
      this.setState({ ...data });
   };

   handleSelectChange = (value, field) => {
      let update = {};
      if (field === 'employeeSize') {
         update = { employeeSize: value };
      } else if (field === 'IndustryName') {
         update = { industry: value };
      } else {
         update = {};
      }
      this.setState({ ...update });
   };

   // Get country ID by name
   getCountriesByName = (event) => {
      const value = event.target.value;
      this.setState({
         loadingCountries: true,
      });
      getSuggestCountry(value || 'a')
         .then((res) => {
            if (res.status && res.data) {
               this.setState({
                  loadingCountries: false,
               });
               this.setState({
                  countries: res.data.map(({ name, ...rest }) => ({
                     name: name,
                     label: name,
                     value: name,
                     ...rest,
                  })),
               });
            } else {
               throw res;
            }
         })
         .catch((error) => {
            console.log('getSuggestCountry error :: ', error);
         });
   };

   getIndustriesByName = (event) => {
      const value = event.target.value;
      return getSuggestIndustry(value || 'a')
         .then((res) => {
            let industries =
               (res.data &&
                  res.data.length &&
                  res.data.map(({ name, id, ...rest }) => ({
                     name: name,
                     label: name,
                     value: id,
                     ...rest,
                  }))) ||
               [];
            this.setState({
               industries,
            });
         })
         .catch((error) => {
            console.log('getSuggestIndustry error :: ', error);
            showErrorNotification(error);
         });
   };

   validateAndInsertUserExperienceData = () => {
      const {
         industry,
         location,
         country,
         employeeSize,
         name,
      } = this.state;
      const { auth0 } = this.props;
      const profileDtls = {
         organizationName: name,
         location: location,
         countryId: country?.id,
         industryId: industry?.value,
         employeeSize: employeeSize,
         userId:   auth0.user.username,
         organizationTypeId : 2,
         userType : 1
      };


      if (
         profileDtls.employeeSize === null ||
         !profileDtls.countryId ||
         !profileDtls.organizationName ||
         // !profileDtls.story ||
         !profileDtls.location ||
         !profileDtls.industryId
         // !profileDtls.phoneNo
      ) {
         showErrorNotification({ message: 'All fields are required' });
         return false;
      }
      this.insertCompanyProfileDetails(profileDtls);
   };

   insertCompanyProfileDetails = (data) => {
      this.setState({
         loading: true,
      });
      registerOrganization(data,false)
         .then((res) => {
            this.setState({
               loading: false,
            });
            if(res && res.data) {
               // if(this.state.profilePic) {
               //    console.log("in if")
               //    this.handleFileUpload();
               // }
               // this.clearData();
               // this.props.toggle();
               this.switchOrganization(res.data.id) // function to call api to switch to organization
               showNotification('Company created successfully','primary')
            } else {
               this.clearData();
               this.props.toggle();
               showErrorNotification('Something went wrong');
            }
         })
         .catch((err) => {
            console.log('error in inserting company profile details ::', err);
            showErrorNotification(err);
            this.clearData();
            this.props.toggle();
            this.setState({
               loading: false,
            });
         });
   };

   switchOrganization = (orgId) => {
      this.setState({
         loading: true,
      });
      switchOrg(orgId).then(res => {
         this.clearData();
         this.props.toggle();
         this.setState({
            loading: false,
         });
         setTimeout(() => {
            if(res) {
               window.location.href = '/organization/profile';
            }
         },500)
      }).catch(err => {
         this.clearData();
         this.props.toggle();
         showErrorNotification(err)
         this.setState({
            loading: false,
         });
      })
   }

   // handleFileUpload = () => {
   //    let reader = new FileReader();
   //    let file = this.state.profilePic; //e.target.files[0];
   //    console.log("in if 1")
   //    reader.onloadend = () => {
   //       console.log("in if 2")
   //       const fileSize = file.size / 1024 / 1024;
   //       if (fileSize < appConfigs.consts.IMAGE_FILE_SIZE) {
   //          if (
   //             file.type === 'image/png' ||
   //             file.type === 'image/gif' ||
   //             file.type === 'image/jpeg'
   //          ) {
   //             let form = new FormData();
   //             form.append('profilePhoto', file);
   //             uploadProfileImage(form)
   //                .then((response) => {
   //                   this.clearData();
   //                   this.props.toggle();
   //                   showNotification(response.message, 'primary');
   //                   this.props.history.push('/b2cOrganization/profile');
   //                })
   //                .catch((err) => {
   //                   console.error('uploadProfileImage error :: ', err);
   //                   showNotification(err.message);
   //                });
   //          } else {
   //             showNotification('Invalid File Type.');
   //          }
   //       } else {
   //          showNotification('File size must be less than 1 MB');
   //       }
   //    };
   //    if (file && file.type.match('image.*')) {
   //       reader.readAsDataURL(file);
   //       // reader.readAsDataURL(file);
   //    }
   // };

   setProfilePicture = (e) => {
      const file = e.target.files[0];
      this.setState({
         profilePic: file,
         fileName: file.name,
      });
   };

   render() {
      let { isOpen } = this.props;
      let {
         industry,
         location,
         country,
         name,
         countries,
         industries,
      } = this.state;
      return (
         <Fragment>
            <Modal isOpen={isOpen} data-tut={'tour_complete_org_modal'}>
               {this.state.loading ? (
                  <div className="panel__refresh" style={{justifyContent: 'center', display: 'flex',width:' 95%'}}>
                     <LoadingIcon />
                  </div>
               ) : null}
               <ModalHeader
                  toggle={() => {
                     this.clearData();
                     this.props.toggle();
                  }}
               >
                  <div className={'exp-modal-header'}>Create Company</div>
               </ModalHeader>
               <ModalBody>
                  <Col md={12}>
                     <Row>
                        <TextField
                           variant={'outlined'}
                           size={'small'}
                           label={'Company Name'}
                           type={'text'}
                           name="name"
                           value={name}
                           onChange={(e) =>
                              this.setState({ name: e.target.value })
                           }
                           placeholder="Company Name"
                           // disabled
                        />
                     </Row>

                     <Row>
                        <Autocomplete
                           id="combo-box-demo"
                           loading={this.state.loadingCountries}
                           loadingText={<>Loading</>}
                           options={industries}
                           disableClearable={true}
                           value={industry}
                           onChange={(event, value) =>
                              this.handleSelectChange(value, 'IndustryName')
                           }
                           getOptionLabel={(option) => option.label}
                           // style={{ width: 300 }}
                           className={'mt-3 w-100'}
                           renderInput={(params) => (
                              <TextField
                                 {...params}
                                 size={'small'}
                                 label="Industry"
                                 variant="outlined"
                                 color={'primary'}
                                 onChange={this.getIndustriesByName}
                              />
                           )}
                        />
                     </Row>
                     <Row style={{ marginTop: '15px' }}>
                        <TextField
                           variant={'outlined'}
                           size={'small'}
                           label={'Location'}
                           type="text"
                           name="Location"
                           className="orgprofile__input "
                           id="Location"
                           value={location}
                           onChange={(e) =>
                              this.setState({ location: e.target.value })
                           }
                           placeholder="Location"
                        />
                     </Row>

                     <Row>
                        <Autocomplete
                           id="combo-box-demo"
                           loading={this.state.loadingCountries}
                           loadingText={<>Loading</>}
                           options={countries}
                           disableClearable={true}
                           value={country}
                           onChange={(event, value) =>
                              this.setState({
                                 country: value,
                              })
                           }
                           getOptionLabel={(option) => option.name}
                           // style={{ width: 300 }}
                           className={'mt-3 w-100'}
                           renderInput={(params) => (
                              <TextField
                                 {...params}
                                 size={'small'}
                                 label="Country"
                                 variant="outlined"
                                 color={'primary'}
                                 onChange={this.getCountriesByName}
                              />
                           )}
                        />
                     </Row>
                     <Row>
                        <div className="w-100">
                           <FormControl
                              hiddenLabel={false}
                              variant="outlined"
                              size={'small'}
                              label={'Company Size'}
                              className={'mt-3'}
                           >
                              <InputLabel id="demo-simple-select-outlined-label">
                                 Company Size
                              </InputLabel>
                              <Select
                                 autoWidth
                                 labelId="demo-simple-select-outlined-label"
                                 id="demo-simple-select-outlined"
                                 defaultValue={0}
                                 onChange={(e) =>
                                    this.handleSelectChange(
                                       e.target.value,
                                       'employeeSize'
                                    )
                                 }
                                 label="Company Size"
                              >
                                 {employeeSizeOptions.map((item, index) => {
                                    return (
                                       <MenuItem
                                          // selected={index == 0}
                                          value={item.value}
                                       >
                                          {' '}
                                          {item.label}
                                       </MenuItem>
                                    );
                                 })}
                              </Select>
                           </FormControl>
                        </div>
                     </Row>
                     {/*<Row style={{ marginTop: '15px' }}>*/}
                     {/*   <TextField*/}
                     {/*      variant={'outlined'}*/}
                     {/*      size={'small'}*/}
                     {/*      label={'Phone Number'}*/}
                     {/*      type="text"*/}
                     {/*      name="phoneNumber"*/}
                     {/*      className="orgprofile__input "*/}
                     {/*      id="phoneNumber"*/}
                     {/*      value={phoneNumber}*/}
                     {/*      onChange={(e) =>*/}
                     {/*         this.setState({ phoneNumber: e.target.value })*/}
                     {/*      }*/}
                     {/*      placeholder="Phone Number"*/}
                     {/*   />*/}
                     {/*</Row>*/}
                     {/*<Row>*/}
                     {/*   <TextField*/}
                     {/*      variant={'outlined'}*/}
                     {/*      size={'small'}*/}
                     {/*      multiline*/}
                     {/*      label={'Description'}*/}
                     {/*      className={'mt-3'}*/}
                     {/*      name="description"*/}
                     {/*      type={'textarea'}*/}
                     {/*      value={description}*/}
                     {/*      onChange={(e) =>*/}
                     {/*         this.setState({ description: e.target.value })*/}
                     {/*      }*/}
                     {/*      placeholder={'Description'}*/}
                     {/*   />*/}
                     {/*</Row>*/}

                     {/*<Row>*/}
                     {/*   <label className="file mt-3 w-100">*/}
                     {/*      <input*/}
                     {/*         type="file"*/}
                     {/*         id="file"*/}
                     {/*         aria-label="File browser"*/}
                     {/*         onChange={this.setProfilePicture}*/}
                     {/*      />*/}
                     {/*      <span className="file-custom"> {fileName}*/}
                     {/*         <UploadIcon />*/}
                     {/*      </span>*/}
                     {/*   </label>*/}
                     {/*</Row>*/}
                  </Col>

                  <Row className={'mt-4'}>
                     <Col md={'12'} className={'d-flex'}>
                        <Col md={'8'} />
                        <Col md={'4'} style={{ 'padding-left': '56px' }}>
                           <Button
                              size={'md'}
                              style={{ color: 'white' }}
                              className={
                                 'btn btn-primary btn-round  mr-1 post-button m-0'
                              }
                              disabled={this.state.loading}
                              onClick={this.validateAndInsertUserExperienceData}
                           >
                              Create
                           </Button>
                        </Col>
                     </Col>
                  </Row>
               </ModalBody>
            </Modal>
         </Fragment>
      );
   }
}


export default withRouter(
   connect((state) => ({
      user: state.user
   }))(hookAuth0(CreateOrgIndividualModal))
);
