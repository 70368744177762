import { request } from '../shared/helpers/RequestWrapper';
import appConfigs from '../config/appConfigs';
const API_HOST = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;
const NOMINATION_FORM_INSERT = '/jobseeker/insertNomination';
const GET_USER_CAMPAIGNS = '/userDetails/getCurrentUserCampaignDetails';
const GET_NOMINATION_SUBMISSION = '/userDetails/getUserNominationSubmission';

export function submitNomination(data) {
   return request({
      url: API_HOST + NOMINATION_FORM_INSERT,
      method: 'POST',
      body: data
   });
}

export function getUserCampaigns() {
   return request({
      url: API_HOST + GET_USER_CAMPAIGNS,
      method: 'GET'
   })
}
export function getUserNominationSubmitted(campaign) {
   return request({
      url: API_HOST + GET_NOMINATION_SUBMISSION,
      method: 'GET',
      params: {campaign}
   })
}