import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import Dashboard from '../../../B2COrganization/Dashboard';
import JobApplicantDetails from '../../../B2COrganization/JobApplicantDetails';
// import Opportunities from '../../../B2COrganization/Opportunities';
// import Profile from '../../../B2COrganization/Profile';
import PostJobProject from '../../../B2COrganization/PostJobProject';
import CompareEmployee from '../../../B2COrganization/Opportunities/newComponents/CompareEmployee';

const Dashboard = lazy(() => import('../../../Pages/Organization/Dashboard'));
const Profile = lazy(() => import('../../../Pages/Organization/Profile'));
const Opportunities = lazy(() =>
   import('../../../Pages/Organization/Opportunities')
);
const Competition = lazy(() =>
   import('../../../Pages/Organization/Competition')
);
const HostCompetition = lazy(() =>
   import('../../../Pages/Organization/Competition/HostCompetition')
);
const SearchBoard = lazy(() => import('../../../Dashboards/Search'));


const B2COrganizationRoutes = [
   {
      path: '/organization/dashboard',
      name: 'Dashboard',
      Component: Dashboard,
   },
   {
      path: '/organization/profile',
      name: 'Organisation Profile',
      Component: Profile,
   },
   {
      path: '/organization/opportunities',
      name: 'Opportunities',
      Component: Opportunities,
   },
   {
      path: '/organization/opportunities/job/applicantDetails/:jobSeekerId/:jobId',
      name: 'Job Applicant Details',
      Component: JobApplicantDetails,
   },
   {
      path: '/organization/opportunities/postjob',
      name: 'Post a Job',
      alias: 'Post New Job',
      Component: PostJobProject,
   },
   {
      path: '/organization/opportunities/postjob/:jobId',
      name: 'Post a Job',
      alias: 'Post New Job',
      Component: PostJobProject,
   },
   {
      path: '/organization/opportunities/postproject/:projectId',
      name: 'Post a Project',
      alias: 'Post New Project',
      Component: PostJobProject,
   },
   {
      path: '/organization/opportunities/postproject',
      name: 'Post a Project',
      alias: 'Post New Project',
      Component: PostJobProject,
   },
   {
      path: '/organization/opportunities/job/applicantDetails/:jobSeekerId/:jobId',
      name: 'Job Applicant Details',
      Component: JobApplicantDetails,
   },
   {
      path: '/organization/competition',
      name: 'Competitions',
      Component: Competition,
   },
   {
      path: '/organization/competition/host',
      name: 'Host',
      Component: HostCompetition,
   },
   {
      path: '/organization/competition/:id',
      name: 'Competitions',
      Component: Competition,
   },
   {
      path: '/organization/search',
      name: 'Organization Search',
      Component: SearchBoard
   },
   {
      path: '/organization/compare/:id/:id',
      name: 'Compare',
      Component: CompareEmployee
   }
];

export default () => (
   <Switch>
      <Redirect exact from="/organization" to="/organization/dashboard" />
      {B2COrganizationRoutes.map(({ path, name, Component }, key) => (
         <Route
            exact
            path={path}
            key={key}
            render={(props) => {
               const crumbs = B2COrganizationRoutes
                  // Get all routes that contain the current one.
                  .filter(({ path }) => props.match.path.includes(path))
                  // Swap out any dynamic routes with their param values.
                  // E.g. "/employee/:employeeId" will become "/employee/1"
                  .map(({ path, ...rest }) => ({
                     path: Object.keys(props.match.params).length
                        ? Object.keys(props.match.params).reduce(
                             (path, param) =>
                                path.replace(
                                   `:${param}`,
                                   props.match.params[param]
                                ),
                             path
                          )
                        : path,
                     ...rest,
                  }));
               // crumbs.map(({ name, path }) => console.log({ name, path }));
               return <Component crumbs={crumbs} {...props} />;
            }}
         />
      ))}
   </Switch>
);
