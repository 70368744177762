import appConfigs from '../../config/appConfigs';
import { request } from '../../shared/helpers/RequestWrapper';

// const API_BASE_SKILLSTREETS = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;
const API_ROOT_SKILLSTREETS = appConfigs.endpoint.SKILLSTREETS_ROOT;
const REGISTER_ORGANIZATION = '/public/registerOrganization';


export function registerOrganization(data,isB2B) {
   return request({
      url: API_ROOT_SKILLSTREETS + REGISTER_ORGANIZATION + '?isB2B='+isB2B ,
      method: 'POST',
      body: data,
   });
}
