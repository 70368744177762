import { request } from '../../shared/helpers/RequestWrapper';
import appConfigs from '../../config/appConfigs';

const API_BASE_SKILLSTREETS = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;
const API_BASE_TALENTSPY = appConfigs.endpoint.API_BASE_TALENTSPY;
const INSERT_JOBSEEKER_PREFERRED_SKILLS = '/preferredSkills/insert';
const DELETE_JOBSEEKER_REFERRED_SKILL = '/preferredSkills/deleteById';

const UPDATE_SKILL_STATUS = '/jobseeker/skills/insert';
const DELETE_USER_SKILL = '/jobseeker/skills/deleteById';

const GET_OTHER_SKILLS_SEARCH = '/otherSkillMst/getOtherSkillByBelongType';
const GET_OTHER_SKILLS = '/jobSeeker/otherSkill/getByJobSeekerId';
const GET_OTHER_SKILLS_V1 = '/jobSeeker/otherSkill/getByJobSeeker';
const INSERT_OTHER_SKILL = '/jobSeeker/otherSkill/insert';
const INSERT_OTHER_SKILL_V1 =
   '/jobSeeker/otherSkill/insertJobSeekerOtherSkillMst';
const DELETE_OTHER_SKILL = '/jobSeeker/otherSkill/deleteById';
const DELETE_OTHER_SKILL_V1 =
   '/jobSeeker/otherSkill/deleteJobSeekerOtherSkillMst';

const GET_SKILLS = 'getSkills.json';

const CREATE_USER_DEFINED_OTHER_SKILL = '/otherSkillMst/createUserDefinedOtherSkill';

export function insertPreferredSkills(skillId) {
   return request({
      url: API_BASE_SKILLSTREETS + INSERT_JOBSEEKER_PREFERRED_SKILLS,
      method: 'POST',
      body: JSON.stringify({
         skillId: skillId,
      }),
   });
}

export async function deleteJobseekerPrefferdSkills(id) {
   return await request({
      url: API_BASE_SKILLSTREETS + DELETE_JOBSEEKER_REFERRED_SKILL + '/' + id,
      method: 'DELETE',
   });
}

export async function deleteUserSkill(id) {
   return await request({
      url: API_BASE_SKILLSTREETS + DELETE_USER_SKILL + '/' + id,
      method: 'DELETE',
   });
}

export async function updateSkillStatus(id, status) {
   return await request({
      url: API_BASE_SKILLSTREETS + UPDATE_SKILL_STATUS,
      method: 'POST',
      body: JSON.stringify({
         // jobSeekerId: userId,
         skillId: id,
         status: status,
      }),
   });
}

export async function getOtherSkills() {
   return await request({
      url: API_BASE_SKILLSTREETS + GET_OTHER_SKILLS,
      method: 'GET',
   });
}

export async function getOtherSkillsV1(skillRelatedTo) {
   return await request({
      url:
         API_BASE_SKILLSTREETS +
         GET_OTHER_SKILLS_V1 +
         '?skillRelatedTo=' +
         skillRelatedTo,
      method: 'GET',
   });
}

export async function getOtherSKillsSearch(belongToId, otherSkillName) {
   return await request({
      url:
         API_BASE_SKILLSTREETS +
         GET_OTHER_SKILLS_SEARCH +
         '?belongToId=' +
         belongToId +
         '&otherSkillName=' +
         otherSkillName,
      method: 'GET',
   });
}

export async function deleteOtherSkills(id) {
   return await request({
      url: API_BASE_SKILLSTREETS + DELETE_OTHER_SKILL + '/' + id,
      method: 'DELETE',
   });
}

export async function deleteOtherSkillsV1(body) {
   return await request({
      url: API_BASE_SKILLSTREETS + DELETE_OTHER_SKILL_V1,
      method: 'DELETE',
      body: body,
   });
}

export async function insertOrUpdateOtherSkills(data) {
   return await request({
      url: API_BASE_SKILLSTREETS + INSERT_OTHER_SKILL,
      method: 'POST',
      body: JSON.stringify(data),
   });
}

export async function insertOrUpdateOtherSkillsV1(data) {
   return await request({
      url: API_BASE_SKILLSTREETS + INSERT_OTHER_SKILL_V1,
      method: 'POST',
      body: data,
   });
}

export async function createOtherSkillUserDefined(data) {
   return await request({
      url: API_BASE_SKILLSTREETS + CREATE_USER_DEFINED_OTHER_SKILL+'?otherSkillName='+data,
      method: 'POST'
   });
}

export function getSkillsCore(email) {
   return request({
      url: API_BASE_TALENTSPY + '/' + GET_SKILLS,
      method: 'POST',
      body: JSON.stringify({
         email: email,
      }),
   });
}
