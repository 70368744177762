import React, { Fragment, Component } from 'react';
import { Row, Col } from 'reactstrap';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { withRouter } from 'react-router';
import Breadcrumbs from '../../../../../shared/components/Breadcrumbs';
import { Avatar, InputBase } from '@material-ui/core';
import { Button } from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import appConfigs from '../../../../../config/appConfigs';
import { hookAuth0 } from '../../../../../shared/components/auth/withAuth0';
import { connect } from 'react-redux';
import { getEmployeeProfileSS } from '../../../../../api/Individual/employee.api';
import {
   getAllTeamMessages,
   getTeamMembersByTeamId,
} from '../../../../../api/Competition/competition.api';

let stompClient = null;
const dateFormatter = (date) => {
   let today = new Date(date);
   return (
      today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
   );
};

function getImageFromEmail(email, members) {
   let image = null;
   members.forEach((item) =>{ 
      if(item.userId === email){
         image = item.profilePhoto
      } 
   })
   return image;
}

class GroupChat extends Component {
   constructor(props) {
      super(props);

      this.state = { messages: [], teamMembers: [], dimensions: {}, currentUser: props.auth0.user };
   }

   handleResize = () => {
      this.setState({
         dimensions: {
            height: window.innerHeight,
            width: window.innerWidth,
         },
      });
   }

   componentWillUnmount() {
      window.removeEventListener('resize', () => {})
   }

   componentDidMount() {
      window.addEventListener('resize', this.handleResize);
      this.setState({
         dimensions: {
            height: window.innerHeight,
            width: window.innerWidth,
         },
      });
      const { pathname } = this.props.location;
      let paths = pathname.split('/');
      let id = paths[paths.length - 1];
      this.setState({
         teamId: id,
         
      });

      this.getTeamMembers(id);
      this.getAllMessages(id);

      getEmployeeProfileSS()
         .then((res) => {
            if (res.status && res.data) {
               this.setState({
                  currentUser: res.data.userDetails,
               });
            }
         })
         .catch((err) => console.log(err));

      const Stomp = require('stompjs');
      let SockJS = require('sockjs-client');
      SockJS = new SockJS(`${appConfigs.endpoint.SKILLSTREETS_ROOT}/public/ws`);
      stompClient = Stomp.over(SockJS);

      stompClient.connect(
         {},
         (connection) => {
            stompClient.subscribe(
               '/user/' + id + '/queue/messages',
               this.onMessageReceived
            );
            this.setState({
               stompClient,
            });
         },
         (error) => {
            console.log('stompClient ERROR :: ', error);
         }
      );
   }

   getAllMessages = (id) => {
      getAllTeamMessages(id).then((response) => {
         if (response && response.status) {
            this.setState({
               messages: response.data,
            });
         }
      });
   };

   getTeamMembers = (id) => {
      getTeamMembersByTeamId(id, 0, 20).then((response) => {
         if (response && response.status === 200) {
            this.setState({
               teamMembers: response.data.content.map(
                  (item) => item.memberInfo
               ),
            });
         }
      });
   };

   onMessageReceived = (message) => {
      const { messages} = this.state;
      let newMessage = JSON.parse(message.body)
      messages.push(newMessage)
      this.setState({
         messages
      })
   };

   sendMessage = () => {
      const {
         stompClient,
         teamId,
         newMessage,
         currentUser,
      } = this.state;
      const { auth0 } = this.props;

      if (newMessage.trim() !== '') {
         const message = {
            teamId: teamId,
            senderId: auth0.user.email,
            senderName: currentUser.fname + ' ' + currentUser.lname,
            content: newMessage,
            timestamp: new Date(),
         };

         stompClient.send('/app/chat/team', {}, JSON.stringify(message));
         // const newMessages = [...messages];
         // newMessages.push(message);
         this.setState({
            // messages: newMessages,
            newMessage: ''
         });
         // this.setMessages(newMessages);
      }
   };

   handleTextChange = (e) => {
      this.setState({
         [e.target.name]: e.target.value,
      });
   };

   componentDidUpdate() {
      let objDiv = document.getElementById('message_scroll');
      objDiv.scrollTop = objDiv.scrollHeight;
   }

   routeChange = () => {
      this.props.history.goBack()
   }

   render() {
      const { crumbs } = this.props;
      const { messages, currentUser, newMessage,  dimensions, teamMembers } = this.state;
      
      return (
         <Fragment>
            <Row className={'group-chat-page-title'}>
               <Col md={6}>
                  <div className="dashboard__info ml-2">
                     <button
                        className="dashboard__info-icon"
                        onClick={this.routeChange}
                     >
                        <ArrowLeftIcon size={'30'}  />
                     </button>
                     <div className="dashboard__info-title">
                        <h3 className="page-title">Group Chat</h3>
                        <Breadcrumbs
                           crumbs={crumbs} 
                           fieldValue={'asdasd' || null}
                        />
                     </div>
                  </div>
               </Col>
            </Row>
            <div>
               <div
                  id="message_scroll"
                  style={{
                     overflow: 'hidden auto',
                     maxHeight: dimensions.height - 260 + 'px',
                     marginBottom: '-2px',
                  }}
               >
                  {messages ? (
                     messages.map((msg, index) => (
                        <div className="inbox__email-body ml-0" key={index}>
                           <Row className="pl-4">
                              <Col xs={8} sm={8} md={11}>
                                 <div className={'grp-chat-sender'}>
                                    <div className={'grp-chat-sender-img'}>
                                       <Avatar
                                          className="grp-chat-pic"
                                          src={getImageFromEmail(msg.senderId, teamMembers)}
                                          alt="profile pic"
                                       />
                                    </div>
                                    <div className={'grp-chat-sender-content'}>
                                       {msg.senderId === currentUser.userId ? (
                                          <b>You</b>
                                       ) : (
                                          <b>{msg.senderName}</b>
                                       )}

                                       <b>
                                          <p>
                                             {msg.content.length > 0 &&
                                                msg.content
                                                   .split(' ')
                                                   .map((item) => {
                                                      if (
                                                         [
                                                            'https://',
                                                            'http://',
                                                            'www.',
                                                            '.com',
                                                            '.in',
                                                            '.co',
                                                         ].some((el) =>
                                                            item.includes(el)
                                                         )
                                                      ) {
                                                         return (
                                                            <span
                                                               style={{
                                                                  color: '#951B1E',
                                                               }}
                                                            >
                                                               <a
                                                                  href={item}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  style={{
                                                                     color: '#951B1E',
                                                                  }}
                                                               >
                                                                  {item}
                                                               </a>{' '}
                                                            </span>
                                                         );
                                                      } else {
                                                         return (
                                                            <span>{item} </span>
                                                         );
                                                      }
                                                   })}
                                          </p>
                                       </b>
                                    </div>
                                 </div>
                              </Col>
                              <Col xs={4} sm={4} md={1}>
                                 <div className={'email-date-formatter'}>
                                    {dateFormatter(msg.timestamp)}
                                 </div>
                              </Col>
                           </Row>
                        </div>
                     ))
                  ) : (
                     <div className={'empty-message'}>No messages</div>
                  )}
               </div>
               <div
                  className={
                     'messages-send-message-main__div assign-send-msg-div-bottom '
                  }
                  style={{
                     display: 'flex',
                  }}
               >
                  <Row
                     className={'messages-send-message__div'}
                     style={{
                        borderTop: '2px solid #D8DADB',
                        background: 'white',
                     }}
                  >
                     <Col md={11} xs={11} sm={11} className="p-0">
                        <InputBase
                           name="user_input"
                           placeholder="Write your message..."
                           value={newMessage}
                           name="newMessage"
                           onChange={this.handleTextChange}
                           onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                 this.sendMessage(newMessage);
                                 // setText('');
                              }
                           }}
                           className="inbox__email-reply-textarea"
                           // onFocus={onQuickReply}
                           inputProps={{ 'aria-label': 'naked' }}
                        />
                     </Col>
                     <Col
                        md={1}
                        xs={1}
                        sm={1}
                        style={{ textAlign: 'center' }}
                        className="p-0 perkeso_send_message_button_main"
                     >
                        <div style={{ right: '0%', marginBottom: '20px' }}>
                           <Button
                              size="sm"
                              color="primary"
                              className={'topbar_notification_icon_desktop'}
                              onClick={this.sendMessage}
                           >
                              Send Reply
                           </Button>
                           <SendIcon
                              className={'topbar_notification_icon_mobile mt-2'}
                              style={{ color: '#951B1E' }}
                              onClick={this.sendMessage}
                           />
                        </div>
                     </Col>
                  </Row>
               </div>
            </div>
         </Fragment>
      );
   }
}
export default connect((state) => ({
   currentUser: state.userProfile.currentUser,
}))(withRouter(hookAuth0(GroupChat)));
