import React, { Component } from 'react';
import SmartSkill from './SmartSkill';
import * as PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

class SmartSkillContainer extends Component {

   render() {
      let {
         color,
         level,
         value,
         title,
         description,
         id,
         isSelected,
      } = this.props;
      return (
         <Card className={"smart-skill-card"}>
            <CardBody className="smart-skill-container">
               <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                     this.props.setSelectedCard(id);
                  }}
               >
                  <div className="text-center">
                     <div className="smart-skill-circle-center">
                        <SmartSkill
                           color={color}
                           level={level}
                           value={value}
                           isSelected={isSelected}
                        />
                     </div>

                     <div className="smart-skill-text" style={{ color: color }}>
                        <b>{title}</b> <br />
                        {description}
                     </div>
                  </div>
               </a>
            </CardBody>
         </Card>
      );
   }
}

SmartSkillContainer.propTypes = {
   color: PropTypes.any,
   level: PropTypes.any,
   value: PropTypes.any,
   title: PropTypes.any,
   description: PropTypes.string,
   id: PropTypes.any,
};

SmartSkillContainer.defaultProps = { description: '' };

export default SmartSkillContainer;
