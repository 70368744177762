import appConfigs from "../config/appConfigs";
import { request } from "../shared/helpers/RequestWrapper";

const API_BASE_CADS_HEBE = appConfigs.endpoint.API_BASE_URL_CADS_HEBE;

const NOTIFICATIONS = API_BASE_CADS_HEBE + "/notifications";
const MARK_AS_READ = "requestmarkasreadnotification";
const MARK_ALL_AS_READ = "requestmarkasreadallnotifications";
const REMOVE_NOTIFICATION = "requestdeletenotification";
export function getNotifications(email, pageNo, pageSize) {
  return request({
    url: NOTIFICATIONS,
    method: "post",
    body: JSON.stringify({
      "email": email,
      "pageNumber": pageNo,
      "pageSize": pageSize
    })
  });
}

export function markNotificationAsRead(email, notificationId) {
  return request({
    url: NOTIFICATIONS + "/" + MARK_AS_READ,
    method: "post",
    body: JSON.stringify({
      "email": email,
      "notificationId": notificationId
    })
  });
}

/**
 * Mark As Read
 * <p>Currently this api is not used anywhere on the ui</p>
 * @param email
 * @param notificationId
 * @returns {Promise<AxiosResponse<T>>|{message, status}|*}
 */
export function markAsRead(email, notificationId) {
  return request({
    url: NOTIFICATIONS + "/" + MARK_AS_READ,
    method: "post",
    body: JSON.stringify({
      "email": email,
      "notificationId": notificationId
    })
  });
}

export function removeNotification(email, notificationId) {
  return request({
    url: NOTIFICATIONS + "/" + REMOVE_NOTIFICATION,
    method: "post",
    body: JSON.stringify({
      "email": email,
      "notificationId": notificationId
    })
  });
}


export function markAllNotificationAsRead(email) {
  return request({
    url: NOTIFICATIONS + "/" + MARK_ALL_AS_READ,
    method: "post",
    body: JSON.stringify({
      "email": email
    })
  });
}
