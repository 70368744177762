import React from 'react';
import Cookies from "universal-cookie";
import { hookAuth0 } from '../auth/withAuth0';
import appConfigs from '../../../config/appConfigs';

const cookie = new Cookies();
class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
        if(this.props.isError===false){
            window.location.href='/'
        }
    }

    logoutAll =  () => {
        cookie.remove("access_token", { domain: appConfigs.endpoint.BASE_COOKIE });
        cookie.remove("user_data", { domain: appConfigs.endpoint.BASE_COOKIE });
        cookie.remove("token_data", { domain: appConfigs.endpoint.BASE_COOKIE });
        window.location.href = appConfigs.endpoint.LOGIN_ROOT;
    }

    render() {
        return (
            <div className={"text-center"} style={{ height: '100vh', color: "#4e6174", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h4>Oh snap! The platform has not loaded correctly. <br />Here are a few basic troubleshooting steps to consider. If problems still persist, pls contact
                    <a href="#"> support@thecads.com</a>
                    <br /><div className="mt-3">Please try to login again <a href="javascript:void(0)" onClick={this.logoutAll}>click here</a></div>
                </h4>
            </div>
        )
    }
}

export default hookAuth0(Error);