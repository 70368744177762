import appConfigs from '../config/appConfigs';
import { request } from '../shared/helpers/RequestWrapper';

const API_HOST = appConfigs.endpoint.SKILLSTREETS_ROOT;
export const GET_INDIVIDUAL_PUBLIC_PROFILE = '/public/getSEOJobSeekerProfile';
export const GET_PUBLIC_JOBS = '/public/getAllPublicJobsList';
export const GET_PUBLIC_QUESTION_LIST = '/public/getQuestionList';
export const GET_PUBLIC_QUESTION_DETAILS = '/public/question';
export const GET_PUBLIC_QUESTION_ANSWER = '/public/questionAnswer';
export const GET_PUBLIC_QUESTION_ANSWER_FROM_SEARCH_VAL = '/public/getQuestionList/search';
export const GET_PUBLIC_COMPETITION_LIST = '/public/competition/all';
export const GET_PUBLIC_COMPETITION_DETAILS = '/public/competition';
export const GET_PUBLIC_COMPETITION_QUESTION_ANSWER_FROM_ID = '/public/competition/question';

export function getIndividualPubliProfile(id) {
   return request({
      url: API_HOST + GET_INDIVIDUAL_PUBLIC_PROFILE + '/' + id,
      method: 'get',
   });
}
export function getPublicJobs(type,search) {
   return request({
      url: API_HOST + GET_PUBLIC_JOBS + '/' + type,
      method: 'get',
      params: { pageNo: 0, pageSize: 50, search: search },
   });
}

// public Q&A apis
export function getPublicQuestions(params) {
   return request({
      url: API_HOST + GET_PUBLIC_QUESTION_LIST,
      method: 'GET',
      params,
   });
}

export function getPublicQuestionDetails(id) {
   return request({
      url: API_HOST + GET_PUBLIC_QUESTION_DETAILS + '/' + id,
      method: 'GET',
   });
}

export function getPublicAnswersById(id) {
   return request({
      url: API_HOST + GET_PUBLIC_QUESTION_ANSWER + '/' + id,
      method: 'GET',
   });
}

export function getPublicQuestionFromSearchVal(params) {
   return request({
      url: API_HOST + GET_PUBLIC_QUESTION_ANSWER_FROM_SEARCH_VAL,
      method: 'GET',
      params
   });
}

// public competition apis
export function getPublicCompetitionList(params) {
   return request({
      url: API_HOST + GET_PUBLIC_COMPETITION_LIST,
      method: 'GET',
      params,
   });
}

export function getPublicCompetitionDetails(id) {
   return request({
      url: API_HOST + GET_PUBLIC_COMPETITION_DETAILS + '/' + id,
      method: 'GET',
   });
}

export function getPublicCompetitionQuestionAnswer(id,params) {
   return request({
      url: API_HOST + GET_PUBLIC_COMPETITION_QUESTION_ANSWER_FROM_ID + '/' + id,
      method: 'GET',
      params
   });
}