import React from 'react';
import { Card } from 'reactstrap';
import InboxComponent from './InboxComponent';

const InboxCard = () => (
   <div style={{ width: '100%' }}>
      <Card data-tut={'tour_messages'}>
         <InboxComponent />
      </Card>
   </div>
);

export default InboxCard;
