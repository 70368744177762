/* eslint-disable react/no-array-index-key */
import React, {Fragment, PureComponent} from "react";
import {Collapse} from "reactstrap";
import BellOutlineIcon from "mdi-react/BellOutlineIcon";
import {showErrorNotification, showNotification,} from "../../../shared/components/toastMessage";
import {getNotifications, markAllNotificationAsRead,} from "../../../api/notification.api";
import {timeAgo} from "../../Dashboards/Notification/components/Notification";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";

class TopbarNotification extends PureComponent {
  state = {
    collapse: false,
    email: this.props.email,
    loading: false,
    notifications: [],
  };

  componentDidMount() {
    this.getNotifications();
    this.props.history.listen((location) => {
      this.getNotifications(); // for calling get notification when route changes so red dot displays
    });
  }

  toggle = () => {
    if (!this.state.collapse) this.getNotifications(true);
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  getNotifications = (showDot) => {
    this.setState({ loading: true });
    getNotifications(this.state.email, 0, 3)
      .then((res) => {
        if (res.returnStatus !== undefined && !res.returnStatus) throw res;
        this.setState({
          notifications: res.data.notificationList,
          // dot: !showDot,
          loading: false,
        });

        if (
          res.data &&
          res.data.notificationList &&
          res.data.notificationList.length > 0
        ) {
          const list = res.data.notificationList;
          for (let i in list) {
            if (list[i].isread) {
              this.setState({
                dot: !showDot,
              });
            }
          }
        } else {
          this.setState({
            dot: false,
          });
        }
      })
      .catch((err) => {
        console.error("ERROR:::: ", err);
        // showErrorNotification(err);
      });
  };

  markAllAsRead(email) {
    markAllNotificationAsRead(email)
      .then((res) => {
        if (res.status !== undefined && !res.status) {
          throw res;
        }
        this.getNotifications();
        showNotification("Marked all as read", "primary");
      })
      .catch((err) => {
        showErrorNotification(err);
        console.error("error while marking all notifications as read");
      });
  }

  redirectToNotification = (redirectionURL) => {
     if(redirectionURL && redirectionURL !== '') {
        this.toggle();
        setTimeout(() => {
           let array = redirectionURL.split('/');
           array.pop();
           let path = array.join("/");
           if(window.location.pathname === redirectionURL) {
              this.props.history.push('/individual');
              this.props.history.push(redirectionURL)
           } else if(window.location.pathname === path) {
              this.props.history.push('/individual');
              this.props.history.push(redirectionURL)
           } else {
              this.props.history.push(redirectionURL);
           }
        },500)

     } else {
        // this.toggle();
        // setTimeout(() => {
        //    this.props.history.push('/individual/profile');
        // },500)
     }
  }

  render() {
    const { email, collapse, loading, notifications } = this.state;
    return (
      <div className="topbar__collapse">
        <button
          className={`topbar__btn topbar_notification_icon_desktop topbar__btn--mail topbar__btn${
            notifications.filter((n) => !n.isread).length !== 0 ? "--new" : ""
          }`}
          type="button"
          onClick={this.toggle}
        >
          <BellOutlineIcon />
          <div className="topbar__btn-new-label">
            <div />
          </div>
        </button>


        {/*Bell icon to be displayed for mobile view*/}
        {/*<Link*/}
        {/*   className="topbar__collapse-link"*/}
        {/*   to="/notifications"*/}
        {/*   onClick={this.toggle}*/}
        {/*>*/}
        <Link
           className={`topbar__btn topbar_notification_icon_mobile topbar_notification_icon_padding topbar__btn--mail topbar__btn${
              notifications.filter((n) => !n.isread).length !== 0 ? "--new" : ""
              }`}
           to="/notifications"
           // type="button"
           // onClick={this.toggle}
        >
          <BellOutlineIcon />
          <div className="topbar__btn-new-label">
            <div />
          </div>
        </Link>
        {/*Bell icon to be displayed for mobile view ends*/}


        {collapse && (
          <button
            className="topbar__back"
            type="button"
            onClick={this.toggle}
          />
        )}
        <Collapse isOpen={collapse} className="topbar__collapse-content">
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">Notifications</p>
            <button
              className="topbar__collapse-button"
              type="button"
              onClick={() => this.markAllAsRead(email)}
            >
              Mark all as read
            </button>
          </div>
          {loading && (
            <div
              className={"topbar__collapse-item"}
              style={{ textAlign: "center" }}
            >
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path
                    fill="#2E485C"
                    d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                  />
                </svg>
              </div>
            </div>
          )}
          <Fragment>
            {notifications.length === 0 ? (
              <div style={{ textAlign: "center", paddingTop: "10px" }}>
                <p>You have no new notifications</p>
              </div>
            ) : (
              notifications.map((notification, index) => (
                <div className={(notification?.action && notification?.action !== '') ? "topbar__collapse-item notification_pointer" : "topbar__collapse-item"} key={index} onClick={() => this.redirectToNotification(notification.action)}>
                  <div className="topbar__collapse-img-wrap">
                    {/*<i className="notification-icon" />*/}
                    <BellOutlineIcon className={"notification-icon"} size={18} />
                  </div>
                  <span className="topbar__collapse-name">
                    <div
                      style={
                        notification.isread
                          ? { width: "200px", color: "#ababab" }
                          : { width: "200px" }
                      }
                    >
                      {notification.title}
                    </div>
                    <p
                      className="topbar__collapse-message"
                      style={
                        notification.isread
                          ? { width: "270px", color: "#ababab" }
                          : { width: "270px" }
                      }
                    >
                      {notification.subtitle}
                    </p>
                  </span>
                  <p className="topbar__collapse-date">
                    {timeAgo(
                      new Date(notification.notification_datetime * 1000)
                    )}
                  </p>
                </div>
              ))
            )}
          </Fragment>
          <Link
            className="topbar__collapse-link"
            to="/notifications"
            onClick={this.toggle}
          >
            See all notifications
          </Link>
        </Collapse>
      </div>
    );
  }
}

export default withRouter(TopbarNotification);
