import React, { Fragment } from 'react';
import { showErrorNotification, showNotification } from '../../../../shared/components/toastMessage';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import { getSkills } from '../../../../api/skills.api';
import ApplicantSkills from './ApplicantSkills';
import ApplicantOtherSkills from '../../../B2COrganization/JobApplicantDetails/components/ApplicantOtherSkills';
// import ApplicantCertifications from './ApplicantCertifications';
import ApplicantExperienceList from '../../../B2COrganization/JobApplicantDetails/components/ApplicantExperienceList';
import ReadMoreLessComponent from '../../../../shared/components/ReadMoreLessComponent';
import { getIndividualSmartSkillStatus } from '../../../../api/Individual/opportunities.api';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { getIndividualPubliProfile } from '../../../../api/public.api';
import { Helmet } from 'react-helmet';
import { hookAuth0 } from '../../../../shared/components/auth/withAuth0';
import { withRouter } from 'react-router';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

const profilePhotoDefault = `${process.env.PUBLIC_URL}/img/EmployeeProfile/profile.png`;

//TODO SEO Implementaion goes in this file.

class PublicProfileComponent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         contentClass: 'hidden',
         isProfileRefresh: true,
         fname: '',
         lname: '',
         email: '',
         profilePhoto: '',
         location: '',
         countryName: '',
         bio: '',
         experienceList: [],
         userSkills: [],
         skills: [],
         skillList: [],
         preferredSkills: [],
         selectedSkills: [],
         otherskillList: [],
         skills1: [],
         smartSkillStatus: 0,
         otherSkillStatus: 0,
         selfSelectedSkillStatus: 0,
         skillVerifiedStatus: 0,
         locationStatus: 0,
         bioStatus: 0,
         workExpStatus: 0,
         schoolExpStatus: 0,
         isOpen: false,
         messageValue: '',
         loading: false,
         skillCertificates: [],
         othersCertificates: [],
         jobSeekerId: 0,
         auth0: null
      };
   }

   componentDidMount() {
      let url = window.location.pathname;
      url = url.split('/');
      const jobSeekerId = url[url.length - 1];
      this.getJobSeekerDetailsForOrgById(jobSeekerId);
      const { auth0 } = this.props;
      this.setState({
         auth0:auth0
      })
      // this.getIndividualSmartSkillStatus();
   }

   componentWillReceiveProps(nextProps, nextContext) {
      const { auth0 } = nextProps;
      this.setState({
         auth0:auth0
      })
   }

   getAllSkills = () => {
      getSkills({})
         .then((response) => {
            this.setState(
               {
                  skills: response.data,
               },
               () => {
                  this.getUserSkills();
               }
            );
         })
         .catch((err) => {
            console.log('getAllSkills error:', err);
            showErrorNotification(err);
         });
   };

   getUserSkills = () => {
      let skillVerifiedStatus = false;

      let allSkills = this.state.skills;
      let userSkill = this.state.userSkills;
      let list = [];
      // console.log(parseInt(userSkill[j].level.splice(5,1)))
      for (let i in allSkills) {
         let status = false;
         for (let j in userSkill) {
            if (allSkills[i].id === userSkill[j].skill.id) {
               let obj = {};

               obj.id = userSkill[j].id;
               obj.skillId = userSkill[j].skillId;
               obj.name = allSkills[i].name;
               obj.status = userSkill[j].status;
               obj.level = userSkill[j].status
                  ? userSkill[j].level
                     ? parseInt(userSkill[j].level.split('').splice(5, 1))
                     : 0
                  : 0;
               obj.badgeLevel = userSkill[j].skillBadgeLevel
                  ? userSkill[j].skillBadgeLevel
                  : 0;
               list.push(obj);
               if (!skillVerifiedStatus) {
                  skillVerifiedStatus = userSkill[j].status
                     ? userSkill[j].levelValue > 1
                     : false;
               }
               status = true;
            }
         }
         if (!status) {
            let obj = {};
            obj.skillId = allSkills[i].id;
            obj.name = allSkills[i].name;
            obj.status = false;
            obj.level = 0;
            obj.badgeLevel = 0;
            list.push(obj);
         }
      }
      this.setState(
         {
            skills1: list,
         },
         () => {
            let list1 = this.state.skills1.slice(0, list.length / 2);
            let list2 = this.state.skills1.slice(list.length / 2, list.length);
            this.setState({
               skillList: [list1, list2],
            });
         }
      );
   };

   getIndividualSmartSkillStatus = () => {
      getIndividualSmartSkillStatus()
         .then((response) => {
            this.setState({ smartSkillStatus: 10 });
         })
         .catch((err) =>
            console.error('getIndividualSmartSkillStatus error :: ', err)
         );
   };
   setRemainingProfileStatus = () => {
      const { experienceList, userSkills, otherskillList } = this.state;

      if (experienceList.length) {
         this.setState({
            workExpStatus: experienceList.filter((wrkExp) => wrkExp.type === 0)
               .length
               ? 10
               : 0,
            schoolExpStatus: experienceList.filter(
               (schExp) => schExp.type === 1
            ).length
               ? 10
               : 0,
         });
      }
      if (otherskillList.length) {
         this.setState({ otherSkillStatus: 10 });
      }

      if (userSkills.length) {
         const skillVerifiedStatus = userSkills.filter(
            (usk) => usk.status && usk.levelValue > 1
         ).length
            ? 30
            : 0;
         this.setState({
            skillVerifiedStatus,
            selfSelectedSkillStatus: skillVerifiedStatus
               ? 10
               : userSkills.filter((usk) => usk.status && usk.levelValue === 1)
                    .length
               ? 10
               : 0,
         });
      }
   };

   getJobSeekerDetailsForOrgById(jobSeekerId) {
      getIndividualPubliProfile(jobSeekerId)
         .then((res) => {
            let data = res.data;
            //TODO Rendered Data is live here.
            document.title = `Profile | ${data.JobSeeker?.firstName} ${data.JobSeeker?.lastName}`;
            this.setState(
               {
                  profilePhoto: data.JobSeeker?.profilePhoto,
                  location: data.JobSeeker?.location,
                  countryName: data.JobSeeker?.countryName,
                  bio: data.JobSeeker?.bio,
                  fname: data.JobSeeker?.firstName,
                  lname: data.JobSeeker?.lastName,
                  jobSeekerId: jobSeekerId,
                  // email: data.JobSeeker?.userDetails?.userId,
                  experienceList: data.JobSeekerExperience
                     ? [...data.JobSeekerExperience]
                     : [],
                  userSkills: data.JobSeekerSkills ? data.JobSeekerSkills : [],
                  otherskillList: data.JobSeekerOtherSkills
                     ? data.JobSeekerOtherSkills
                     : [],
                  // bioStatus: data.jobSeeker.bio ? 10 : 0,
                  // locationStatus: data.jobSeeker.location ? 10 : 0,
                  skills: data.TotalSkill,
                  skillCertificates: data.JobSeekerCertificates,
                  othersCertificates: data.JobSeekerOtherCertificates,
               },
               () => {
                  this.getUserSkills();
                  // this.setRemainingProfileStatus();
               }
            );
            this.setState({ isProfileRefresh: false });
         })
         .catch((err) => {
            console.log('getSpecificJobsSeekerDetailsById error::', err);
            this.setState({ isProfileRefresh: false });
            showErrorNotification(err);
         });
   }

   renderProfilePhoto = (profilePhoto) => {
      return (
         <label htmlFor="file-upload">
            <button type="button" className="border-0 p-0 bg-transparent">
               <img
                  className="orgprofile__avatar-img"
                  style={{ height: '110px', width: '110px' }}
                  src={profilePhoto || profilePhotoDefault}
                  alt="avatar"
               />
            </button>
         </label>
      );
   };


   toggleList = () => {
      this.setState((prevState) => {
         return {
            isListOpen: !prevState.isListOpen,
         };
      });
   };

   setIsOpen = (isOpen) => {
      this.setState({
         isOpen,
         messageValue: '',
      });
   };

   sendMessage = () => {
      let user  = this.state.auth0?.user;
      const { fname, lname, messageValue,jobSeekerId } = this.state;
      if (messageValue.trim() !== '') {
         const message = {
            senderId: user.email,
            recipientId: jobSeekerId,
            senderName: user.firstName + ' ' + user.lastName,
            recipientName: fname + ' ' + lname,
            content: messageValue,
            timestamp: new Date(),
            isRecipientId:true,
         };
         this.props.stompClient.send('/app/chat', {}, JSON.stringify(message));
         //TODO: to check condition
         showNotification('Message sent successfully', 'primary');
      }
      this.setIsOpen(false);
   };

   handleMessageChange = (event) => {
      let messageValue = event.target.value;
      if( messageValue &&  messageValue.trim() !== '' && messageValue.length <= 1000 ) {
         this.setState({
            messageValue: event.target.value,
            descriptionError: false,
            requiredFieldMsg: 'This field is required'
         })
      } else if(messageValue === '') {
         this.setState({
            messageValue: event.target.value,
            descriptionError: false,
            requiredFieldMsg: 'This field is required'
         })
      } else {
         this.setState({
            descriptionError: true,
            requiredFieldMsg: 'Maximum only 5000 characters allowed',
         })
      }
   };

   render() {
      const {
         isProfileRefresh,
         profilePhoto,
         fname,
         lname,
         bio,
         location,
         jobSeekerId,
         countryName,
         skillList,
         otherskillList,
         experienceList,
         isListOpen,
         skillCertificates,
         othersCertificates,
         isOpen,
         messageValue,
         loading,
         auth0
      } = this.state;


      return (
         <Fragment>
            {process.env.REACT_APP_HOMEPAGE === 'https://rubiqe.cads.ai' ? (
               <Helmet>
                  <meta charSet="utf-8" data-react-helmet="true" />
                  <title>{`${fname} ${lname} | Rubiqe`}</title>
                  <meta
                     name="title"
                     content={`${fname} ${lname}`}
                     data-react-helmet="true"
                  />
                  <link
                     rel="canonical"
                     href={
                        process.env.REACT_APP_HOMEPAGE +
                        `/pro/${fname}-${lname}-${jobSeekerId}`
                     }
                     data-react-helmet="true"
                  />
                  <meta
                     name="description"
                     content={`View ${fname} ${lname}'s profile on Rubiqe, the largest data professional community.`}
                     data-react-helmet="true"
                  />
                  <meta
                     property="og:title"
                     content={`${fname} ${lname} | Rubiqe`}
                     data-react-helmet="true"
                  />
                  <meta
                     name="og:description"
                     content={`View ${fname} ${lname}'s profile on Rubiqe, the largest data professional community.`}
                     data-react-helmet="true"
                  />
                  <meta
                     property="og:image"
                     content={profilePhoto}
                     data-react-helmet="true"
                  />
                  <meta
                     property="twitter:title"
                     content={`${fname} ${lname} | Rubiqe`}
                     data-react-helmet="true"
                  />
                  <meta
                     property="twitter:image"
                     content={profilePhoto}
                     data-react-helmet="true"
                  />
                  <meta
                     property="twitter:desccription"
                     content={`${fname} ${lname} | Rubiqe`}
                     data-react-helmet="true"
                  />
               </Helmet>
            ) : (
               ''
            )}
            <Modal
               className="send-message_modal_rounded"
               size={'lg'}
               contentClassName={'p-4'}
               isOpen={isOpen}
               toggle={() => this.setIsOpen(!isOpen)}
            >
               <ModalHeader
                  className={'p-0 send-message_modal_header'}
                  toggle={() => this.setIsOpen(!isOpen)}
               >
                  <h4>
                     <b>Send Message</b>
                  </h4>
               </ModalHeader>
               <ModalBody className={'p-0'}>
                  {loading ? (
                     <div className="panel__refresh">
                        <LoadingIcon style={{ marginLeft: '46%' }} />
                     </div>
                  ) : null}
                  <Row className={'mt-4'}>
                     <Col md={'12'}>
                        <p className="mt-1 mb-2 send-message_modal_para">
                           Send a message to{' '}
                           <i>
                              {fname} {lname}
                           </i>
                        </p>
                        <div className="input-icons">
                           <Input
                              type={'textarea'}
                              rows={8}
                              className="input-field"
                              placeholder={'Type your message'}
                              value={messageValue}
                              onChange={this.handleMessageChange}
                           />
                           {this.state.descriptionError &&
                           <p className={'mt-0 mb-0'} style={{color: 'red'}}>{this.state.requiredFieldMsg}</p>
                           }
                        </div>
                     </Col>
                  </Row>
                  {/*{window.location.href.includes('public-profile') && (*/}
                     <Row style={{ justifyContent: 'center' }}>
                        <Button
                           disabled={!this.state.messageValue}
                           // disabled={true}
                           className="mb-0 mt-1"
                           color={'primary'}
                           style={{ padding: '5px 10px', cursor: 'pointer' }}
                           onClick={this.sendMessage}
                        >
                           Send Message
                        </Button>
                     </Row>
                  {/*)}*/}
               </ModalBody>
            </Modal>
            {isProfileRefresh ? (
               <div className="panel__refresh">
                  <LoadingIcon />
               </div>
            ) : null}
            <Row>
               <Col md={4} className="text-center">
                  <Row>
                     <Col md={4}>
                        <label htmlFor="file-upload" className="mb-0">
                           {this.renderProfilePhoto(profilePhoto)}
                        </label>
                     </Col>
                     <Col md={8}>
                        <div className="applicant-profile_personal_details">
                           <div className="d-inline">
                              <h3 className="orgprofile-name">
                                 {' '}
                                 {`${fname} ${lname}`}
                              </h3>
                           </div>
                           <div
                              className="d-inline orgprofile__basic-body font-weight-normal"
                              style={{ lineHeight: '3.6em' }}
                           >
                              {location && countryName
                                 ? `${location},${countryName}`
                                 : `-`}
                           </div>
                        </div>
                     </Col>
                  </Row>
               </Col>
               <Col md={6} className="d-flex profile-bio-field ">
                  {/*<div className={'vertical-line'} />*/}
                  <div
                     id="bio"
                     className="applicant-profile__bio justify d-flex"
                  >
                     <ReadMoreLessComponent
                        story={bio ? bio : `-`}
                        limit={200}
                        fontSize={'16px'}
                     />
                  </div>
               </Col>
               <Col md={2} className="m-auto d-flex">
                  {auth0?.user &&
                     <div className="d-flex align-items-center">
                        <Button
                           size={'sm'}
                           color="white"
                           className="btn btn-primary w-auto m-0"
                           onClick={() => this.setIsOpen(!isOpen)}
                        >
                           Send Message
                        </Button>
                     </div>
                  }
               </Col>
            </Row>
            <Row className={'container mt-3'}>
               <Col md={8} lg={8} sm={12} xs={12}>
                  <Row>
                     <Col md={12} lg={12} sm={12} xs={12}>
                        <h4 className={'employee-certifications__title ml-0'}>
                           <b>My Skills</b>
                        </h4>
                     </Col>
                  </Row>
                  <Row className="p-0 m-0 w-100">
                     <Col md={8} lg={8} sm={8} xs={12} className="p-0">
                        <div className={'card-body p-0 skills-card-body'}>
                           <div
                              className={` individual__skills_list ${
                                 isListOpen
                                    ? `individual__skills_list_open`
                                    : `individual__skills_list_close`
                              }`}
                           >
                              <div
                                 className={'d-flex'}
                                 data-tut={'tour_user_skills'}
                              >
                                 <div>
                                    <div className={'skill-title'}>
                                       Data literacy skills I have
                                    </div>
                                 </div>
                              </div>
                              <div className={'row mt-2'}>
                                 {skillList.map((item) => {
                                    return (
                                       <div className={'col-md-6 p-0'}>
                                          {item.map((skill, index1) => (
                                             <ApplicantSkills
                                                index={index1}
                                                skill={skill}
                                             />
                                          ))}
                                       </div>
                                    );
                                 })}
                              </div>
                              <div
                                 className={`view_all__button`}
                                 onClick={() => this.toggleList()}
                              >
                                 {isListOpen ? (
                                    <>
                                       View Less
                                       <ChevronUpIcon
                                          size={'28'}
                                          className="individual__skills_list__arrow"
                                       />
                                    </>
                                 ) : (
                                    <>
                                       View All
                                       <ChevronDownIcon
                                          size={'28'}
                                          className="individual__skills_list__arrow"
                                       />
                                    </>
                                 )}
                              </div>
                           </div>
                           <div className={'skill-legends'}>
                              <div className={'d-flex mt-3 ml-4'}>
                                 <span
                                    className={'skill-claimed-badge mr-2'}
                                    title={'Self-Claim'}
                                 >
                                    T
                                 </span>
                                 <span className={'badge-title'}>
                                    Self-Claim:
                                 </span>
                                 <span className={'legend-text'}>
                                    Click on Skill
                                 </span>
                              </div>
                              <div className={'d-flex mt-3 ml-4'}>
                                 <span
                                    className={'skill-completed-badge mr-2'}
                                    title={'Badge'}
                                 />
                                 <span className={'badge-title'}>Badge:</span>
                                 <span className={'legend-text'}>
                                    Score 30% or more in Skill Quiz
                                 </span>
                              </div>
                              <div className={'d-flex mt-3 ml-4'}>
                                 <span
                                    className={'skill-certified-badge mr-2'}
                                    title={'Certification'}
                                 />
                                 <span className={'badge-title'}>
                                    Certificate:
                                 </span>
                                 <span className={'legend-text'}>
                                    Score 70% or more in Certification Quiz.
                                    This assessment will be unlocked after your first try of Skill Quiz.
                                 </span>
                              </div>
                           </div>
                        </div>
                     </Col>
                     <Col
                        md={4}
                        lg={4}
                        sm={8}
                        xs={12}
                        style={{ height: '100%' }}
                     >
                        <div className="card-body h-auto">
                           <div className={'d-flex'}>
                              <b className={'mb-3'}>Other skills I have</b>
                           </div>
                           <Row>
                              {otherskillList.map((otherskill, index) => (
                                 <div className={'col-md-12 p-0 d-flex'}>
                                    <ApplicantOtherSkills
                                       index={index}
                                       skill={otherskill}
                                    />
                                 </div>
                              ))}
                              {otherskillList.length === 0 && (
                                 <div className={'mt-3 col-md-12 text-center'}>
                                    <p align={'left'}>No other skills added</p>
                                 </div>
                              )}
                           </Row>
                        </div>
                     </Col>
                  </Row>
               </Col>
               <Col md={4} lg={4} sm={8} xs={12}>
                  <Row>
                     <Col md={12} lg={12} sm={12} xs={12}>
                        <h4 className={'employee-certifications__title ml-0'}>
                           <b>Certifications Completed</b>
                        </h4>
                        <div>
                           <div className={'employee-certifications'}>
                              {skillCertificates.length === 0 &&
                                 othersCertificates.length === 0 && (
                                    <p className={'pl-3 mt-3'}>
                                       User have not completed any data,
                                       analytics, or AI skill certification by
                                       CADS.
                                    </p>
                                 )}
                              {skillCertificates.map((item) => (
                                 <div
                                    className={'employee-certifications__block'}
                                 >
                                    <img
                                       className={
                                          'employee-certifications__skill-small'
                                       }
                                       src={item.skillsImage}
                                       // src={
                                       //   "https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/module-images/HBS_Competing_on_business_analytics_and_Bigdata.png"
                                       // }
                                       alt={'Skill Certification LOGO'}
                                    />
                                 </div>
                              ))}
                              {othersCertificates.map((item) => (
                                 <div
                                    className={'employee-certifications__block'}
                                    // onClick={() =>
                                    //    this.getCertificateLink(item.certificate)
                                    // }
                                 >
                                    <img
                                       className={
                                          'employee-certifications__skill-small'
                                       }
                                       src={item.skillsImage}
                                       // src={
                                       //   "https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/module-images/HBS_Competing_on_business_analytics_and_Bigdata.png"
                                       // }
                                       alt={'Skill Certification LOGO'}
                                    />
                                 </div>
                              ))}
                           </div>
                        </div>
                     </Col>
                  </Row>
               </Col>
            </Row>
            <Row className="container mt-3">
               <Col>
                  <Row>
                     <Col md={12} lg={12} sm={12} xs={12}>
                        <h4 className="employee-certifications__title d-inline-block ml-0">
                           <b>Experience</b>
                        </h4>
                     </Col>
                  </Row>

                  {experienceList.map((experience, index) => (
                     <Row className={'w-100'}>
                        <ApplicantExperienceList
                           index={index}
                           experience={experience}
                        />
                     </Row>
                  ))}
                  {experienceList.length === 0 && (
                     <div className={'mt-3 col-md-12 text-center'}>
                        User had not added any experiences yet.
                     </div>
                  )}
               </Col>
            </Row>
         </Fragment>
      );
   }
}

// export default withRouter(hookAuth0(PublicProfileComponent));

// export default compose(
//     withTranslation('common'),
//     connect((state) => ({
//        rtl: state.rtl,
//        stompClient: state.messages.stompClient,
//     }))
// )(hookAuth0(ApplicantProfile));

export default compose(
    withTranslation('common'),
    connect((state) => ({
       rtl: state.rtl,
       stompClient: state.messages.stompClient,
    }))
)(withRouter(hookAuth0(PublicProfileComponent)));
