import React from "react";
import Overview from "./../CompareEmployee/components/Overview";
import PropTypes from 'prop-types';
import { RTLProps } from '../../../../../shared/prop-types/ReducerProps';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const CompareEmployee = (props) => {
  return <Overview props={props} />;
};


CompareEmployee.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(withTranslation("common")(CompareEmployee));

