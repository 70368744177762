import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import OpportunitiesTabs from './components/OpportunitiesTabs';

const Opportunities = ({ t, rtl }) => (
	<Container className='dashboard p-0'>
		<Row>
			<OpportunitiesTabs dir={rtl.direction} />
		</Row>
	</Container>
);

Opportunities.propTypes = {
	t: PropTypes.func.isRequired,
	rtl: RTLProps.isRequired,
};

export default connect((state) => ({
	rtl: state.rtl,
}))(withTranslation('common')(Opportunities));
