import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const EmailsControls = ({
   onChangeSelectAll,
   partialChecked,
   allChecked,
   isChecked,
   handleMarkAsReadClick,
   handleMarkAsUnReadClick,
   handleDeleteContactClick,
   totalContact,
   contactIdList,
}) => (
   <div className="inbox__emails-controls-wrap">
      <div className="inbox__emails-controls">

         {/*To display mobile view*/}
         <span className={"topbar_notification_icon_mobile"} style={{width:"100%"}}>
            <Container>
                 <Row className={"mb-3"}>
                    <Col sm={2} md={2} xs={2} xl={2}>
                     <label
                        className="checkbox-btn checkbox-btn--colored-click inbox__emails-control-checkbox"
                        htmlFor="checkbox"
                        style={{marginLeft:"20%"}}
                     >
                        <Checkbox
                           indeterminate={partialChecked || (partialChecked && !isChecked)}
                           checked={isChecked && !partialChecked}
                           onChange={onChangeSelectAll}
                           className="messages_list__checkbox_header"
                           color="primary"
                           inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                     </label>
                    </Col>
                    <Col sm={7} md={7} xs={7} xl={7}>
                     <span className={'mt-3'}>
                        <label className={"pt-1"}>
                           {isChecked || partialChecked ? (
                              <>
                                 {partialChecked
                                    ? contactIdList.length
                                    : totalContact.length}{' '}
                                 Messages selected
                              </>
                           ) : (
                              <>{totalContact.length} Messages</>
                           )}
                        </label>
                     </span>
                    </Col>
                 </Row>

               {(partialChecked || allChecked ) &&
                  <Row className={"mb-3 mt-3"}>
                     <Col sm={12} md={12} xs={12} xl={12} className={"align-three-btn-center"}>
                        <div
                           // className={'inbox__emails-controls-right'}
                             style={{textAlign:"center",right:"unset",top:"unset"}}>

                              <div
                                 className="inbox__emails_controls__delete mr-1"
                                 style={{float:"left"}}
                                 onClick={handleDeleteContactClick}
                              >
                                 Delete{' '}
                                 {partialChecked
                                    ? contactIdList.length
                                    : isChecked
                                       ? totalContact.length
                                       : 0}{' '}
                                 Messages
                              </div>
                              &nbsp;&nbsp;
                              <div
                                 style={{float:"left"}}
                                 className="inbox__emails_controls__other mr-1"
                                 onClick={handleMarkAsReadClick}
                              >
                                 Mark Read
                              </div>
                              &nbsp;&nbsp;
                              <div
                                 style={{float:"left"}}
                                 className="inbox__emails_controls__other"
                                 onClick={handleMarkAsUnReadClick}
                              >
                                 Mark Unread
                              </div>
                        </div>
                     </Col>
                  </Row>

               }
            </Container>
         </span>

         {/*To display desktop view*/}
         <span className={"topbar_notification_icon_desktop"}>
            <label
               className="checkbox-btn checkbox-btn--colored-click inbox__emails-control-checkbox"
               htmlFor="checkbox" style={{marginLeft: "46px"}}
            >
               <Checkbox
                  indeterminate={partialChecked || (partialChecked && !isChecked)}
                  checked={isChecked && !partialChecked}
                  onChange={onChangeSelectAll}
                  className="messages_list__checkbox_header"
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
               />
            </label>
            <span className={'mt-1 messages-count-title'}>
               <label className={"mt-1"}>
                  {isChecked || partialChecked ? (
                     <>
                        {partialChecked
                           ? contactIdList.length
                           : totalContact.length}{' '}
                        Messages selected
                     </>
                  ) : (
                     <>{totalContact.length} Messages</>
                  )}
               </label>
            </span>

            <div className={'inbox__emails-controls-right'}>
               <div
                  className="inbox__emails_controls__delete"
                  onClick={handleDeleteContactClick}
               >
                  Delete{' '}
                  {partialChecked
                     ? contactIdList.length
                     : isChecked
                     ? totalContact.length
                     : 0}{' '}
                  Messages
               </div>
               &nbsp;&nbsp;
               <div
                  className="inbox__emails_controls__other"
                  onClick={handleMarkAsReadClick}
               >
                  Mark as Read
               </div>
               &nbsp;&nbsp;
               <div
                  className="inbox__emails_controls__other"
                  onClick={handleMarkAsUnReadClick}
               >
                  Mark as Unread
               </div>
            </div>
         </span>
      </div>
   </div>
);

EmailsControls.propTypes = {
   onChangeSelectAll: PropTypes.func.isRequired,
   handleMarkAllReadClick: PropTypes.func.isRequired,
   handleMarkAsUnReadClick: PropTypes.func.isRequired,
   handleDeleteContactClick: PropTypes.func.isRequired,
   totalContact: PropTypes.number,
};

export default EmailsControls;
