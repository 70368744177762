import appConfigs from "../config/appConfigs";
import {
    request
} from "../shared/helpers/RequestWrapper";

const API_HOST = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;
export const SKILL_URL = "/skill";
export const SKILL_GET_ALL_URL = SKILL_URL + "/getAll";
const GET_OTHER_SKILLS_SEARCH = "/otherSkillMst/getOtherSkillByBelongType";
const DELETE_OTHER_SKILL = "/jobSeeker/otherSkill/deleteJobSeekerOtherSkillMst";
const INSERT_OTHER_SKILL = "/jobSeeker/otherSkill/insertJobSeekerOtherSkillMst";
const GET_OTHER_SKILLS = "/jobSeeker/otherSkill/getByJobSeeker";

export function getOtherSKills(skillRelatedTo) {
    return request({
      url: API_HOST + GET_OTHER_SKILLS + "?skillRelatedTo=" + skillRelatedTo,
      method: "GET"
    });
  }

export function insertOtherSkillV1(body) {
    return request({
      url: API_HOST + INSERT_OTHER_SKILL,
      method: "POST",
      body: body
    });
  }

export function deleteOtherSkillV1(body) {
    return request({
      url: API_HOST + DELETE_OTHER_SKILL,
      method: "DELETE",
      body: body
    });
  }

export function getSkills(params) {
    if (!params.pageNo) {
        params.pageNo = 0
    }
    if (!params.pageSize) {
        params.pageSize = 50
    }
    return request({
        url: API_HOST + SKILL_GET_ALL_URL,
        method: "get",
        params
    });
}

export function getOtherSKillsSearch(belongToId, otherSkillName) {
    return request({
      url:
        API_HOST +
        GET_OTHER_SKILLS_SEARCH +
        "?belongToId=" +
        belongToId +
        "&otherSkillName=" +
        otherSkillName,
      method: "GET"
    });
  }