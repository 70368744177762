import {SET_CURRENT_ORG_USER, SET_CURRENT_USER_DETAILS, SET_CURRENT_USER_GENERIC_DETAILS} from "../actions/userAction";


const initialState = {
  currentUser: {},
  user: {},
  orgUser: {},

};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER_DETAILS:
      return {
        ...state,
        user: action.user
      };

    case SET_CURRENT_USER_GENERIC_DETAILS:
      return {
        ...state,
        currentUser: action.currentUser
      };

    case SET_CURRENT_ORG_USER:
      return {
        ...state,
        orgUser: action.orgUser
      };

    default:
      return state;
  }
};

export default userReducer;
