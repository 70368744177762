import React from 'react';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { withRouter } from 'react-router';
import { switchOrg } from '../../api/profiles.api';
import Avatar from '@material-ui/core/Avatar';
import Cookie from 'universal-cookie';
import appConfigs from '../../config/appConfigs';
const cookie = new Cookie();

const NavbarItem = (props) => {
   const { users, showOptions, toggleShowOptions } = props;

   function switchProfile(user) {
      cookie.remove('last_profile');
      cookie.remove('subdomain');
      cookie.remove('orgid');
      if (user.admin) {
         switchOrg(user.orgDetail.organization.id).then((res) => {
            if (res) {
               // props.history.push('/organization/profile')
               if (user.rubiqe) {
                  window.location.href = '/organization/profile';
                  cookie.set('last_profile', 'rubiqe_organization', {
                     domain: appConfigs.endpoint.COOKIE_DOMAIN,
                  });
                  cookie.set('subdomain', appConfigs.endpoint.TS_SUBDOMAIN, {
                     domain: appConfigs.endpoint.COOKIE_DOMAIN,
                  });
                  cookie.set('orgid', user.orgDetail.organization.id, {
                     domain: appConfigs.endpoint.COOKIE_DOMAIN,
                  });
               } else if (user.acceltic) {
                  cookie.set('last_profile', 'acceltic_organization', {
                     domain: appConfigs.endpoint.COOKIE_DOMAIN,
                  });
                  cookie.set('subdomain', user.subDomainName, {
                     domain: appConfigs.endpoint.COOKIE_DOMAIN,
                  });
                  cookie.set('orgid', user.orgDetail.organization.id, {
                     domain: appConfigs.endpoint.COOKIE_DOMAIN,
                  });
                  window.location.href =
                     appConfigs.endpoint.ACCELTIC_ROOT + '/organization';
               }
            }
         });
      } else {
         // props.history.push('/individual/profile')
         switchOrg(user.orgDetail.organization.id).then((res) => {
            if (user.rubiqe) {
               window.location.href = '/individual/profile';
               cookie.set('last_profile', 'rubiqe_individual', {
                  domain: appConfigs.endpoint.COOKIE_DOMAIN,
               });
               cookie.set('subdomain', appConfigs.endpoint.TS_SUBDOMAIN, {
                  domain: appConfigs.endpoint.COOKIE_DOMAIN,
               });
               cookie.set('orgid', user.orgDetail.organization.id, {
                  domain: appConfigs.endpoint.COOKIE_DOMAIN,
               });
            } else if (user.acceltic) {
               cookie.set('last_profile', 'acceltic_individual', {
                  domain: appConfigs.endpoint.COOKIE_DOMAIN,
               });
               cookie.set('subdomain', user.subDomainName, {
                  domain: appConfigs.endpoint.COOKIE_DOMAIN,
               });
               cookie.set('orgid', user.orgDetail.organization.id, {
                  domain: appConfigs.endpoint.COOKIE_DOMAIN,
               });
               window.location.href =
                  appConfigs.endpoint.ACCELTIC_ROOT + '/individual';
            }
         });
      }
   }

   return (
      <>
         {showOptions && (
            <div
               className="modal-backdrop fade show"
               onClick={toggleShowOptions}
            ></div>
         )}
         {showOptions && <div className={'showDisplacementDiv'}></div>}
         <div
            className={
               showOptions ? 'nav-dropdown-box-show' : 'nav-dropdown-box'
            }
         >
            {users?.length > 0
               ? users?.map((user, index) => {
                    if (index === 0) {
                       return (
                          <div
                             className="nav-dropdown-box-inr first-box"
                             onClick={users?.length > 1 && toggleShowOptions}
                          >
                             <div className="dropdown_image">
                                {/*<div className="dropdown_image_inr">*/}
                                <Avatar
                                   className={'dropdown_image_inr'}
                                   src={
                                      user.admin
                                         ? user.orgDetail.profilePhoto
                                         : user.profilePhoto
                                   }
                                   alt="profile pic"
                                />

                                {/*</div>*/}
                             </div>
                             <div className="nav-dropdown-box-content">
                                <label>
                                   {user.admin ? (
                                      <>{user.orgDetail.organization.name}</>
                                   ) : (
                                      <>
                                         {user.fname} {user.lname}
                                      </>
                                   )}
                                   {/*<span>{user.des}</span>*/}
                                </label>
                                {user.rubiqe && (
                                   <p>{`Rubiqe ${
                                      user.admin ? 'organization' : 'individual'
                                   }`}</p>
                                )}
                                {user.acceltic && (
                                   <p>{`${user.orgDetail.organization.name} ${
                                      user.admin ? 'organization' : 'individual'
                                   }`}</p>
                                )}
                             </div>
                             {/*<div className={'ml-auto'}>*/}
                             {/*   {users?.length > 1 && (*/}
                             {/*      <ChevronDownIcon*/}
                             {/*         className={*/}
                             {/*            showOptions ? 'rotateIcon' : ''*/}
                             {/*         }*/}
                             {/*         size={20}*/}
                             {/*      />*/}
                             {/*   )}*/}
                             {/*</div>*/}
                          </div>
                       );
                    }
                 })
               : ''}
            <div className={showOptions ? 'drop_box' : 'drop_box hide'}>
               {users?.length > 0
                  ? users?.map((user, index) => {
                       if (index !== 0) {
                          return (
                             <div
                                className="nav-dropdown-box-inr"
                                onClick={() => switchProfile(user)}
                             >
                                <div className="dropdown_image">
                                   <Avatar
                                      className={'dropdown_image_inr'}
                                      src={
                                         user.admin
                                            ? user.orgDetail.profilePhoto
                                            : user.profilePhoto
                                      }
                                      alt="profile pic"
                                   />
                                </div>
                                <div className="nav-dropdown-box-content">
                                   <label>
                                      {user.admin ? (
                                         <>{user.orgDetail.organization.name}</>
                                      ) : (
                                         <>
                                            {user.fname} {user.lname}
                                         </>
                                      )}
                                      {/*<span>{user.des}</span>*/}
                                   </label>
                                   {user.rubiqe && (
                                      <p>{`Access Rubiqe ${
                                         user.admin
                                            ? 'organization'
                                            : 'individual'
                                      }`}</p>
                                   )}
                                   {user.acceltic && (
                                      <p
                                         className={'light_green'}
                                      >{`Switch to ${user.orgDetail.organization.name} ${
                                         user.admin
                                            ? 'organization'
                                            : 'individual'
                                      }`}</p>
                                   )}
                                </div>
                             </div>
                          );
                       }
                    })
                  : ''}
            </div>
         </div>
      </>
   );
};

export default withRouter(NavbarItem);
