// import mixpanel from "mixpanel-browser";
var mixpanel = require('mixpanel-browser');
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

export let Mixpanel = mixpanel;
const isLocal = window && window.location && window.location.hostname && window.location.hostname.includes('localhost');
export const trackEventMixpanel = (event, payload) => {
   if (!isLocal)
      Mixpanel.track(event, payload);
};

export const setUserIdentityMixpanel = (responseUser) => {
    if(!isLocal) {
        Mixpanel.identify(responseUser.email);
        Mixpanel.people.set({
            $email: responseUser.email,
            USER_ID: responseUser.username,
        });
    }
};
