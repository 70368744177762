import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Keycloak from 'keycloak-js';
import appConfigs from '../../../config/appConfigs';
import Cookie from 'universal-cookie';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';

import { setCookie, setUserCookie } from '../../helpers/token';
import {
   setUserIdentityMixpanel,
   trackEventMixpanel,
} from '../../helpers/Mixpanel';
import {
   getAllProfilesOfUser,
   getProfileAccess,
} from '../../../api/profiles.api';
import { setAccountProfiles1, setCurrentProfile } from '../../../redux/actions/accountActions';
import { campaignEnum } from '../../enums/campaign.enum';

const cookie = new Cookie();
let initOptions = {
   url: appConfigs.keycloak.URL,
   realm: appConfigs.keycloak.REALM,
   clientId: appConfigs.keycloak.CLIENT_ID,
   onLoad: appConfigs.keycloak.ONLOAD,
};

export const Auth0Context = React.createContext({});
export const useAuth0 = () => useContext(Auth0Context);
export const keycloak = Keycloak(initOptions);

export function hookAuth0(WrappedComponent) {
   return function HockComponent(props) {
      const auth0 = useAuth0();
      return <WrappedComponent {...props} auth0={auth0} />;
   };
}

function checkPublicRoute(href) {
   if (href.includes('public/')) {
      return true;
   }
}

const onRedirectCallbackForerror = (onRedirectCallback) => {
   // window.location.href="/error"
   onRedirectCallback({ pathname: '/error', iserror: true });
};

const Auth0Provider = ({ children, onRedirectCallback, ...initOptions }) => {
   const [isAuthenticated, setIsAuthenticated] = useState();
   const [user, setUser] = useState();
   const [auth0Client, setAuth0] = useState();
   const [loading, setLoading] = useState(true);
   const [popupOpen] = useState(false);
   const dispatch = useDispatch();

   useEffect(() => {
      initAuth0();
   }, []);

   const initAuth0 = async () => {
      // no need to check populate user data for public urls
      if(window.location.href.includes('/public')){
         return;
      }

      console.log("inside initAuth0 function")
      const user = cookie.get('user_data');
      console.log("inside initAuth0 function: getting cookie.user_data::",user )

      const access_token = cookie.get('access_token');
      console.log("inside initAuth0 function: getting cookie.access_token::",access_token )

      if (!user || !access_token) {
         console.log(
             "inside is user or access token is not present for initAuth0 function"
         )
         window.location.href = appConfigs.endpoint.LOGIN_ROOT;
      }

      const tokenInfo = cookie.get('token_data');

      setAuth0({ user: tokenInfo });
      localStorage.setItem(campaignEnum.TOP_25_OPENED, false);
      await setUser(tokenInfo);
      handleRedirection();
      let initOptions1 = { onLoad: 'check-sso' };
      // const response = await keycloak.init(initOptions1);
      // if (response) {
      //    await setTokenAndGetUser(response, keycloak);
      // } else {
      //    if (!checkPublicRoute(window.location.href)) {
      //       window.location.href =
      //          appConfigs.endpoint.APHRODITE_ROOT_UI + '/app#login';
      //    }
      // }
      setLoading(false);
   };

   const handleRedirection = () => {
      console.log("inside handle registration to call getallprofiles of user")
      getAllProfilesOfUser()
         .then((res) => {
            dispatch(setAccountProfiles1(res))
            setTimeout(() => {
               document.dispatchEvent(new CustomEvent('refreshProfiles'))
            }, 1000)

            let currentIndividual;
            let currentOrganization;
            let profiles = res.data;
            if (profiles.length > 0) {
               if(localStorage.getItem('tourCompleted') === 'yes'){
                  cookie.set('showTour', 'no', { domain: appConfigs.endpoint.BASE_COOKIE});
               } else {
                  localStorage.setItem('tourCompleted', "no");
                  cookie.set('showTour', 'yes', { domain: appConfigs.endpoint.BASE_COOKIE});
               }
               profiles.forEach((item) => {
                  if (item.current && item.rubiqe && item.admin) {
                     currentOrganization = item;
                     dispatch(setCurrentProfile(currentOrganization));
                     cookie.set('orgid', item.orgDetail.organization.id, {
                        domain: appConfigs.endpoint.BASE_COOKIE,
                     });
                     cookie.set('subdomain', appConfigs.endpoint.TS_SUBDOMAIN, {
                        domain: appConfigs.endpoint.BASE_COOKIE,
                     });
                     cookie.set('last_profile', 'rubiqe_organization', {
                        domain: appConfigs.endpoint.BASE_COOKIE,
                     });
                     onRedirectCallback({ pathname: '/organization' });
                  }
                  if (item.rubiqe && !item.admin && item.current) {
                     currentIndividual = item;
                     dispatch(setCurrentProfile(currentIndividual));
                     cookie.set('orgid', item.orgDetail.organization.id, {
                        domain: appConfigs.endpoint.BASE_COOKIE,
                     });
                     cookie.set('subdomain', appConfigs.endpoint.TS_SUBDOMAIN, {
                        domain: appConfigs.endpoint.BASE_COOKIE,
                     });
                     cookie.set('last_profile', 'rubiqe_individual', {
                        domain: appConfigs.endpoint.BASE_COOKIE,
                     });
                     onRedirectCallback({ pathname: '/individual' });
                  }
               });
               profiles.forEach((item) => {
                  if (item.acceltic && item.current) {
                     window.location.href = appConfigs.endpoint.ACCELTIC_ROOT;
                  }
               });


               getProfileAccess().then((response) => {

                  if (response && response.status === 200) {
                     let access = response.data;
                     let last_profile = cookie.get('last_profile');
                     // if (last_profile) {
                     //    if (
                     //       last_profile === 'rubiqe_individual' &&
                     //       access.rubiqeIndividual
                     //    ) {
                     //       dispatch(setCurrentProfile(currentIndividual))
                     //       cookie.set('subdomain', appConfigs.endpoint.TS_SUBDOMAIN, {
                     //          domain: appConfigs.endpoint.COOKIE_DOMAIN,
                     //       });
                     //       cookie.set(
                     //          'orgid',
                     //          currentIndividual.orgDetail.organization.id,
                     //          { domain: appConfigs.endpoint.COOKIE_DOMAIN }
                     //       );
                     //       onRedirectCallback({ pathname: '/individual' });
                     //    } else if (
                     //       last_profile === 'rubiqe_organization' &&
                     //       access.rubiqeOrganization
                     //    ) {
                     //       dispatch(setCurrentProfile(currentOrganization))
                     //       cookie.set(
                     //          'orgid',
                     //          currentOrganization.orgDetail.organization.id,
                     //          { domain: appConfigs.endpoint.COOKIE_DOMAIN }
                     //       );
                     //       onRedirectCallback({ pathname: '/organization' });
                     //    } else if (access.rubiqeIndividual) {
                     //       dispatch(setCurrentProfile(currentIndividual))
                     //       cookie.set('subdomain', appConfigs.endpoint.TS_SUBDOMAIN, {
                     //          domain: appConfigs.endpoint.COOKIE_DOMAIN,
                     //       });
                     //       cookie.set(
                     //          'orgid',
                     //          currentIndividual.orgDetail.organization.id,
                     //          { domain: appConfigs.endpoint.COOKIE_DOMAIN }
                     //       );
                     //       onRedirectCallback({ pathname: '/individual' });
                     //    }
                     // } else {
                     //    if (access.rubiqeIndividual) {
                     //       dispatch(setCurrentProfile(currentIndividual))
                     //       cookie.set(
                     //          'orgid',
                     //          currentIndividual.orgDetail.organization.id,
                     //          { domain: appConfigs.endpoint.COOKIE_DOMAIN }
                     //       );
                     //       cookie.set('subdomain', appConfigs.endpoint.TS_SUBDOMAIN, {
                     //          domain: appConfigs.endpoint.COOKIE_DOMAIN,
                     //       });
                     //       onRedirectCallback({ pathname: '/individual' });
                     //    } else {
                     //       window.location.href =
                     //          appConfigs.endpoint.APHRODITE_ROOT_UI;
                     //    }
                     // }
                  }
               });
            } else {
               onRedirectCallbackForerror(onRedirectCallback);
            }
         })
         .catch((err) => {
            onRedirectCallbackForerror(onRedirectCallback);
         });
   };

   const setTokenAndGetUser = async (response, keycloak) => {
      setIsAuthenticated(response);
      setCookie({ access_token: keycloak.token });
      const initTime = new Date();
      const responseUser = await keycloak.loadUserProfile();
      setUserIdentityMixpanel(responseUser);
      const finalTime = new Date();
      const secTime = (finalTime - initTime) / 1000;
      trackEventMixpanel('Successful login', {
         'Time elapsed(sec)': secTime,
      });
      // if (
      //    responseUser &&
      //    responseUser &&
      //    responseUser.user_type &&
      //    responseUser.user_type[0].includes('b2b')
      // ) {
      //    window.location.href = appConfigs.endpoint.APHRODITE_ROOT_UI;
      // }
      setUserCookie(responseUser);
      await setUser(keycloak.profile);
      handleRedirection();
   };

   const handleLogout = (props) => {
      cookie.remove('access_token', { domain: '.cads.ai' });
      cookie.remove('user_data', { domain: '.cads.ai' });
      window.location.href = appConfigs.endpoint.LOGIN_ROOT;
   };

   return (
      <Auth0Context.Provider
         value={{
            isAuthenticated,
            user,
            loading,
            popupOpen,
            // loginWithPopup,
            // handleRedirectCallback,
            getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
            loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
            getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
            getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
            logout: (...p) => handleLogout(...p),
         }}
      >
         {children}
      </Auth0Context.Provider>
   );
};

keycloak.onTokenExpired = () => {
   console.warn('token expired');
   keycloak
      .updateToken(30)
      .then(() => {
         console.info('successfully updated token');
         setCookie({ access_token: keycloak.token });
      })
      .catch(() => {
         console.error('Failed to update token');
         keycloak.logout();
         window.location.reload();
      });
};

Auth0Provider.propTypes = {
   children: PropTypes.node.isRequired,
   onRedirectCallback: PropTypes.func,
};

Auth0Provider.defaultProps = {
   onRedirectCallback: () =>
      window.history.replaceState({}, document.title, window.location.pathname),
};

// export default connect(Auth0Provider);
export default Auth0Provider;
