export const SET_CURRENT_USER_DETAILS = "SET_CURRENT_USER_DETAILS";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_CURRENT_USER_GENERIC_DETAILS = "SET_CURRENT_USER_GENERIC_DETAILS";
export const SET_CURRENT_ORG_USER = "SET_CURRENT_ORG_USER";


export function setCurrentOrgUserDetails(currrentOrg) {
  return {
    type: SET_CURRENT_ORG_USER,
    orgUser: { ...currrentOrg },
  }
}

export function setCurrentUserDetails(user) {
  console.log("SEETING CU", user)
  return {
    type: SET_CURRENT_USER_DETAILS,
    user
  };
}
export function setUserDetails(user) {
  return {
    type: SET_USER_DETAILS,
    user
  };
}

export function setCurrentUserGenericDetails(currentUser) {
  return {
    type: SET_CURRENT_USER_GENERIC_DETAILS,
    currentUser
  };
}
