import {

    SET_PROFILE_COMPLETION_DATA,

} from "../actions/profileCompletionAction";

const initialState = {
    profileCompletionData: {
        smartSkillStatus: 0,
        otherSkillStatus: 0,
        selfSelectedSkillStatus: 0,
        skillVerifiedStatus: 0,
        locationStatus: 0,
        bioStatus: 0,
        workExpStatus: 0,
        schoolExpStatus: 0,
    },
};

const profileCompletionReducer = (state = initialState, action) => {

    switch (action.type){
        case SET_PROFILE_COMPLETION_DATA:
            return {...state,profileCompletionData: action.profileCompletionData};
        default:
            return state;
    }

};
export default profileCompletionReducer;
