import appConfigs from '../../config/appConfigs';
import { request } from '../../shared/helpers/RequestWrapper';

const API_BASE_SKILLSTREETS = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;

const GET_ORGANIZATION_DETAILS = '/jobseeker/jobs/previewOrganizationDetails/';
const GET_OPEN_JOBS_OR_PROJECTS =
	'/jobseeker/jobs/previewOranizationPostedJobsOrProjects/';

export function getOrganizationDetails(orgID) {
	return request({
		url: API_BASE_SKILLSTREETS + GET_ORGANIZATION_DETAILS + orgID,
		method: 'GET',
	});
}

export function getOpenJobsOrProjects(orgID, type) {
	return request({
		url: API_BASE_SKILLSTREETS + GET_OPEN_JOBS_OR_PROJECTS + orgID + '/' + type,
		method: 'GET',
	});
}
