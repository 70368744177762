// import React from 'react';
import { apiResponseEnum } from '../enums';
import axios from 'axios';
import { setCookie, getAccessTokenCookie, getUserCookie } from './token';
import appConfigs from '../../config/appConfigs';
import Cookie from 'universal-cookie';
import store from '../../containers/App/store'
const cookie = new Cookie();

const subDomain = appConfigs.endpoint.TS_SUBDOMAIN;

export function setupAxios(axios) {
   axios.interceptors.request.use(
      (config) => {
         const accessToken = getAccessTokenCookie();
         const user = getUserCookie();
         // const domain = user && user.attributes.subdmain && user.attributes.subdmain[0].trim();
         const domain = cookie.get('subdomain') ?? 'b2cplatform';
         if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
         }
         //let orgid = cookie.get("orgid");
         const currentProfile = store.getState().accounts.currentProfile;
         config.headers.orgid = currentProfile?.orgDetail?.organization?.id;
         config.headers.isAccleticUser = false;
         if (window.location.href.includes('/individual')) {
            config.headers.organizationUser = false;
         } else if (window.location.href.includes('/organization')) {
            config.headers.organizationUser = true;
         }
         if (domain) {
            config.url = formatURL(config.url, domain);
         } else {
            config.url = formatURL(config.url, subDomain);
         }

         return config;
      },
      (err) => Promise.reject(err)
   );
   axios.interceptors.response.use(
      (response) => {
         if (response.config.headers && response.headers.authorization) {
            setCookie({
               access_token: response.headers.authorization,
            });
         }
         return response.data || [];
      },
      (error) => {
         console.error('error :: ', error);
         console.error('error :: ', error.response);
         let response = {
            status: false,
            message: apiResponseEnum.SOMETHING_WRONG,
         };
         if (error && error.response) {
            if (error && error.response && error.response.status === 401) {
               if (window.location.pathname.endsWith('/auth/login')) {
                  response = error.response.data
                     ? error.response.data
                     : response;
                  throw response;
               } else {
                  // window.location.href = process.env.PUBLIC_URL + "/";
               }
            } else if (
               error &&
               error.response &&
               error.response.status === 403
            ) {
               // handle403Page(appConfigs.AUTH_BASE_URL);
               // window.location.reload(true);
            } else if (
               error &&
               error.response &&
               error.response.status === 503
            ) {
               // handle403Page(appConfigs.AUTH_BASE_URL);
               // window.location.reload(true);
               window.location.href = '/503';
            } else if (error.response.data && error.response.data.message) {
               response = error.response.data ? error.response.data : response;
               throw response;
            } else {
               throw response;
            }
         } else if (
            error &&
            error.toString() &&
            error.toString().includes('Network Error')
         ) {
            // window.location.href = process.env.PUBLIC_URL + "/";
         } else if (
            error &&
            error.toString() &&
            error.toString().includes('Cancel')
         ) {
            response = error;
            throw response;
         } else {
            throw response;
         }
      }
   );
}

function formatURL(url, domain) {
   if (url.includes('TS_DOMAIN')) {
      return url.replace('TS_DOMAIN', domain);
   }
   return url;
}

export const request = (options) => {
   const config = {
      headers: { 'Content-Type': 'application/json' },
      url: options['url'],
      method: options['method'],
   };

   if (options['body']) {
      config['data'] = options['body'];
   }
   if (options['params']) {
      config['params'] = options['params'];
   }

   if (navigator.onLine) {
      return axios.request(config);
   } else {
      let response;
      response = {
         status: false,
         message: apiResponseEnum.INTERNET_DISCONNECTED,
      };
      return response;
   }
};
export const requestPdf = (options) => {
   const config = {
      headers: { 'Accept': 'application/pdf' },
      url: options['url'],
      method: options['method'],
      responseType: 'blob',
   };

   if (options['body']) {
      config['data'] = options['body'];
   }
   if (options['params']) {
      config['params'] = options['params'];
   }

   if (navigator.onLine) {
      return axios.request(config);
   } else {
      let response;
      response = {
         status: false,
         message: apiResponseEnum.INTERNET_DISCONNECTED,
      };
      return response;
   }
};
