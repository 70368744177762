export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_STOMP_CLIENT = 'SET_STOMP_CLIENT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_ACTIVE_CONTACT = 'SET_ACTIVE_CONTACT';
export const SET_UNREAD_MSG_COUNT = 'SET_UNREAD_MSG_COUNT';

export function setContacts(contacts) {
    return {
        type: SET_CONTACTS,
        contacts,
    };
}
export function setStompClient(stompClient) {
    return {
        type: SET_STOMP_CLIENT,
        stompClient,
    };
}
export function setCurrentUser(currentUser) {
    return {
        type: SET_CURRENT_USER,
        currentUser,
    };
}
export function setMessages(messages) {
    return {
        type: SET_MESSAGES,
        messages,
    };
}
export function setActiveContact(activeContact) {
    return {
        type: SET_ACTIVE_CONTACT,
        activeContact,
    };
}

export function setUnreadMessageCount(unreadMessageCount) {
    return {
        type: SET_UNREAD_MSG_COUNT,
        unreadMessageCount,
    };
}
