import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";
import React, { Fragment, useEffect, useState } from "react";
import { getOtherSKillsSearch } from "../../../../api/skills.api";

const JobOtherSkills = ({ otherSkills, addOtherSkill, removeOtherSkill }) => {
  const [isAdding, toggleAdd] = useState(false);
  const [otherSkillValue, handleOtherSkill] = useState("");
  const [skills, setSkills] = useState("");
  const [searchedOtherSkills, setOtherSkills] = useState([]);
  const [loadingSkills, setLoadingSkills] = useState(false);

  const cancelOtherSkill = () => {
    handleOtherSkill("");
    toggleAdd(!isAdding);
  };

  const handleAdd = () => {
    addOtherSkill(otherSkillValue);
    toggleAdd(!isAdding);
  };

  const removeSkill = (index) => {
    removeOtherSkill(index);
  };

  const handleSearchOtherSkill = (value = "a") => {
    setLoadingSkills(true);
    getOtherSKillsSearch(1, value)
      .then((response) => {
        setOtherSkills(response.data);
        setLoadingSkills(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingSkills(false);
      });
  };

  useEffect(() => {
    handleSearchOtherSkill();
  }, []);

  useEffect(() => {
    setSkills(otherSkills);
    setTimeout(() => {
      setSkills(otherSkills);
    }, 200);
  }, [otherSkills]);

  return (
    <Fragment>
      <div className={"d-flex"}>
        {skills &&
          skills.map((item, index) => (
            <div className="skill-level-component ml-0 mr-2">
              <span className={"skill-component__skill-name mr-1"}>{item}</span>
              <CloseIcon
                size={"14"}
                onClick={() => removeSkill(index)}
                style={{ margin: "auto", marginRight: "10px" }} 
              />
            </div>
          ))}
        {isAdding ? (
          <div
            className={"job-project__post__other-skill_add"}
            style={{ marginTop: "10px", width: "60%", display: "flex" }}>
            <Autocomplete
              className={"mr-1 w-100 orgprofile__input__other_skill"}
              loading={loadingSkills}
              loadingText={<>Loading</>}
              options={searchedOtherSkills}
              disableClearable={true}
              onChange={(event, value) => handleOtherSkill(value)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size={"small"}
                  label="Other Skills"
                  variant="outlined"
                  color={"primary"}
                  onChange={(e) => handleSearchOtherSkill(e.target.value)}
                />
              )}
            />
            <div
              className="orgprofile__input-button ml-2 d-flex"
              style={{ marginTop: "3px" }}>
              <button
                type="button"
                className="save-button mt-0"
                onClick={handleAdd}>
                <CheckIcon size={"24"} />
              </button>
              <button
                type="button"
                onClick={cancelOtherSkill}
                className="cancel-button mt-0">
                <CloseIcon size={"24"} />
              </button>
            </div>
          </div>
        ) : (
          <div
            className="skill-level-component ml-0"
            onClick={() => toggleAdd(!isAdding)}>
            <span className={"skill-component__skill-name"}>
              Add Other Skill
            </span>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default JobOtherSkills;
