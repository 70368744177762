import React from 'react';

import HeartIcon from 'mdi-react/HeartIcon';
import CoffeeIcon from 'mdi-react/CoffeeIcon';
import MedalIcon from 'mdi-react/MedalIcon';
import ImageFilterHdrIcon from 'mdi-react/ImageFilterHdrIcon';
import GamepadVariantIcon from 'mdi-react/GamepadVariantIcon';
import HeadCogIcon from 'mdi-react/HeadCogIcon';

const icons = [
	{
		key: 'heart',
		icon: <HeartIcon size={'32'} />,
	},
	{
		key: 'coffee',
		icon: <CoffeeIcon size={'32'} />,
	},
	{
		key: 'medal',
		icon: <MedalIcon size={'32'} />,
	},
	{
		key: 'image',
		icon: <ImageFilterHdrIcon size={'32'} />,
	},
	{
		key: 'game',
		icon: <GamepadVariantIcon size={'32'} />,
	},
	{
		key: 'head',
		icon: <HeadCogIcon size={'32'} />,
	},
];

const getWorkBenefitIcon = (key) => {
	return icons.map((item) => {
		if (item.key === key) {
			return item.icon;
		}
	});
};

export default getWorkBenefitIcon;
