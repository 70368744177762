import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { hookAuth0 } from '../../../shared/components/auth/withAuth0';
import {
   countNewMessages,
   findChatMessage,
   getNewChatMessageCount,
   getUsers,
} from '../../Mail/components/ApiUtil';
import appConfig from '../../../config/appConfigs';
import {
   setContacts,
   setCurrentUser,
   setMessages,
   setStompClient,
   setUnreadMessageCount,
} from '../../../redux/actions/messageAction';
import { connect } from 'react-redux';
import { getEmployeeProfileSS } from '../../../api/Individual/employee.api';
import { getOrganizationDetails } from '../../../api/Organization/users';
import { showNotification } from '../../../shared/components/toastMessage';
import { setCurrentOrgUserDetails } from '../../../redux/actions/userAction';

const SKILLSTREETS_ROOT = appConfig.endpoint.SKILLSTREETS_ROOT;

let stompClient = null;

class TopbarMessages extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         isClicked: false,
         newMessageCount: 0,
         unreadMessageCount: 0,
         currentUser: {
            id: props.auth0?.user?.email,
            name: '',
         },
      };
      // this.handleMessage = this.handleMessage.bind(this);
   }

   componentWillMount() {
      this.connect();
   }

   componentDidMount() {
      this.loadContacts();
   }

   connect = () => {
      const { auth0 } = this.props;
      const role = auth0?.user?.user_type?.[0];
      if (window.location.href.includes("/individual")) {
         // getEmployeeProfileSS().then((response) => {
         //    let currentUser = this.state.currentUser;
         //    currentUser.name =
         //       response.data && response.data.userDetails
         //          ? response.data.userDetails.fname +
         //            ' ' +
         //            response.data.userDetails.lname
         //          : currentUser.name;
         //    this.setupConnection(currentUser);
         // });
         let currentUser = this.state.currentUser;
         currentUser.name =
            this.props.user && this.props.user.name;
         this.setupConnection(currentUser);
      } else if (window.location.href.includes("/organization")) {
         getOrganizationDetails().then((response) => {
            if (response && response.status) {
               let currentUser = this.state.currentUser;
               currentUser.name = response?.data?.organizationDetails?.name;
               this.props.dispatch(setCurrentOrgUserDetails(currentUser));
               this.setupConnection(currentUser);
            }
         });
      }
   };

   setupConnection = (currentUser) => {
      this.props.dispatch(setCurrentUser(currentUser));
      this.setState({
         currentUser,
      });
      const Stomp = require('stompjs');
      let SockJS = require('sockjs-client');
      SockJS = new SockJS(`${SKILLSTREETS_ROOT}/public/ws`);
      stompClient = Stomp.over(SockJS);

      stompClient.connect(
         {},
         (connection) => {
            stompClient.subscribe(
               '/user/' + currentUser.id + '/queue/messages',
               this.onMessageReceived
            );
            this.props.dispatch(setStompClient(stompClient));
         },
         (error) => {
            console.log('ERROR :: ', error);
         }
      );
   };

   onMessageReceived = (newMessage) => {
      const { activeContact, messages } = this.props;
      const notification = JSON.parse(newMessage?.body);
      if (activeContact) {
         if (activeContact?.id === notification?.senderId) {
            findChatMessage(notification?.id).then((response) => {
               const newMessages = messages;
               newMessages.push(response?.data);
               this.props.dispatch(setMessages(newMessages));
            });
         } else {
            showNotification(
               'Received a new message from ' + notification?.senderName,
               'primary'
            );
         }
      } else {
         showNotification(
            'Received a new message from ' + notification?.senderName,
            'primary'
         );
         this.props.dispatch(
            setUnreadMessageCount(this.props?.unreadMessageCount + 1)
         );
      }
   };

   loadContacts = () => {
      getUsers().then((response) => {
         const users = response?.data || [];
         this.props.dispatch(setContacts(users));
         this.countMessages(users);
         this.getNewMessageCount();
      });
   };

   countMessages = (users) => {
      const { currentUser } = this.state;
      const { unreadMessageCount } = this.state;
      let count = unreadMessageCount;
      new Promise((resolve, reject) => {
         if (users?.hasOwnProperty(0)) {
            users.map(async (contact, index) => {
               let response = await countNewMessages(
                  contact?.id,
                  currentUser?.id
               );
               if (response?.data) {
                  count = count + response.data || 0;
               }
               if (index === users?.length) {
                  resolve(count);
               }
            });
         }
      }).then((msgCount) => {
         this.props.dispatch(setUnreadMessageCount(msgCount));
         this.setState({
            unreadMessageCount: msgCount,
         });
      });
   };

   // componentWillUnmount() {
   //   stompClient.disconnect()
   // }

   getNewMessageCount = () => {
      getNewChatMessageCount(this.props?.auth0?.user?.email).then(
         (newMessageCount) => {
            this.setState({
               newMessageCount: newMessageCount?.data,
            });
            this.props.dispatch(setUnreadMessageCount(newMessageCount?.data));
         }
      );
   };

   onVisited = () => {
      this.setState({
         isClicked: !this.state.isClicked,
      });
   };

   redirect = () => {
      this.props.history.push('/messages');
   };

   render() {
      return (
         <div className="topbar__collapse">
            <button
               // className={`topbar__btn topbar__btn--mail topbar__btn${unreadMessageCount !== 0 ? '--new' : ''}`}
               className={`topbar__btn topbar__btn--mail topbar__btn${
                  this.props?.unreadMessageCount > 0 ? '--new' : ''
               }`}
               type="button"
               onClick={this.toggle}
               data-tut={'tour_messages_link'}
            >
               <EmailOutlineIcon onClick={this.redirect} />
               <div className="topbar__btn-new-label">
                  <div />
               </div>
            </button>
         </div>
      );
   }
}
export default withRouter(
   connect((state) => ({
      customizer: state.customizer,
      sidebar: state.sidebar,
      theme: state.theme,
      rtl: state.rtl,
      user: state.user,
      messages: state.messages.messages,
      activeContact: state.messages.activeContact,
      contacts: state.messages.contacts,
      unreadMessageCount: state.messages.unreadMessageCount,
   }))(hookAuth0(TopbarMessages))
);
