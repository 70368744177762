import BookmarkBorderIcon from "mdi-react/BookmarkBorderIcon";
import React, {PureComponent} from "react";
import {Link} from "react-router-dom";
import {hookAuth0} from "../../../shared/components/auth/withAuth0";
import {AuthOProps, UserProps} from "../../../shared/prop-types/ReducerProps";

class TopbarSetting extends PureComponent {
    static propTypes = {
        user: UserProps.isRequired,
        auth0: AuthOProps.isRequired,
    };

    state = {
        isClicked: false,
    };

    onVisited = () => {
        this.setState({
            isClicked: !this.state.isClicked,
        });
    };

    render() {
        return (
            <div className="topbar__profile">
                <Link
                    className="topbar__btn topbar__btn--mail topbar__btn--new"
                    type="button"
                    to={`/individual/bookmarks`}
                    onClick={this.onVisited}
                >
                    <BookmarkBorderIcon />
                </Link>
            </div>
        );
    }
}

export default hookAuth0(TopbarSetting);
