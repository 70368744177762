import React, {Component, Fragment } from 'react';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import DeleteIcon from "mdi-react/DeleteIcon";
import FindReplaceIcon from "mdi-react/FindReplaceIcon";
import SmartSkillsRowDetails from './SmartSkillsRowDetails';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { getSmartSkillsdata } from '../../../../../../api/PerkesoUser/mySmartSkills.api';
import appConfigs from '../../../../../../config/appConfigs';
const DefaultProfileImage = `${process.env.PUBLIC_URL}/img/ava.png`;

const monthName =  [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

class CompareEmployees extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeColor: "blue",
            activeID: 0,
            selectedSkillID: 0,
            isFlag: false,
        };
    }

    handleToggleColor = (colorName, id) => {
        this.setState({
            activeColor: colorName,
            activeID: id - 1,
            selectedSkillID: 0
        });
    };

    handleSelectedSkill = (id) => {
        this.setState({
            selectedSkillID: id
        });
    };


     convertDateFromUTC = (utcDate) => {
         let dateObj = new Date(utcDate);
         let dateString = (monthName[dateObj.getMonth()]) + " " + dateObj.getFullYear();
         return dateString;
     }

    render() {
        const { JobsSeekerdata } = this.props;
        const {selectedSkillID,activeID,activeColor} = this.state;
        return (
            <React.Fragment>
                {this.state.isLoading ? (
                      <div className="panel__refresh">
                          <LoadingIcon />
                      </div>
                   ) :
                null}
                <Container>
                    <TableContainer className="compare-employees-parent compare-employees-profile-row">

                        {JobsSeekerdata.map((JobsSeekerdata,index)=>(
                            <>
                            <Table>
                                    <TableHead>
                                    <TableRow style={{backgroundColor:'#F4F4F4',color:"#525A60"}}>
                                        {index === 0 &&
                                        <TableCell><div className="compare-employees-cell compare-employees-cell-title compare-employees-profile-cell-title compare-employees-cell-fixed-column1">
                                            Profile
                                        </div></TableCell> }

                                        <TableCell className={'compare_employee_border'}>
                                            <div className="compare-employees-cell compare-employees-profile-cell" style={{minWidth: '280px', minHeight: '100px'}}>
                                                <div className="profile-photo">
                                                    <img src={JobsSeekerdata?.jobSeeker?.profilePhoto || DefaultProfileImage} alt="" />
                                                </div>
                                                <div className="info-container">
                                                    <div className="name">{JobsSeekerdata?.jobSeeker?.userDetails?.fname} {JobsSeekerdata?.jobSeeker?.userDetails?.lname}</div>
                                                    <div className="location">{JobsSeekerdata?.jobSeeker?.location} {JobsSeekerdata?.jobSeeker?.countryDetails?.name}</div>
                                                </div>
                                                <div className={`scope External`}>External</div>
                                                <div className="compare-employees-profile-action-buttons">
                                                    <div className="icon">
                                                        <DeleteIcon onClick={() => this.props.employeeDelete(JobsSeekerdata?.jobSeeker?.id)} />
                                                    </div>
                                                    <div className="icon">
                                                        <FindReplaceIcon onClick={() => this.props.toggleAddProfileModalForRefresh(JobsSeekerdata?.jobSeeker?.id,false)}  />
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        {index === 0 &&
                                        <TableCell className="compare-employees-parent compare-employees-data-literacy-skills-row">
                                            <div className="compare-employees-cell compare-employees-cell-title">
                                                Data Literacy Skills
                                            </div>
                                        </TableCell>}

                                        <TableCell className={'compare_employee_border '}>
                                            <div className="compare-employees-parent compare-employees-data-literacy-skills-row">
                                                <div className="compare-employees-cell compare-employees-data-literacy-skills-cell compare-employees-cell-fixed-column">
                                                    {JobsSeekerdata?.skills && JobsSeekerdata?.skills.length > 0 ? (
                                                        <>
                                                            {JobsSeekerdata?.skills?.map((skill) => (
                                                               <div key={index}
                                                                  className={`skill-level-component ${skill.levelValue === 1 || skill.levelValue === 2 ? 'selected' : ''}`}
                                                               >
                                                                   {skill.levelValue === 3 && <span className={"skill-completed-badge"}></span>}
                                                                   {skill.levelValue === 4 && <span className={"skill-certified-badge"}></span>}
                                                                   <span className={"skill-component__skill-name"}>{skill.skillName}</span>
                                                               </div>
                                                            ))}</>):(
                                                        <div className="text-center no-matches">
                                                            <h5>No Data Available</h5>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        {index === 0 &&

                                        <TableCell className="compare-employees-parent compare-employees-other-skills-row">
                                            <div className="compare-employees-cell compare-employees-cell-title">
                                                Other Skills
                                            </div>
                                        </TableCell>}

                                        <TableCell className={'compare_employee_border'}>
                                            <div className="compare-employees-parent compare-employees-other-skills-row" >
                                                <div className="compare-employees-cell compare-employees-other-skills-cell compare-employees-cell-fixed-column">
                                                    {JobsSeekerdata?.otherSkill && JobsSeekerdata?.otherSkill.length > 0 ? (
                                                        <>
                                                            {JobsSeekerdata?.otherSkill.map((otherSkill) => {
                                                                return <div className="individual-skill">{otherSkill?.otherSkillName}</div>;
                                                            })}</>):(
                                                        <div className="text-center no-matches ">
                                                            <h5>No Data Available</h5>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>


                                    <TableRow>
                                        {index === 0 &&
                                        <TableCell
                                           className={'compare-employees-parent compare-employees-smart-skills-row'}>
                                            <div
                                               className="compare-employees-cell compare-employees-cell-title compare-employees-smart-skills-cell-title">
                                                <div className="name">Smart Skills</div>
                                                <div className="colors">
                                                    <div
                                                       className={`blue ${
                                                          activeColor === "blue" && "active activeBlue"
                                                       }`}
                                                       onClick={() => this.handleToggleColor("blue", 1)}
                                                    />
                                                    <div
                                                       className={`orange ${
                                                          activeColor === "orange" && "active activeOrange"
                                                       }`}
                                                       onClick={() => this.handleToggleColor("orange", 2)}
                                                    />
                                                    <div
                                                       className={`green ${
                                                          activeColor === "green" && "active activeGreen"
                                                       }`}
                                                       onClick={() => this.handleToggleColor("green", 3)}
                                                    />
                                                    <div
                                                       className={`pink ${
                                                          activeColor === "pink" && "active activePink"
                                                       }`}
                                                       onClick={() => this.handleToggleColor("pink", 4)}
                                                    />
                                                </div>
                                            </div>
                                        </TableCell>
                                        }
                                        <SmartSkillComponent index={index} jobSeekerData={JobsSeekerdata} totalData={this.props.JobsSeekerdata}
                                                             selectedSkillID={selectedSkillID} activeID={activeID} activeColor={activeColor}
                                        />
                                    </TableRow>

                                    <TableRow>
                                        {index === 0 &&

                                        <TableCell className="compare-employees-parent compare-employees-experience-row">
                                            <div className="compare-employees-cell compare-employees-cell-title">
                                                Experience
                                            </div>
                                        </TableCell>}

                                        <TableCell className={'compare_employee_border'}>
                                            <div className="compare-employees-parent compare-employees-experience-row">
                                                <div className="compare-employees-cell compare-employees-experience-cell compare-employees-cell-fixed-column">
                                                    {JobsSeekerdata?.experience && JobsSeekerdata?.experience.length > 0 ? (
                                                        <>
                                                            <div className="total-experience-title">
                                                                <div className="title">Total Experience</div>
                                                                <div className="duration">
                                                                    {JobsSeekerdata?.Experience?.totalExperience} Years
                                                                </div>
                                                            </div>
                                                            {JobsSeekerdata?.experience?.map((company) => (
                                                                <div className="total-experience-company">
                                                                    <div className="position">{company?.organizationName}</div>
                                                                    <div className="name">{company.designation}</div>
                                                                    <div className="time">{this.convertDateFromUTC(company.startDate)}-{company?.isCurrent === true ? 'Present' : this.convertDateFromUTC(company.endDate) }</div>
                                                                </div>
                                                            ))}</>):(
                                                        <div className="text-center no-matches">
                                                            <h5>No Data Available</h5>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>


                                    <TableRow>
                                        {index === 0 &&

                                        <TableCell className="compare-employees-parent compare-employees-certifications-row">
                                            <div className="compare-employees-cell compare-employees-cell-title">
                                                Certifications
                                            </div>
                                        </TableCell>}

                                        <TableCell className={'compare_employee_border'}>
                                            <div className="compare-employees-parent compare-employees-certifications-row " >
                                                <div className="compare-employees-cell compare-employees-certifications-cell compare-employees-cell-fixed-column">
                                                    {JobsSeekerdata?.skillCertificates && JobsSeekerdata?.skillCertificates.length > 0 ? (
                                                        <>
                                                            {JobsSeekerdata?.skillCertificates?.map((certificate) => (
                                                                <div className="certificate">
                                                                    <img src={certificate?.skillsImage} />
                                                                </div>
                                                            ))}
                                                        </>):(
                                                        <div className="text-center no-matches">
                                                            <h5>No Data Available</h5>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </TableCell>
                                        {/*}*/}
                                    </TableRow>
                                </TableBody>
                        </Table>
                            </>
                        ))}

                    </TableContainer>
                </Container>
            </React.Fragment>
        );
    }
}

export default CompareEmployees;


class SmartSkillComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSmartSkillLoading:false,
            SmartSkillsDetails: []
        }
    }

    componentDidMount() {
        this.getSmartSkillsData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.totalData.length !== prevProps.totalData.length) {
            this.getSmartSkillsData();
        }
    }

    getSmartSkillsData = () => {
        this.setState({
            loading: true,
        });
        getSmartSkillsdata( appConfigs.endpoint.TS_SUBDOMAIN,this.props.jobSeekerData.jobSeeker.userDetails.userId)
           .then((data) => {
               this.setState(
                  {
                      SmartSkillsDetails: data.factors,
                      loading: false,
                  },);
           })
           .catch((err) => {
               console.log('GAINING Smart Skill ERR::::>>>', err)
               this.setState({
                   SmartSkillsDetails: [],
                   loading: false,
               })
           });
    };

    render() {

        const { SmartSkillsDetails } = this.state;
        const { selectedSkillID,activeID,activeColor } = this.props;
        return(
           <Fragment>
               {SmartSkillsDetails && SmartSkillsDetails.length > 0 && <>
                   <TableCell className={'compare_employee_border'}
                              style={{ verticalAlign: 'initial', width: '18rem',height:'40rem' }}>
                       {this.state.isSmartSkillLoading ? (
                             <div className="panel__refresh">
                                 <LoadingIcon />
                             </div>
                          ) :
                          null}
                       <div className="compare-employees-parent compare-employees-smart-skills-row">

                           <div
                              className={`compare-employees-cell compare-employees-smart-skills-cell ${activeColor}Data`}>

                               <div className="smart-skills-main-skill-component">
                                   <div
                                      className={`score-container score-container-${activeColor} ss-${SmartSkillsDetails[
                                         activeID
                                         ].scoreTitle.toLowerCase()}`}>
                                       <div className="score">
                                           {SmartSkillsDetails[activeID].score}
                                       </div>
                                       <div className={`status status-${activeColor}`}>
                                           {SmartSkillsDetails[activeID].scoreTitle}
                                       </div>
                                   </div>
                                   <div className="name">
                                       {SmartSkillsDetails[activeID].factorName}
                                   </div>
                               </div>

                               <div className="smart-skills-other-skills">
                                   {SmartSkillsDetails[activeID].dimensions.map((skill, index) => (
                                      <div className="smart-skills-other-skill">
                                          <div
                                             className={`score-container score-container-${activeColor} ss-${skill.scoreTitle.toLowerCase()}`}
                                             onClick={() => this.props.handleSelectedSkill(index)}>
                                              <div className="score">{skill.score}</div>
                                              <div className={`status status-${activeColor}`}>
                                                  {skill.scoreTitle}
                                              </div>
                                          </div>
                                          <div className="name">{skill.dimensionName}</div>
                                      </div>
                                   ))}
                               </div>

                               <SmartSkillsRowDetails
                                  empDataContent={SmartSkillsDetails[activeID]}
                                  selectedSkillID={selectedSkillID}
                               />
                           </div>

                       </div>
                   </TableCell>

               </>
               }
               {SmartSkillsDetails && SmartSkillsDetails.length === 0 &&
                <>
                   <TableCell className={'compare_employee_border'}
                   style={{ verticalAlign: 'initial', width: '18rem',height:'40rem' }}>
                       <div className="text-center no-matches" style={{height:'40rem'}}>
                           <h5>No Data Available</h5>
                       </div>
                   </TableCell>
                </>
               }

           </Fragment>
        )
    }


}