import appConfigs from '../../config/appConfigs';
import { request } from '../../shared/helpers/RequestWrapper';

const API_BASE_SKILLSTREETS = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;

const GET_CURRENCY = '/currency/getAllCurrency';
const INVITATION_JOB_APPLIED = '/organization/jobProjectPost/inviteToAppliedJob/';

export function getCurrency() {
    return request({
        url: API_BASE_SKILLSTREETS + GET_CURRENCY,
        method: 'GET',
    });
}


export function sentMailInvitationForTheAppliedJob(jobId,jobSeekerId) {
    return request({
        url: API_BASE_SKILLSTREETS + INVITATION_JOB_APPLIED + jobId + '/' + jobSeekerId,
        method: 'POST',
    });
}