import ExpandLessIcon from "mdi-react/ExpandLessIcon";
import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import React, { Component } from "react";

export class SmartSkillsRowDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDetailsOpen: false
    };
  }

  handleToggleDetails = () => {
    this.setState((prevState) => {
      return {
        isDetailsOpen: !prevState.isDetailsOpen
      };
    });
  };

  render() {
    const { isDetailsOpen } = this.state;
    const { empDataContent, selectedSkillID } = this.props;

    return (
      <div className="smart-skills-show-more-container">
        <div className="toggle-show-more-container">
          {isDetailsOpen ? (
            <span onClick={this.handleToggleDetails}>
              <span>Collapse Details</span> <ExpandLessIcon />
            </span>
          ) : (
            <span onClick={this.handleToggleDetails}>
              <span>View Details</span> <ExpandMoreIcon />
            </span>
          )}
        </div>

        {
          <div
            className={`smart-skills-show-more-container show-more-container-${
              isDetailsOpen ? "show" : "hidden"
            }`}>
            <div
              className={`smart-skills-show-more-content`}
              style={{ color: empDataContent.color }}>
              <div className="title">{empDataContent.factorName}</div>
              <div className="content">{empDataContent.factorDescription}</div>
            </div>
            <div className={`smart-skills-show-more-content`}>
              <div className="title">Behavioral Implications</div>
              <div className="content">{empDataContent.behavioralAnalysis}</div>
            </div>
            <div className={`smart-skills-show-more-content`}>
              <div className="title">Competency Analysis</div>
              <div className="content">
                {empDataContent.competenciesAnalysis}
              </div>
            </div>

            <div
              className={`smart-skills-show-more-content`}
              style={{ color: empDataContent.color }}>
              <div className="title">
                {empDataContent.dimensions[selectedSkillID].dimensionName}
              </div>
              <div className="message">
                You score{" "}
                {empDataContent.dimensions[selectedSkillID].scoreTitle} in{" "}
                {empDataContent.dimensions[selectedSkillID].dimensionName}
              </div>
              <div className="content">
                {
                  empDataContent.dimensions[selectedSkillID]
                    .dimensionDescription
                }
              </div>
            </div>
            <div className={`smart-skills-show-more-content`}>
              <div className="title">Skills Analysis</div>
              <div className="content">
                {empDataContent.dimensions[selectedSkillID].skillAnalysis}
              </div>
            </div>
            <div className={`smart-skills-show-more-content`}>
              <div className="title">Recommendations for Development</div>
              <div className="content">
                {empDataContent.dimensions[selectedSkillID].recommendations}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default SmartSkillsRowDetails;
