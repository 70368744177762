import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import CloseIcon from 'mdi-react/CloseIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import CheckIcon from 'mdi-react/CheckIcon';
import {
   deleteUserSkill,
   getOtherSKillsSearch,
} from '../../../../../api/Individual/employee.skills.api';
import { getSkills } from '../../../../../api/skills.api';
import PreferredSkillComponent from './PreferredSkillComponent';
import TrashCanCircleOutlineIcon from 'mdi-react/TrashCanCircleOutlineIcon';
import { fil } from 'date-fns/locale';
import {
   getUserCampaigns,
   getUserNominationSubmitted,
   submitNomination,
} from '../../../../../api/top25.api';
import {
   showErrorNotification,
   showNotification,
} from '../../../../../shared/components/toastMessage';
import { getCurrentEmployeeSkills } from '../../../../../api/Individual/employee.api';
import { hookAuth0 } from '../../../../../shared/components/auth/withAuth0';
import appConfigs from '../../../../../config/appConfigs';
import { setProfileCompletion } from '../../../../../redux/actions/profileCompletionAction';
import { setProfilProcessBio } from '../../../../../redux/actions/ProfileProcessAction';
import SkillComponent from '../../../../B2CIndividual/EmployeeProfile/components/SkillComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
   setIsNominationDone,
   setNominationOpen,
   setShowNominationBanner,
} from '../../../../../redux/actions/nominationAction';
import { campaignEnum } from '../../../../../shared/enums/campaign.enum';
import { getTokenData } from '../../../../../shared/helpers/token';

const NominationFormModal = (props) => {
   const { isOpen, isNominationDone } = useSelector(
      (state) => state.nomination
   );
   const dispatch = useDispatch();
   const [otherSkill, setOtherSkill] = useState([]);
   const [selectedOtherSkill, setSelectedOtherSkill] = useState([]);
   const [skills, setSkills] = useState([]);
   const [selectedSkills, setSelectedSkills] = useState([]);
   const [intialSelected, setInitialSelected] = useState([]);
   const [preSelectedSkills, setPreSelectedSkills] = useState([]);
   const [workExperienceLink, setWorkExperienceLink] = useState('');
   const [blogLink, setBlogLink] = useState('');
   const [projectPortfolioLink, setProjectPortfolioLink] = useState('');
   // const [isOpen, setIsOpen] = useState(true);

   const initializeModal = () => {
      getOtherSkillDetails();
      getAllSkills();
      getJobSeekerSkills();
   };

   useEffect(() => {
      getCampaigns();
   }, []);

   const getCampaigns = () => {
      getUserNominationSubmitted('top25under25').then((response) => {
         console.log('response :: ', response);
         if (!response.data.isNominationSubmitted) {
            if (
               props?.auth0?.user?.attributes?.campaign &&
               props?.auth0?.user?.attributes?.campaign?.indexOf(
                  'top25under25'
               ) !== -1
            ) {
               dispatch(setNominationOpen(true));
            }
         } else {
            dispatch(setNominationOpen(false));
                  dispatch(setShowNominationBanner(false));
         }
      });
      // getUserCampaigns().then((response) => {
      //    console.log('RESPONSE ::: ', response);
      //    if (
      //       response.data &&
      //       response.data.length > 0 &&
      //       response.data.includes('top25under25')
      //    ) {
      //       dispatch(setNominationOpen(false));
      //       dispatch(setShowNominationBanner(false));
      //    } else {
      //       if (
      //          props?.auth0?.user?.attributes?.campaign &&
      //          props?.auth0?.user?.attributes?.campaign?.indexOf(
      //             'top25under25'
      //          ) !== -1
      //       ) {
      //          console.log(
      //             'NOmination true seting',
      //             props,
      //             props?.auth0?.user?.attributes?.campaign?.indexOf(
      //                'top25under25'
      //             )
      //          );
      //          dispatch(setNominationOpen(true));
      //       }
      //    }
      // });
   };

   const resetModal = () => {
      setSelectedOtherSkill([]);
      // setOtherSkill([]);
      // setSkills([]);
      setSelectedSkills([]);
      setPreSelectedSkills([]);
      setWorkExperienceLink('');
      setBlogLink('');
      setProjectPortfolioLink('');
   };

   useEffect(() => {
      if (isOpen) {
         initializeModal();
      }
   }, [isOpen]);

   const getJobSeekerSkills = () => {
      getCurrentEmployeeSkills().then((response) => {
         if (!response.data || response.data.length === 0) {
            if (getTokenData()?.attributes?.campaign?.[0] === 'top25under25') {
               // setIsOpen(true)
            }
         } else if (response?.data?.length > 0) {
            setSelectedSkills(response.data.map((skill) => skill.skillId));
            setInitialSelected(response.data.map((skill) => skill.skillId));
            setPreSelectedSkills(
               response.data.map((skill) => ({
                  id: skill.id,
                  skillId: skill.skillId,
               }))
            );
         }
      });
   };

   // const getAllSkills = () => {
   //    getSkills({}).then((response) => {
   //       if (response && response.status) {
   //          let skills = response.data.content;
   //          skills.sort((a, b) => (a.name > b.name) ? 1 : -1)
   //          setSkills(skills)
   //       }
   //    });
   // };

   const getAllSkills = () => {
      getSkills({})
         .then((response) => {
            let skills = response.data?.content;
            // getUserSkills(skills);
            setSkills(skills);
         })
         .catch((err) => {
            console.log('getAllSkills error', err);
         });
   };

   const getUserSkills = (skills) => {
      getCurrentEmployeeSkills()
         .then((response) => {
            let skillVerifiedStatus = false;
            let userSkills = response.data || [];

            let allSkills = skills;
            let list = [];
            let skillVerified = 0;
            let selfSelectedSkill = 0;

            for (let i in allSkills) {
               let status = false;
               for (let j in userSkills) {
                  if (allSkills[i].id === userSkills[j].skillId) {
                     let obj = {};
                     obj.id = userSkills[j].id;
                     obj.skillId = userSkills[j].skillId;
                     obj.name = allSkills[i].name;
                     obj.status = userSkills[j].status;
                     obj.level = userSkills[j].status
                        ? userSkills[j].levelValue
                        : 0;
                     obj.badgeLevel = userSkills[j].skillBadgeLevel
                        ? userSkills[j].skillBadgeLevel
                        : 0;
                     list.push(obj);
                     if (!skillVerifiedStatus) {
                        skillVerifiedStatus = userSkills[j].status
                           ? userSkills[j].levelValue > 1
                           : false;
                     }
                     status = true;
                     if (!skillVerified) {
                        skillVerified =
                           userSkills[j].status && userSkills[j].levelValue > 1
                              ? 30
                              : 0;
                     }
                     if (!selfSelectedSkill) {
                        selfSelectedSkill = skillVerified
                           ? 10
                           : userSkills[j].status &&
                             userSkills[j].levelValue === 1
                           ? 10
                           : 0;
                     }
                  }
               }
               if (!status) {
                  let obj = {};
                  obj.skillId = allSkills[i].id;
                  obj.name = allSkills[i].name;
                  obj.status = false;
                  obj.level = 0;
                  obj.badgeLevel = 0;
                  list.push(obj);
               }
            }

            setSkills(list);
         })
         .catch((err) => {
            console.log('getUserSkills error ', err);
         });
   };

   const getOtherSkillDetails = () => {
      getOtherSKillsSearch(1, 'a')
         .then((res) => {
            setOtherSkill(res.data);
         })
         .catch((err) => {
            console.log(err);
         });
   };

   const handleOnChangeOtherSkill = (event, value) => {
      setSelectedOtherSkill(value);
   };

   const deletePreSelectedSkill = async (id) => {
      try {
         const res = await deleteUserSkill(id);
         if (res.status === 200) {
            setPreSelectedSkills([
               ...preSelectedSkills.filter(
                  (preSelectedSkill) => preSelectedSkill.id !== id
               ),
            ]);
         } else {
            throw new Error('Error in delete skill api');
         }
      } catch (error) {
         console.error(error);
         showErrorNotification('Error while removing skill from your profile.');
      }
   };

   const toggleSelectSkill = async (skillId) => {
      if (selectedSkills.includes(skillId)) {
         // if skill already there
         setSelectedSkills([
            ...selectedSkills.filter(
               (selectedSkillId) => selectedSkillId !== skillId
            ),
         ]);
         if (
            preSelectedSkills?.map((skill) => skill.skillId)?.includes(skillId)
         ) {
            await deletePreSelectedSkill(
               preSelectedSkills?.filter(
                  (skill) => skill.skillId === skillId
               )?.[0]?.id
            );
         }
      } else {
         // add new skill
         setSelectedSkills([...selectedSkills, skillId]);
      }
   };

   const handleOnChnage = (event, filed) => {
      if (filed === 1) {
         setWorkExperienceLink(event.target.value);
      }
      if (filed === 2) {
         setBlogLink(event.target.value);
      }
      if (filed === 3) {
         setProjectPortfolioLink(event.target.value);
      }
   };

   const isError = () => {
      let isError = false;
      if (preSelectedSkills.length === 0 && selectedSkills.length === 0) {
         isError = true;
         showNotification('please select skills');
      }
      return isError;
   };

   const submitNominationData = () => {
      const preSelectedSkillIds = preSelectedSkills.map(
         (skill) => skill.skillId
      );
      const data = {
         skills: selectedSkills
            .filter((skill) => !preSelectedSkillIds.includes(skill))
            .map((skill) => ({ skillId: skill, status: true })),
         otherSkills: selectedOtherSkill?.map((item) => ({
            skillsRelatedId: 1,
            otherSkillName: item.name,
            otherSkillId: item.id,
         })),
         workExperienceLink: workExperienceLink,
         blogLink: blogLink,
         projectPortfolioLink: projectPortfolioLink,
         code: 'top25under25',
      };
      // double checking in case the modal is open for some reason
      if (isNominationDone) {
         showErrorNotification({
            message: 'You have already submitted your nomination.',
         });
         dispatch(setIsNominationDone(true));
         return;
      }
      let error = isError();
      if (!error) {
         submitNomination(data)
            .then((response) => {
               showNotification(
                  'Your nomination has been submitted. We will reach out to you if you have been listed as CADS Sunrise 25',
                  'success'
               );
               dispatch(setNominationOpen(false));
               dispatch(setShowNominationBanner(false));
               // localStorage.setItem(campaignEnum.TOP_25, `${true}`);
               // dispatch(setIsNominationDone(true));
            })
            .catch((error) => {
               showErrorNotification(error);
            });
      }
   };
   const copyLink = () => {
      let link = `${appConfigs.endpoint['LOGIN_ROOT']}/account/DataStarSignUp?subdomain=top25under25&referral=true&user=${props.auth0.user.email}`;
      let textField = document.createElement('textarea');
      textField.innerText = link;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      showNotification('Link Copied!', 'primary');
   };

   return (
      <>
         {console.log('IS NOMINATION OPE :: ', isOpen)}
         <Modal
            isOpen={isOpen}
            onClosed={() => resetModal()}
            size="lg"
            contentClassName="post-details-page text-left"
            centered="false"
         >
            <div className="post-page-icons">
               <div
                  className="share-post ml-auto d-flex"
                  style={{ cursor: 'pointer' }}
               >
                  <div onClick={() => dispatch(setNominationOpen(false))}>
                     <CloseIcon /> Close
                  </div>
               </div>
            </div>
            <div className="page-body" style={{ color: '#2E485C' }}>
               <div className="body__image">
                  <div className="">
                     <img
                        src={
                           'https://sgp1.digitaloceanspaces.com/general-assets/assets/nomination-cover.png'
                        }
                        style={{
                           height: '200px',
                           objectFit: 'cover',
                        }}
                        alt="no file found"
                     />
                     <span className={'top1-span'}>Top 1%</span>
                  </div>
               </div>
               <div className={'mt-4 pl-3 pr-3'}>
                  <h3 style={{ fontWeight: '700' }}>
                     Are you one of ASEAN’s top 25 data scientists under 25 yrs
                     old?
                  </h3>
                  <p className={'mt-4 mb-4'} style={{ fontWeight: '600' }}>
                     Data whiz - if you think you got it, nominate yourself and
                     get the recognition you deserve for the impact you created
                     with data, analytics and AI in Southeast Asia.
                  </p>
                  <p>
                     CADS is committed to foster data literacy among youths in
                     ASEAN. We thrive to be an avenue for you them to learn data
                     literacy skills science from A to Z. We want to honor
                     rising stars who are creating positive impacts with data
                     science. And we are encouraging data scientists under the
                     age of 25 in Singapore, Indonesia, Malaysia Philippines and
                     Thailand to nominate yourself to get the recognition you
                     deserve.
                  </p>
                  <p style={{ fontWeight: '600' }} className={'mt-4 mb-2'}>
                     Use the link below to nominate your friend.
                  </p>
                  <div className={'copy_link_plot'}>
                     <span
                        className={'ml-3'}
                        style={{ fontWeight: '500' }}
                     >{`${appConfigs.endpoint['LOGIN_ROOT']}/account/DataStarSignUp?subdomain=top25under25`}</span>
                     <span className={'ml-auto'}>
                        <Button
                           color="primary"
                           className="icon profile__btn mt-0 m-2"
                           onClick={copyLink}
                        >
                           <p>Copy Link</p>
                        </Button>
                     </span>
                  </div>
                  <h3 style={{ fontWeight: '700' }} className={'mt-5'}>
                     Please fill in your information below to nominate yourself.
                  </h3>
                  <p className={'mt-3'}>
                     Select the Data Literacy skills you have <span style={{fontSize: '11px'}}>(note : You will cannot deselect already selected skills below.)</span>
                  </p>
                  <div className={'skill_view my-2'}>
                     {skills.map((skill, index) => {
                        return (
                           <PreferredSkillComponent
                              index={index}
                              skill={skill}
                              initialSelected={ intialSelected.indexOf(skill.id) !== -1}
                              isSelected={
                                 selectedSkills.indexOf(skill.id) !== -1
                              }
                              toggleSkill={toggleSelectSkill}
                           />
                        );
                     })}
                  </div>
                  <div className={'other_skill_view my-2'}>
                     <p>Other skills you have</p>
                     <div className={'my-2'}>
                        <Autocomplete
                           multiple
                           id="combo-box-demo"
                           value={selectedOtherSkill}
                           options={otherSkill}
                           size="small"
                           onChange={(event, newValue) => {
                              handleOnChangeOtherSkill(event, newValue);
                           }}
                           selectOnFocus
                           clearOnBlur
                           getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === 'string') {
                                 return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                 return option.optionVal;
                              }
                              // Regular option
                              return option.name;
                           }}
                           renderInput={(params) => (
                              <TextField
                                 {...params}
                                 label="Enter Skill Name"
                                 variant="outlined"
                              />
                           )}
                        />
                     </div>
                  </div>

                  <div className={'nomination_note mt-5 py-4 mb-4'}>
                     <p style={{ fontWeight: '700' }} className={'mx-4'}>
                        Would you like to take an assessment?
                     </p>
                     <p className={'mx-4'}>
                        Taking skills assessment proves to us that you are
                        technically skilled and will increase your chances of
                        being listed
                     </p>
                  </div>
                  <div className={'optional_field'}>
                     <TextField
                        id={'outlined-basic'}
                        variant="outlined"
                        className="my-2"
                        placeholder={'Work experience link'}
                        value={workExperienceLink}
                        onChange={(e) => handleOnChnage(e, 1)}
                     />
                     <TextField
                        variant="outlined"
                        className="my-2"
                        placeholder={'Blog link'}
                        value={blogLink}
                        onChange={(e) => handleOnChnage(e, 2)}
                     />
                     <TextField
                        variant="outlined"
                        className="my-2"
                        placeholder={'Project or portfolio link'}
                        value={projectPortfolioLink}
                        onChange={(e) => handleOnChnage(e, 3)}
                     />
                  </div>
                  <div className={'submit_button float-right'}>
                     <Button
                        color="primary"
                        onClick={submitNominationData}
                        className="icon profile__btn mt-0 m-2"
                     >
                        <p>Submit Nomination</p>
                     </Button>
                  </div>
               </div>
            </div>
         </Modal>
      </>
   );
};

export default hookAuth0(NominationFormModal);
