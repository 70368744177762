import React from "react";
import { Button, Col, Row } from "reactstrap";
import { withTranslation } from "react-i18next/src/index";
import PropTypes from "prop-types";

import { RTLProps } from "../../../../shared/prop-types/ReducerProps";
import { getNotifications, markAllNotificationAsRead } from "../../../../api/notification.api";
import { showErrorNotification, showNotification } from "../../../../shared/components/toastMessage";
import  Notification  from "./Notification";


class Notifications extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      isLast: false,
      loading: false,
      pageNo: 0,
      email: this.props.email
    };
  }

  componentDidMount() {
    this.getNotifications(this.state.email);
  }

  getNotifications = (email, pageNo = 0, pageSize = 6) => {
    this.setState({ loading: true });
    getNotifications(email, pageNo, pageSize).then(res => {
      if (!res.returnStatus)
        throw res;
      if (pageNo === 0) {
        this.setState({ notifications: res.data.notificationList });
      } else {
        const notifications = [...this.state.notifications, ...res.data.notificationList];
        this.setState({ notifications });
      }
      this.setState({ isLast: res.data.last, pageNo: res.data.pageNumber, loading: false });
    }).catch(err => {
      console.error("ERROR:::: ", err);
      showErrorNotification(err);
    });
  };

  markAllAsRead(email) {
    markAllNotificationAsRead(email).then(res => {
      if (res.status !== undefined && !res.status) {
        throw res;
      }
      this.getNotifications(this.state.email);
      showNotification("Marked all as read", "primary");
    }).catch(err => {
      showErrorNotification(err);
      console.error("error while marking all notifications as read");
    });
  }

  render() {
    const {
      email,
      notifications,
      isLast,
      pageNo,
      loading
    } = this.state;
    return <div>
      <Row>
        <Col md={12}>
          <h3 className="page-title notification-title" style={{ display: "inline-block" }}>Notifications</h3>
          <button className="topbar-button notification-button" type="button" onClick={() => this.markAllAsRead(email)}>
            Mark all as read
          </button>
          <hr className={"topbar_notification_icon_desktop"} style={{border: "1px solid #b5b5c6"}}/>
        </Col>
      </Row>

      <Row style={{ paddingTop: "18px" }}>
        {notifications.length === 0 ?
          <Col md={12} style={{textAlign: "center"}}>
            <p>You have no new notifications</p>
          </Col>
        :
          notifications.map(notification => <Notification notification={notification} email={email}
                                                         getNotifications={this.getNotifications}/>)
        }
      </Row>
      {
        (!isLast && notifications.length !== 0) &&
        <div className="text-center" style={{ marginTop: "12px" }}>
          {loading ?
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path fill="#2E485C" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
              </svg>
            </div>
            :
            <Button color="primary" style={{ padding: "5px 25px" }}
                    onClick={() => this.getNotifications(email, pageNo + 1)}>
              Load More
            </Button>
          }
        </div>
      }
    </div>;
  }
}

Notifications.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired
};

export default withTranslation("common")(Notifications);
