export const SET_TOUR_OPEN = "SET_TOUR_OPEN";
export const SET_TOUR_CONFIG = "SET_TOUR_CONFIG";
export const SET_EXP = "SET_EXP";
export const SET_LEARN = "SET_LEARN";
export const SET_REDIRECT_URL ="SET_REDIRECT_URL";

export function setTourOpen(isOpen) {
    return {
        type: SET_TOUR_OPEN,
        isOpen
    };
}
export function setTourConfig(tourConfig) {
    return {
        type: SET_TOUR_CONFIG,
        tourConfig
    };
}

export function setTourExperience(status) {
    return {
        type: SET_EXP,
        status
    };
}


export function setTourLearn(status) {
    return {
        type: SET_LEARN,
        status
    };
}

export function setTourRedirectUrl(status) {
    return {
        type: SET_REDIRECT_URL,
        status
    };
}