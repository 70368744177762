import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { getAccessTokenCookie } from "../helpers/token";

const PrivateRoute = ({ component: Component, roles, currentProfile, ...rest }) => {
  // const user_role = useAuth0().user.user_type && useAuth0().user.user_type[0];
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!getAccessTokenCookie()) {
          // not logged in so redirect to login page with the return url
          return <Redirect to="/" />;
        }
        // if (roles.indexOf(user_role) === -1) {
        //   return <Redirect to="/" />;
        // }
        // authorised so return component
         if(currentProfile && currentProfile.admin && rest.path.includes('individual')) {
            return <Redirect to="/" />;
         } else if(currentProfile && !currentProfile.admin && rest.path.includes('organization')) {
            return <Redirect to="/" />;
         }
        return <Component {...props} />;
      }}
    />
  );
};

export default withRouter(PrivateRoute);
