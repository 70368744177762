import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { Card, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import SkillTab from './SkillTab';
import RecommendationTab from './RecommendationTab';

const detailedTabs = [
   {
      name: 'Skills Analysis',
      value: 'skill',
   },
   {
      name: 'Recommendations for Development',
      value: 'recommendation',
   },
];

class DetailedTabs extends PureComponent {
   constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = {
         activeTab: 'skill',
      };
   }

   toggle(tab) {
      const { activeTab } = this.state;
      if (activeTab !== tab) {
         let update = { activeTab: tab };
         this.setState({ ...update });
      }
   }

   /**
    * This will be used to create a Profile Tab division.
    */

   render() {
      const { activeTab } = this.state;
      const { currentDimension } = this.props;

      return (
         <Row className={"mt-3"}>
            <Col md={12} lg={12} xl={12}>
               <Card>
                  <div className="profile__card tabs tabs--bordered-bottom">
                     <div className="tabs__wrap">
                        <Nav tabs className={"p-0"} style={{ border: '0px' }}>
                           {detailedTabs.map((profileTab, index) => {
                              return (
                                 <NavItem key={index}>
                                    <NavLink
                                       className={classnames({
                                          active:
                                             activeTab === profileTab.value,
                                       })}
                                       onClick={() => {
                                          this.toggle(profileTab.value);
                                       }}
                                    >
                                       {profileTab.name}
                                    </NavLink>
                                 </NavItem>
                              );
                           })}
                        </Nav>
                        {activeTab === 'skill' ? (
                           <SkillTab skills={currentDimension.skillAnalysis} />
                        ) : activeTab === 'recommendation' ? (
                           <RecommendationTab
                              recommendation={currentDimension.recommendations}
                           />
                        ) : null}
                     </div>
                  </div>
               </Card>
            </Col>
         </Row>
      );
   }
}

export default DetailedTabs;
