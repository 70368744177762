import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RTLProps } from '../../../../../../../shared/prop-types/ReducerProps';
import OverviewDesign from './OverviewDesign';

class Overview extends Component {

   render() {
      let { smartSkillData } = this.props;
      return <OverviewDesign smartSkillData={smartSkillData} />;
   }
}

Overview.propTypes = {
   t: PropTypes.func.isRequired,
   rtl: RTLProps.isRequired,
   smartSkillData: PropTypes.any,
};

export default compose(
   withTranslation('common'),
   connect((state) => ({
      rtl: state.rtl,
   }))
)(Overview);
