import classnames from 'classnames';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
   Card,
   Col,
   Nav,
   NavItem,
   NavLink,
   TabContent,
   TabPane,
} from 'reactstrap';
import { getOpenJobsOrProjects } from '../../../../api/Individual/organization.details.api';
import { showErrorNotification } from '../../../../shared/components/toastMessage';
import OpenJobsProjects from './OpenJobsProjects';

const opportunityTabs = [
   {
      name: 'Open Jobs',
      value: 'JOB',
   },
   {
      name: 'Open Projects',
      value: 'PROJECT',
   },
];

class OpportunitiesTabs extends PureComponent {
   constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = {
         activeTab: 'JOB',
         jobs: [],
         projects: [],
         isJobRefresh: true,
         isProjectRefresh: true,
      };
   }
   componentDidMount() {
      const { activeTab } = this.state;
      let previousPageType = activeTab;
      if (this.props.location.state) {
         previousPageType =
            this.props.location.state.pathname === 'postproject'
               ? 'PROJECT'
               : 'JOB';
         if (activeTab !== previousPageType) {
            this.setState({ activeTab: previousPageType });
         }
      }
      this.getJobsOrProjects(previousPageType);
   }

   getJobsOrProjects(tab = this.state.activeTab) {
      let url = window.location.pathname;
      url = url.split('/');
      const orgID = url[url.length - 1];
      getOpenJobsOrProjects(orgID, tab)
         .then((res) => {
            let update = {};
            if (tab === 'JOB') {
               update.jobs = res.data || [];
               update.isJobRefresh = false;
            } else {
               update.projects = res.data || [];
               update.isProjectRefresh = false;
            }
            this.setState({ ...update });
         })
         .catch((error) => {
            console.log('getJobsOrProjects error :: ', error);
            this.setState({
               isJobRefresh: false,
               isProjectRefresh: false,
            });
            showErrorNotification(error);
         });
   }

   toggle(tab) {
      const { activeTab } = this.state;
      if (activeTab !== tab) {
         let update = { activeTab: tab };
         if (tab === 'JOB') {
            update.isJobRefresh = true;
         } else {
            update.isProjectRefresh = true;
         }
         this.setState({ ...update });
         this.getJobsOrProjects(tab);
      }
   }

   routeChange = () => {
      let { history } = this.props;
      let { activeTab } = this.state;
      let path = `/b2borganization/opportunities/${
         activeTab === 'JOB' ? 'postjob' : 'postproject'
      }`;
      history.push(path);
   };

   render() {
      const { dir } = this.props;

      const {
         activeTab,
         isJobRefresh,
         isProjectRefresh,
         projects,
         jobs,
      } = this.state;
      return (
         <Col md={12} lg={12} xl={12} className={'p-0'}>
            <Card>
               <div className="profile__card tabs tabs--bordered-bottom">
                  <div className="tabs__wrap p-0">
                     <Nav tabs>
                        {opportunityTabs.map((opportunityTab, index) => {
                           return (
                              <NavItem key={index}>
                                 <NavLink
                                    className={classnames({
                                       active:
                                          activeTab === opportunityTab.value,
                                    })}
                                    onClick={() => {
                                       this.toggle(opportunityTab.value);
                                    }}
                                 >
                                    {opportunityTab.name}
                                 </NavLink>
                              </NavItem>
                           );
                        })}
                     </Nav>
                     <TabContent
                        className="tabs__opportunities"
                        activeTab={activeTab}
                     >
                        <TabPane tabId="JOB">
                           {isJobRefresh ? (
                              <div className="panel__refresh">
                                 <LoadingIcon />
                              </div>
                           ) : null}
                           <OpenJobsProjects
                              dir={dir}
                              jobs={jobs}
                              isJobView={true}
                           />
                           {!isJobRefresh &&
                              (!jobs ||
                                 (jobs.length === 0 && (
                                    <div className={'empty-message'}>
                                       No Jobs posted yet.
                                    </div>
                                 )))}
                        </TabPane>
                        <TabPane tabId="PROJECT">
                           {isProjectRefresh ? (
                              <div className="panel__refresh">
                                 <LoadingIcon />
                              </div>
                           ) : null}
                           <OpenJobsProjects
                              dir={dir}
                              jobs={projects}
                              isJobView={false}
                           />
                           {!isProjectRefresh &&
                              (!projects ||
                                 (projects.length === 0 && (
                                    <div className={'empty-message'}>
                                       No Projects posted yet.
                                    </div>
                                 )))}
                        </TabPane>
                     </TabContent>
                  </div>
               </div>
            </Card>
         </Col>
      );
   }
}

OpportunitiesTabs.propTypes = {
   t: PropTypes.func.isRequired,
   dir: PropTypes.string.isRequired,
   themeName: PropTypes.string.isRequired,
};

export default connect((state) => ({ themeName: state.theme.className }))(
   withRouter(withTranslation('common')(OpportunitiesTabs))
);
