export const SET_NOMINATION_OPEN = "SET_NOMINATION_OPEN";
export const SET_NOMINATION_DONE = "SET_NOMINATION_DONE";
export const SET_SHOW_NOMINATION_BANNER = "SET_SHOW_NOMINATION_BANNER";

export function setNominationOpen(isOpen) {
   return {
      type: SET_NOMINATION_OPEN,
      isOpen
   };
}

export function setShowNominationBanner(status) {
   return {
      type: SET_SHOW_NOMINATION_BANNER,
      status
   };
}

export function setIsNominationDone(isNominationDone) {
   return {
      type: SET_NOMINATION_DONE,
      isNominationDone
   }
}
