import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getEmployeeProfileSS } from '../../../api/Individual/employee.api';
import { setCurrentUserDetails, setCurrentUserGenericDetails } from '../../../redux/actions/userAction';
import { hookAuth0, useAuth0 } from '../../../shared/components/auth/withAuth0';
import { roleEnum } from '../../../shared/enums';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
import { Button } from 'reactstrap';
import TopbarMenu from './TopbarMenu';
import TopbarMessages from './TopbarMessages';
import TopbarNotification from './TopbarNotification';
import TopbarSaved from './TopbarSaved';
import TopbarSearch from './TopbarSearch';
import TopbarSetting from './TopbarSetting';
import TopbarSidebarButton from './TopbarSidebarButton';
import appConfigs from '../../../config/appConfigs';
import NavbarItem from '../../../shared/components/NavbarItem';
import { getAllProfilesOfUser } from '../../../api/profiles.api';
import { setAccountProfiles } from '../../../redux/actions/accountActions';
// import {changeSidebarVisibility, changeMobileSidebarVisibility} from "../../../redux/actions/sidebarActions";
import Cookie from 'universal-cookie';

const cookie = new Cookie();

const aphroditeRoot = appConfigs.endpoint.APHRODITE_ROOT_UI;


class Topbar extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         showOptions: false,
         profileData: [],
         user: null
      };
   }

   getOrganizationDetailsData = () => {
      if (
         window.location.href.includes("/individual")
      ) {

         getEmployeeProfileSS()
            .then((res) => {
               const user = {};
               if (res.status && res.data) {

                  const user = {
                     name: res.data.userDetails.fname + ' ' + res.data.userDetails.lname,
                     profilePhoto: res.data.profilePhoto,
                     ...res.data
                  }
                  this.props.dispatch(
                     setCurrentUserDetails({...user, userDetails: res.data.userDetails})
                  );
                  this.setState({
                     user
                  })
                  user.lms = res.data.orgLmsTypeDTO[0].lmsType;
                  user.jobs = res.data.orgJobTypeDTO[0].jobType;

               } else {
                  throw res;
               }
               let obj = {
                  lmsID: user.lms,
                  jobsID: user.jobs,
               };
               this.props.dispatch(setCurrentUserGenericDetails(obj));
            })
            .catch((err) => console.log(err));
      }
   };

   componentDidMount() {
      this.getOrganizationDetailsData();
      this.getProfiles();
      document.addEventListener("refreshProfiles",() => {
         this.getProfiles()
      })
   }
   componentWillUnmount() {
      document.removeEventListener("refreshProfiles", () => {})
   }

   getProfiles = () => {
      // getAllProfilesOfUser()
      //    .then((response) => {

      const response = this.props.profiles
            if (response && response.status) {
               let data = response.data;
               let profileData=[];
               let index;
               for(let i in data) {
                  if(data[i].rubiqe && data[i].current && !data[i].admin) {
                     profileData[0] = data[i]
                     index = parseInt(i)
                     cookie.set("last_profile", "rubiqe_individual", {domain: appConfigs.endpoint.COOKIE_DOMAIN})
                     cookie.set('subdomain', appConfigs.endpoint.TS_SUBDOMAIN, {
                        domain: appConfigs.endpoint.COOKIE_DOMAIN,
                     });
                  }else if(data[i].current && data[i].rubiqe && data[i].admin) {
                     profileData[0] = data[i]
                     index = parseInt(i)
                     cookie.set("last_profile", "rubiqe_organization", {domain: appConfigs.endpoint.COOKIE_DOMAIN})

                     //code of userleap and sideby
                     setTimeout(()=> {
                        console.log('inside the org',this.props.userProfile,this.props.profileData);
                        // window.UserLeap.displaySurvey(32521)
                        // window.sideby.setUserData(
                        //    {
                        //       email: data[i].userId,
                        //       fullName:data[i].fname+" "+data[i].lname,
                        //    }
                        // );
                  }, 6000)
                  }
               }
               profileData.push(...data.filter((item, i) => i !== index && item))
               this.setState({
                  profileData,
               });
               this.props.dispatch(setAccountProfiles(profileData))
            }
         // })
         // .catch((err) => {
         //    console.error(err);
         // });

   };

   toggleShowOptions = () => {
      this.setState({
         showOptions: !this.state.showOptions,
      });
   };

   render() {
      const {
         user,
         changeSidebarVisibility,
         changeMobileSidebarVisibility,
         auth0,
         publicRoute
      } = this.props;
      const { showOptions, profileData } = this.state;
      const authUser = auth0.user;

      return (
         <div className="topbar">
            <div className="topbar__wrapper">
               <div className="topbar__left">
                  <TopbarSidebarButton
                     changeMobileSidebarVisibility={
                        changeMobileSidebarVisibility
                     }
                     changeSidebarVisibility={changeSidebarVisibility}
                  />
                  <div className="topbar_logo_left">
                     <Link className="topbar__logo" to="/">
                        <img
                           alt="logo"
                           src={
                              'https://general-assets.sgp1.digitaloceanspaces.com/rubiqe-acceltic-logos/rubiqe-primary.png'
                           }
                           style={{
                              width: '130px',
                              backgroundVisibility: 'hidden',
                           }}
                        />
                     </Link>
                  </div>
                  {profileData && profileData.length > 0 && (
                     <NavbarItem
                        users={profileData}
                        // showOptions={showOptions}
                        // toggleShowOptions={this.toggleShowOptions}
                     />
                  )}
                  <TopbarMenu authUser publicRoute />
               </div>
               {authUser ? (
                  <div className="topbar__right">
                     <TopbarSearch />
                     {authUser.user_type && authUser.user_type[0] ===
                        roleEnum.B2C_INDIVIDUAL &&
                     authUser?.variation &&
                     authUser?.variation[0] ===
                        roleEnum.PERKESO_USER ? null : (
                           user &&
                        <TopbarMessages user={user} />
                     )}
                     {window.location.href.includes("/individual") ? (
                        <TopbarSaved />
                     ) : null}
                     <TopbarNotification email={authUser.email} />
                     <TopbarSetting user={user} />
                  </div>
               ) : (
                  <div className="topbar__right">
                     <Button
                        onClick={() => {
                           window.location.href = appConfigs.endpoint.LOGIN_ROOT + '/Account/SignUp';
                        }}
                        size={'sm'}
                        className={'m-auto'}
                        color={'primary'}
                     >
                        {' '}
                        Sign Up{' '}
                     </Button>
                  </div>
               )}
            </div>
         </div>
      );
   }
}

Topbar.propTypes = {
   changeMobileSidebarVisibility: PropTypes.func.isRequired,
   changeSidebarVisibility: PropTypes.func.isRequired,
   user: UserProps.isRequired,
};

export default connect((state) => ({
   userProfile: state.userProfile,
   profiles: state.accounts.profiles
}))(hookAuth0(Topbar));
