import appConfigs from '../../config/appConfigs';
import { request } from '../../shared/helpers/RequestWrapper';

const API_HOST = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS + "/organization";
export const JOB_PROJECT_POST_URL = "/jobProjectPost";
export const GET_SPECIIC_JOBSEKKER_DETAILS = "/getSpecificJobsSeekerDetails"
export const GET_LIST_TO_COMPARE = "/compare/allApplicant"

export function getSpecificJobsSeekerDetails(jobId, params) {

   let URL = API_HOST + JOB_PROJECT_POST_URL + GET_SPECIIC_JOBSEKKER_DETAILS + "/" + jobId

   return request({
      url: URL,
      method: "get",
      params
   });
}

export function getListToCompare(jobId,pageNo,pageSize) {
   let URL = API_HOST + JOB_PROJECT_POST_URL + GET_LIST_TO_COMPARE + "/" + jobId + "/"+'EXTERNAL?pageNo='+pageNo+'&pageSize='+pageSize;
   return request({
      url: URL,
      method: "get"
   });
}