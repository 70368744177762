import { request } from "../../shared/helpers/RequestWrapper";
import appConfigs from "../../config/appConfigs";

const API_BASE_SKILLSTREETS = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;
const API_BASE_TALENTSPY = appConfigs.endpoint.API_BASE_TALENTSPY;

const JOB_SEEKER = "jobSeeker";
const OPPORTUNITIES = "opportunity";
const MATCHED_JOB_PROJECT = "getMatchedJobProjectForIndividual";
const JOB = "JOB";
const PROJECT = "PROJECT";
const GET_INDIVIDUAL_SMART_SKILL_STATUS = "/getSmartSkillsStatus.json";
const SAVE_JOB_VISIT_COUNT = '/jobs/saveJobProjectVisitCount/'

export function getMatchedJobsOrProjects(isJob) {
    return request({
        url: API_BASE_SKILLSTREETS + "/" + JOB_SEEKER + "/" + OPPORTUNITIES + "/" + MATCHED_JOB_PROJECT + "/" +
            `${isJob ? JOB : PROJECT}`,
        method: "GET"
    });
}

export function getIndividualSmartSkillStatus() {
    return request({
        url: API_BASE_TALENTSPY + GET_INDIVIDUAL_SMART_SKILL_STATUS,
        method: "GET"
    });
}

export function saveJobVisitCountFromJobId(jobId) {
    return request({
        url: API_BASE_SKILLSTREETS + "/" + 'jobseeker' + SAVE_JOB_VISIT_COUNT + jobId,
        method: "POST"
    });
}
