import classnames from 'classnames';
import React, { Component } from 'react';
import {
   Card,
   Col,
   Container,
   Nav,
   NavItem,
   NavLink,
   Row,
   TabContent,
   TabPane,
} from 'reactstrap';
import { hookAuth0 } from '../../../shared/components/auth/withAuth0';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ApplicantProfile from './components/ApplicantProfile';
import { withRouter } from 'react-router';
import MySmartSkills from '../../PerkesoUser/Profile/components/mySmartSkills';

const profileTabs = [
   {
      name: 'Profile',
      value: 'profile',
   },
   {
      name: 'Smart Skills',
      value: 'smartSkills',
   },
];

class JobApplicantDetails extends Component {
   constructor(props) {
      super(props);

      this.state = {
         activeTab: 'profile',
      };
   }

   routeChange = () => {
      let { history, location } = this.props;
      if(location.state.prevPath){
         history.push({
            pathname: location.state.prevPath,
            state: {
               ...location.state,
               prevPath: location.pathname
            },
         })
      } else {
         history.goBack();
      }
   };

   getSmartSkill = (email) => {
      this.setState({
         applicantEmail: email,
      });
   };

   toggle = (tab) => {
      this.setState({
         activeTab: tab,
      });
   };

   render() {
      const { auth0 } = this.props;
      const { activeTab, applicantEmail } = this.state;
      return (
         <React.Fragment>
            <Row className={'pl-3'}>
               <div className="header d-flex ml-3">
                  <ArrowBackIcon
                     className="back-arrow"
                     onClick={this.routeChange}
                     style={{ cursor: 'pointer' }}
                  />
                  <div className="d-inline-block pl-3 ">
                     <h4 className="font-weight-bold">Applicant details</h4>
                     <div className="orgprofile__basic-header-title">
                        Opportunities / Jobs / Applicant Details
                     </div>
                  </div>
               </div>
            </Row>
            <div className="profile__card tabs tabs--bordered-bottom d-none d-md-block mt-4">
               <div className="tabs__wrap">
                  <Nav tabs className={'pl-5'}>
                     {profileTabs.map((tab, index) => {
                        return (
                           <NavItem key={index}>
                              <NavLink
                                 className={classnames({
                                    active: activeTab === tab.value,
                                 })}
                                 onClick={() => {
                                    this.toggle(tab.value);
                                 }}
                                 data-tut={`tour_${tab.value}_tab_link`}
                              >
                                 {tab.name}
                              </NavLink>
                           </NavItem>
                        );
                     })}
                  </Nav>
               </div>
            </div>
            {activeTab === 'smartSkills' && applicantEmail && (
               <MySmartSkills email={applicantEmail} />
            )}
            <Container className={'dashboard p-3'}>
               <Row className={'mt-3'}>
                  <Col md={12} lg={12} xl={12}>
                     <Card style={{ paddingBottom: '0rem' }}>
                        <div className="profile__card tabs tabs--bordered-bottom">
                           <div className="tabs__wrap">
                              <TabContent
                                 className="tabs__opportunities"
                                 activeTab={activeTab}
                              >
                                 <TabPane tabId="profile">
                                    <ApplicantProfile
                                       user={auth0.user}
                                       callSmartSkill={this.getSmartSkill}
                                    />
                                 </TabPane>
                                 {/*<TabPane tabId="smartSkills">*/}
                                 {/*   {applicantEmail &&*/}
                                 {/*      <MySmartSkills email={applicantEmail}/>*/}
                                 {/*   }*/}
                                 {/*</TabPane>*/}
                              </TabContent>
                           </div>
                        </div>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </React.Fragment>
      );
   }
}

export default withRouter(hookAuth0(JobApplicantDetails));
