import PropTypes from "prop-types";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {RTLProps} from "../../../../../shared/prop-types/ReducerProps";
import SmartSkillTabs from "./components/SmartSkillTabs";

const MySmartSkills = ({ t, rtl, userProfile, email }) => (
        <SmartSkillTabs email={email}/>
);

MySmartSkills.propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
};

export default compose(
    withTranslation("common"),
    connect((state) => ({
        rtl: state.rtl,
    }))
)(MySmartSkills);
