import appConfigs from "../../config/appConfigs";
import {
    request
} from "../../shared/helpers/RequestWrapper";

const API_HOST = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS + "/organization";
const API_HOST_ENDPOINT = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;
export const JOB_PROJECT_POST_URL = "/jobProjectPost";
export const INSERT_UPDATE_JOB_OR_PROJECT_URL = JOB_PROJECT_POST_URL + "/insertUpdate";
export const GET_JOB_OR_PROJECT_URL = JOB_PROJECT_POST_URL + "/getJobOrProject";
export const RECOMMENDED_APPLICANT_URL = JOB_PROJECT_POST_URL + "/v2/recommendedApplicant";
export const ALL_APPLICANT_URL = JOB_PROJECT_POST_URL + "/v2/allApplicant";
export const SHORTLIST_APPLICANT_URL = JOB_PROJECT_POST_URL + "/shortlist";
const GET_ORG_SUGGGESTION_BY_NAME = "/getSuggestionByName";
const SEND_INTERVIEW_REQUEST ='/jobHiringProcess/sendInterviewRequest';
const GET_INTERVIEW_SCHEDULESHOW ='/jobHiringProcess/getInterviewDetails';
const GET_INTERVIEW_REQUEST ='/jobHiringProcess/getInvitationDetails';
const PUT_INTERVIEW_REQUEST = '/jobHiringProcess/setInterviewSchedule';
const DELETE_INTERVIEW_REQUEST = '/jobHiringProcess/cancelRequest';
const EDIT_INTERVIEW_REQUEST = '/jobHiringProcess/getInvitationDetailsById';

export function getInterviewSchedule(hiringId) {
    return request({
        url: API_HOST_ENDPOINT + EDIT_INTERVIEW_REQUEST + "/" + hiringId,
        method: "get",
    });
}

export function deleteInterviewSchedule(hiringId) {
    return request({
        url: API_HOST_ENDPOINT + DELETE_INTERVIEW_REQUEST + "/" + hiringId,
        method: "put",
    });
}
export function putInterviewScheduleRequestShow(flag,jobHiringId,reason) {
    return request({
        url: API_HOST_ENDPOINT + PUT_INTERVIEW_REQUEST + "/" + flag + "/" + jobHiringId + "?reason=" +reason,
        method: "put",
    });
}

export function getInterviewScheduleRequestShow(JobId,process) {
    return request({
        url: API_HOST_ENDPOINT + GET_INTERVIEW_REQUEST + "/" + JobId + "/" + process,
        method: "get",
    });
}


export function getInterviewScheduleShow(schedulejobSeekerId,scheduleJobId , scheduleprocess ) {
    return request({
        url: API_HOST_ENDPOINT + GET_INTERVIEW_SCHEDULESHOW + "/" + schedulejobSeekerId  + '/' +scheduleJobId + '/' + scheduleprocess ,
        method: "get",
    });
}

export  function sendInterviewRequest(body){
    return request({
        url : API_HOST_ENDPOINT + SEND_INTERVIEW_REQUEST,
        method : "post",
        body: body
    });
}

export function addJobOrProject(body) {
    return request({
        url: API_HOST + INSERT_UPDATE_JOB_OR_PROJECT_URL,
        method: "post",
        body: body
    });
}

export function getJobsOrProjects(type,isActive , params) {
    return request({
        url: API_HOST + GET_JOB_OR_PROJECT_URL + "/" + type +'?isActive=' +isActive ,
        method: "get",
        params
    });
}

export function getRecommendedApplicant(jobId, type, params,countryName="",employeeName="",location="") {
    return request({
        url: API_HOST + RECOMMENDED_APPLICANT_URL + "/" + jobId + "/" + type + "?countryName=" +countryName + "&employeeName=" + employeeName + "&location=" + location +'&' ,
        method: "get",
        params
    });
}

export function shortListApplicant(jobId, jobSeekerId, isShortlisted) {
    return request({
        url: API_HOST + SHORTLIST_APPLICANT_URL + "/" + jobId + "/" + jobSeekerId + "/" + isShortlisted,
        method: "post",
    });
}

export function getAllApplicant(jobId, type, params,applicantType,countryName="",employeeName="",location="") {
    const myParams = {
        ...params,
        process : applicantType
    }
    return request({
        url: API_HOST + ALL_APPLICANT_URL + "/" + jobId + "/" + type + "?countryName=" +countryName + "&employeeName=" + employeeName + "&location=" + location +'&' ,
        method: "get",
        params : myParams
    });
}

export async function getOrganizationSuggestionByName(name) {
    return await request({
        url: API_HOST + GET_ORG_SUGGGESTION_BY_NAME +'/' + name,
        method: "GET",
    });
}
