import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import WrappedRoutes from './WrappedRoutes';
import Loading from '../../../shared/components/Loading';
import PublicRoutes from './PublicRoutes';
import Impersonate from './Impersonate';
import { withRouter } from 'react-router';
import Error from '../../../shared/components/error/Error';

const Router = (props) => {
   useEffect(() => {
      console.log('inside router   ');
   }, []);

   return (
      <MainWrapper>
         <main>
            <Suspense fallback={<Loading />}>
               <Switch>
                  <Route path="/public" render={PublicRoutes} />
                  <Route path="/impersonate" component={Impersonate} />
                  {/*<Route path="/public/opportunities" component={PublicProfile} />*/}
                  <Route
                     path="/503"
                     render={(props) => <div>503 Server Down</div>}
                  />
                  <Route
                     path="/error"
                     // component={Error}
                     render={() => <Error {...props} />}
                  />

                  {props.isError === true && <Redirect to={'/error'} />}
                  <Route
                     path="/"
                     render={(props) => (
                        <WrappedRoutes
                           {...props}
                           redirectTo={props.redirectTo}
                        />
                     )}
                  />
                  {/*<Route path="/" Component={WrappedRoutes} />*/}
               </Switch>
            </Suspense>
         </main>
      </MainWrapper>
   );
};

export default withRouter(Router);
