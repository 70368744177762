import React, { Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const ApplicantDisplayCertificateModal = ({
                                     isOpen,
                                     toggle,
                                     url,
                                     downloadPdf,
                                     selectedCertificateLink,

                                 }) => {
    return (
        <Fragment>
            <Modal size={"lg"} isOpen={isOpen} toggle={toggle}>
                <ModalHeader className={"p-0 m-0"} toggle={toggle} />
                <ModalBody className={"pb-0"}>
                    <div className={"certification-certificate__document"}>
                        {url && (
                            <iframe
                                color={"white"}
                                src={`${url}#toolbar=0&navpanes=0&scrollbars=0`}
                                className={"certification-iframe"}
                                scrolling="0"
                                frameBorder={0}
                                allowTransparency={true}
                            />
                        )}
                    </div>

                    <div className={"certification-certificate__document pt-4"}>
                        <Button
                            size={"sm"}
                            color={"primary"}
                            className={"mb-0"}
                            onClick={() => downloadPdf(selectedCertificateLink)}>
                            Download
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default ApplicantDisplayCertificateModal ;
