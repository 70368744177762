import { combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  cryptoTableReducer,
  newOrderTableReducer,
  sidebarReducer,
  themeReducer,
  customizerReducer,
  todoReducer,
  rtlReducer,
  authReducer,
  userReducer,
  profileCompletionReducer,
  tourReducer,
  profileProcessReducer, nominationReducer
} from '../../redux/reducers/index';
import messageReducer from "../../redux/reducers/messageReducer";
import fileUploadReducer from '../../redux/reducers/fileUploadReducer';
import accountReducer from '../../redux/reducers/accountsReducer';
import { acceleratorReducer } from '../../redux/reducers/acceleratorsReducer';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  rtl: rtlReducer,
  sidebar: sidebarReducer,
  cryptoTable: cryptoTableReducer,
  newOrder: newOrderTableReducer,
  customizer: customizerReducer,
  todos: todoReducer,
  user: authReducer,
  userProfile: userReducer,
  messages: messageReducer,
  profile: profileCompletionReducer,
  tour: tourReducer,
  fileUpload: fileUploadReducer,
  accounts: accountReducer,
  profileProcess : profileProcessReducer,
  nomination : nominationReducer,
  dataStarAccelerator: acceleratorReducer
});
const store = createStore(reducer);

export default store;
