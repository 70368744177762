import { request } from "../../shared/helpers/RequestWrapper";
import appConfigs from "../../config/appConfigs";

const API_BASE_SKILLSTREETS = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;
const API_BASE_TALENTSPY = appConfigs.endpoint.API_BASE_TALENTSPY;

const JOB_SEEKER = "jobSeeker";
const JOB_SEEKER2 = "jobseeker";
const OPPORTUNITIES = "opportunity";
const JOBS = "jobs";
const ALL_AVAILABLE_JOBS_PROJECTS = "getAllAvailableJobProject";
const ALL_SAVED_JOBS_PROJECTS = "getAllBookMarkedJobsProject";
const ALL_APPLIED_JOBS_PROJECTS = "getAllAppliedJobProject";
const GET_APPLIED_JOBS_PROJECTS = "getAppliedJobProject";
const JOBS_FOR_YOU_FROM_APPLIED = "getMatchingJobOrProjectsFromApplied";
const ADD_UPDATE_JOB_APPLICATION = "insertUpdateJobApplication";
const MATCHED_JOB_PROJECT = "getMatchingJobProject";
const ALL_JOB_PROJECT = "ALL";
const JOB = "JOB";
const PROJECT = "PROJECT";
const PAGE_NO = "pageNo";
const PAGE_SIZE = "pageSize";
const INSERT_BOOKMARK = "/jobseeker/jobs/bookMarkJob";

const GET_SMARTSKILL_ASESSMENT_DETAILS = "/getSmartskillsAssessment.json";

export function getAppliedJobsOrProjectsForB2C(isJob, params) {
  return request({
    url:
        API_BASE_SKILLSTREETS +
        "/" +
        JOB_SEEKER2 +
        "/" +
        JOBS +
        "/" +
        GET_APPLIED_JOBS_PROJECTS +
        "/" +
        `${isJob ? JOB : PROJECT}`,
    method: "GET",
    params,
  });
}

export function getMatchedJobsOrProjects(isJob, params) {
  return request({
    url:
      API_BASE_SKILLSTREETS +
      "/" +
      JOB_SEEKER2 +
      "/" +
      JOBS +
      "/" +
      MATCHED_JOB_PROJECT +
      "/" +
      `${isJob ? JOB : PROJECT}`,
    method: "GET",
    params,
  });
}

export function getALLJobDetails(isJob, params) {
  return request({
    url:
      API_BASE_SKILLSTREETS +
      "/" +
      JOB_SEEKER2 +
      "/" +
      JOBS +
      "/" +
      ALL_JOB_PROJECT +
      "/" +
      `${isJob ? JOB : PROJECT}`,
    method: "GET",
    params,
  });
}

export function getJobsAndProjectsForYou(isJob, params) {
  return request({
    url:
      API_BASE_SKILLSTREETS +
      "/" +
      JOB_SEEKER2 +
      "/" +
      JOBS +
      "/" +
      JOBS_FOR_YOU_FROM_APPLIED +
      "/" +
      `${isJob ? JOB : PROJECT}`,
    method: "GET",
    params,
  });
}

export function getSavedJobsOrProjects(isJob, params) {
  return request({
    url:
      API_BASE_SKILLSTREETS +
      "/" +
      JOB_SEEKER2 +
      "/" +
      JOBS +
      "/" +
      ALL_SAVED_JOBS_PROJECTS +
      "/" +
      `${isJob ? JOB : PROJECT}`,
    method: "GET",
    params,
  });
}

export function getAvailableJobsOrProjects(isJob, pageNo = 0, pageSize = 5) {
  return request({
    url:
      API_BASE_SKILLSTREETS +
      "/" +
      JOB_SEEKER +
      "/" +
      OPPORTUNITIES +
      "/" +
      ALL_AVAILABLE_JOBS_PROJECTS +
      "/" +
      `${isJob ? JOB : PROJECT}` +
      "?" +
      PAGE_NO +
      "=" +
      pageNo +
      "&" +
      PAGE_SIZE +
      "=" +
      pageSize,
    method: "GET",
  });
}

export function getAppliedJobsOrProjects(isJob, params) {
  return request({
    url:
      API_BASE_SKILLSTREETS +
      "/" +
      JOB_SEEKER +
      "/" +
      OPPORTUNITIES +
      "/" +
      ALL_APPLIED_JOBS_PROJECTS +
      "/" +
      `${isJob ? JOB : PROJECT}`,
    method: "GET",
    params,
  });
}

export function applyForJobOrProject(jobOrProjectId) {
  return request({
    url:
      API_BASE_SKILLSTREETS +
      "/" +
      JOB_SEEKER.toLowerCase() +
      "/" +
      JOBS +
      "/" +
      ADD_UPDATE_JOB_APPLICATION +
      "/" +
      jobOrProjectId,
    method: "POST",
  });
}

export function saveJobOrProjectAPI(id, isBookMarked) {
  return request({
    url: API_BASE_SKILLSTREETS + INSERT_BOOKMARK,
    method: "POST",
    body: JSON.stringify({ jobProjectPostedId: id, isBookMarked }),
  });
}

export function getSmartSkillAssessmentDetails() {
  return request({
    url: API_BASE_TALENTSPY + GET_SMARTSKILL_ASESSMENT_DETAILS,
    method: "GET",
  });
}
