import appConfigs from "../config/appConfigs";
import {
    request
} from "../shared/helpers/RequestWrapper";
const API_HOST = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;
export const COUNTRY_URL = "/country";
export const COUNTRY_GET_ALL_URL = COUNTRY_URL + "/getAll";
export const COUNTRY_ADD_URL = COUNTRY_URL + "/insert";
export const COUNTRY_SUGGEST_URL = COUNTRY_URL + "/suggest";

export function addCountry(body) {
    return request({
        url: API_HOST + COUNTRY_ADD_URL,
        method: "post",
        body: body
    });
}

export function getCountries(params) {
    return request({
        url: API_HOST + COUNTRY_GET_ALL_URL,
        method: "get",
        params
    });
}

export function getSuggestCountry(countryName) {
    return request({
        url: API_HOST + COUNTRY_SUGGEST_URL + "/" + countryName,
        method: "get"
    });
}