import { request } from '../../shared/helpers/RequestWrapper';
import appConfigs from '../../config/appConfigs';

const API_BASE_TALENTSPY = appConfigs.endpoint.API_BASE_TALENTSPY;
const API_BASE_SKILLSTREETS = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;

const GET_CURRENT_EMP_DETAILS_SS = '/jobseeker/getCurrentJobSeekerProfile';
const UPDATE_EMPLOYEE_PROFILE_TS = '/updateEmployeeProfile.json';
const UPDATE_EMPLOYEE_PROFILE_PICTURE = '/jobseeker/uploadProfilePhoto';
const UPLOAD_RESUME = '/jobseeker/uploadResume';

const GET_USER_SKILLS = '/jobseeker/skills/getByJobSeekerId';
const GET_JOBSEEKER_PREFFERED_SKILLS = '/preferredSkills/getByJobsseker';
const GET_JOB_SEEKER_SMART_RESUME_DETAILS = '/jobseeker/getJobSeekerProfileForResumePDF';
const DELETE_JOBSEEKER_RESUME = '/jobseeker/deleteResume';
const PREFERENCE_UPDATE_PERMISSION = '/jobseeker/preference/updatePermission';
const PREFERENCE_GET_PERMISSION = '/jobseeker/preference/getPermissionDetails';

export function getEmployeeProfileSS() {
   return request({
      url: API_BASE_SKILLSTREETS + GET_CURRENT_EMP_DETAILS_SS,
      method: 'GET',
   });
}


export function getpreferencePermission() {
   return request({
      url: API_BASE_SKILLSTREETS + PREFERENCE_GET_PERMISSION,
      method: 'GET',
   });
}

export function preferenceUpdatePermission(data) {
   console.log("datadatadata",data)
   return request({
      url: API_BASE_SKILLSTREETS + PREFERENCE_UPDATE_PERMISSION,
      method: 'POST',
      body:JSON.stringify(data),
   });
}



export function updateEmployeeDetail(data) {
   return request({
      url: API_BASE_TALENTSPY + UPDATE_EMPLOYEE_PROFILE_TS,
      method: 'POST',
      body: JSON.stringify(data),
   });
}

export function updateEmployeeProfilePhoto(body) {
   return request({
      url: API_BASE_SKILLSTREETS + UPDATE_EMPLOYEE_PROFILE_PICTURE,
      method: 'post',
      body: body,
   });
}

export function getCurrentEmployeeSkills() {
   return request({
      url: API_BASE_SKILLSTREETS + GET_USER_SKILLS,
      method: 'GET',
   });
}

export function getCurrentJobSeekerPreferredSkills() {
   return request({
      url: API_BASE_SKILLSTREETS + GET_JOBSEEKER_PREFFERED_SKILLS,
      method: 'GET',
   });
}

/**
 * This is used to upload job seeker resume
 * @param body
 * @return {Promise | {message: string, status: boolean}}
 */
export const uploadJobSeekerResume = (body) => {
   return request({
      url: API_BASE_SKILLSTREETS + UPLOAD_RESUME,
      method: 'post',
      body: body,
   });
};

export function deleteJobSeekerResume() {
   return request({
      url: API_BASE_SKILLSTREETS + DELETE_JOBSEEKER_RESUME,
      method: 'DELETE',
   });
}


export function getJobSeekerDetailsForSmartResume(emailId) {
   let URL = API_BASE_SKILLSTREETS + GET_JOB_SEEKER_SMART_RESUME_DETAILS;
   if(emailId && emailId != null) {
      URL = API_BASE_SKILLSTREETS + GET_JOB_SEEKER_SMART_RESUME_DETAILS + "?emailId=" + emailId;
   }
   return request({
      url: URL,
      method: 'GET',
   });
}
