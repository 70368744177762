export const FETCH_PROGRAM_DETAILS_BY_ID = 'FETCH_PROGRAM_DETAILS_BY_ID';
export const SET_APPLICATION_STATUS_FLAG = 'SET_APPLICATION_STATUS_FLAG';

export const getDataStarAccelerators = (data) => {
  return {
    type: FETCH_PROGRAM_DETAILS_BY_ID,
    payload: { data: data }
  };
}

export const setApplicationStatusFlag = (data) => {
  return {
    type: SET_APPLICATION_STATUS_FLAG,
    payload: { data: data }
  }
}