import React, { Component, Fragment } from 'react';
import {
   Button,
   Col, Container,
   Row,
} from 'reactstrap';
import './custom.scss';
import CloseIcon from 'mdi-react/CloseIcon';
import SendIcon from 'mdi-react/SendIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { showNotification } from '../../shared/components/toastMessage';
import appConfig from '../../config/appConfigs';
import { sendIndividualInvitation } from '../../api/invitation.api';
import { validate as validateEmail } from 'email-validator';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/igm;

class InviteIndividualMobilePage extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isOpen: false,
         emails: [],
         emailValue: '',
      };
   }

   handleEmails = (event) => {
      let emailList = this.state.emails;
      if (event.includes(",")) {
         let email = event.substr(
            0,
            event.indexOf(',')
         );

         this.setState({
            emailValue: email,
         });
         if (validateEmail(email)) {
            if (this.state.emails.indexOf(email) === -1) {
               emailList.push(
                  event.substr(0, event.indexOf(','))
               );
               this.setState({
                  emails: emailList,
               });
            }
            
            this.setState({
               emailValue: '',
            });
         }
      }
   };

   handleEmailChange = (event) => {
      let value = event.target.value;
      this.setState({
         emailValue: event.target.value,
      },() => {
         this.handleEmails(value);
      });
      
   };

   setIsOpen = (isOpen) => {
      this.setState({
         isOpen,
      });
   };

   removeEmail = (index) => {
      let emailList = this.state.emails;
      let emailListNew = [];
      for (let i in emailList) {
         if (i !== index) {
            console.info(i, index);
            emailListNew.push(emailList[i]);
         }
      }
      this.setState({
         emails: emailListNew,
      });
      console.info(emailListNew);
   };

   sendEmails = () => {
      this.setState({
         loading: true,
      });
      const { emailValue, emails } = this.state;
      if (validateEmail(emailValue)) {
         if (this.state.emails.indexOf(emailValue) === -1) {
            emails.push(emailValue);
         }
      } else {
         if (emailValue.trim().length > 0) {
            this.setState({
               loading: false,
            });
            showNotification('Invalid Email');
         }
      }
      if (emails && emails.length > 0) {
         sendIndividualInvitation(emails).then((response) => {
            if (response && response.status) {
               showNotification(response.message, 'primary');
               this.setState({
                  emails: [],
                  emailValue: '',
               });
            }
            this.setState({
               loading: false,
               emails: [],
               emailValue: '',
            });
         });
      } else {
         this.setState({
            loading: false,
         });
      }
   };

   getAndCopyInviteLink = () => {
      this.setState({
         loading: true,
      });
      const APHRODITE_UI = appConfig.endpoint.APHRODITE_ROOT_UI;
      let registrationLink = APHRODITE_UI + '/register';
      let textField = document.createElement('textarea');
      textField.innerText = registrationLink;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      showNotification('Link Copied!', 'primary');
      this.setState({
         loading: false,
      });
   };

   toggleModal = (isOpen) => {
      this.setState({
         emails: [],
      });
      this.props.toggle(!isOpen);
   };

   render() {
      const { emailValue, emails, loading } = this.state;
      return (
         <Fragment>
            <Container>
               <Row className="post-page-icons mb-3 mt-3" >
                  <Col xs={1} sm={1} lg={1}>
                     <ArrowBackIcon
                        className="back-icon"
                        onClick={() => this.props.setInviteIndividual()}
                        style={{ cursor: 'pointer' }}
                     />
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                     <h4 style={{ textTransform: 'none' }}>
                        <b>Invite Individual</b>
                     </h4>

                     {loading ? (
                        <div className="panel__refresh">
                           <LoadingIcon style={{ marginLeft: '46%' }} />
                        </div>
                     ) : null}
                  </Col>
               </Row>
                  <Row
                     className={'mt-4'}
                     data-tut={'tour_invite_individual_email'}
                  >
                     <Col md={'12'} className={"mt-1"}>
                        <div className={'divider'} />
                        <h4><b>By Email</b></h4>
                        <p className={'mt-1'}>
                           Get your friends to join Rubiqe. You can add multiple
                           email addresses by entering commas.
                        </p>
                        <div className="input-icons">
                           <TextField
                              label={'Emails'}
                              variant={'outlined'}
                              size={'small'}
                              className={'mt-3'}
                              value={emailValue}
                              placeholder="Emails"
                              onChange={this.handleEmailChange}
                              // onKeyUp={this.handleEmails}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <SendIcon
                                          style={{ cursor: 'pointer' }}
                                          onClick={this.sendEmails}
                                          title={'Send'}
                                       />
                                    </InputAdornment>
                                 ),
                              }}
                           />
                        </div>
                        <div className={'emails-tags d-flex flex-wrap mt-2'}>
                           {emails.map((item, index) => (
                              <span className={'email-tag-box'}>
                                 {item}{' '}
                                 <CloseIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.removeEmail(index)}
                                 />
                              </span>
                           ))}
                        </div>
                     </Col>
                  </Row>
                  <Row className={'mt-4'}>
                     <Col md={'12'}>
                        <div className={'d-flex'}>
                           <div style={{ width: "95%"}}>
                              <h4><b>Share Link</b></h4>
                              <p className={'mt-1'}>
                                 Spread the word and invite your peers! Copy and
                                 share the link.
                              </p>
                           </div>
                        </div>
                     </Col>
                  </Row>
            </Container>
            <Container>
               <Row>
                  <Col xl={12}  style={{
                     position: "fixed",
                     bottom: "10px",
                     width: "100%"
                  }}>
                     <div>
                        <Button
                           className={'mt-4'}
                           style={{width: "100%"}}
                           color={'primary'}
                           onClick={this.getAndCopyInviteLink}
                        >
                           Copy Link
                        </Button>
                     </div>
                  </Col>
               </Row>
            </Container>
         </Fragment>
      );
   }
}

export default InviteIndividualMobilePage;
