import {request} from "../../../shared/helpers/RequestWrapper";
import appConfig from "../../../config/appConfigs";
const SKILLSTREETS_ROOT = appConfig.endpoint.SKILLSTREETS_ROOT;
const AUTH_SERVICE = SKILLSTREETS_ROOT;
const CHAT_SERVICE = SKILLSTREETS_ROOT;
// const request = (options) => {
//   const headers = new Headers();
//
//   if (options.setContentType !== false) {
//     headers.append("Content-Type", "application/json");
//   }
//
//   if (localStorage.getItem("accessToken")) {
//     headers.append(
//       "Authorization",
//       "Bearer " + localStorage.getItem("accessToken")
//     );
//   }
//
//   const defaults = { headers: headers };
//   options = Object.assign({}, defaults, options);
//
//   return fetch(options.url, options).then((response) =>
//     response.json().then((json) => {
//       if (!response.ok) {
//         return Promise.reject(json);
//       }
//       return json;
//     })
//   );
// };

export function login(loginRequest) {
  return request({
    url: AUTH_SERVICE + "/signin",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function facebookLogin(facebookLoginRequest) {
  return request({
    url: AUTH_SERVICE + "/facebook/signin",
    method: "POST",
    body: JSON.stringify(facebookLoginRequest),
  });
}

export function signup(signupRequest) {
  return request({
    url: AUTH_SERVICE + "/users",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function getCurrentUser() {
/*  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }*/

  return request({
    url: AUTH_SERVICE + "/users/me",
    method: "GET",
  });
}

export function getUsers() {
 /* if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
*/
    return request({
        url: CHAT_SERVICE + "/api/messages/contacts",
        method: "GET",
    });

  // let users = [{id:1}];
  // return users;
}

export function countNewMessages(senderId, recipientId) {
  /*if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }*/

  return request({
    url: CHAT_SERVICE + "/api/messages/count?senderId=" + encodeURIComponent(senderId) + "&recipientId=" + encodeURIComponent(recipientId) ,
    method: "GET",
  });
}

export function findChatMessages(senderId, recipientId) {
  /*if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }*/

  return request({
    url: CHAT_SERVICE + "/api/messages?senderId=" + encodeURIComponent(senderId) + "&recipientId=" + encodeURIComponent(recipientId),
    method: "GET",
  });
}

export function getLastChatMessage(senderId, recipientId) {
  /*if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }*/

  return request({
    url: CHAT_SERVICE + `/api/messages/lastMessage?senderId=${encodeURIComponent(senderId)}&recipientId=${encodeURIComponent(recipientId)}`,
    method: "GET",
  });
}

export function getNewChatMessageCount(recipientId) {
  return request({
    url: CHAT_SERVICE + "/api/messages/newMessage?recipientId=" + encodeURIComponent(recipientId),
    method: "GET",
  });
}

export function findChatMessage(id) {
  /*if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }*/

  return request({
    url: CHAT_SERVICE + "/api/messages/" + encodeURIComponent(id),
    method: "GET",
  });
}

export function markAsReadUnRead(contactIdList,readFlag) {
  /*if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }*/

  return request({
    url: CHAT_SERVICE + "/api/messages/markMessagesReadUnread/"+readFlag,
    method: "POST",
    body:JSON.stringify(contactIdList),
  });
}

export function deleteContact(contactIdList) {
  /*if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }*/

  return request({
    url: CHAT_SERVICE + "/api/messages/deleteContact",
    method: "POST",
    body:contactIdList,
  });
}

