import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {RTLProps} from "../../../../../../../shared/prop-types/ReducerProps";

class CompetencyTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}


    }

    render() {
        const {competenciesAnalysis} = this.props;
        return (
            <Fragment>
                <div style={{marginTop: "20px", color: "#1D3B55"}}>
                    {competenciesAnalysis}
                </div>
                <br/><br/>
            </Fragment>
        );
    }
}

CompetencyTab.propTypes = {
    t: PropTypes.func.isRequired,
    rtl: RTLProps.isRequired,
    id: PropTypes.any,
    competenciesAnalysis: PropTypes.string,
};

export default compose(
    withTranslation("common"),
    connect((state) => ({
        rtl: state.rtl,
    }))
)(CompetencyTab);
