import {
   SET_ACCOUNT_PROFILES, SET_CURRENT_PROFILE
} from '../actions/accountActions';

const initialState = {
   profiles: [],
   currentProfile: null
};

const accountReducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_ACCOUNT_PROFILES:
         console.log("PROOOOOOOOOOOOOOOOOOFS action ", action)
         return {
            ...state,
            profiles: action.profiles,
         };
      case SET_CURRENT_PROFILE:
         return {
            ...state,
            currentProfile: action.profile
         };
      default:
         return state;
   }
};

export default accountReducer;
