import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RTLProps } from '../../../../../../../shared/prop-types/ReducerProps';
import { Col, Row } from 'reactstrap';
import OverviewTabs from './OverviewTabs';

class OverviewDescription extends React.Component {
   constructor(props) {
      super(props);

      this.state = {};
   }

   render() {
      let {
         id,
         color,
         title,
         description,
         behavioralAnalysis,
         competenciesAnalysis,
      } = this.props;
      return (
         <Fragment>
            <Row className={'mt-3'}>
               <Col md={'12'}>
                  <div style={{ color: color }}>
                     <b>{title}</b>
                  </div>
                  <div style={{ color: color }}>{description}</div>
               </Col>
               <br />
            </Row>
            <OverviewTabs
               id={id}
               behavioralAnalysis={behavioralAnalysis}
               competenciesAnalysis={competenciesAnalysis}
            />
         </Fragment>
      );
   }
}

OverviewDescription.propTypes = {
   t: PropTypes.func.isRequired,
   rtl: RTLProps.isRequired,
   color: PropTypes.any,
   title: PropTypes.any,
   description: PropTypes.string,
   id: PropTypes.any,
   behavioralAnalysis: PropTypes.string,
   competenciesAnalysis: PropTypes.string,
};

export default compose(
   withTranslation('common'),
   connect((state) => ({
      rtl: state.rtl,
   }))
)(OverviewDescription);
