/* eslint-disable react/no-array-index-key,react/no-unused-state */
import PropTypes from 'prop-types';
import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Container, Table } from 'reactstrap';
import {
   setContacts,
   setUnreadMessageCount,
} from '../../../redux/actions/messageAction';
import { hookAuth0, useAuth0 } from '../../../shared/components/auth/withAuth0';
import { EmailsProps } from '../../../shared/prop-types/EmailProps';
import { deleteContact, getUsers, markAsReadUnRead } from './ApiUtil';
import EmailListItem from './EmailListItem';
import EmailsControls from './EmailsControls';
import EmailListItemMobileView from './EmailListItemMobileView';

function InboxTable({
   emails,
   onLetter,
   contacts,
   settingActiveContact,
   ...props
}) {
   const loggedInUser = useAuth0().user;
   const currentUser = {
      id: loggedInUser.email,
      name: loggedInUser.firstName + ' ' + loggedInUser.lastName,
   };

   const [isChecked, setIsChecked] = useState(false);
   const [partialChecked, setPartialChecked] = useState(false);
   const [allChecked, setAllChecked] = useState(false);
   const [contactIdList, setContactIdList] = useState([]);
   const [contactIdListLength, setContactIdListLength] = useState(0);
   const onChangePage = (pageOfItems) => setIsChecked(pageOfItems);
   const [reloadFlag, setReloadFlag] = useState(true);

   useEffect(() => {
      if (isChecked) {
         contacts.map((key) => {
            if (!contactIdList.includes(key.id)) {
               const tempList = contactIdList;
               tempList.push(key.id);
               setContactIdList(tempList);
            }
         });
      }
      if (!isChecked) {
         const tempList = contactIdList;
         tempList.splice(0, contactIdList.length);
         setContactIdList(tempList);
      }
   }, [isChecked]);

   useEffect(() => {
      getUsers().then((response) => {
         if (response && response.status) {
            const users = response.data || [];
            setContacts(users);
            props.dispatch(setContacts(users));
         }
      });
   }, []);

   const onChangeSelectAll = () => {
      isChecked && setPartialChecked(false);
      setPartialChecked(false);
      setAllChecked(!isChecked);
      setIsChecked(!isChecked);
   };

   const updateContactIdList = (value) => {
      const tempList = contactIdList;
      if (contactIdList.includes(value)) {
         const index = contactIdList.indexOf(value);
         tempList.splice(index, 1);
      } else {
         tempList.push(value);
      }
      setContactIdList(tempList);
      setContactIdListLength(tempList.length);
      
      if (tempList.length === 0) {
         setIsChecked(false);
      }
      if (tempList.length === contacts.length) {
         setPartialChecked(false);
         setIsChecked(true);
         setAllChecked(true);
      } else {
         setAllChecked(false);
      }
      if (tempList.length > 0 && tempList.length !== contacts.length) {
         setPartialChecked(true);
         setAllChecked(false);
      } else {
         setPartialChecked(false);
      }
   };

   useEffect(() => {
   },[contactIdListLength])

   const handleMarkAsReadClick = () => {
      markAsReadUnRead(contactIdList, true).then((response) => {
         if (response && response.status) {
            props.dispatch(
               setUnreadMessageCount(
                  props.unreadMessageCount - contactIdList.length
               )
            );
            setIsChecked(false);
            setPartialChecked(false);
            setAllChecked(false);
            const tempList = contactIdList;
            tempList.splice(0, contactIdList.length);
            setContactIdList(tempList);
            setReloadFlag(false);
         }
         setReloadFlag(true);
      });
   };

   const handleMarkAsUnReadClick = () => {
      markAsReadUnRead(contactIdList, false).then((response) => {
         if (response && response.status) {
            props.dispatch(
               setUnreadMessageCount(
                  props.unreadMessageCount + contactIdList.length
               )
            );
            setIsChecked(false);
            setPartialChecked(false);
            setAllChecked(false);
            const tempList = contactIdList;
            tempList.splice(0, contactIdList.length);
            setContactIdList(tempList);
            setReloadFlag(false);
         }
         setReloadFlag(true);
      });
   };

   const handleDeleteContactClick = () => {
      deleteContact(contactIdList, true).then((response) => {
         if (response && response.status) {
            props.dispatch(setContacts(response.data));
            setIsChecked(false);
            const tempList = contactIdList;
            tempList.splice(0, contactIdList.length);
            setContactIdList(tempList);
            setReloadFlag(false);
         }
         setReloadFlag(true);
         setPartialChecked(false);
         setIsChecked(false);
      });
   };

   return (
      <div className="inbox__content">
         <div className="page-title page-title-messages pb-3" >
            <div style={{ marginLeft: '30px'}}>
               Messages
            </div>
         </div>
         {reloadFlag && (
            <EmailsControls
               onChangePage={onChangePage}
               partialChecked={partialChecked}
               allChecked={allChecked}
               onChangeSelectAll={onChangeSelectAll}
               isChecked={isChecked}
               contactIdList={contactIdList}
               totalContact={contacts}
               handleMarkAsReadClick={handleMarkAsReadClick}
               handleMarkAsUnReadClick={handleMarkAsUnReadClick}
               handleDeleteContactClick={handleDeleteContactClick}
            />
         )}
         <div className={'topbar_notification_icon_desktop'}>
            <Table className="table--bordered inbox__emails-table" responsive>
               <tbody>
                  {contacts.length !== 0 ? (
                     reloadFlag &&
                     contacts &&
                     contacts.map((contact) => (
                        <EmailListItem
                           email={contact}
                           key={contact.id}
                           itemId={contact.id}
                           onLetter={onLetter}
                           isChecked={isChecked}
                           settingActiveContact={settingActiveContact}
                           currentUser={currentUser}
                           updateContactIdList={updateContactIdList}
                        />
                     ))
                  ) : (
                     <Col
                        md={12}
                        style={{ textAlign: 'center', marginTop: '30px' }}
                     >
                        <p>no messages found</p>
                     </Col>
                  )}
               </tbody>
            </Table>
         </div>
         {/* view to display in mobile  */}
         <div className={"topbar_notification_icon_mobile"}>
            <div className={"inbox__emails-table mt-4"}>
               <Container>
               {contacts.length !== 0 ? (
                  reloadFlag &&
                  contacts &&
                  contacts.map((contact) => (
                     <EmailListItemMobileView
                        email={contact}
                        key={contact.id}
                        itemId={contact.id}
                        onLetter={onLetter}
                        isChecked={isChecked}
                        settingActiveContact={settingActiveContact}
                        currentUser={currentUser}
                        updateContactIdList={updateContactIdList}
                     />
                  ))
               ) : (
                  <Col
                     md={12}
                     style={{ textAlign: 'center', marginTop: '30px' }}
                  >
                     <p>no messages found</p>
                  </Col>
               )}
               </Container>
            </div>
         </div>

      </div>
   );
}
InboxTable.propTypes = {
   emails: EmailsProps.isRequired,
   onLetter: PropTypes.func.isRequired,
   contacts: PropTypes.array,
   settingActiveContact: PropTypes.func,
};

// export default InboxTable;
export default withRouter(
   connect((state) => ({
      contacts: state.messages.contacts,
      unreadMessageCount: state.messages.unreadMessageCount,
   }))(hookAuth0(InboxTable))
);
