/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NotificationSystem from 'rc-notification';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import {
   changeMobileSidebarVisibility,
   changeSidebarVisibility,
} from '../../redux/actions/sidebarActions';
import {
   changeThemeToDark,
   changeThemeToLight,
} from '../../redux/actions/themeActions';
import {
   changeDirectionToLTR,
   changeDirectionToRTL,
} from '../../redux/actions/rtlActions';
import {
   changeBorderRadius,
   toggleBoxShadow,
   toggleTopNavigation,
} from '../../redux/actions/customizerActions';
import {
   CustomizerProps,
   RTLProps,
   SidebarProps,
   ThemeProps,
   UserProps,
} from '../../shared/prop-types/ReducerProps';
import InviteIndividualMobilePage from '../../shared/InviteIndividualModal/InviteIndividualMobilePage';

let notification = null;


class Layout extends Component {
   static propTypes = {
      dispatch: PropTypes.func.isRequired,
      sidebar: SidebarProps.isRequired,
      customizer: CustomizerProps.isRequired,
      theme: ThemeProps.isRequired,
      rtl: RTLProps.isRequired,
      user: UserProps.isRequired,
   };

  constructor(props) {
    super(props);
    this.state = {
      isInviteIndividual: false
    }
  }


  componentDidMount() {
    NotificationSystem.newInstance(
       { style: { top: 65 } },
       (n) => (notification = n)
    );
    // setTimeout(() => showNotification(rtl.direction), 700);
  }

   componentWillUnmount() {
      notification.destroy();
   }

   changeSidebarVisibility = () => {
      const { dispatch } = this.props;
      dispatch(changeSidebarVisibility());
   };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
    if(this.state.isInviteIndividual) {
      this.setState({
        isInviteIndividual: false
      })
    }
    window.onscroll=function(){};
    document.documentElement.style.overflowY = 'auto';
  };

   changeToDark = () => {
      const { dispatch } = this.props;
      dispatch(changeThemeToDark());
   };

   changeToLight = () => {
      const { dispatch } = this.props;
      dispatch(changeThemeToLight());
   };

   changeToRTL = () => {
      const { dispatch } = this.props;
      dispatch(changeDirectionToRTL());
   };

   changeToLTR = () => {
      const { dispatch } = this.props;
      dispatch(changeDirectionToLTR());
   };

   toggleTopNavigation = () => {
      const { dispatch } = this.props;
      dispatch(toggleTopNavigation());
   };

   changeBorderRadius = () => {
      const { dispatch } = this.props;
      dispatch(changeBorderRadius());
   };

   toggleBoxShadow = () => {
      const { dispatch } = this.props;
      dispatch(toggleBoxShadow());
   };

  setInviteIndividual = () => {
    if(!this.state.isInviteIndividual) {
      this.changeMobileSidebarVisibility();
      window.onscroll=function(){window.scrollTo(0,0);};
      document.documentElement.style.overflowY = 'hidden';
    } else {
      window.onscroll=function(){};
      document.documentElement.style.overflowY = 'auto';
    }
    this.setState({
       isInviteIndividual: !this.state.isInviteIndividual
    })
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //    console.log("In index in side bar menu called , IN UPDATE COMPONENT")
  //    if( prevProps.location.pathname !== window.location.pathname) {
  //        this.setState({
  //           isInviteIndividual: false
  //        })
  //    }
  // }

   render() {
    const { customizer, sidebar, user, publicRoute } = this.props;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
      'layout--top-navigation': customizer.topNavigation,
    });

      return (
         <div className={layoutClass}>
            {/*<Customizer*/}
            {/*  customizer={customizer}*/}
            {/*  sidebar={sidebar}*/}
            {/*  theme={theme}*/}
            {/*  rtl={rtl}*/}
            {/*  changeSidebarVisibility={this.changeSidebarVisibility}*/}
            {/*  toggleTopNavigation={this.toggleTopNavigation}*/}
            {/*  changeToDark={this.changeToDark}*/}
            {/*  changeToLight={this.changeToLight}*/}
            {/*  changeToRTL={this.changeToRTL}*/}
            {/*  changeToLTR={this.changeToLTR}*/}
            {/*  changeBorderRadius={this.changeBorderRadius}*/}
            {/*  toggleBoxShadow={this.toggleBoxShadow}*/}
            {/*/>*/}

           <Topbar
              changeMobileSidebarVisibility={
                this.changeMobileSidebarVisibility
              }
              changeSidebarVisibility={this.changeSidebarVisibility}
              user={user}
              publicRoute
           />
           {!publicRoute && (
              <Sidebar
                 isInviteIndividual = {this.setInviteIndividual}
                 sidebar={sidebar}
                 props={this.props}
                 changeToDark={this.changeToDark}
                 changeToLight={this.changeToLight}
                 changeMobileSidebarVisibility={
                   this.changeMobileSidebarVisibility
                 }
              />
           )}
           {this.state.isInviteIndividual &&
           <div style={{
            position: "fixed",
            bottom: "0",
            zIndex: "99",
            height: "100vh",
            width: "100%",
            paddingTop: "60px",
            backgroundColor: 'white'
            //  position: "absolute",
            //  width: "100%",
            //  height: "100%",
            //  top: "65px",
            //  left: "0",
            //  zIndex: "10",
            //  backgroundColor: 'white',
            //  overflowY: 'hidden'
           }}
           >
             <InviteIndividualMobilePage setInviteIndividual={()=>this.setInviteIndividual()}/>
           </div>
           }
      </div>
    );
  }
}

export default withRouter(
   connect((state) => ({
      customizer: state.customizer,
      sidebar: state.sidebar,
      theme: state.theme,
      rtl: state.rtl,
      user: state.user,
   }))(Layout)
);
