import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import InboxTable from './InboxTable';
import Email from './Email';
import {
   countNewMessages,
   findChatMessage,
   findChatMessages,
   getNewChatMessageCount,
   getUsers,
} from './ApiUtil';
import {
   setActiveContact,
   setContacts,
   setMessages,
   setStompClient,
   setUnreadMessageCount,
} from '../../../redux/actions/messageAction';
import { showNotification } from '../../../shared/components/toastMessage';
import appConfig from '../../../config/appConfigs';
import { hookAuth0 } from '../../../shared/components/auth/withAuth0';
import { setTourConfig, setTourOpen } from '../../../redux/actions/tourAction';
import { tourEnum } from '../../../shared/enums/tour.enum';

const SKILLSTREETS_ROOT = appConfig.endpoint.SKILLSTREETS_ROOT;

let stompClient = null;

class InboxComponent extends Component {
   constructor(props) {
      super(props);

      this.state = {
         emails: [],
         email: false,
         mailBox: 0,
         messages: [],
         currentUser:[],
      };
   }

   onLetter = (e) => {
      e.preventDefault();
      this.setEmail(true);
   };

   setEmail = (status) => {
      this.setState({
         email: status,
      });
   };

   setActiveContact = (activeContact) => {
      // const { currentUser } = this.props;
      this.setState({
         activeContact,
      });
      this.props.dispatch(setActiveContact(activeContact));
      findChatMessages(activeContact.id, this.props.auth0.user.email).then((response) => {
         this.setMessages(response.data);
         this.props.dispatch(setMessages(response.data));
         this.getNewMessageCount();
      });
      this.countMessages(this.props.contacts);
   };

   getNewMessageCount = () => {
      getNewChatMessageCount(this.props.auth0.user.email).then(
         (newMessageCount) => {
            this.props.dispatch(setUnreadMessageCount(newMessageCount.data));
         }
      );
   };

   setMessages = (messages) => {
      this.setState({
         messages,
      });
   };

   onMailBox = (mailbox, e) => {
      e.preventDefault();
      this.setEmail(false);
      this.setMailbox(mailbox);
      // this.setActiveContact(null);
      this.props.dispatch(setActiveContact(null));
      this.setState({
         activeContact: null,
      });
      this.countMessages(this.props.contacts);
   };

   countMessages = (users) => {
      const { currentUser } = this.props;
      const { unreadMessageCount } = this.state;
      let count = unreadMessageCount;
      new Promise((resolve, reject) => {
         if (users && users.hasOwnProperty(0)) {
            users.map(async (contact, index) => {
               let response = await countNewMessages(
                  contact.id,
                  currentUser.id
               );
               count = count + response.data || 0;
               if (index === users.length) {
                  resolve(count);
               }
            });
         }
      }).then((msgCount) => {
         // this.props.dispatch(setUnreadMessageCount(msgCount));
      });
   };

   setMailbox = (mailBox) => {
      this.setState({
         mailBox,
      });
   };

   sendMessage = (msg) => {
      const { activeContact } = this.state;
      const { stompClient, messages } = this.props;
      const currentUser=this.props.auth0.user;
      if (msg.trim() !== '') {
         const message = {
            senderId: currentUser.email,
            recipientId: activeContact.id,
            senderName: currentUser.name,
            recipientName: activeContact.name,
            content: msg,
            timestamp: new Date(),
         };
         stompClient.send('/app/chat', {}, JSON.stringify(message));
         const newMessages = [...messages];
         newMessages.push(message);
         this.props.dispatch(setMessages(newMessages));
         this.setMessages(newMessages);
      }
   };

   // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
   //   if(nextProps.messages && nextProps.messages.hasOwnProperty(0)) {
   //     if(this.state.messages.length !== nextProps.messages) {
   //       this.setMessages(nextProps.messages)
   //     }
   //   }
   // }

   componentDidMount() {
      const { auth0 } = this.props;
      this.setState({
         currentUser: auth0.user,
      });
      const email = auth0.user.email;
      const Stomp = require('stompjs');
      let SockJS = require('sockjs-client');
      SockJS = new SockJS(`${SKILLSTREETS_ROOT}/public/ws`);
      stompClient = Stomp.over(SockJS);

      stompClient.connect(
         {},
         (connection) => {
            stompClient.subscribe(
               '/user/' + email + '/queue/messages',
               this.onMessageReceived
            );
            this.props.dispatch(setStompClient(stompClient));
         },
         (error) => {
            console.log('ERROR :: ', error);
         }
      );
      this.props.dispatch(setTourConfig(tourEnum.ORG_MESSAGES));
      setTimeout(() => {
         this.props.dispatch(setTourOpen(true));
      }, 500);
   }

   componentWillUnmount() {
      this.props.dispatch(setTourOpen(false));
   }

   onMessageReceived = (newMessage) => {
      const { messages } = this.props;
      const { activeContact} = this.state;
      const notification = JSON.parse(newMessage.body);
      if (activeContact) {
         if (activeContact.id === notification.senderId) {
            findChatMessage(notification.id).then((response) => {
               const newMessages = messages;
               newMessages.push(response.data);
               this.props.dispatch(setMessages(newMessages));
               this.setState({
                  messages:newMessages
               });

            });
         } else {
            showNotification(
               'Received a new message from ' + notification.senderName,
               'primary'
            );
         }
      } else {
         getUsers().then((response) => {
            const users = response.data || [];
            this.props.dispatch(setContacts(null));
            setTimeout(() => {
               this.props.dispatch(setContacts(users));
            }, 100);
         });
      }
   };

   render() {
      const { email,  mailbox, activeContact, messages } = this.state;
      const { contacts, currentUser } = this.props;
      return (
         <Fragment>
            {email ? (
               <Email
                  onSubmit
                  onBack={(e) => this.onMailBox(mailbox, e)}
                  messages={messages}
                  sendMessage={this.sendMessage}
                  activeContact={activeContact}
               />
            ) : (
               contacts && (
                  <InboxTable
                     // emails={emails}
                     onLetter={this.onLetter}
                     contacts={contacts}
                     currentUser={currentUser}
                     settingActiveContact={this.setActiveContact}
                  />
               )
            )}
         </Fragment>
      );
   }
}

export default connect((state) => ({
   contacts: state.messages.contacts,
   stompClient: state.messages.stompClient,
   currentUser: state.messages.currentUser,
   messages: state.messages.messages,
}))(hookAuth0(InboxComponent));
