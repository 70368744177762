import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    Card,
   Col,
   Collapse,
   Container,
   Modal,
   ModalBody,
   ModalHeader,
   Nav,
   NavItem,
   NavLink,
   Row, TabContent, TabPane,
} from 'reactstrap';
import Cookie from 'universal-cookie';
import appConfigs from '../../../config/appConfigs';
import { setTourConfig, setTourOpen } from '../../../redux/actions/tourAction';
import { hookAuth0 } from '../../../shared/components/auth/withAuth0';
import { roleEnum } from '../../../shared/enums';
import { tourEnum } from '../../../shared/enums/tour.enum';
import InviteIndividualModal from '../../../shared/InviteIndividualModal';
import { AuthOProps, UserProps } from '../../../shared/prop-types/ReducerProps';
import TopbarMenuLink from './TopbarMenuLink';
import CreateOrgIndividualModal from '../../../shared/CreateOrgIndividualModal';
import { window } from 'mixpanel-browser/src/utils';
import { getpreferencePermission, preferenceUpdatePermission } from '../../../api/Individual/employee.api';
import { showErrorNotification } from '../../../shared/components/toastMessage';
import FormControl from '@material-ui/core/FormControl';
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import classnames from 'classnames';

const cookie = new Cookie();

const profileTabs = [
   {
      name: 'Account',
      value: 'account',
   },
   {
      name: 'Notifications',
      value: 'notifications',
   },
];

class TopbarSetting extends PureComponent {
   static propTypes = {
      user: UserProps.isRequired,
      auth0: AuthOProps.isRequired,
   };

   constructor() {
      super();
      this.state = {
         collapse: false,
         orgModalFlag: false,

         jobAlertTelegram: false,
         jobAlertWhatsapp: false,
         activeTab: 'account',
      };
   }

   toggle = () => {
      this.setState((prevState) => ({ collapse: !prevState.collapse }));
      // this.props.dispatch(setTourOpen(false));
      // this.props.dispatch(setTourConfig(tourEnum.INVITE_IND));
      // setTimeout(() => {
      //    this.props.dispatch(setTourOpen(true));
      // }, 100);
   };

   logout = () => {
      localStorage.removeItem('thecads');
   };

   toggleInvite = () => {
      this.toggleInviteModal(true);
      this.props.dispatch(setTourOpen(false));
      this.props.dispatch(setTourConfig(tourEnum.INVITE_IND_MODAL));
      setTimeout(() => {
         this.props.dispatch(setTourOpen(true));
      }, 800);
   };

   toggleInviteModal = (status) => {
      this.setState({
         inviteEmpModal: status,
      });
   };

   toggleOrgModal = () => {
      this.setState({
         orgModalFlag: !this.state.orgModalFlag,
      });
   };

   tutorials = () => {
      this.props.history.push('/individual/tutorials');
      this.setState((prevState) => ({ collapse: !prevState.collapse }));
   };

   rewindTour = () => {
      localStorage.setItem('tourCompleted', "no");
      cookie.set('showTour', 'yes', {
         domain: appConfigs.endpoint.BASE_COOKIE,
      });
      cookie.set('activeTab', window.location.href.split('/')[4], {
         domain: appConfigs.endpoint.BASE_COOKIE,
      });

   };

   cookieRemove = () => {
      cookie.remove('activeTab', { domain: '.' + appConfigs.endpoint.BASE_COOKIE, path: '/' });
      cookie.remove('access_token', { domain: '.' + appConfigs.endpoint.BASE_COOKIE, path: '/' });
      cookie.remove('last_profile', { domain: '.' + appConfigs.endpoint.BASE_COOKIE, path: '/' });
      cookie.remove('orgid', { domain: '.' + appConfigs.endpoint.BASE_COOKIE, path: '/' });
      cookie.remove('subdomain', { domain: '.' + appConfigs.endpoint.BASE_COOKIE, path: '/' });
      cookie.remove('showTour', { domain: '.' + appConfigs.endpoint.BASE_COOKIE, path: '/' });
   };

   logoutAll = () =>{
      this.cookieRemove();
      cookie.remove("access_token", { domain: '.cads.ai' })
      cookie.remove("user_data", { domain: '.cads.ai' })
      cookie.remove("access_token", { domain: '.cads.ai', path:'/' })
      cookie.remove("user_data", { domain: '.cads.ai', path:'/' })
      cookie.remove("token_data", { domain: '.cads.ai' })
      cookie.remove("token_data", { domain: '.cads.ai', path:'/' })
      cookie.remove("subdomain", { domain: '.cads.ai'})
      cookie.remove("subdomain", { domain: '.cads.ai', path:'/' })
      this.props.auth0.logout({
         redirectUri: appConfigs.endpoint.LOGIN_ROOT,
      });
   };


   settingModal = () => {
      this.setState({
         isopen: !this.state.isopen,
      }, () => {
         if(window.location.href.includes("individual")) {
            getpreferencePermission()
               .then((response) => {
                  this.setState({
                     jobAlertTelegram: response.data.jobAlertTelegram,
                     jobAlertWhatsapp: response.data.jobAlertWhatsapp,
                     openToJobStatus: response.data.openToJobStatus,
                  });
               })
               .catch((error) => {
                  showErrorNotification(error);
                  console.error('getCourseStatus error : ', error);
               });
         }
      });
   };

    handleChange = (e) => {
      console.log("eeeeeeeeeeeeeeeee",e.target.name)
      if (e.target.name === 'jobAlertWhatsapp') {
         this.setState(() => {
            return {
               jobAlertWhatsapp: !this.state.jobAlertWhatsapp,
            };
         },()=>this.saveJobPreferece());
      }
      else if(e.target.name === 'jobAlertTelegram') {
         this.setState({
            jobAlertTelegram : !this.state.jobAlertTelegram
         },()=>this.saveJobPreferece())
      }
   };

    componentDidMount() {
       if(window.location.href.includes("individual")) {
          this.getpreferencePermission()
       }
    }

   getpreferencePermission = () => {
      getpreferencePermission()
         .then((response) => {
            this.setState({
               jobAlertTelegram: response.data.jobAlertTelegram,
               jobAlertWhatsapp: response.data.jobAlertWhatsapp,
            });
         })
         .catch((error) => {
            showErrorNotification(error);
            console.error('getCourseStatus error : ', error);
         });
   }

   saveJobPreferece = () => {
      console.log('saveJobPreferece');
      preferenceUpdatePermission({
         'jobAlertTelegram': this.state.jobAlertTelegram,
         'jobAlertWhatsapp': this.state.jobAlertWhatsapp,
      })
         .then((response) => {
            console.log('tttttttttttttttttttttt');
            this.setState({
               // isopen: !this.state.isopen,
            });
         })
         .catch((error) => {
            showErrorNotification(error);
            console.error('getCourseStatus error : ', error);
         });

   };

   toggleSettingModal(tab) {
      const { activeTab } = this.state;
      if (activeTab !== tab) {
         let update = { activeTab: tab };
         this.setState({ ...update });
      }
   }

   render() {
      const { auth0 } = this.props;
      const { collapse } = this.state;
      const { activeTab } = this.state;

      return (
         <div className='topbar__profile d-none d-md-block'>

            <button
               className='topbar__btn topbar__btn--mail topbar__btn--new'
               type='button'
               style={{ paddingLeft: 0 }}
               onClick={this.toggle}
               data-tut={'tour_settings_menu'}
            >
               <DotsHorizontalIcon />
            </button>
            {collapse && (
               <button
                  className='topbar__back'
                  type='button'
                  onClick={this.toggle}
               />
            )}
            <Collapse isOpen={collapse} className='topbar__menu-wrap'>
               <div className='topbar__menu'>
                  <div className='topbar__collapse-title-wrap-settings'>
                     <h5 className='bold-text'>Settings</h5>
                  </div>
                  {auth0.user.attributes?.variation &&
                  auth0.user.attributes?.variation[0] ===
                  roleEnum.PERKESO_USER ? (
                     <TopbarMenuLink
                        title={'Tutorials'}
                        onClick={this.tutorials}
                        id={'tutorials_link'}
                     />
                  ) : (
                     <>
                        <TopbarMenuLink
                           title={'Invite Individuals'}
                           onClick={this.toggleInvite}
                           id={'tour_invite_individual_link'}
                        />
                        {cookie.get("program") === "q3management" &&
                           <TopbarMenuLink
                              title={'Replay On-Boarding'}
                              onClick={this.rewindTour}
                              id={'tour_invite_individual_link'}
                           />}
                        {window.location.href.includes("individual") &&
                        <TopbarMenuLink
                           title={'Setting'}
                           onClick={this.settingModal}
                           id={'tour_invite_individual_link'}
                        />
                        }
                     </>
                  )}
                  <TopbarMenuLink
                     title='Log Out'
                     onClick={
                        this.logoutAll
                     }
                  />
               </div>
            </Collapse>
            <CreateOrgIndividualModal
               isOpen={this.state.orgModalFlag}
               toggle={this.toggleOrgModal}
            />
            <InviteIndividualModal
               isOpen={this.state.inviteEmpModal}
               toggle={this.toggleInviteModal}
            />
            <Modal isOpen={this.state.isopen} className={'setting-modalContent'} toggle={this.settingModal} size={'lg'}>

               <ModalHeader toggle={this.settingModal}>Setting</ModalHeader>
               <div style={{ borderBottom: '1px solid #dee2e6' }} />
               <ModalBody className={'modal-question'} style={{paddingTop: '0px'}}>
                  <React.Fragment>
                     <Row>
                        <Col xl={4} style={{borderRight: '1px solid #dee2e6', height: '350px'}}>
                           <div className='profile__card tabs tabs--bordered-bottom d-none d-md-block'
                                style={{ position: 'relative' }}>
                              <div className='tabs__wrap p-3'>
                                 <Nav tabs className={'settingmodal'}>
                                    {profileTabs.map((opportunityTab, index) => {
                                       return (
                                          <NavItem key={index}>
                                             <NavLink
                                                className={classnames({
                                                   active: activeTab === opportunityTab.value,
                                                })}
                                                onClick={() => {
                                                   this.toggleSettingModal(opportunityTab.value);
                                                }}
                                                data-tut={`tour_${opportunityTab.value}_tab_link`}
                                             >
                                                {opportunityTab.name}
                                             </NavLink>
                                          </NavItem>
                                       );
                                    })}

                                 </Nav>
                              </div>
                           </div>
                        </Col>
                        <Col xl={8}>
                           <div className={'home-page-title d-md-none'}>
                              My Profile
                           </div>
                           <Container className={'dashboard p-3'}>
                              <Row className={'mt-3'}>
                                 <Col md={12} lg={12} xl={12}>
                                    <Card style={{ paddingBottom: '0rem' }}>
                                       <div className='profile__card tabs tabs--bordered-bottom'>
                                          <div className='tabs__wrap'>
                                             <TabContent
                                                className='tabs__opportunities'
                                                activeTab={activeTab}
                                             >
                                                {activeTab === 'account' && (
                                                   <TabPane tabId='account'>

                                                   </TabPane>
                                                )}
                                                {activeTab === 'notifications' && (
                                                   <TabPane tabId='notifications'>
                                                      <Row>
                                                         <Col xl={9}>
                                                            <p style={{ fontSize: 'large', color: '#2E485C' }}>Whatsapp Alert</p>
                                                         </Col><Col xl={3}>
                                                         <FormControl component='fieldset' className={'profile_tab_switch'}>
                                                            <FormGroup aria-label='position' row>
                                                               <FormControlLabel
                                                                  // value="opentowork"
                                                                  checked={this.state.jobAlertWhatsapp}
                                                                  control={<Switch />}
                                                                  name={'jobAlertWhatsapp'}
                                                                  onChange={(event) => this.handleChange(event)}
                                                               />
                                                            </FormGroup>
                                                         </FormControl>
                                                      </Col>
                                                      </Row>
                                                      <Row>
                                                         <Col xl={9}>
                                                            <p style={{ fontSize: 'large', color: '#2E485C' }}>Telegram Alert</p>
                                                         </Col><Col xl={3}>
                                                         <FormControl component='fieldset' className={'profile_tab_switch'}>
                                                            <FormGroup aria-label='position' row>
                                                               <FormControlLabel
                                                                  // value="opentowork"
                                                                  checked={this.state.jobAlertTelegram}
                                                                  control={<Switch />}
                                                                  name={'jobAlertTelegram'}
                                                                  onChange={(event) => this.handleChange(event)}
                                                               />
                                                            </FormGroup>
                                                         </FormControl>
                                                      </Col>
                                                      </Row>
                                                   </TabPane>
                                                )}
                                             </TabContent>
                                          </div>
                                       </div>
                                    </Card>
                                 </Col>
                              </Row>
                           </Container>
                        </Col>
                     </Row>

                  </React.Fragment>

               </ModalBody>
            </Modal>
         </div>
      );
   }
}

export default connect((state) => ({
   activeTab: state.tour,
}))(withRouter(hookAuth0(TopbarSetting)));
