import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Col} from "reactstrap";
import DomainIcon from "mdi-react/DomainIcon";

class ApplicantExperienceList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orgProfilePic: props.experience.orgProfilePic,
            designation: props.experience.designation,
            degree: props.experience.degree,
            organizationName: props.experience.organizationName,
            startDate: props.experience.startDate,
            // startYear: props.experience.startYear,
            endDate: props.experience.endDate,
            // endYear: props.experience.endYear,
            location: props.experience.location,
            countryName: props.experience.countryName,
            description: props.experience.description,
            experienceId: props.experience.id,
            isCurrent: props.experience.isCurrent,
            type: props.experience.type,
            id: props.experience.id,
        }

    }

    render() {

        let {index, experience} = this.props;
        let {
            designation,
            degree,
            organizationName,
            startDate,
            // startYear,
            endDate,
            // endYear,
            location,
            countryDetails,
            contryName,
            description,
            isCurrent,
            type,
            orgProfilePic
        } = experience;

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let startMonth = new Date(startDate).getMonth();
        let startYear = new Date(startDate).getFullYear();
        let endMonth = new Date(endDate).getMonth();
        let endYear = new Date(endDate).getFullYear();

        if(isCurrent){
            let date= new Date();
            endYear= date.getFullYear();
            endMonth= date.getMonth()
        }

        let totalMon = ((endYear-startYear)*12)+(12-startMonth)+ endMonth;
        let finalYears = endYear === startYear ? 0 : Math.floor(totalMon/12);
        let finalMon = endYear === startYear ? endMonth - startMonth : totalMon %12 ;

        return (
            <Fragment>

                <div
                    key={index}
                    className="m-1 d-flex w-100"
                >
                    <Col md={2} xl={1} className="d-inline-block">
                        {orgProfilePic ?
                            <img src={orgProfilePic} className={"rounded-circle experience-company__img"}
                                 alt="Company logo" />
                            :
                            <div className="experience-company__no-img-icon text-center">
                                <DomainIcon size={54} />
                            </div>
                        }
                    </Col>
                    <Col md={10} xl={11} className="d-inline-block">
                        <span className="individual-exp-name">
                            {(type === 0)
                                ? designation
                                : degree
                            }
                            </span>

                        <div className={"individual-exp-name"} style={{"font-size": "14px"}}>
                            {organizationName}
                        </div>
                        <div className="individual-exp-loc-org">
                            {`${months[startMonth]} ${startYear} - `}
                            {isCurrent
                                ? <>Present - </>
                                : `${months[endMonth]} ${endYear} -`
                            }
                            {finalYears > 0 && (` ${finalYears} Year`)}
                            {finalMon > 0 && (` ${finalMon} Month`)}
                        </div>
                        <div className={"individual-exp-loc-org"}>
                            {location && location}{' - '}{countryDetails && countryDetails.name}{contryName}
                        </div>
                        <br/>
                        <div className="individual-exp-desc">
                            {description}
                        </div>
                    </Col>
                </div>

            </Fragment>
        );
    }
}

export default connect((state) => ({
    rtl: state.rtl
}))(ApplicantExperienceList);
