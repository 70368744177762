import React, { Component, Suspense } from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import '../../scss/app.scss';
import PropTypes from 'prop-types';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import Auth0Provider from '../../shared/components/auth/withAuth0';
import Loading from '../../shared/components/Loading';
import Rollbar from 'rollbar';

i18n.init(i18nextConfig);

const ThemeComponent = ({ children, themeName }) => {
   const theme = createMuiTheme({
      palette: {
         type: themeName === 'theme-dark' ? 'dark' : 'light',
      },
   });
   return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ThemeComponent.propTypes = {
   children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.object,
   ]),
   themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect((state) => ({
   themeName: state.theme.className,
}))(ThemeComponent);

class App extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isError: false,
         rollbar: new Rollbar({
            accessToken: process.env.REACT_APP_ROLLBAR_KEY,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
               environment: process.env.REACT_APP_ROLLBAR_ENV,
            },
         }),
      };
   }

   onRedirectCallbackAuth0 = (appState) => {
      this.setState({
         redirectTo: appState.pathname,
         isError: appState.iserror || false,
      });
   };

   render() {
      const { redirectTo, isError } = this.state;
      return (
         <Provider store={store}>
            <Auth0Provider
               redirect_uri={`${window.location.origin}/`}
               returnTo={`${window.location.origin}/`}
               onRedirectCallback={this.onRedirectCallbackAuth0}
            >
               <BrowserRouter basename='/'>
                  <I18nextProvider i18n={i18n}>
                     <ScrollToTop>
                        <ConnectedThemeComponent>
                           <Suspense fallback={<Loading loading={true} />}>
                              <Router redirectTo={redirectTo} isError={isError} />
                           </Suspense>
                        </ConnectedThemeComponent>
                     </ScrollToTop>
                  </I18nextProvider>
               </BrowserRouter>
            </Auth0Provider>
         </Provider>
      );
   }
}

export default App;
