import { request } from "../shared/helpers/RequestWrapper";
import appConfigs from "../config/appConfigs";

const API_HOST = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;

export const INVITE_INDIVIDUALS = "inviteIndividuals";

export function sendIndividualInvitation(emails) {
    return request({
        url: API_HOST + "/" + INVITE_INDIVIDUALS,
        method: "POST",
        body: JSON.stringify(emails)
    });
}
