import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
   showErrorNotification,
   showNotification,
} from '../../../../shared/components/toastMessage';
import { getSpecificJobsSeekerDetailsById } from '../../../../api/Organization/applicant.details.api';
import LoadingIcon from 'mdi-react/LoadingIcon';
import {
   Button,
   Col,
   Input,
   Modal,
   ModalBody,
   ModalHeader,
   Row,
} from 'reactstrap';
import { hookAuth0 } from '../../../../shared/components/auth/withAuth0';
import Progress from 'reactstrap/es/Progress';
import { getSkills } from '../../../../api/skills.api';
import ApplicantSkills from './ApplicantSkills';
import ApplicantOtherSkills from './ApplicantOtherSkills';
import ApplicantCertifications from './ApplicantCertifications';
import ApplicantExperienceList from './ApplicantExperienceList';
import ReadMoreLessComponent from '../../../../shared/components/ReadMoreLessComponent';
import { getIndividualSmartSkillStatus } from '../../../../api/Individual/opportunities.api';
import { withRouter } from 'react-router';
import PDFTemplate from '../../../../shared/components/PDF_template';

const profilePhotoDefault = `${process.env.PUBLIC_URL}/img/EmployeeProfile/profile.png`;
const opentowork = `${process.env.PUBLIC_URL}/img/EmployeeProfile/opentowork.png`;

class ApplicantProfile extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         contentClass: 'hidden',
         isProfileRefresh: true,
         fname: '',
         lname: '',
         email: '',
         profilePhoto: '',
         location: '',
         countryName: '',
         bio: '',
         experienceList: [],
         userSkills: [],
         skills: [],
         skillList: [],
         preferredSkills: [],
         selectedSkills: [],
         otherskillList: [],
         skills1: [],
         smartSkillStatus: 0,
         otherSkillStatus: 0,
         selfSelectedSkillStatus: 0,
         skillVerifiedStatus: 0,
         locationStatus: 0,
         bioStatus: 0,
         workExpStatus: 0,
         schoolExpStatus: 0,
         isOpen: false,
         messageValue: '',
         loading: false,
         modalFlagSmartResume:false,
         openToJobStatus : false

      };
   }

   componentDidMount() {
      let url = window.location.pathname;
      url = url.split('/');
      const jobId = url[url.length - 1];
      const jobSeekerId = url[url.length - 2];
      this.getJobSeekerDetailsForOrgById(jobSeekerId, jobId);
      this.getIndividualSmartSkillStatus();
   }

   getAllSkills = () => {
      getSkills({})
         .then((response) => {
            this.setState(
               {
                  skills: response.data?.content,
               },
               () => {
                  this.getUserSkills();
               }
            );
         })
         .catch((err) => {
            console.log('getAllSkills error:', err);
            showErrorNotification(err);
         });
   };

   getUserSkills = () => {
      let skillVerifiedStatus = false;

      let allSkills = this.state.skills;
      let userSkill = this.state.userSkills;
      let list = [];

      for (let i in allSkills) {
         let status = false;
         for (let j in userSkill) {
            if (allSkills[i].id === userSkill[j].skillId) {
               let obj = {};

               obj.id = userSkill[j].id;
               obj.skillId = userSkill[j].skillId;
               obj.name = allSkills[i].name;
               obj.status = userSkill[j].status;
               obj.level = userSkill[j].status ? userSkill[j].levelValue : 0;
               obj.badgeLevel = userSkill[j].skillBadgeLevel
                  ? userSkill[j].skillBadgeLevel
                  : 0;
               list.push(obj);
               if (!skillVerifiedStatus) {
                  skillVerifiedStatus = userSkill[j].status
                     ? userSkill[j].levelValue > 1
                     : false;
               }
               status = true;
            }
         }
         if (!status) {
            let obj = {};
            obj.skillId = allSkills[i].id;
            obj.name = allSkills[i].name;
            obj.status = false;
            obj.level = 0;
            obj.badgeLevel = 0;
            list.push(obj);
         }
      }
      this.setState(
         {
            skills1: list,
         },
         () => {
            let list1 = this.state.skills1.slice(0, list.length / 2);
            let list2 = this.state.skills1.slice(list.length / 2, list.length);
            this.setState({
               skillList: [list1, list2],
            });
         }
      );
   };

   getIndividualSmartSkillStatus = () => {
      getIndividualSmartSkillStatus()
         .then((response) => {
            this.setState({ smartSkillStatus: 10 });
         })
         .catch((err) =>
            console.error('getIndividualSmartSkillStatus error :: ', err)
         );
   };
   setRemainingProfileStatus = () => {
      const { experienceList, userSkills, otherskillList } = this.state;

      if (experienceList.length) {
         this.setState({
            workExpStatus: experienceList.filter((wrkExp) => wrkExp.type === 0)
               .length
               ? 10
               : 0,
            schoolExpStatus: experienceList.filter(
               (schExp) => schExp.type === 1
            ).length
               ? 10
               : 0,
         });
      }
      if (otherskillList.length) {
         this.setState({ otherSkillStatus: 10 });
      }

      if (userSkills.length) {
         const skillVerifiedStatus = userSkills.filter(
            (usk) => usk.status && usk.levelValue > 1
         ).length
            ? 30
            : 0;
         this.setState({
            skillVerifiedStatus,
            selfSelectedSkillStatus: skillVerifiedStatus
               ? 10
               : userSkills.filter((usk) => usk.status && usk.levelValue === 1)
                    .length
               ? 10
               : 0,
         });
      }
   };

   getJobSeekerDetailsForOrgById(jobSeekerId, jobId) {
      getSpecificJobsSeekerDetailsById(jobSeekerId, jobId)
         .then((res) => {
            let data = res.data;
            this.setState(
               {
                  profilePhoto: data.jobSeeker?.profilePhoto,
                  location: data.jobSeeker?.location,
                  countryName: data.jobSeeker?.countryDetails?.name,
                  bio: data.jobSeeker?.bio,
                  fname: data.jobSeeker?.userDetails?.fname,
                  lname: data.jobSeeker?.userDetails?.lname,
                  email: data.jobSeeker?.userDetails?.userId,
                  experienceList: data.experience ? [...data.experience] : [],
                  userSkills: data.skills ? data.skills : [],
                  otherskillList: data.otherSkill ? data.otherSkill : [],
                  bioStatus: data.jobSeeker.bio ? 10 : 0,
                  locationStatus: data.jobSeeker.location ? 10 : 0,
                  openToJobStatus: data?.jobSeeker?.openToJobStatus,
               },
               () => {
                  this.getAllSkills();
                  this.setRemainingProfileStatus();
               }
            );
            this.props.callSmartSkill(data.jobSeeker.userDetails.userId)
            this.setState({ isProfileRefresh: false });
         })
         .catch((err) => {
            console.log('getSpecificJobsSeekerDetailsById error::', err);
            this.setState({ isProfileRefresh: false });
            showErrorNotification(err);
         });
   }

   renderProfilePhoto = (profilePhoto) => {
      return (
         <label htmlFor="file-upload">
            <button type="button" className="border-0 p-0 bg-transparent">
               <img
                  className="orgprofile__avatar-img"
                  // style={{ height: '110px', width: '110px' }}
                  src={profilePhoto || profilePhotoDefault}
                  alt="avatar"
                  style={{borderRadius : '50%' , width : '170px' , height:'170px', padding : '8px' }}
               />
               {this.state.openToJobStatus &&
               <div className={'opentowork'}>
                  <img src={opentowork} className={''} style={{    position: 'absolute',
                     top: '6px',
                     left: '23px',
                     transform: 'rotate(-2deg)',
                     width : '155px'
                  }}  />
               </div>}
            </button>
         </label>
      );
   };

   setIsOpen = (isOpen) => {
      this.setState({
         isOpen,
         messageValue: '',
      });
   };

   handleMessageChange = (event) => {
      this.setState({
         messageValue: event.target.value,
      });
   };

   sendMessage = () => {
      const { user } = this.props;
      const { fname, lname, email, messageValue } = this.state;
      if (messageValue.trim() !== '') {
         const message = {
            senderId: user.email,
            recipientId: email,
            senderName: user.firstName + ' ' + user.lastName,
            recipientName: fname + ' ' + lname,
            content: messageValue,
            timestamp: new Date(),
         };
         this.props.stompClient.send('/app/chat', {}, JSON.stringify(message));
         //TODO: to check condition
         showNotification('Message sent successfully', 'primary');
      }
      this.setIsOpen(false);
   };

   routeChange = () => {
      let ids = window.location.pathname.split('/');
      let jobSeekerId = ids[ids.length - 2]
      let jobId = ids[ids.length - 1]
      let { history } = this.props;
      let path = '/organization/compare/' + jobId + "/" + jobSeekerId;
      history.push(path);
   };

   toogleModalForSmartResume = () => {
      this.setState({
         modalFlagSmartResume: !this.state.modalFlagSmartResume,
      });
   };

   render() {
      const {
         isProfileRefresh,
         profilePhoto,
         fname,
         lname,
         bio,
         location,
         countryName,
         skillList,
         otherskillList,
         experienceList,
         email,
         smartSkillStatus,
         otherSkillStatus,
         selfSelectedSkillStatus,
         skillVerifiedStatus,
         locationStatus,
         bioStatus,
         workExpStatus,
         schoolExpStatus,
         isOpen,
         messageValue,
         loading,
      } = this.state;

      const profileCompletion =
         smartSkillStatus +
         otherSkillStatus +
         selfSelectedSkillStatus +
         skillVerifiedStatus +
         locationStatus +
         bioStatus +
         workExpStatus +
         schoolExpStatus;

      return (
         <Fragment>
            <Modal
               className="send-message_modal_rounded"
               size={'lg'}
               contentClassName={'p-4'}
               isOpen={isOpen}
               toggle={() => this.setIsOpen(!isOpen)}
            >
               <ModalHeader
                  className={'p-0 send-message_modal_header'}
                  toggle={() => this.setIsOpen(!isOpen)}
               >
                  <h4>
                     <b>Send Message</b>
                  </h4>
               </ModalHeader>
               <ModalBody className={'p-0'}>
                  {loading ? (
                     <div className="panel__refresh">
                        <LoadingIcon style={{ marginLeft: '46%' }} />
                     </div>
                  ) : null}
                  <Row className={'mt-4'}>
                     <Col md={'12'}>
                        <p className="mt-1 mb-2 send-message_modal_para">
                           Send a message to{' '}
                           <i>
                              {fname} {lname}
                           </i>
                        </p>
                        <div className="input-icons">
                           <Input
                              type={'textarea'}
                              rows={8}
                              className="input-field"
                              placeholder={'Type your message'}
                              value={messageValue}
                              onChange={this.handleMessageChange}
                           />
                        </div>
                     </Col>
                  </Row>
                  {!window.location.href.includes('public-profile') && (
                     <Row style={{ justifyContent: 'center' }}>
                        <Button
                           disabled={!this.state.messageValue}
                           className="mb-0 mt-1"
                           color={'primary'}
                           style={{ padding: '5px 10px', cursor: 'pointer' }}
                           onClick={this.sendMessage}
                        >
                           Send Message
                        </Button>
                     </Row>
                  )}
               </ModalBody>
            </Modal>
            {isProfileRefresh ? (
               <div className="panel__refresh">
                  <LoadingIcon />
               </div>
            ) : null}
            <Row className="container">
               <Col md={4} className="m-0 p-0 text-center">
                  <Row>
                     <Col md={4} p-0>
                        <label htmlFor="file-upload" className="mb-0">
                           {this.renderProfilePhoto(profilePhoto)}
                        </label>
                     </Col>
                     <Col md={8}>
                        <div className="applicant-profile_personal_details">
                           <div className="d-inline">
                              <h3 className="orgprofile-name">
                                 {' '}
                                 {`${fname} ${lname}`}
                              </h3>
                           </div>
                           <div
                              className="d-inline orgprofile__basic-body font-weight-normal"
                              style={{ lineHeight: '3.6em' }}
                           >
                              {location && countryName
                                 ? `${location},${countryName}`
                                 : `-`}
                           </div>
                           <div className="d-inline">
                              <p
                                 className="orgprofile-name"
                                 style={{ fontSize: '12px' }}
                              >
                                 {' '}
                                 {`${email}`}
                              </p>
                           </div>
                           <div className="progress__label-bar_text">
                              {`Profile Completion is ${profileCompletion}%`}
                           </div>
                           <div className="progress-wrap progress-wrap--small progress-wrap--blue progress-wrap--label-top">
                              <Progress value={profileCompletion} />
                           </div>
                        </div>
                     </Col>
                  </Row>
               </Col>
               {/*<div className={'vertical-line'} />*/}
               <Col md={6} className="m-auto ">
                  <Row>
                     <Col md={9} className={'d-flex'}>
                        <div className={'vertical-line'} />
                        <div
                           id="bio"
                           className="applicant-profile__bio justify d-flex"
                        >
                           <ReadMoreLessComponent
                              story={bio ? bio : `-`}
                              limit={200}
                              fontSize={'16px'}
                           />
                        </div>
                     </Col>
                     <Col md={3} />
                  </Row>



                  {/*<div className="vertical-line float-right" />*/}
               </Col>
               <Col md={2} className="m-auto d-flex">
                  <div className=" align-items-center">
                     <Button
                        style={{
                           width: '9.3rem',
                        }}
                        size={'sm'}
                        color="white"
                        className="btn btn-primary mb-2"
                        onClick={() => this.setIsOpen(!isOpen)}
                     >
                        Send Message
                     </Button>
                     <Button
                        size={'sm'}
                        outline
                        className={'mb-2'}
                        style={{
                           width: '9.3rem',
                           fontSize: '12px',
                           border: '2px solid #951b1e',
                           color: "#951b1e"
                        }}
                        onClick={this.routeChange}

                     >Compare Profile</Button>
                     <Button
                        size={'sm'}
                        outline
                        className={'mb-2'}
                        style={{
                           width: '9.3rem',
                           fontSize: '12px',
                           border: '2px solid #951b1e',
                           color: "#951b1e"
                        }}
                        onClick={this.toogleModalForSmartResume}
                     >Download Profile</Button>
                     <PDFTemplate emailId={email} modalFlagSmartResume={this.state.modalFlagSmartResume} toogleModalForSmartResume={this.toogleModalForSmartResume}/>


                  </div>
               </Col>
            </Row>
            <Row className={'container mt-3'}>
               <Col md={8} lg={8} sm={12} xs={12}>
                  <Row>
                     <Col md={12} lg={12} sm={12} xs={12}>
                        <h4 className={'employee-certifications__title ml-0'}>
                           <b>My Skills</b>
                        </h4>
                     </Col>
                  </Row>
                  <Row className="p-0 m-0 w-100">
                     <Col md={8} lg={8} sm={8} xs={12} className="p-0">
                        {/* {loading ? (
                                <div className="panel__refresh">
                                    <LoadingIcon />
                                </div>
                            ) : null}*/}
                        <div className={'card-body'}>
                           <b className={'mb-3'}>Data literacy skills I have</b>
                           <div className={'row'}>
                              {skillList.map((item) => {
                                 return (
                                    <div className={'col-md-6 p-0'}>
                                       {item.map((skill, index1) => (
                                          <ApplicantSkills
                                             index={index1}
                                             skill={skill}
                                          />
                                       ))}
                                    </div>
                                 );
                              })}
                           </div>
                           {/*<hr className={'mb-4 mt-4'} />*/}
                           <div className={'skill-legends'}>
                              <div className={'d-flex mt-3 ml-4'}>
                           <span
                              className={'skill-claimed-badge mr-2'}
                              title={'Self-Claim'}
                           >
                              T
                           </span>
                                 <span className={'badge-title'}>Self-Claim:</span>
                                 <span className={'legend-text'}>Self Claimed Skill</span>
                              </div>
                              <div className={'d-flex mt-3 ml-4'}>
                           <span
                              className={'skill-completed-badge mr-2'}
                              title={'Badge'}
                           />
                                 <span className={'badge-title'}>Badge:</span>
                                 <span className={'legend-text'}>
                              Score 30% or more in Skill Quiz
                           </span>
                              </div>
                              <div className={'d-flex mt-3 ml-4'}>
                           <span
                              className={'skill-certified-badge mr-2'}
                              title={'Certification'}
                           />
                                 <span className={'badge-title'}>Certificate:</span>
                                 <span className={'legend-text'}>
                              Score 70% or more in Certification Quiz. This assessment will be unlocked after your first try of Skill Quiz.
                           </span>
                              </div>
                           </div>

                           {/*<div>*/}
                           {/*   <Row>*/}
                           {/*      <Col md={'12'}>*/}
                           {/*         <table>*/}
                           {/*            <tr>*/}
                           {/*               <td*/}
                           {/*                  className={'level0 mr-1 pt-0'}*/}
                           {/*                  style={{ width: '65px' }}*/}
                           {/*               >*/}
                           {/*                  Level 0*/}
                           {/*               </td>*/}
                           {/*               <td>No Skill</td>*/}
                           {/*            </tr>*/}
                           {/*            <tr>*/}
                           {/*               <td className={'level1 mr-1 pt-0'}>*/}
                           {/*                  Level 1*/}
                           {/*               </td>*/}
                           {/*               <td>Self-Claimed</td>*/}
                           {/*            </tr>*/}
                           {/*            <tr>*/}
                           {/*               <td className={'level2 mr-1 pt-0'}>*/}
                           {/*                  Level 2*/}
                           {/*               </td>*/}
                           {/*               <td>*/}
                           {/*                  Assessment score between 50% to 80%*/}
                           {/*               </td>*/}
                           {/*            </tr>*/}
                           {/*            <tr>*/}
                           {/*               <td className={'level3 mr-1 pt-0'}>*/}
                           {/*                  Level 3*/}
                           {/*               </td>*/}
                           {/*               <td>Assessment score above 80%</td>*/}
                           {/*            </tr>*/}
                           {/*            <tr>*/}
                           {/*               <td className={'level4 mr-1 pt-0'}>*/}
                           {/*                  Level 4*/}
                           {/*               </td>*/}
                           {/*               <td>*/}
                           {/*                  Certified skill with Bronze, Silver*/}
                           {/*                  & Gold Types*/}
                           {/*               </td>*/}
                           {/*               <div className="skill-badge__bronze d-inline-block inline-badge">*/}
                           {/*                  <TrophyVariantOutlineIcon*/}
                           {/*                     style={{*/}
                           {/*                        width: '18',*/}
                           {/*                        height: '18',*/}
                           {/*                     }}*/}
                           {/*                  />*/}
                           {/*               </div>*/}
                           {/*               <div className="skill-badge__silver d-inline-block inline-badge">*/}
                           {/*                  <TrophyVariantOutlineIcon*/}
                           {/*                     style={{*/}
                           {/*                        width: '18',*/}
                           {/*                        height: '18',*/}
                           {/*                     }}*/}
                           {/*                  />*/}
                           {/*               </div>*/}
                           {/*               <div className="skill-badge__gold d-inline-block inline-badge">*/}
                           {/*                  <TrophyVariantOutlineIcon*/}
                           {/*                     style={{*/}
                           {/*                        width: '18',*/}
                           {/*                        height: '18',*/}
                           {/*                     }}*/}
                           {/*                  />*/}
                           {/*               </div>*/}
                           {/*            </tr>*/}
                           {/*            <tr>*/}
                           {/*               <td className={'level5 mr-1 pt-0'}>*/}
                           {/*                  Level 5*/}
                           {/*               </td>*/}
                           {/*               <td>*/}
                           {/*                  Proven skill through capstone*/}
                           {/*                  completion*/}
                           {/*               </td>*/}
                           {/*            </tr>*/}
                           {/*         </table>*/}
                           {/*      </Col>*/}
                           {/*   </Row>*/}
                           {/*</div>*/}
                        </div>
                     </Col>
                     <Col
                        md={4}
                        lg={4}
                        sm={8}
                        xs={12}
                        style={{ height: '100%' }}
                     >
                        {/*{loadingOther ? (
                                <div className="panel__refresh">
                                    <LoadingIcon />
                                </div>
                            ) : null}*/}
                        <div className="card-body h-auto">
                           <div className={'d-flex'}>
                              <b className={'mb-3'}>Other skills I have</b>
                           </div>
                           <Row>
                              {otherskillList.map((otherskill, index) => (
                                 <div className={'col-md-12 p-0 d-flex'}>
                                    <ApplicantOtherSkills
                                       index={index}
                                       skill={otherskill}
                                    />
                                 </div>
                              ))}
                              {otherskillList.length === 0 && (
                                 <div className={'mt-3 col-md-12 text-center'}>
                                    <p align={'left'}>
                                       User can add any other skill which he has
                                       learnt here.
                                    </p>
                                 </div>
                              )}
                           </Row>
                        </div>
                     </Col>
                  </Row>
               </Col>
               <Col md={4} lg={4} sm={8} xs={12}>
                  <Row>
                     <Col md={12} lg={12} sm={12} xs={12}>
                        <h4 className={'employee-certifications__title ml-0'}>
                           <b>Certifications Completed</b>
                        </h4>
                     </Col>
                  </Row>
                  <Row>
                     <Col
                        md={12}
                        lg={12}
                        sm={12}
                        xs={12}
                        style={{ height: '100%' }}
                     >
                        <ApplicantCertifications email={email} />
                     </Col>
                  </Row>
               </Col>
            </Row>
            <Row className="container mt-3">
               <Col>
                  <Row>
                     <Col md={12} lg={12} sm={12} xs={12}>
                        <h4 className="employee-certifications__title d-inline-block ml-0">
                           <b>Experience</b>
                        </h4>
                     </Col>
                  </Row>

                  {experienceList.map((experience, index) => (
                     <Row className={'w-100'}>
                        <ApplicantExperienceList
                           index={index}
                           experience={experience}
                        />
                     </Row>
                  ))}
                  {experienceList.length === 0 && (
                     <div className={'mt-3 col-md-12 text-center'}>
                        User had not added any experiences yet.
                     </div>
                  )}
               </Col>
            </Row>
         </Fragment>
      );
   }
}
ApplicantProfile.propTypes = {
   t: PropTypes.func.isRequired,
   rtl: RTLProps.isRequired,
};

export default compose(
   withTranslation('common'),
   connect((state) => ({
      rtl: state.rtl,
      stompClient: state.messages.stompClient,
   }))
)(hookAuth0(withRouter(ApplicantProfile)));
