import React, { useState,useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';
import { hookAuth0 } from '../../../shared/components/auth/withAuth0';
import { Input } from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import appConfigs from '../../../config/appConfigs';

import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import QuestionMarkCircleOutlineIcon from 'mdi-react/QuestionMarkCircleOutlineIcon';
import LogoutIcon from 'mdi-react/LogoutIcon';

const Sidebar = ({
   changeToDark,
   changeToLight,
   changeMobileSidebarVisibility,
   sidebar,
   props,
   auth0,
   isInviteIndividual
}) => {

   const [searchParam, setSearchParam] = useState('');

   const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
   });


  const handleKeyPress = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.trim();
   //  if (searchValue) {
   if (e.which === 13 && searchValue) {
      // this.setState((prevState) => ({ inputOpen: !prevState.inputOpen }));
      let { history } = props;
      let path = "/search";
      history.push({
        pathname: path,
        state: { searchQuery: searchValue ? searchValue.trim() : "" }
      });
      changeMobileSidebarVisibility();
    }
  };

   useEffect(() => {
     setSearchParam('')
   }, [sidebar.show]);
  


   return (
      <div className={sidebarClass + ' d-md-none'}>
         {/*{isInviteIndividual &&*/}
         {/*<div style={{*/}
         {/*   position: "absolute",*/}
         {/*   width: "100%",*/}
         {/*   height: "100%",*/}
         {/*   top: "50",*/}
         {/*   left: "0",*/}
         {/*   zIndex: "10",*/}
         {/*   backgroundColor: 'white'*/}
         {/*}}*/}
         {/*>*/}
         {/*    <h1>Invite</h1>*/}
         {/*</div>*/}
         {/*}*/}
         {/*<button*/}
         {/*  className="sidebar__back"*/}
         {/*  type="button"*/}
         {/*  onClick={changeMobileSidebarVisibility}*/}
         {/*/>*/}
         <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
               // user_role={useAuth0().user.user_type && useAuth0().user.user_type[0]}
               onClick={changeMobileSidebarVisibility}
               // user_role={useAuth0().user.user_type[0]}
               changeToDark={changeToDark}
               changeToLight={changeToLight}
            />
         </div>
         <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <div className={'sidebar__search'}>
               <MagnifyIcon/>
               <Input
                  type="text"
                  autoFocus
                  name={'searchQuery'}
                  value={searchParam}
                  placeholder={'Search news, projects & opportunities'}
                  onKeyDown={handleKeyPress}
                  // onKeyPress={handleKeyPress}
                  onChange={(e) =>
                     setSearchParam(e.target.value)
                  }  
                  // onBlur={() => {
                  //   this.setState({
                  //     inputOpen: false
                  //   });
                  // }}
               />
            </div>
            <SidebarContent
               // user_role={useAuth0().user.user_type[0]}
               onClick={changeMobileSidebarVisibility}
               changeToDark={changeToDark}
               changeToLight={changeToLight}
            />
         </div>
         <div className={'sidebar-other-actions'}>
            <div onClick={isInviteIndividual} className={'sidebar-other-action-item'}>
               <AccountPlusIcon /> <span>Invite Individuals</span>
            </div>
            <div className={'sidebar-other-action-item'}>
               <QuestionMarkCircleOutlineIcon /> <span>Tutorials</span>
            </div>
            <div
               className={'sidebar-other-action-item logout'}
               onClick={() =>
                  auth0.logout({
                     redirectUri: appConfigs.endpoint.APHRODITE_ROOT_UI,
                  })
               }
            >
               <LogoutIcon /> <span>Logout</span>
            </div>
         </div>
      </div>
   );
};

Sidebar.propTypes = {
   sidebar: SidebarProps.isRequired,
   changeToDark: PropTypes.func.isRequired,
   changeToLight: PropTypes.func.isRequired,
   changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default hookAuth0(Sidebar);
