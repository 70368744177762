import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import OrganizationDetails from '../../../B2CIndividual/OrganizationDetails';
import JobProjectDetailsMobile from '../../../B2CIndividual/Opportunities/components/JobProjectDetailsMobile';
import JobProjectSavedDetailsMobile from '../../../B2CIndividual/SavedData/components/components/JobProjectSavedDetailsMobile';
import GroupChat from '../../../B2CIndividual/Competition/Teams/GroupChat';
import PublicProfileForQandA from '../../../B2CIndividual/QandA/PublicProfileForQandA';
import NominationFormModal from './NominationFormModal';

const Assessments = lazy(() => import('../../../Pages/Individual/Assessments'));
const Profile = lazy(() => import('../../../Pages/Individual/Profile'));


const Home = lazy(() => import('../../../B2CIndividual/Home'));
const QandATabs = lazy(() => import('../../../Pages/Individual/QandAPage'));
const Learn = lazy(() => import('../../../Pages/Individual/Learn'));
const Opportunities = lazy(() =>
   import('../../../Pages/Individual/Opportunities')
);
const AssessmentDetail = lazy(() =>
   import('../../../Pages/Individual/AssessmentsDetail')
);
const AssessmentProgress = lazy(() =>
   import('../../../Pages/Individual/AssessmentProgress')
);
const CourseDetails = lazy(() =>
   import('../../../B2CIndividual/CourseDetails')
);
const SavedData = lazy(() => import('../../../B2CIndividual/SavedData'));
const Tutorials = lazy(() => import('../../../PerkesoUser/Tutorials'));
const Competition = lazy(() => import('../../../Pages/Individual/Competition'));
const Karisma = lazy(() => import('../../../Pages/Individual/Karisma'));
const SearchBoard = lazy(() => import('../../../Dashboards/Search'));
const DataStar = lazy(() => import('../../../B2CIndividual/DataStar'))
const DataAcceleratorDetail = lazy(() => import('../../../B2CIndividual/DataStar/AcceleratorDetails'))


const INDIVIDUAL_ROUTES = [
   {
      path: '/individual/home',
      name: 'Home',
      Component: Home,
   },
   {
      path: '/individual/QandA',
      name: 'QandA',
      Component: QandATabs,
   },
   {
      path: '/individual/QandA/:id',
      name: 'QandA',
      Component: QandATabs,
   },
   {
      path: '/individual/publicProfile/:id',
      name: 'Public Profile',
      Component: PublicProfileForQandA,
   },
   {
      path: '/individual/profile',
      name: 'Profile',
      Component: Profile,
   },
   {
      path: '/individual/quiz',
      name: 'Skill Quiz',
      Component: Assessments,
   },
   {
      path: '/individual/assessments',
      name: 'Assessments',
      Component: Assessments,
   },
   {
      path: '/individual/learn',
      name: 'Learn',
      Component: Learn,
   },
   {
      path: '/individual/opportunities',
      name: 'Opportunities',
      Component: Opportunities,
   },
   {
      path: '/individual/opportunities/:id',
      name: 'Opportunities',
      Component: Opportunities,
   },
   {
      path: '/individual/opportunities/details',
      name: 'Opportunity Details',
      Component: JobProjectDetailsMobile,
   },
   {
      path: '/individual/assessmentDetails',
      name: 'Assessment Details',
      Component: AssessmentDetail,
   },
   {
      path: '/individual/assessment/survey',
      name: 'Assessment Progress',
      Component: AssessmentProgress,
   },
   {
      path: '/individual/organizationVisit/:id',
      name: 'Organization Details',
      Component: OrganizationDetails,
   },
   {
      path: '/individual/courseDetails/:id',
      name: 'Course Details',
      Component: CourseDetails,
   },
   {
      path: '/individual/bookmarks',
      name: 'Bookmarks Page',
      Component: SavedData,
   },
   {
      path: '/individual/bookmarks/details',
      name: 'Opportunity Details',
      Component: JobProjectSavedDetailsMobile,
   },
   {
      path: '/individual/tutorials',
      name: 'Tutorials Page',
      Component: Tutorials,
   },
   {
      path: '/individual/competition/:id',
      name: 'Competitions',
      Component: Competition,
   },
   {
      path: '/individual/competition',
      name: 'Competitions',
      Component: Competition,
   },
   {
      path: '/individual/karisma',
      name: 'Karisma',
      Component: Karisma,
   },
   {
      path: '/individual/competition/chat/:id',
      name: 'Group Chat',
      Component: GroupChat,
   },
   {
      path: '/individual/search',
      name: 'Individual Search',
      Component: SearchBoard
   },
   {
      path: '/individual/dataStar',
      name: 'Data Star',
      Component: DataStar
   },
   {
      path: '/individual/dataStar/:id',
      name: 'Data Accelerator Detail',
      Component: DataAcceleratorDetail
   }

];

export default () => (
   <>
      <Switch>
      <Redirect exact from="/individual" to="/individual/home" />
         {INDIVIDUAL_ROUTES.map(({ path, name, Component }, key) => (
            <Route
               exact
               path={path}
               key={key}
               render={(props) => {
                  const crumbs = INDIVIDUAL_ROUTES
                     // Get all routes that contain the current one.
                     .filter(({ path }) => props.match.path.includes(path))
                     // Swap out any dynamic routes with their param values.
                     // E.g. "/employee/:employeeId" will become "/employee/1"
                     .map(({ path, ...rest }) => ({
                        path: Object.keys(props.match.params).length
                           ? Object.keys(props.match.params).reduce(
                                (path, param) =>
                                   path.replace(
                                      `:${param}`,
                                      props.match.params[param]
                                   ),
                                path
                             )
                           : path,
                        ...rest,
                     }));
                  // crumbs.map(({ name, path }) => console.log({ name, path }));
                  return <Component crumbs={crumbs} {...props} />;
               }}
            />
         ))}
      </Switch>
   <NominationFormModal/>
   </>
);
