import React from 'react';
import KcAdminClient from 'keycloak-admin';

const kcAdminClient = new KcAdminClient({
   baseUrl: 'https://keycloak.skillstreets.com/auth',
   realmName: 'cads',
});

// Authorize with username / password

export default class Impersonate extends React.Component {

   componentDidMount() {
      this.initAdmin();
   }

   initAdmin = async () => {
      await kcAdminClient.auth({

      });
   };

   switchUser = () => {
      var myHeaders = new Headers();
      kcAdminClient.getAccessToken().then((res) => {
         myHeaders.append('Authorization', `Bearer ${res}`);
         // myHeaders.append("withCredentials", true);

         // var requestOptions = {
         //    method: 'POST',
         //    headers: myHeaders,
         //    redirect: 'follow',
         // };

         // fetch("https://keycloak.skillstreets.com/auth/admin/realms/cads/users/b80f7c69-bbaa-4dba-b772-a2ce56eda0a7/impersonation", requestOptions)
         //    .then(response => {
         //       console.log("RES HEADERS",response.headers.get("set-cookie"));
         //
         //      return response.text()
         //    })
         //    .then(result => console.log(result))
         //    .catch(error => console.log('error', error));
         // const config = {
         //    headers: {
         //       'Authorization': `Bearer ${res}`,
         //       'withCredentials': 'true'
         //    },
         //    url: 'https://keycloak.skillstreets.com/auth/admin/realms/cads/users/b80f7c69-bbaa-4dba-b772-a2ce56eda0a7/impersonation',
         //    method: 'post',
         // };

         kcAdminClient.users.findOne({id: 'b80f7c69-bbaa-4dba-b772-a2ce56eda0a7'}).then(res => {
         })


         // axios.request(config).then((res) => {
         //    console.log('axios res :: ', res);
         // });

         fetch("http://8420383f181e.ngrok.io/public/hello/impersonate2User?userName=b80f7c69-bbaa-4dba-b772-a2ce56eda0a7").then(response => {
            return response.text()
         }).then(res2 => {
            //let data = JSON.parse(res2)
         })
      });
   };

   render() {
      return <button onClick={this.switchUser}>Switch User</button>;
   }
}
