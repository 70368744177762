import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ crumbs, fieldValue }) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div className="mb-3 breadcrumbs">
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ name, path, alias }, index) =>
        index + 1 === crumbs.length ? (
          <span key={index} className="bold">
            {fieldValue && alias ? fieldValue : alias ? alias : name}
          </span>
        ) : (
          <div key={index}>
            <Link key={index} className="text-blue-500" to={path}>
              {fieldValue && alias ? fieldValue : alias ? alias : name}
            </Link>{" "}
            &nbsp;/&nbsp;
          </div>
        )
      )}
    </div>
  );
};

export default Breadcrumbs;
