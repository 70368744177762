import Cookies from "universal-cookie";
import {
    cookieEnum
} from "../enums";
import appConfigs from "../../config/appConfigs";
const COOKIE_DOMAIN = appConfigs.endpoint.COOKIE_DOMAIN;

const cookie = new Cookies();

export function setCookie({
                              access_token
                          }) {
    // return authorization header with jwt token
    cookie.set(cookieEnum.ACCESS_TOKEN, access_token, {
        httpOnly: false,
        path: "/",
        domain: COOKIE_DOMAIN
    });

}

export function removeCookie() {
    // return authorization header with jwt token
    cookie.remove(cookieEnum.ACCESS_TOKEN, {
        httpOnly: false,
        path: "/",
        domain: COOKIE_DOMAIN
    });
}

export function getAccessTokenCookie() {
    // return authorization header with jwt token
    return cookie.get(cookieEnum.ACCESS_TOKEN,{domain: COOKIE_DOMAIN});
}

export function setUserCookie(profile) {
    if (profile) {
        cookie.set(cookieEnum.USER, JSON.stringify(profile), {
            httpOnly: false,
            path: "/"
        });
    }
}

export function getUserCookie() {
    let user = cookie.get(cookieEnum.USER);
    if (user) {
        return user;
    }
    return null;
}

export function getTokenData() {
    return cookie.get(cookieEnum.TOKEN_DATA) ?? {};
}
