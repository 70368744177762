import { Checkbox } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

import {showNotification} from "../../../../../../shared/components/toastMessage";
import { Button } from 'reactstrap';

const Photo = `${process.env.PUBLIC_URL}/img/EmployeeProfile/profile.png`;

export default function BasicTable({ selectedEmployees, handleEmployeesList,recommendationDetails,jobSeekerId,loadMore,isLast,removeValueIfExist}) {

  const handleEmployee = (key,event) => {
    if(selectedEmployees.length <= 2 || !event.target.checked) {
      handleEmployeesList(key)
    } else if(selectedEmployees.includes(jobSeekerId)) {
      removeValueIfExist(jobSeekerId,key)
    } else {
      showNotification('You cannot select more than 3 profile at a time')
    }
  }

  return (
    <TableContainer component={Paper} className="compare-employees-table">
      <Table style={{height:'min-content'}} aria-label="simple table">
        <TableBody>
              {recommendationDetails?.map((employee,key) => (
                 <>
            <TableRow key={key}>
              <TableCell
                component="th"
                scope="row"
                className="compare-employees-employee-name">
                <div style={{maxHeight:'3rem',height:'3rem',padding: '10px',display:'flex'}}>
                  <img src={employee?.profileImage ||Photo} alt="User Profile Photo" />
                  <label>{employee?.employeeName}</label>
                </div>

              </TableCell>
              <TableCell align="right">{employee?.location && <>{employee?.location}, </>}{employee?.countryName}</TableCell>
              <TableCell
                align="right"
                className="compare-employees-employees-skills">
                  <p className="dashboard__competitor-result">
                    {(employee.requiresSkillCount &&
                        employee.matchSkillCount &&
                        `Has ${employee.matchSkillCount} out of ${employee.requiresSkillCount} Skills`) ||
                    `-`}
                  </p>
              </TableCell>
              <TableCell
                align="right"
                className="compare-employees-select-employees-checkbox">
                <Checkbox
                  inputProps={{ "aria-label": "primary checkbox" }}
                  checked={selectedEmployees.indexOf(employee.jobSeekerId) !== -1}
                  onChange={(event) => handleEmployee(employee.jobSeekerId,event)}
                />
              </TableCell>
            </TableRow>

              {recommendationDetails.length-1 === key &&
              <TableRow key={key}>
                <TableCell>

                </TableCell>
                <TableCell align="right">
                  <div style={{ textAlign: 'end',
                    justifyContent: 'end',width:'100%'}}>
                    <Button
                       color="primary"
                       style={{
                         padding: '5px 15px',
                         marginRight: '0px',
                       }}
                       onClick={loadMore}
                       disabled={isLast}
                    >
                      Load More
                    </Button>
                  </div>
                </TableCell>
                <TableCell
                   align="right"
                   className="compare-employees-employees-skills">

                </TableCell>
                <TableCell
                   align="right"
                   className="compare-employees-select-employees-checkbox">

                </TableCell>
              </TableRow>
              }
                 </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
