import React, {Fragment} from "react";

const PreferredSkillComponent = ({ index, skill, isSelected, toggleSkill, style, initialSelected }) => {
   console.log("SKILL ::", skill)
   return (
      <Fragment>
         <div
            style={style}
            key={index}
            className={`skill-level-component ${
               isSelected ? " preferred-skill" : ""
               }`}
            onClick={() => !initialSelected && toggleSkill && toggleSkill(skill.id)}>
        <span className={"skill-component__skill-name"}>
          {skill.name || skill.otherSkillName}
        </span>
         </div>
      </Fragment>
   );
};

export default PreferredSkillComponent;
