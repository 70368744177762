import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { hookAuth0,  } from '../../../shared/components/auth/withAuth0';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { withRouter } from 'react-router';
import PublicProfileComponent from '../../Pages/Public/Profile/PublicProfile';

class PublicProfileForQandA extends Component {

   routeChange = () => {
      let { history } = this.props;
      history.goBack();
   };

   render() {
      return (
         <Container className='dashboard p-3'>
            <Row>
               <div className='header d-flex pl-4'>
                  <ArrowBackIcon
                     className='back-arrow'
                     onClick={this.routeChange}
                     style={{ cursor: 'pointer' }}
                  />
                  <div className='d-inline-block pl-4'>
                     <h4 className='font-weight-bold'>Individual details</h4>
                     {/*<div className='orgprofile__basic-header-title'>*/}
                     {/*   Opportunities / Jobs / Applicant Details*/}
                     {/*</div>*/}
                  </div>
               </div>
            </Row>

            <Row />
            <Row>
               <Col md={12}>
                  <div className='container m-4'>
                     <PublicProfileComponent />
                  </div>
               </Col>
            </Row>
         </Container>
      );
   }
}

export default withRouter(hookAuth0(PublicProfileForQandA));
