import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";
import Notifications from "./components/Notifications";
import { useAuth0 } from "../../../shared/components/auth/withAuth0";

const NotificationBoard = ({ t, rtl }) => (
  <Container className="dashboard">
    <Notifications email={useAuth0().user.email}/>
  </Container>
);

NotificationBoard.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired
};

export default connect((state) => ({
  rtl: state.rtl
}))(withTranslation("common")(NotificationBoard));
