import React, { Component, Fragment } from 'react';
import {
   Button,
   Col,
   Modal,
   ModalBody,
   ModalHeader,
   Row,
} from 'reactstrap';
import './custom.scss';
import CloseIcon from 'mdi-react/CloseIcon';
import SendIcon from 'mdi-react/SendIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { showNotification } from '../../shared/components/toastMessage';
import appConfig from '../../config/appConfigs';
import { sendIndividualInvitation } from '../../api/invitation.api';
import { validate as validateEmail } from 'email-validator';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { trackEventMixpanel } from '../helpers/Mixpanel';

// const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/igm;

class InviteIndividualModal extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isOpen: false,
         emails: [],
         emailValue: '',
      };
   }

   handleEmails = (event) => {
      let emailList = this.state.emails;
      if (event.keyCode === 188) {
         let email = event.target.value.substr(
            0,
            event.target.value.length - 1
         );

         this.setState({
            emailValue: email,
         });
         if (validateEmail(email)) {
            if (this.state.emails.indexOf(email) === -1) {
               emailList.push(
                  event.target.value.substr(0, event.target.value.length - 1)
               );
               this.setState({
                  emails: emailList,
               });
            }
            this.setState({
               emailValue: '',
            });
         }
      }
   };

   handleEmailChange = (event) => {
      this.setState({
         emailValue: event.target.value,
      });
   };

   setIsOpen = (isOpen) => {
      this.setState({
         isOpen,
      });
   };

   removeEmail = (index) => {
      let emailList = this.state.emails;
      let emailListNew = [];
      for (let i in emailList) {
         if (i !== index) {
            console.info(i, index);
            emailListNew.push(emailList[i]);
         }
      }
      this.setState({
         emails: emailListNew,
      });
      console.info(emailListNew);
   };

   sendEmails = () => {
      this.setState({
         loading: true,
      });
      const { emailValue, emails } = this.state;
      if (validateEmail(emailValue)) {
         if (this.state.emails.indexOf(emailValue) === -1) {
            emails.push(emailValue);
         }
      } else {
         if (emailValue.trim().length > 0) {
            this.setState({
               loading: false,
            });
            showNotification('Invalid Email');
         }
      }
      if (emails && emails.length > 0) {
         const initTime = new Date();
         sendIndividualInvitation(emails).then((response) => {
            const finalTime = new Date();
            const secTime = (finalTime - initTime) / 1000;
            if (response && response.status) {
               showNotification(response.message, 'primary');
               trackEventMixpanel('invite_people', {
                  'Time elapsed(sec)': secTime,
               });
               this.setState({
                  emails: [],
                  emailValue: '',
               });
            }
            this.setState({
               loading: false,
               emails: [],
               emailValue: '',
            });
         });
      } else {
         this.setState({
            loading: false,
         });
      }
   };

   getAndCopyInviteLink = () => {
      this.setState({
         loading: true,
      });
      const APHRODITE_UI = `${appConfig.endpoint.APHRODITE_ROOT_UI}/register`;
      let registrationLink = `https://${appConfig.endpoint.LOGIN_ROOT}/Account/SignUp`;
      let textField = document.createElement('textarea');
      textField.innerText = registrationLink;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      showNotification('Link Copied!', 'primary');
      this.setState({
         loading: false,
      });
   };

   toggleModal = (isOpen) => {
      this.setState({
         emails: [],
      });
      this.props.toggle(!isOpen);
   };

   render() {
      const { isOpen } = this.props;
      const { emailValue, emails, loading } = this.state;
      return (
         <Fragment>
            <Modal
               size={'lg'}
               centered={true}
               contentClassName={'p-4'}
               isOpen={isOpen}
               toggle={() => this.toggleModal(isOpen)}
            >
               <ModalHeader
                  className={'p-0'}
                  toggle={() => this.toggleModal(isOpen)}
               >
                  <h4 style={{ textTransform: 'none' }}>
                     <b>Invite Individual</b>
                  </h4>
               </ModalHeader>
               <ModalBody className={'p-0'}>
                  {loading ? (
                     <div className="panel__refresh">
                        <LoadingIcon style={{ marginLeft: '46%' }} />
                     </div>
                  ) : null}
                  <Row
                     className={'mt-4'}
                     data-tut={'tour_invite_individual_email'}
                  >
                     <Col md={'12'}>
                        <div className={'divider'} />
                        <b>By Email</b>
                        <p className={'mt-1'}>
                           Get your friends to join Rubiqe. You can add multiple
                           email addresses by entering commas.
                        </p>
                        <div className="input-icons">
                           <TextField
                              label={'Emails'}
                              variant={'outlined'}
                              size={'small'}
                              className={'mt-3'}
                              value={emailValue}
                              placeholder="Emails"
                              onChange={this.handleEmailChange}
                              onKeyUp={this.handleEmails}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <SendIcon
                                          style={{ cursor: 'pointer' }}
                                          onClick={this.sendEmails}
                                          title={'Send'}
                                       />
                                    </InputAdornment>
                                 ),
                              }}
                           />
                        </div>
                        <div className={'emails-tags d-flex flex-wrap mt-2'}>
                           {emails.map((item, index) => (
                              <span className={'email-tag-box'}>
                                 {item}{' '}
                                 <CloseIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.removeEmail(index)}
                                 />
                              </span>
                           ))}
                        </div>
                     </Col>
                  </Row>
                  <hr className={'mt-4 mb-4'} />
                  <Row className={'mt-4'}>
                     <Col md={'12'}>
                        <div className={'d-flex'}>
                           <div>
                              <b>Share Link</b>
                              <p className={'mt-1'}>
                                 Spread the word and invite your peers! Copy and
                                 share the link.
                              </p>
                           </div>
                           <Button
                              className={'mt-4 ml-auto'}
                              size={'sm'}
                              color={'primary'}
                              onClick={this.getAndCopyInviteLink}
                           >
                              Copy Link
                           </Button>
                        </div>
                     </Col>
                  </Row>
               </ModalBody>
            </Modal>
         </Fragment>
      );
   }
}

export default InviteIndividualModal;
