import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class TopbarMenuLinks extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    onClick: PropTypes.func.isRequired
  };

  render() {
    const { title, icon, path, onClick, id } = this.props;

    return (
      <Link className="topbar__link" to={path || "/" } onClick={onClick} data-tut={id}>
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">{title}</p>
      </Link>
    );
  }
}
