const SKILLSTREETS_ROOT = 'https://skillstreet.staging.cads.ai';
//const SKILLSTREETS_ROOT = 'http://localhost:9003';
const PERKESO_ROOT = 'https://perkeso-service.cads.ai';
const APHRODITE_ROOT = 'https://staging.cads.ai';
const HEBE_ROOT = 'https://api.staging.cads.ai/hebe';
const TALENTSPY_DOMAIN = 'talentspy.staging.cads.ai';
const BASE_COOKIE = 'aphrodite.cads.ai';
const ASSESSMENT_SUBDOMAIN = 'b2cplatform';
const HOMEPAGE = 'https://rubiqe.staging.cads.ai'; //http://localhost:3000/
const LOGIN_ROOT = 'https://login.staging.cads.ai';
const CADS_BASE_API = 'https://apiservice.staging.cads.ai';

export default {
   SKILLSTREETS_ROOT: `${SKILLSTREETS_ROOT}`,
   API_BASE_URL_SKILLSTREETS: `${SKILLSTREETS_ROOT}/api/v1`,
   API_BASE_URL_SKILLSTREETS_PUBLIC: `${SKILLSTREETS_ROOT}/public`,

   PERKESO_ROOT: `${PERKESO_ROOT}`,
   API_BASE_URL_PERKESO: `${PERKESO_ROOT}/api/v1`,

   SMARTSKILL_REPORT_ROOT: `https://api.cads.ai/smartskills`,
   LOGIN_ROOT,
   APHRODITE_ROOT: `${APHRODITE_ROOT}`,
   APHRODITE_ROOT_UI: `${APHRODITE_ROOT}`,

   MIXPANEL_TOKEN: `c51fbb7d43b50fa816d26fd1f45f2e66`,
   ROLLBAR_KEY: `b7276555c9d543aebaba2f8ddd9d8fb6`,
   ROLLBAR_ENV: `production`,

   HEBE_ROOT: `${HEBE_ROOT}`,
   API_BASE_URL_CADS_HEBE: `${HEBE_ROOT}/api`,

   CADS_BASE_API : `${CADS_BASE_API}`,
   API_ROOT_CADS_BASE_API :`${CADS_BASE_API}/api/v1`,

   TALENTSPY_DOMAIN: `${TALENTSPY_DOMAIN}`,
   API_BASE_TALENTSPY: `https://TS_DOMAIN.${TALENTSPY_DOMAIN}/api/Webservice`,


   BASE_COOKIE: `${BASE_COOKIE}`,
   COOKIE_DOMAIN: `${BASE_COOKIE}`,

   ASSESSMENT_SUBDOMAIN: `${ASSESSMENT_SUBDOMAIN}`,

   TALENT_SPY_ASSESSMENT_LINK: `https://${ASSESSMENT_SUBDOMAIN}${TALENTSPY_DOMAIN}`,
   TS_ASSESSMENT_HOST: TALENTSPY_DOMAIN,
   TS_SUBDOMAIN: ASSESSMENT_SUBDOMAIN,
   URL_LOGIN: HOMEPAGE,
   WOOCOMMERCE_URL: `https://store.dev.skillstreets.com`
};
