import appConfigs from '../../config/appConfigs';
import { request } from '../../shared/helpers/RequestWrapper';
const API_BASE_URL_LANDING = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;

const GET_ALL_COMPETITION = '/competition/getAll';
const UPLOAD_MATERIAL_FILE = '/competition/uploadCompetitionMaterial/';
const POST_PRICE = '/competition/price';
const DELETE_PRICE = '/competition/price';
const GET_COMPETITION = '/competition/';
const GET_COMPETITION_DETAIL = '/competition/';
const POST_COMPETITION_INFORMATION = '/competition/information/insertUpdate';
const GET_ALL_CURRENCY = '';

const COMPETITION_URL = '/competition';
const GET_COMPETITION_Question = COMPETITION_URL + '/question';
const REGISTER_INDIVIDUAL_INTEREST = '/competition/enroll';
const INDIVIDUAL_ALREADY_JOINED = '/competition/enroll/status/';
const GET_COMPETITION_START = "/competition/stats";

const GET_TEAM_INVITATION_DETAILS = '/competition/teamInvite';
const ADD_TEAM_DETAILS = '/competition/team';
const GET_TEAM_DETAILS = '/competition/team';
const GET_TEAM_MEMBER_DETAILS = '/competition/teamMember';
const GET_TEAM_SUBMISSION = '/competition/team/submission';
const UPDATE_TEAM_DETAILS = '/competition/team';
const UPDATE_TEAM_STATUS = '/competition/team/teamStatus';
const UPLOAD_PROFILE_PICTURE = '/competition/team/profile';
const SHOW_AVAILABAL_TEAM_AND_MEMBER = '/competition/availableUsersAndTeams';

const GET_LIST_OF_AVAILABLE_INVITE_MEMBER = '/competition/availableUser';
const GET_AVAILABLE_MEMBER_DETAILS = '/competition/team/matching';
const UPDATE_ENROLLMENT_STATUS = '/competition/enroll';
const SEND_TEAM_INVITE_TO_INDIVIDUAL = '/competition/teamInvite';

const GET_WINNERS_DETAILS = '/competition/prizeWinner/all';
const GET_PRIZE_STATS = '/competition/price/stats';
const INSERT_PUBLISHED_WINNERS_DETAILS = '/competition/price/winners';
const GET_PUBLISHED_WINNERS_DETAILS = '/competition/prizeWinner/published';
const INSERT_UPDATE_WINNERS_TEAM = '/competition/prizeWinner';
const GET_COMPETITION_PRIZE = '/competition/prize/competition';
const GET_COMPETITION_PRIZEWINNER_PRIZE = "/competition/prizeWinner/prize";

const GET_LIST_OF_AVAILABLE_TEAMS =
   '/competition/team/openForPublicEnroll';
const GET_TEAM_INFO_BY_TEAM_ID = '/competition/team';
const GET_TEAM_MEMBERS_BY_TEAM_ID = '/competition/teamMember';
const SEND_INVITE_TO_JOIN_TEAM = '/competition/teamInvite';
const DELETE_TEAM_FROM_ID = '/competition/team';
const ACCEPT_DELETE_CANCEL_INVITATION = '/competition/teamInvite';
const DELETE_TEAM_MEMBER_FROM_ID = '/competition/teamMember';
const SUBMIT_TEAM_FROM_TEAM_TEAM_ID = '/competition/team/submit'

const GET_All_MESSAGES_BY_TEAM = '/competition/team/messages';

const GET_ALL_UPLOADED_FILES_BY_TEAM_ID = '/competition/team/submission';
const UPLOAD_FILE_BY_TEAM_ID = '/competition/team/submission'
const DELETE_TEAM_FILE_BY_FILE_ID = '/competition/team/submission'

export function getAllTeamMessages(teamId) {
   return request({
      url:
         API_BASE_URL_LANDING + GET_All_MESSAGES_BY_TEAM + '?teamId=' + teamId,
      method: 'GET',
   });
}

const ASSIGN_ROLE_TO_TEAM_MEMBER = '/competition/team/assignRole'

export function getAllCompetitionData(isAllCompetitionFlag, pageNo, pageSize,isEnrolled,isActive) {
   return request({
      url:
         API_BASE_URL_LANDING +
         GET_ALL_COMPETITION +
         '?isActive=' +
         isActive +
         '&isAllCompetitionFlag=' +
         isAllCompetitionFlag +
         '&pageNo=' +
         pageNo +
         '&pageSize=' +
         pageSize +
         '&isEnrolled=' +
         isEnrolled,
      method: 'GET',
   });
}

export function getCompetitionIdData(data) {
   return request({
      url: API_BASE_URL_LANDING + GET_COMPETITION + '/' + data,
      method: 'GET',
   });
}

export function insertPriceCompetition(data) {
   if (data.id && typeof data.id === 'number') {
      return request({
         url: API_BASE_URL_LANDING + POST_PRICE + '/' + data?.id,
         method: 'PUT',
         body: data,
      });
   } else {
      data.id = null;
      return request({
         url: API_BASE_URL_LANDING + POST_PRICE,
         method: 'POST',
         body: data,
      });
   }
}

export function deletePriceCompetition(priceId) {

   return request({
      url: API_BASE_URL_LANDING + DELETE_PRICE + '/' + priceId,
      method: 'DELETE',
   });
}

export function getCompetitionDetail(competitionId) {
   return request({
      url: API_BASE_URL_LANDING + GET_COMPETITION_DETAIL + competitionId,
      method: 'GET',
   });
}

export function joinCompetitionForIndividual(data) {
   return request({
      url: API_BASE_URL_LANDING + REGISTER_INDIVIDUAL_INTEREST,
      method: 'POST',
      body: data,
   });
}

export function individualAlreadyJoinedComp(competitionId) {
   return request({
      url: API_BASE_URL_LANDING + INDIVIDUAL_ALREADY_JOINED + competitionId,
      method: 'GET',
   });
}

export function getAllCurrency() {
   return request({
      url: API_BASE_URL_LANDING + GET_ALL_CURRENCY,
      method: 'GET',
   });
}

export function insertUpdateCompetitionInformation(data) {
   return request({
      url: API_BASE_URL_LANDING + POST_COMPETITION_INFORMATION,
      method: 'POST',
      body: data,
   });
}


export async function getCompetitionTeamMemberById(id, params) {
   const data = await request({
      url: API_BASE_URL_LANDING + GET_TEAM_MEMBER_DETAILS + '/' + id + '',
      method: 'GET',
      params,
   });
   return data;

}




export async function getCompetitionQuestionById(id, params) {
   const data = await request({
      url: API_BASE_URL_LANDING + GET_COMPETITION_Question + '/' + id + '',
      method: 'GET',
      params,
   });
   return data;
}

export async function getTeamEnrolledStatus(competitionId) {
   return request({
      url:
         API_BASE_URL_LANDING +
         GET_TEAM_INVITATION_DETAILS +
         '?competitionId=' +
         competitionId,
      method: 'GET',
   });
}

export function getCompetitionTeamSubmissionById(id) {
   return request({
      url: API_BASE_URL_LANDING + GET_TEAM_SUBMISSION + '/' + id,
      method: 'GET',
   });
}

export async function createTeamForIndividual(data) {
   return request({
      url: API_BASE_URL_LANDING + ADD_TEAM_DETAILS,
      method: 'POST',
      body: data,
   });
}

export async function getTeamDetails(competitionId) {
   return request({
      url: API_BASE_URL_LANDING + GET_TEAM_DETAILS + '/' + competitionId,
      method: 'GET',
   });
}

export async function updateTeamDetails(data, competitionId) {
   return request({
      url: API_BASE_URL_LANDING + UPDATE_TEAM_DETAILS + '/' + competitionId,
      method: 'PUT',
      body: data,
   });
}

export async function updateTeamStatus(id, val) {
   return request({
      url:
         API_BASE_URL_LANDING +
         UPDATE_TEAM_STATUS +
         '/' +
         id +
         '?newTeamStatus=' +
         val,
      method: 'PUT',
   });
}


export async function getAllTeamCompetitionById(id, params) {
   const data = await request({
      url: API_BASE_URL_LANDING + GET_TEAM_DETAILS + '/' + id + '/all',
      method: 'GET',
      params,
   });
   return data;
}

export async function uploadTeamProfilePicture(data, competitionId) {
   return request({
      url: API_BASE_URL_LANDING + UPLOAD_PROFILE_PICTURE + '/' + competitionId,
      method: 'POST',
      body: data,
   });
}

export async function loadRandomTeamMember(competitionId) {
   return request({
      url: API_BASE_URL_LANDING + GET_TEAM_DETAILS + '/' + competitionId,
      method: 'GET',
   });
}

export function uploadMaterialFile(competitionId, body) {
   return request({
      url: API_BASE_URL_LANDING + UPLOAD_MATERIAL_FILE + competitionId,
      method: 'post',
      body: body,
   });
}

export async function showAvailableTeamAndMember(
   competitionId,
   pageNo,
   pageSize
) {
   return request({
      url:
         API_BASE_URL_LANDING +
         SHOW_AVAILABAL_TEAM_AND_MEMBER +
         '/' +
         competitionId +
         '?pageNo=' +
         pageNo +
         '&pageSize=' +
         pageSize,
      method: 'GET',
   });
}

export async function getAvailableMemberList(
   competitionId,
   pageNo,
   pageSize,
   searchVal
) {
   let url =
      API_BASE_URL_LANDING +
      GET_LIST_OF_AVAILABLE_INVITE_MEMBER +
      '/' +
      competitionId +
      '?pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize;
   if (searchVal && searchVal !== '') {
      url =
         API_BASE_URL_LANDING +
         GET_LIST_OF_AVAILABLE_INVITE_MEMBER +
         '/' +
         competitionId +
         '?pageNo=' +
         pageNo +
         '&pageSize=' +
         pageSize +
         '&searchValue=' +
         searchVal;
   }
   return request({
      url: url,
      method: 'GET',
   });
}

export async function getAvailableMemberDetails(teamId, userId) {
   return request({
      url:
         API_BASE_URL_LANDING +
         GET_AVAILABLE_MEMBER_DETAILS +
         '/' +
         teamId +
         '/' +
         userId,
      method: 'GET',
   });
}

export async function updateEnrollmentStatus(params) {
   return request({
      url: API_BASE_URL_LANDING + UPDATE_ENROLLMENT_STATUS,
      method: 'PUT',
      body: params,
   });
}

export async function sendTeamInviteToIndividual(params) {
   return request({
      url: API_BASE_URL_LANDING + SEND_TEAM_INVITE_TO_INDIVIDUAL,
      method: 'POST',
      body: params,
   });
}

export async function deleteTeamFromId(teamId, competitionId) {
   return request({
      url:
         API_BASE_URL_LANDING +
         DELETE_TEAM_FROM_ID +
         '/' +
         teamId +
         '?competitionId=' +
         competitionId,
      method: 'DELETE',
   });
}

export async function acceptDeleteCancelMemberInvitation(params) {
   return request({
      url: API_BASE_URL_LANDING + ACCEPT_DELETE_CANCEL_INVITATION,
      method: 'POST',
      body: params,
   });
}

export async function deleteTeamMemberById(teamId, competitionId, memberId) {
   return request({
      url:
         API_BASE_URL_LANDING +
         DELETE_TEAM_MEMBER_FROM_ID +
         '/' +
         teamId +
         '?competitionId=' +
         competitionId +
         '&memberUserId=' +
         memberId,
      method: 'DELETE',
   });
}

export async function assignRoleToTeamMembers(teamId,params) {
   return request({
      url: API_BASE_URL_LANDING + ASSIGN_ROLE_TO_TEAM_MEMBER + "/" + teamId,
      method: 'POST',
      body: params
   });
}

export async function submitTeamByTeamByTeamId(teamId) {
   return request({
      url: API_BASE_URL_LANDING + SUBMIT_TEAM_FROM_TEAM_TEAM_ID + "/" + teamId,
      method: 'POST',
   });
}

// join team competition

export async function getAllUploadedFilesByTeamId(teamId) {
   return request({
      url: API_BASE_URL_LANDING + GET_ALL_UPLOADED_FILES_BY_TEAM_ID + '/' + teamId,
      method: 'GET',
   });
}

export async function uploadTeamFilesByTeamId(teamId,data) {
   return request({
      url: API_BASE_URL_LANDING + UPLOAD_FILE_BY_TEAM_ID + '/' + teamId,
      method: 'POST',
      body: data,
   });
}

export async function deleteTeamFileById(fileId) {
   return request({
      url: API_BASE_URL_LANDING + DELETE_TEAM_FILE_BY_FILE_ID + '/' + fileId,
      method: 'DELETE',
   });
}




export async function getTeamListFromCompetitionId(
   competitionId,
   pageNo,
   pageSize,
   searchVal
) {
   let url =
      API_BASE_URL_LANDING +
      GET_LIST_OF_AVAILABLE_TEAMS +
      '/' +
      competitionId +
      '?pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize;
   if (searchVal && searchVal !== '') {
      url =
         API_BASE_URL_LANDING +
         GET_LIST_OF_AVAILABLE_TEAMS +
         '/' +
         competitionId +
         '?pageNo=' +
         pageNo +
         '&pageSize=' +
         pageSize +
         '&searchValue=' +
         searchVal;
   }
   return request({
      url: url,
      method: 'GET',
   });
}

export async function getTeamInfoByTeamId(teamId) {
   return request({
      url: API_BASE_URL_LANDING + GET_TEAM_INFO_BY_TEAM_ID + '/' + teamId,
      method: 'GET',
   });
}

export async function getTeamMembersByTeamId(teamId, pageNo, pageSize) {
   return request({
      url:
         API_BASE_URL_LANDING +
         GET_TEAM_MEMBERS_BY_TEAM_ID +
         '/' +
         teamId +
         '?pageNo=' +
         pageNo +
         '&pageSize=' +
         pageSize,
      method: 'GET',
   });
}

export async function sendInviteToJoinTeam(params) {
   return request({
      url: API_BASE_URL_LANDING + SEND_INVITE_TO_JOIN_TEAM,
      method: 'POST',
      body: params,
   });
}


export function getWinnersDetailByCompetitionId(competitionId) {
   return request({
      url: API_BASE_URL_LANDING + GET_WINNERS_DETAILS + '/' + competitionId,
      method: 'GET',
   });
}

export function getPrizeStatsByCompetitionId(competitionId) {
   return request({
      url: API_BASE_URL_LANDING + GET_PRIZE_STATS + '?competitionId=' + competitionId,
      method: 'GET',
   });
}


export function insertUpdatePrizeWinner(body) {
   return request({
      url: API_BASE_URL_LANDING + INSERT_UPDATE_WINNERS_TEAM,
      method: 'post',
      body: body,
   });
}

export function insertPublicWinnerDetails(competitionId) {
   return request({
      url: API_BASE_URL_LANDING + INSERT_PUBLISHED_WINNERS_DETAILS + '?competitionId=' + competitionId,
      method: 'post',
   });
}




export function getPublicWinnersDetailByCompetitionIdTrue(competitionId,data) {
   return request({
      url: API_BASE_URL_LANDING + GET_PUBLISHED_WINNERS_DETAILS + '/' + competitionId + "?isAllInfo=" + data,
      method: 'GET',
   });
}



export function getPublicWinnersDetailByCompetitionIdfalse(competitionId,data) {
   return request({
      url: API_BASE_URL_LANDING + GET_PUBLISHED_WINNERS_DETAILS + '/' + competitionId + "?isAllInfo=" + data,
      method: 'GET',
   });
}

export function getCompetitionPrizeByCompetitionId(competitionId) {
   return request({
      url: API_BASE_URL_LANDING + GET_COMPETITION_PRIZE + '/' + competitionId,
      method: 'GET',
   });
}


export function getCompetitionPrizeWinnerByCompetitionPrizeId(competitionPrizeId,data) {
   return request({
      url: API_BASE_URL_LANDING + GET_COMPETITION_PRIZEWINNER_PRIZE + '/' + competitionPrizeId + "?isAllInfo=" + data ,
      method: 'GET',

   });
}


export function getDashboardCompetition() {
   return request({
      url: API_BASE_URL_LANDING + GET_COMPETITION_START,
      method: "Get",

   });
}