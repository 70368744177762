import TrophyVariantOutlineIcon from 'mdi-react/TrophyVariantOutlineIcon';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'reactstrap';

export const JobProjectDetail = (props) => {
   const { data, isJobView } = props;
   const skills = data.jobRequiredSkills.split(',');
   return (
      <div className="typography-message">
         <h5>
            <b>Required Skills</b>
         </h5>
         <p></p>
         <div className="job-skills-detail">
            <button className="job-skills-icon">
               <TrophyVariantOutlineIcon size={18} />
            </button>
            <div>
               <p className="jobs-skills-header">Data Literacy Skills</p>
            </div>
         </div>
         <br />
         <br />
         <p></p>
         {skills &&
            skills.map((requiredSkill, index) => {
               return (
                  <Button key={index} color="black" className="btn-sm rounded">
                     {requiredSkill}
                  </Button>
               );
            })}
            <br />
            <br />
         <h5>
            <b>{isJobView ? `Job` : `Project`} Description</b>
         </h5>
         <div
            className="job-desc-p"
            dangerouslySetInnerHTML={{
               __html: data.description,
            }}
         />

      </div>
   );
};

JobProjectDetail.propTypes = {
   data: PropTypes.object.isRequired,
};
