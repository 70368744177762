import '@fortawesome/fontawesome-free/css/all.min.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import React from 'react';
import Gallery from 'cads-gallery';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import { getOrganizationDetails } from '../../../api/Individual/organization.details.api';
import WorkBenefitIconComponent from '../../../shared/helpers/workBenefiteIcons';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import Opportunities from './Opportunities';
import ReadMoreLessComponent from '../../../shared/components/ReadMoreLessComponent';
import DomainIcon from 'mdi-react/DomainIcon';

class OrganizationDetails extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         noData: false,
         orgName: '',
         orgDP: '',
         orgDescrption: '',
         orgCity: '',
         orgCountry: '',
         orgEmpSize: 0,
         orgImageGallery: [],
         orgBenefits: [],
         industryType: ''
      };
   }

   getDimentions(imageGallery) {
      this.setState({
         galleryLoading: imageGallery.length > 0 ? true : false,
      });
      if (imageGallery.length > 0) {
         new Promise((resolve, reject) => {
            let imageDimensions = [];
            imageGallery.map((imageData) => {
               let img = new Image();
               img.src = imageData.image;
               img.onload = function () {
                  imageDimensions.push({
                     src: imageData.image,
                     thumbnail: imageData.image,
                     thumbnailWidth: this.width,
                     thumbnailHeight: this.height,
                  });
               };
               return 1;
            });
            setTimeout(() => {
               resolve(imageDimensions);
            }, 4000);
         }).then((res) => {
            this.setState({
               orgImageGallery: res,
               galleryLoading: false,
            });
         });
      }
   }

   getDetails(url) {
      getOrganizationDetails(url[url.length - 1]).then((res) => {
         this.setState({
            orgName: res.data.organization.name,
            orgDP: res.data.organizationDetails.profilePhoto,
            orgDescrption: res.data.organizationDetails.story,
            orgCity: res.data.organizationDetails.location,
            orgCountry: res.data.organizationDetails.country?.name,
            orgEmpSize: res.data.organizationDetails.employeeSize,
            industryType: res.data.organization?.industryDetails?.name,
            orgBenefits: res.data.workBenefits,
         });

         this.getDimentions(res.data.imageGallery);
      });
   }

   componentDidMount() {
      let url = window.location.pathname;
      url = url.split('/');
      this.getDetails(url);
   }

   routeChange = () => {
      let { history } = this.props;
      history.push({
         pathname: '/individual/opportunities',
         state:{
            selectedJob: history.location?.state?.selectedJob ?? null
         }
      });
   };

   render() {
      const { galleryLoading } = this.state;
      if (this.state.orgEmpSize === 0) {
         this.setState({
            orgEmpSize: '1 - 100',
         });
      }
      if (this.state.orgEmpSize === 1) {
         this.setState({
            orgEmpSize: '101 - 1000',
         });
      }
      if (this.state.orgEmpSize === 2) {
         this.setState({
            orgEmpSize: '1001 - 10000',
         });
      }
      if (this.state.orgEmpSize === 3) {
         this.setState({
            orgEmpSize: '10000 +',
         });
      }

      if (this.state.noData === true) {
         return <Redirect to="/individual/home" />;
      }
      return (
         <Container fluid  className="dashboard container-fluid p-3">
            <div className="organization-details">
               <div className="header">
                  <ArrowBackIcon
                     className="back-arrow"
                     onClick={this.routeChange}
                     style={{ cursor: 'pointer' }}
                  />
                  <h4 className={"topbar_notification_icon_desktop"}>{this.state.orgName}</h4>
                  <h4 className={"topbar_notification_icon_mobile"}>Profile</h4>
               </div>

               <div className="body">
                  <div className="left-content  topbar_notification_icon_desktop">
                     {this.state.orgDP ?
                     <img src={this.state.orgDP} alt={this.state.orgName} /> :
                     <div className={'org-profile'} > <DomainIcon className={'org-profile_detail'}  /> </div> }
                  </div>

                  <div className="right-content  topbar_notification_icon_desktop">
                     <div className="org-details">
                        <h4>About the Company</h4>
                        <ReadMoreLessComponent
                           story={this.state.orgDescrption}
                           limit={280}
                           fontSize={'16px'}
                        />

                        <div
                           className="org-address"
                           style={{ color: '#99a3a9' }}
                        >
                           {this.state.orgCity} - {this.state.orgCountry}
                           <br />
                           {this.state.orgEmpSize} Employees.
                        </div>
                     </div>

                     <div className="office-images">
                        <h4>Our Office</h4>

                        {galleryLoading ? (
                           <div
                              className="panel__refresh"
                              style={{ position: 'relative' }}
                           >
                              <LoadingIcon />
                           </div>
                        ) : this.state.orgImageGallery.length > 0 ? (
                           <Gallery
                              images={this.state.orgImageGallery}
                              enableImageSelection={false}
                           />
                        ) : (
                           <p className="no_data">No photos available</p>
                        )}
                     </div>

                     <div className="benefits">
                        <h4>Benefits</h4>
                        <Row>
                           {this.state.orgBenefits.length > 0 ? (
                              this.state.orgBenefits.map((benefit, index) => {
                                 return (
                                    <Col
                                       className={'org-work-benefit__main'}
                                       xl={'4'}
                                       lg={'4'}
                                       md={'4'}
                                       sm={'6'}
                                       xs={'12'}
                                    >
                                       <div
                                          className={
                                             'org-work-benefit__icon-outer'
                                          }
                                       >
                                          <div className={'work-benefit__icon'}>
                                             {WorkBenefitIconComponent(
                                                benefit.icon
                                             )}
                                          </div>
                                       </div>
                                       <div
                                          className={
                                             'org-work-benefit__details'
                                          }
                                       >
                                          <h5
                                             className={
                                                'org-work-benefit__heading'
                                             }
                                          >
                                             {benefit.heading}
                                          </h5>
                                          <div
                                             className={
                                                'org-work-benefit__description'
                                             }
                                          >
                                             {benefit.description}
                                          </div>
                                       </div>
                                    </Col>
                                 );
                              })
                           ) : (
                              <p className="no_data">No benefits found</p>
                           )}
                        </Row>
                     </div>

                     <Opportunities />
                  </div>
               </div>

               <div className="body topbar_notification_icon_mobile">
                  <Row>
                     <Col sm={4} xs={4} lg={4} md={4} xl={4}>
                        <div style={{borderRadius:'50%'}}>
                           {this.state.orgDP ?
                           <img src={this.state.orgDP} alt={this.state.orgName} /> :
                           <div className={'org-profile'}> <DomainIcon className="profile-pic" /> </div> }
                        </div>
                     </Col>
                     <Col sm={8} xs={8} lg={8} md={8} xl={8}>
                        <div style={{verticalAlign:'center'}}>
                           <h3><b>{this.state.orgName}</b></h3>
                        </div>
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={12} sm={12} lg={12} md={12} xl={12}>
                        <div
                           style={{ color: '#99a3a9', fontWeight: '500'}}
                        >
                           <span style={{fontWeight: '500'}}>
                              {this.state.industryType} &nbsp; {this.state.orgEmpSize} Employees. 
                           </span>
                           <br/>
                           <span style={{fontWeight: '600'}}>
                              {this.state.orgCity} - {this.state.orgCountry}
                           </span>
                        </div>
                        <div className="org-details mt-4">
                           {/* <p style={{fontSize:'17px'}}><b>About the Company</b></p> */}
                           <ReadMoreLessComponent
                              story={this.state.orgDescrption}
                              limit={280}
                              fontSize={'15px'}
                           />
                        </div>
                     </Col>
                  </Row>
                  <Row className={"mt-3"}>
                     <Col xs={12} sm={12} lg={12} md={12} xl={12}>
                        <div className="office-images">
                           <p style={{fontSize:'17px'}} className="mt-2 mb-3"><b>Our Office</b></p>

                           {galleryLoading ? (
                              <div
                                 className="panel__refresh"
                                 style={{ position: 'relative' }}
                              >
                                 <LoadingIcon />
                              </div>
                           ) : this.state.orgImageGallery.length > 0 ? (
                              <Gallery
                                 images={this.state.orgImageGallery}
                                 enableImageSelection={false}
                              />
                           ) : (
                              <p className="no_data">No photos available</p>
                           )}
                        </div>
                     </Col>
                  </Row>
                  <Row>
                     <Col xs={12} sm={12} lg={12} md={12} xl={12}>
                        <div className="benefits mt-4">
                           <p style={{fontSize:'17px'}} className="mt-2 mb-2"><b>Benefits</b></p>
                           <Row>
                              {this.state.orgBenefits.length > 0 ? (
                                 this.state.orgBenefits.map((benefit, index) => {
                                    return (
                                       <Col
                                          className={'org-work-benefit__main'}
                                          style={{
                                             alignItems: "center"
                                          }}
                                          xl={'4'}
                                          lg={'4'}
                                          md={'4'}
                                          sm={'6'}
                                          xs={'12'}
                                       >
                                          <div
                                             className={
                                                'org-work-benefit__icon-outer'
                                             }
                                          >
                                             <div className={'work-benefit__icon'}>
                                                {WorkBenefitIconComponent(
                                                   benefit.icon
                                                )}
                                             </div>
                                          </div>
                                          <div
                                             className={
                                                'org-work-benefit__details'
                                             }
                                          >
                                             <p
                                                style={{fontSize: "14px"}}
                                                className={
                                                   'org-work-benefit__heading'
                                                }
                                             >
                                             <span> <b>   
                                                {benefit.heading} 
                                                </b> </span>   
                                                <br/>
                                             <span
                                                className={
                                                   'org-work-benefit__description'
                                                }
                                             >
                                                {benefit.description}
                                                </span>
                                             </p>
                                          </div>
                                       </Col>
                                    );
                                 })
                              ) : (
                                 <p className="no_data">No benefits found</p>
                              )}
                           </Row>
                        </div>
                     </Col>
                  </Row>
                  <Row className={"mt-4"}>
                     <Col xs={12} sm={12} lg={12} md={12} xl={12}>
                        <p style={{fontSize:'17px'}} className="mt-2 mb-2"><b>Open jobs at {this.state.orgName}</b></p>
                        <Opportunities />
                     </Col>
                  </Row>


               </div>

            </div>
         </Container>
      );
   }
}

OrganizationDetails.propTypes = {
   t: PropTypes.func.isRequired,
   rtl: RTLProps.isRequired,
};

export default compose(
   withTranslation('common'),
   connect((state) => ({
      rtl: state.rtl,
   }))
)(OrganizationDetails);
