import React from 'react';
import {connect} from 'react-redux';
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import Tour from "reactour";
import {setTourOpen} from "../../redux/actions/tourAction";
import {Button, Modal, ModalBody} from 'reactstrap';
import Cookie from 'universal-cookie';
import appConfigs from "../../config/appConfigs";
import { hookAuth0 } from '../../shared/components/auth/withAuth0';
import { withRouter } from 'react-router';


const cookie = new Cookie();

class AppTour extends React.PureComponent{

    constructor(props) {
        super(props)

        this.state = {
            currentStep: 0
        }
    }

    disableBody = target => disableBodyScroll(target);
    enableBody = target => enableBodyScroll(target);

    handleOnBeforeClose = () => {

        this.setState({
            confirmClose: true
        })
    }

    closeTour = () => {
        this.props.dispatch(setTourOpen(false))
        this.handleOnBeforeClose()
    }

    componentDidMount() {

    }

    completeTour = () => {
        this.closeTour();
        cookie.set("showTour", "no", {domain: appConfigs.endpoint.BASE_COOKIE});
        localStorage.setItem('tourCompleted', "yes");
        this.forceUpdate()
    }

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     console.log(this.state)
    //     console.log(nextState)
    // }

    getCurrentStep = (currentStep) => {
        this.setState({
            currentStep
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isTourOpen === false) {
            this.setState({
                currentStep: 0
            })
        }
    }

    closeTourHelper = (flag) => {
        document.body.style.overflowY = 'auto'
        cookie.set("showTour", flag, {domain: appConfigs.endpoint.BASE_COOKIE});
        this.setState({
            confirmClose: false
        })
        let path = "profile";

        if(this.props.activeTab){
            path= this.props.activeTab.split('/')[4];
        }
        this.props.history.push({
            pathname: cookie.get("activeTab")
        });

        cookie.remove("activeTab")
        // window.location.href = "/"
    }

    render() {
        const {tourConfig, isTourOpen} = this.props;
        const {currentStep} = this.state;
        const accentColor = "#5cb7b7";
        const showTour = cookie.get("showTour");
        const program = cookie.get("program");
        return (
            <React.Fragment>
                {isTourOpen && showTour === "yes" &&
                   program === 'q3management' && tourConfig?.[0]?.forQ3 &&
                <Tour
                   disableFocusLock={true}
                    onRequestClose={this.closeTour}
                    steps={tourConfig}
                    isOpen={isTourOpen}
                    getCurrentStep={this.getCurrentStep}
                    disableInteraction={tourConfig[currentStep] ? !tourConfig[currentStep].canInteract : true}
                   disableKeyboardNavigation={true}
                    closeWithMask={false}
                    // maskClassName=""
                    className="helper pr-5 app-tour"
                    disableDotsNavigation={false}
                    showNumber={false}
                    showNavigation={false}
                    rounded={5}
                    accentColor={accentColor}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                    // nextButton={<Button size={"sm"} color={"primary"}>Next</Button>}
                    // prevButton={<Button size={"sm"} color={"primary"}>Previous</Button>}
                    nextButton={<p className={`tour_next_btn ${currentStep === tourConfig.length - 1 && 'last'}`}><u>Next</u></p>}
                    prevButton={tourConfig[currentStep]?.last ? <p/> : <p className={`tour_prev_btn ${currentStep === 0 && 'first'}`}><u>Prev</u></p>}
                    nextStep={tourConfig[currentStep]?.nextStep ?? null}
                    lastStepNextButton={tourConfig[currentStep].last && <Button color={"success mr-5"} onClick={tourConfig[currentStep]?.onClick ?? this.completeTour}>{tourConfig[currentStep]?.btnText ?? "Finish"}</Button>}
                />
                }

                <Modal isOpen={this.state.confirmClose} centered>
                    <ModalBody className={"tour_modal_body p-0"}>
                        <div className={"text-center"}><h4>You can select Reply on-boarding in more Options Icon to see this on-boarding journey again.</h4>
                            {/*<p>You can choose to replay the Onboarding Journey anytime by clicking on the More Options icon and selecting Replay Onboarding</p>*/}
                        </div>
                        <div className={"mt-3 d-flex justify-content-center"}>
                            {/*<Button className={"mb-0 "} size={"sm"} color={"primary"} onClick={()=>this.closeTourHelper("yes")}>Yes</Button>*/}
                            <Button className={"mb-0 ml-3"} size={"sm"} color={"warning"} onClick={()=>this.closeTourHelper("no")}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    tourConfig: state.tour.tourConfig,
    isTourOpen: state.tour.isTourOpen,
    activeTab: state.tour.tourRedirectUrl,
    tour: state.tour,
    rtl: state.rtl,
}))(hookAuth0(withRouter(AppTour)));
