import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RTLProps } from '../../../../../../../shared/prop-types/ReducerProps';
import SmartSkill from '../../../../../../../shared/components/SmartSkill';
import { Card, CardBody, Col, Row } from 'reactstrap';
import DetailedViewDescription from './DetailedViewDescription';

class DetailedViewDesign extends React.Component {
   constructor() {
      super();

      this.state = {
         currentDimension: [],
         currentColor: [],
      };
   }

   componentDidMount() {
      this.setState({
         currentDimension: this.props.smartSkillData[0].dimensions[0],
         currentColor: this.props.smartSkillData[0].color,
      });
   }

   setcurrentDimension = (skill, color) => {
      this.setState({ currentDimension: skill, currentColor: color });
   };

   render() {
      const { currentDimension, currentColor } = this.state;

      const { smartSkillData } = this.props;
      return (
         <Fragment>
            <Row>
               <Col md={'12'}>
                  <div className="card-deck">
                     {smartSkillData.map((smartSkillsData, index) => {
                        return (
                           <Card className={'smart-skill-card'}>
                              <CardBody className="smart-skill-container">
                                 <div className="container-smarts">
                                    {smartSkillsData.dimensions.map(
                                       (skill, skillIndex) => {
                                          return (
                                             <div
                                                className="div-container-smart-skill"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                   this.setcurrentDimension(
                                                      skill,
                                                      smartSkillsData.color
                                                   )
                                                }
                                             >
                                                <div className="ss-detail-circle-center">
                                                   <SmartSkill
                                                      color={
                                                         smartSkillsData.color
                                                      }
                                                      level={skill.scoreTitle}
                                                      value={skill.score}
                                                      detail={true}
                                                      isSelected={
                                                         currentDimension.dimensionId ===
                                                         skill.dimensionId
                                                      }
                                                   />
                                                </div>
                                                <div
                                                   className="smart-skill-text-detailed"
                                                   style={{
                                                      color:
                                                         smartSkillsData.color,
                                                   }}
                                                >
                                                   {skill.dimensionName}
                                                </div>
                                             </div>
                                          );
                                       }
                                    )}
                                 </div>
                              </CardBody>
                           </Card>
                        );
                     })}
                  </div>
               </Col>
            </Row>
            <Row className={"p-5"}>
               <Col md={"12"}>
            <DetailedViewDescription
               currentDimension={currentDimension}
               color={currentColor}
            />
               </Col>
            </Row>
         </Fragment>
      );
   }
}

DetailedViewDesign.propTypes = {
   t: PropTypes.func.isRequired,
   rtl: RTLProps.isRequired,
   smartSkillData: PropTypes.any,
};

export default compose(
   withTranslation('common'),
   connect((state) => ({
      rtl: state.rtl,
   }))
)(DetailedViewDesign);
