/* eslint-disable react/no-array-index-key */
import InputBase from '@material-ui/core/InputBase';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import PropTypes from 'prop-types';
import React, {  useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import Col from 'reactstrap/es/Col';
import Row from 'reactstrap/es/Row';
import { useAuth0 } from '../../../shared/components/auth/withAuth0';
import SendIcon from '@material-ui/icons/Send';

const Email = ({ onBack, messages, sendMessage, activeContact }) => {
   const [text, setText] = useState('');
   const [ setQuickReply] = useState(false);
   const loggedInUser = useAuth0().user;
   const currentUser = {
      id: loggedInUser.email,
      name: loggedInUser.firstName + ' ' + loggedInUser.lastName,
   };

   const onQuickReply = () => {
      setQuickReply(true);
   };


   const dateFormatter = (date) => {
      let today = new Date(date);
      return (
         today.getDate() +
         '/' +
         (today.getMonth() + 1) +
         '/' +
         today.getFullYear()
      );
   };

   useEffect(() => {
      let objDiv = document.getElementById('message_scroll');
      objDiv.scrollTop = objDiv.scrollHeight;
   });

   const [dimensions, setDimensions] = React.useState({
      height: window.innerHeight,
      width: window.innerWidth,
   });
   React.useEffect(() => {
      function handleResize() {
         setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener('resize', handleResize);
      return () => {
         window.removeEventListener('resize', handleResize);
      };
   });

   return (
      <div className="container">
         <div className="page-title page-title-messages pb-3 topbar_notification_icon_desktop" >
            <div style={{ marginLeft: '30px'}}>
               Messages
            </div>
         </div>
         <div className="inbox__email inbox__content">
            <div className="inbox__email-header">
               <div className="inbox__email-left">
                  <button
                     className="inbox__email-back"
                     type="button"
                     onClick={onBack}
                  >
                     <ArrowLeftIcon />
                  </button>
                  <h6
                     className="inbox__email-name"
                     style={{ marginTop: '13px' }}
                  >
                     <span className={"topbar_notification_icon_desktop"} style={{float:"left"}}> Message with </span><b className={"title-mail "}>&nbsp;{activeContact.name}</b>
                  </h6>
               </div>
            </div>

            <div
               id="message_scroll"
               style={{
                  overflow: "hidden auto",
                  maxHeight: dimensions.height - 300 + 'px',
                  marginBottom: '-2px',
               }}
            >
               {messages ? (
                  messages.map((msg, index) => (
                     <div className="inbox__email-body" key={index}>
                        <Row>
                           <Col xs={8} sm={8} md={11}>
                              {msg.senderId === currentUser.id ? (
                                 <b>You</b>
                              ) : (
                                 <b>{msg.senderName}</b>
                              )}
                              <b>
                                 <p>
                                    {msg.content.length > 0 &&
                                       msg.content.split(' ').map((item) => {
                                          if (
                                             [
                                                'https://',
                                                'http://',
                                                'www.',
                                                '.com',
                                                '.in',
                                                '.co',
                                             ].some((el) => item.includes(el))
                                          ) {
                                             return (
                                                <span
                                                   style={{ color: '#951B1E' }}
                                                >
                                                   <a
                                                      href={item}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      style={{
                                                         color: '#951B1E',
                                                      }}
                                                   >
                                                      {item}
                                                   </a>{' '}
                                                </span>
                                             );
                                          } else {
                                             return <span>{item} </span>;
                                          }
                                       })}
                                 </p>
                              </b>
                           </Col>
                           <Col  xs={4} sm={4} md={1}>
                              <div className={"email-date-formatter"}>
                                 {dateFormatter(msg.timestamp)}
                              </div>
                           </Col>
                        </Row>
                     </div>
                  ))
               ) : (
                  <div className={'empty-message'}>No messages</div>
               )}
            </div>
         </div>
         <div
            className={'messages-send-message-main__div assign-send-msg-div-bottom '}
            style={{
               display: 'flex',
               justifyContent: 'center',
            }}
         >
            <Row
               className={'container messages-send-message__div'}
               style={{
                  borderTop: '2px solid #D8DADB',
               }}
            >
               <Col md={11} xs={11} sm={11} className="p-0">
                  <InputBase
                     name="user_input"
                     placeholder="Write your message..."
                     value={text}
                     onChange={(event) => setText(event.target.value)}
                     onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                           sendMessage(text);
                           setText('');
                        }
                     }}
                     className="inbox__email-reply-textarea"
                     onFocus={onQuickReply}
                     inputProps={{ 'aria-label': 'naked' }}
                  />
               </Col>
               <Col
                  md={1} xs={1} sm={1}
                  style={{ textAlign: 'center' }}
                  className="p-0 perkeso_send_message_button_main"
               >
                  <div style={{ right: '0%', marginBottom: "20px"}}>
                     <Button
                        size="sm"
                        color="primary"
                        className={"topbar_notification_icon_desktop"}
                        onClick={() => {
                           sendMessage(text);
                           setText('');
                        }}
                     >
                        Send Reply
                     </Button>
                     <SendIcon
                        className={"topbar_notification_icon_mobile mt-2"}
                        style={{color:"#951B1E"}}
                        onClick={() => {
                           sendMessage(text);
                           setText('');
                        }}
                     />
                  </div>
               </Col>
            </Row>
         </div>
      </div>
   );
};
Email.propTypes = {
   onBack: PropTypes.func.isRequired,
   messages: PropTypes.array,
   sendMessage: PropTypes.func,
   activeContact: PropTypes.array,
};

export default Email;
