import appConfigs from "../../config/appConfigs";
import {
    request
} from "../../shared/helpers/RequestWrapper";

const API_HOST = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS + "/organization";
const API_BASE_TALENTSPY = appConfigs.endpoint.API_BASE_TALENTSPY;

export const JOB_PROJECT_POST_URL = "/users";
export const INSERT_UPDATE_JOB_OR_PROJECT_URL = JOB_PROJECT_POST_URL + "/uploadProfileImage";
export const ORG_DETAILS_URL = JOB_PROJECT_POST_URL + "/getOrganizationDetails";
export const INSERT_UPDATE_ORGANIZATION_USER_URL = JOB_PROJECT_POST_URL + "/insertUpdateOrganizationUsers";

export const GET_CHRO_PROFILE_URL = "/getCHROProfile.json";
export const UPDATE_CHRO_PROFILE_URL = "/updateCHROProfile.json";


export function updateProfilePhoto(body) {
    return request({
        url: API_HOST + INSERT_UPDATE_JOB_OR_PROJECT_URL,
        method: "post",
        body: body
    });
}

export function getOrganizationDetails(body) {
    return request({
        url: API_HOST + ORG_DETAILS_URL,
        method: "get"
    });
}


export function insertUpdateOrganizationProfile(body) {
    return request({
        url: API_HOST + INSERT_UPDATE_ORGANIZATION_USER_URL,
        method: "post",
        body: body
    });
}

export function getCHROProfile(body) {
    return request({
        url: API_BASE_TALENTSPY + GET_CHRO_PROFILE_URL,
        method: "post"
    });
}

export function updateCHROProfile(body) {
    return request({
        url: API_BASE_TALENTSPY + UPDATE_CHRO_PROFILE_URL,
        method: "post",
        body: body
    });
}
