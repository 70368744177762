import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, ButtonToolbar, Card, CardBody, Col, Row, Tooltip } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { getSuggestCountry } from '../../../../api/country.api';
import { addJobOrProject } from '../../../../api/Organization/jobProjectPosted.api';
import { getCurrency } from '../../../../api/Organization/opportunities.api';
import { getSkills } from '../../../../api/skills.api';
import {
   setTourConfig,
   setTourOpen,
} from '../../../../redux/actions/tourAction';
import RenderCheckBoxRoundedGroupField from '../../../../shared/components/form/CheckBoxRoundedGroup';
import {
   showErrorNotification,
   showNotification,
} from '../../../../shared/components/toastMessage';
import { tourEnum } from '../../../../shared/enums/tour.enum';
import JobOtherSkills from './JobOtherSkills';
import validate from './validate';
import { trackEventMixpanel } from '../../../../shared/helpers/Mixpanel';
import { eventsEnum } from '../../../../shared/enums';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import { Slider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { getJobsSeekerDetailsById } from '../../../../api/Organization/applicant.details.api';



const renderField = ({
   className,
   input,
   placeholder,
   type,
   min,
   meta: { touched, error },
   ...rest
}) => (

   <div className={`form__form-group-input-wrap ${className}`}>
      {/*{placeholder}*/}
      <TextField
         {...rest}
         label={placeholder}
         size={'small'}
         variant={'outlined'}
         {...input}
         placeholder={placeholder}
         type={type}
         min={min}
         mousewhee={(e) => e.preventDefault()}
      />
      {touched && error && (
         <span className="form__form-group-error">{error}</span>
      )}
   </div>
);

const renderRadioButtonField = (props) => {
   const {
      input,
      label,
      disabled,
      className,
      radioValue,
   } = props;
   return (
      <FormControlLabel
         {...input}
         name={'name'}
         color={'primary'}
         // defaultChecked={defaultChecked}
         // checked={defaultChecked}
         className={className}
         disabled={disabled}
         control={<Radio color={'primary'} name={'name'} size={"small"} />}
         label={label}
         value={radioValue}
      />
   );
};

const CheckBoxGroupComponent = (props, value) => {
   const {
      meta: { touched, error },
   } = props;
   return (
      <>
         <RenderCheckBoxRoundedGroupField {...props} value={value} />
         {touched && error && (
            <span className="form__form-group-error">{error}</span>
         )}
      </>
   );
};

renderField.propTypes = {
   input: PropTypes.shape().isRequired,
   placeholder: PropTypes.string,
   type: PropTypes.string,
   meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
   }),
};

renderField.defaultProps = {
   placeholder: '',
   meta: null,
   type: 'text',
};


const DegreeData = [
   { name: 'Bachelors', id: 0 },
   { name: 'Masters', id: 1 },
   { name: 'PhD', id: 2 },
]


function valuetext(value) {
   return `${value}°C`;
}


class PostJobProjectForm extends PureComponent {
   static propTypes = {
      t: PropTypes.func.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      pristine: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
   };

   constructor(props) {
      super(props);
    //  const selectedData = props?.location?.state?.selectedData;
      this.state = {
         refresh: true,
         countries: [],
         specificSkills: [],
         disableButton: false,
         displayInformation: false,
         requiredOtherSkills: [],
         otherSkillsDisplay: true,
         currencies: [],
         currencySymbol: 1,
         hiringManager: null,
         isCountryEmpty: true,
         certificateData: [],
         selectedCertificate: [],
         certificateAddForJobId: [],
         // degreeId: selectedData?.degreeId?.id ?? -1,
         degreeId: null,
         cgpa: null,
         getJobsSeekerDetails: [],
         currenciesId: 1,
         currencyShortName: '',
         isEditForm: false,
         countryValue: '',
         selectedDegree: "",
         selectedCetificatedData: [],
         locationRequirement: "1",
         isSmartSkillRequired: "1",
         jobType: "1",
         jobLevel: "1",
         selectedRequriedSkill: [],
         designation: "", description: "", salaryBegin: "", salaryEnd: "", location: "",
         selectedCertificateId: [], jobStatus: 2,
         pageType: "jobs",
         applicantSource: "1",
         isSalaryNegotiable: false,
         expirydate: null


      };
   }

   componentDidMount() {
      let jobId = window.location.href.split("/")[6]
      if (jobId) {
         this.setState({
            isEditForm: true,
            jobId: jobId,
            pageType: this.props.location.state?.activeTab || "jobs"

         })
         this.getJobsSeekerDetailsById(jobId);
      }


      console.log("this.props.......",this.props?.location?.state?.activeTab)
      trackEventMixpanel(eventsEnum.STARTED_JOB_POSTS);
      setTimeout(() => {
         this.getSkills();
         this.getCurrency();
         this.props.dispatch(setTourConfig(tourEnum.POST_JOB));
         this.props.dispatch(setTourOpen(true));
         this.getCountriesByName({ target: { value: 'a' } });
      }, 2000)

   }

   componentWillUnmount() {
      this.props.dispatch(setTourOpen(false));


      console.log("this.props1.......",this.props?.location?.state?.activeTab)
   }


   getJobsSeekerDetailsById = (jobId) => {
      getJobsSeekerDetailsById(jobId)
         .then((data) =>
            this.setState({
               designation: data?.data?.designation || '',
               description: data?.data?.description || '',
               currencyShortName: data?.data?.currencyShortName || '',
               salaryBegin: data?.data?.salaryBegin || undefined,
               salaryEnd: data?.data?.salaryEnd || undefined,
               location: data?.data?.location || '',
               cgpa: data?.data?.cgpa || 0,
               countryValue: {
                  name: data?.data?.country?.name,
                  label: data?.data?.country?.name,
                  value: data?.data?.country?.name,
                  id: data?.data?.country?.id,
                  status: true
               },
               selectedDegree: DegreeData.filter((res) => res.id === data?.data?.degreeId)[0] || '',
               selectedCertificateId: data?.data?.certificateId?.split(",") || "",
               locationRequirement: data?.data?.locationRequirement?.toString() || "1",
               isSmartSkillRequired: data?.data?.isSmartSkillRequired ? "1" : "2",
               jobType: data?.data?.jobType?.toString() || "1",
               jobLevel : data?.data?.jobLevel?.toString() || "1",
               selectedRequriedSkill: data?.data?.jobRequiredSkills?.map(res => { return res.skill }) || [],
               requiredOtherSkillsSubmitted: data?.data?.otherSkill?.map(res => { return res.id }) || [],
               requiredOtherSkills: data?.data?.otherSkill?.map(res => { return res.name }) || [],
               expirydate: data?.data?.expirydate || null
            }, () => {
               console.log("selectedRequriedSkill :: ", this.state.selectedRequriedSkill)
            })
         )
         .catch((error) => {
            console.log('getSkills error :: ', error);
            showErrorNotification(error);
         }).finally(() => {
            // this.setState({
            //    refresh:false
            // })
         });
   };


   getCurrency = () => {
      getCurrency()
         .then((data) =>
            this.setState({
               currencies: [...data.data],
               currencySymbol: 1,
            }, () => {
               const { currencies, currencyShortName, isEditForm } = this.state;
               if (isEditForm) {
                  let currenciesId = currencies.filter((res) => res.shortName === currencyShortName)[0]?.id;
                  this.setState({
                     currenciesId
                  })
               }
            }
            )
         )
         .catch((error) => {
            console.log('getSkills error :: ', error);
            this.setState({
               refresh: !this.state.refresh,
            });
            showErrorNotification(error);
         });
   };

   getSkills = () => {
      getSkills({})
         .then((skillsResponse) => {
            let update = {};
            let certificateData = []
            skillsResponse.data.content.filter(res => res.certificationName).map((res) => {
               let certiFicationObj = {}
               certiFicationObj.certificateName = res.certificationName;
               certiFicationObj.id = res.id;
               return certificateData.push(certiFicationObj)
            })
            update.specificSkills = skillsResponse.data.content || [];
            update.refresh = !this.state.refresh;
            this.setState({ ...update, certificateData: certificateData }, () => {
               const { isEditForm, certificateData, selectedCertificateId } = this.state;
               if (isEditForm) {
                  let selectedCetificatedData = certificateData.filter((res) => selectedCertificateId.includes(res.id.toString())).map((res) => {
                     return {
                        certificateName: res.certificateName,
                        id: res.id
                     }
                  });
                  this.setState({
                     selectedCetificatedData
                  })
               }
            });
         })
         .catch((error) => {
            console.log('getSkills error :: ', error);
            this.setState({
               refresh: !this.state.refresh,
            });
            showErrorNotification(error);
         });
   };

   handleSubmit = (e) => {
      e.preventDefault();
      return false;
   };

   handleHiringManager = (value) => {
      this.setState({
         hiringManager: value,
      });
   };

   onSubmit = (e) => {
      e.preventDefault();
      const {pageTypeJobOrProject} = this.props
      const { expirydate, pageType, designation, applicantSource, isSalaryNegotiable, jobStatus, isSmartSkillRequired, description, salaryBegin, salaryEnd, location, isEditForm, jobId, countryValue, selectedCetificatedData, locationRequirement, jobType,jobLevel, selectedRequriedSkill } = this.state;
      if (designation === null || designation === '') {
         showNotification("Designation is required")
      } else if (description === null || description === '') {
         showNotification("description is required")
      } else if (expirydate === null) {
         showNotification("expiry date is required")
      } else {
         let formValues = {}
         this.setState({
            refresh: !this.state.refresh,
         });
         if (isEditForm) {
            formValues.id = parseInt(jobId);
         }
         formValues.type = pageTypeJobOrProject==="Job" ?  1 : 2;
         formValues.isSmartSkillRequired = isSmartSkillRequired === '1';
         formValues.designation = designation;
         formValues.description = description;
         formValues.salaryBegin = salaryBegin;
         formValues.salaryEnd = salaryEnd;
         formValues.location = location;
         formValues.otherSkills = this.state.requiredOtherSkillsSubmitted || [];
         formValues.currencyId = this.state.currencySymbol;
         formValues.countryId = countryValue.id;
         formValues.certificateId = selectedCetificatedData.length > 0 ? selectedCetificatedData.map((res) => { return res.id }).join(",") : "";
         formValues.degreeId = this.state.degreeId;
         formValues.locationRequirement = parseInt(locationRequirement)
         formValues.cgpa = this.state.cgpa
         formValues.expirydate = this.state.expirydate
         formValues.jobType = parseInt(jobType)
         formValues.jobLevel = parseInt(jobLevel)
         formValues.requiredSkills = selectedRequriedSkill?.map((res) => { return res.id })
         formValues.jobStatus = jobStatus
         formValues.isSalaryNegotiable = isSalaryNegotiable
         formValues.applicantSource = applicantSource
         formValues.expirydate = expirydate

         addJobOrProject(formValues)
            .then(() => {
               trackEventMixpanel(eventsEnum.POSTED_JOB_POSTS);
               showNotification("Job / Project posted successfully", 'primary');
               this.setState({ refresh: !this.state.refresh });
               let path = '/organization/opportunities';
               let activetab = this.props.location.state.activeTab
               this.props.history.push(
                  {
                     pathname: path,
                     state: {activeTab : activetab  }
                  });
            })
            .catch((error) => {
               console.log('addJobOrProject error :: ', error);
               this.setState({
                  refresh: !this.state.refresh,
                  // disableButton: true
               });
               showErrorNotification(error);
            });
      }

   };

   countryChange = (value) => {
      this.setState({
         countryValue: value,
         selectedCountry: value,
         isCountryEmpty: !value
      });
   };

   getCountryByName = (value) => {
      return getSuggestCountry(value || 'a')
         .then((res) => {
            let countries =
               (res.data &&
                  res.data.length &&
                  res.data.map(({ name, ...rest }) => ({
                     name: name,
                     label: name,
                     value: name,
                     ...rest,
                  }))) ||
               [];
            return countries;
         })
         .catch((error) => {
            console.log('getSuggestCountry error :: ', error);
            // showErrorNotification(error);
         });
   };

   addOtherSkill = (skill) => {
      if(skill!==""){
         let requiredOtherSkills = this.state.requiredOtherSkills || [];
         let requiredOtherSkillsSubmitted =
            this.state.requiredOtherSkillsSubmitted || [];
         if (
            requiredOtherSkills.indexOf(skill.name) === -1 &&
            requiredOtherSkillsSubmitted.indexOf(skill.id) === -1
         ) {
            requiredOtherSkills.push(skill.name);
            requiredOtherSkillsSubmitted.push(skill.id);
            this.setState({
               requiredOtherSkills: requiredOtherSkills,
               requiredOtherSkillsSubmitted: requiredOtherSkillsSubmitted
            });
         }
      }

   };

   removeOtherSkill = (index) => {
      let requiredOtherSkills = this.state.requiredOtherSkills || [];
      let requiredOtherSkillsSubmitted =
         this.state.requiredOtherSkillsSubmitted || [];
      requiredOtherSkills.splice(index, 1);
      requiredOtherSkillsSubmitted.splice(index, 1);
      this.setState(
         {
            requiredOtherSkills: requiredOtherSkills,
            requiredOtherSkillsSubmitted: requiredOtherSkillsSubmitted,
            otherSkillsDisplay: false
         },
         () => {
            this.setState({
               otherSkillsDisplay: true
            });
         }
      );
   };

   toggleDisplayInformation = () => {
      this.setState({
         displayInformation: !this.state.displayInformation,
      });
   };

   toggleDisplayInformationSmartSkills = () => {
      this.setState({
         displayInformationSmartSkills: !this.state.displayInformationSmartSkills,
      });
   };



   getCountriesByName = (event) => {
      const value = event.target.value;
      this.setState({
         loadingCountries: true,
      });
      getSuggestCountry(value || 'a')
         .then((res) => {
            if (res.status && res.data) {
               this.setState({
                  loadingCountries: false,
               });
               this.setState({
                  countries: res.data.map(({ name, ...rest }) => ({
                     name: name,
                     label: name,
                     value: name,
                     ...rest,
                  })),
               });
            } else {
               throw res;
            }
         })
         .catch((error) => {
            console.log('getSuggestCountry error :: ', error);
         });
   };

   handleOnChnageDropDown = (event, value, index) => {
      if (index === 1) {
         let certificateAddForJob = []
         value.map((res) => {
            return certificateAddForJob.push(res.id)
         })
         this.setState({
            selectedCetificatedData: value,
            selectedCertificate: value,
            certificateAddForJobId: certificateAddForJob.join(',')
         })
      }

      if (index === 2) {
         this.setState({
            currenciesId: event.target.value,
            currencySymbol: event.target.value,
         })
      }


   }
   handleOnChnageDropDownDegreeData = (value) => {
      this.setState({
         selectedDegree: value,
         degreeId: value?.id
      })
   }

   handleOnChaneRadioButton = (event, value) => {
      if (value === 1) {
         this.setState({
            locationRequirement: event.target.value
         })
      }
      if (value === 2) {
         this.setState({
            isSmartSkillRequired: event.target.value
         })
      }
      if (value === 3) {
         this.setState({
            jobType: event.target.value
         })
      }
      if (value === 4) {
         this.setState({
            jobLevel: event.target.value
         })
      }
   }

   handleONChnageField = (event) => {
      this.setState({
         [event.target.name]: event.target.value
      })
   }

   handleOnChangeSkill = (event) => {
      const { specificSkills } = this.state
      const updatedSkill = specificSkills.filter((res) => event.includes(res.id)).map((res) => {
         return res;
      })
      this.setState({
         selectedRequriedSkill: updatedSkill
      })

   }

   render() {

      const {
         pageType,
         refresh,
         specificSkills,
         requiredOtherSkills,
         displayInformation,
         otherSkillsDisplay,
         currencies,
         countries,
         displayInformationSmartSkills,
         certificateData,


         designation, description, salaryBegin, salaryEnd, location, cgpa,
         currenciesId = 5,
         countryValue,
         selectedDegree,
         selectedCetificatedData,
         locationRequirement,
         isSmartSkillRequired,
         jobType,jobLevel,
         selectedRequriedSkill = [],
         isEditForm
      } = this.state;
    const   {pageTypeJobOrProject} = this.props
      return (
         <Col md={12} lg={12} xl={7}>
            <Card data-tut={'tour_post_job_form'}>
               <CardBody className="job__form">
                  {refresh ? (
                     <div className="panel__refresh">
                        <LoadingIcon />
                     </div>
                  ) : null}
                  <div className="card__title job__form-title">
                     <h5 className="bold-text">Basic Details</h5>
                  </div>
                  <form
                     method={"POST"}
                     onSubmit={this.onSubmit}
                  >
                     <div className="form__form-group col-sm-12">
                        <TextField
                           size={'small'}
                           variant={'outlined'}
                           ant={'outlined'}
                           className={'w-100 mr-3'}
                           name="designation"
                           label={`${pageTypeJobOrProject ==='Job'  ? 'job' : 'project' } title*`}
                           value={designation}
                           onChange={this.handleONChnageField}
                        />
                     </div>
                     <div className="form__form-group col-sm-12">

                        <TextField
                           size={'small'}
                           variant={'outlined'}
                           ant={'outlined'}
                           className={'w-100 mr-3'}
                           name="description"
                           value={description}
                           multiline
                           rows={4}
                           onChange={this.handleONChnageField}
                           label={`${pageTypeJobOrProject ==='Job'  ? 'job' : 'project' } description*`}
                        />


                     </div>

                     <div className="form__form-group col-12 d-flex">
                        <FormControl
                           variant="outlined"
                           size={'small'}
                           label={'Currency'}
                        >
                           <InputLabel id="demo-simple-select-outlined-label">
                              Currency
                           </InputLabel>
                           <Select
                              autoWidth
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={currenciesId}
                              onChange={(e, value) => this.handleOnChnageDropDown(e, value, 2)

                              }
                              label="Currency"
                           >
                              {currencies.map((currency, index) => {
                                 return (
                                    <MenuItem
                                       selected={index === 0}
                                       value={currency.id}
                                    >
                                       {' '}
                                       {currency.shortName} {currency.symbol}
                                    </MenuItem>
                                 );
                              })}
                           </Select>
                        </FormControl>

                        <TextField
                           size={'small'}
                           variant={'outlined'}
                           ant={'outlined'}
                           className={'ml-2 mr-2'}
                           value={salaryBegin}
                           onChange={this.handleONChnageField}
                           type="number"
                           name={'salaryBegin'}
                           label={`Min. Salary`}
                        />
                        <span className="form__form-group-label job__form-group-lable mt-1">
                           To
                        </span>

                        <TextField
                           size={'small'}
                           variant={'outlined'}
                           ant={'outlined'}
                           className={'ml-2'}
                           value={salaryEnd}
                           onChange={this.handleONChnageField}
                           type="number"
                           name={'salaryEnd'}
                           label={`Max. Salary`}
                        />

                     </div>
                     <div className="form__form-group col-sm-12 col-md-12 d-flex">
                        <Autocomplete
                           className={'mr-1 w-50'}
                           loading={this.state.loadingCountries}
                           loadingText={<>Loading</>}
                           options={countries}
                           value={countryValue}
                           disableClearable={true}
                           onChange={(event, value) =>
                              this.countryChange(value)
                           }
                           getOptionLabel={(option) => option.name}
                           renderInput={(params) => (
                              <div>
                                 <TextField
                                    {...params}
                                    size={'small'}
                                    label="Country"
                                    variant="outlined"
                                    color={'primary'}
                                 />
                              </div>
                           )}
                        />
                        <TextField
                           size={'small'}
                           variant={'outlined'}
                           ant={'outlined'}
                           className={'ml-1 w-50'}
                           value={location}
                           onChange={this.handleONChnageField}
                           type={'text'}
                           name={'location'}
                           label={`City`}
                        />
                     </div>

                     <div className="form__form-group col-sm-12 col-md-12 d-flex">
                        <Autocomplete
                           className={'mr-1 w-50'}
                           loading={false}
                           loadingText={<>Loading</>}
                           options={DegreeData}
                           value={selectedDegree}
                           name={'selectedDegree'}
                           placeholder={"Highest Educational Attainment"}
                           disableClearable={true}
                           onChange={(event, value) =>
                              this.handleOnChnageDropDownDegreeData(value,1)
                           }
                           getOptionLabel={(option) => option.name}
                           renderInput={(params) => (
                              <div>
                                 <TextField
                                    {...params}
                                    size={'small'}
                                    label="Highest Educational Attainment"
                                    variant="outlined"
                                    color={'primary'}
                                 />
                              </div>
                           )}
                        />
                        <div className={'form__form-group col-6 pl-2 pr-0'}>
                           <div className="input_box">
                              <div className="d-flex w-100 half_input_box">
                                 <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    style={{ height: '0.1876em' }}
                                 >
                                    <DatePicker
                                       required
                                       className="start-end-date form__form-group-datepicker no_border"
                                       inputVariant="outlined"
                                        label="Job Expiry Date"
                                      // placeholder="Job End Date"
                                       value={this.state.expirydate}
                                       minDate={moment().toDate()}
                                       format="d MMMM yyyy"
                                       onChange={(selectedDate) => {

                                          this.setState({
                                             expirydate: selectedDate,
                                          });

                                          setTimeout(() => {
                                             if (
                                                moment(
                                                   this.state.deadlineStart
                                                ).format('X') >
                                                moment(
                                                   this.state.deadlineEnd
                                                ).format('X')
                                             ) {
                                                this.setState({
                                                   dateValidationMsg:
                                                      'Deadline Date is not valid',
                                                   deadlineStartError: true,
                                                });
                                             } else {
                                                this.setState({
                                                   deadlineStartError: false,
                                                });
                                             }
                                          }, 500);
                                       }}
                                       // onBlur={(e) =>
                                       //    this.validateNullValueOnBlur(
                                       //       e,
                                       //       'deadlineStartError'
                                       //    )
                                       // }
                                       // error={this.state.deadlineStartError}
                                       // helperText={
                                       //    this.state.deadlineStartError &&
                                       //    dateValidationMsg
                                       // }
                                       InputLabelProps={{
                                          shrink: true,
                                          variant: 'outlined',
                                       }}
                                    />
                                 </MuiPickersUtilsProvider>
                                 {/* <DatePicker
                                       className="form__form-group-datepicker"
                                       selected={deadlineStart}
                                       onChange={this.handleChangeStart}
                                       dateFormat="d MMMM yyyy"
                                       dropDownMode="select"
                                       withPortal={isMobileOnly}
                                       value={deadlineStart}
                                       placeholderText="1 March 2022"
                                    /> */}
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className={'form__form-group col-12'}>
                        <Autocomplete
                           multiple
                           id="combo-box-demo"
                           loading={false}
                           loadingText={<>Loading</>}
                           options={certificateData}
                           value={selectedCetificatedData}
                           disableClearable={false}
                           onChange={(event, value) =>
                              this.handleOnChnageDropDown(
                                 event,
                                 value,
                                 1
                              )
                           }
                           getOptionLabel={(option) => option.certificateName}
                           className={''}
                           renderOption={(option) => (
                              <Typography style={{ fontSize: "12px" }}>{option.certificateName}</Typography>
                           )}
                           renderInput={(params) => (
                              <TextField
                                 {...params}
                                 size={'small'}
                                 label="Enter Certificate Name"
                                 variant="outlined"
                                 color={'primary'}
                              />
                           )}
                        />
                     </div>

                     <div>
                        <span className="form__form-group-label job__form-group-lable pl-3">
                           Required CGPA
                        </span>
                        <Box sx={{ width: "auto", marginTop: "35px", padding: "0 22px 0 22px"  }} className={'post_job_projeect_box_slider'}>
                           <Slider
                              aria-label="Small steps"
                              defaultValue={0.0}
                              getAriaValueText={valuetext}
                              step={0.1}
                              marks
                              min={0.0}
                              max={4.0}
                              valueLabelDisplay="on"
                              value={cgpa}
                              onChange={(_, v) => this.setState({
                                 cgpa: v
                              })}
                           />
                        </Box>
                     </div>
                     <div className="form__form-group col-sm-12 col-md-6"></div>
                     <Row>
                        <Col md={6} >
                     <div className="form__form-group col-sm-12">
                        <span className="form__form-group-label job__form-group-lable">
                           Location Requirement
                        </span>
                        <div>
                           <RadioGroup
                              aria-label="locationRequirment"
                              name="locationRequirment1"
                              className={'d-block'}
                              // defaultValue={"1"}
                              value={locationRequirement}
                              onChange={(event) => this.handleOnChaneRadioButton(event, 1)}
                           >
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="locationRequirement"
                                       component={renderRadioButtonField}
                                       label="Onsite"
                                       radioValue="1"
                                       defaultChecked
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="locationRequirement"
                                       component={renderRadioButtonField}
                                       label="Remote"
                                       radioValue="2"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="locationRequirement"
                                       component={renderRadioButtonField}
                                       label="Hybrid"
                                       radioValue="3"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                           </RadioGroup>
                        </div>
                     </div>
                     <div
                        className="form__form-group col-sm-12"
                        data-tut={'tour_post_job_smar_skill'}
                     >
                        <span className="form__form-group-label job__form-group-lable">
                           Require Mandatory Smart Skills?{' '}
                           <>
                              <AlertCircleIcon
                                 title={
                                    'Smart skills helps hiring managers and recruiters to identify qualified candidate whose character traits are best suited and a good fit for a specific position. Smart skills provides evaluation scoring of 14 personality dimensions such as leadership, communications, innovation, creativity and more.'
                                 }
                                 className="panel__btn_alert-circle"
                                 id={'tooltipDisplayInfoSmartSkill'}
                              />
                              <Tooltip placement="right" isOpen={displayInformationSmartSkills} target={'tooltipDisplayInfoSmartSkill'} toggle={this.toggleDisplayInformationSmartSkills}>
                                 Smart skills helps hiring managers and recruiters to identify qualified candidate whose character traits are best suited and a good fit
                                 for a specific position. Smart skills provides evaluation
                                 scoring of 14 personality dimensions such as leadership, communications, innovation, creativity and more.
                              </Tooltip>
                           </>
                        </span>

                        <div>
                           <RadioGroup
                              aria-label="isSmartSkillRequired"
                              name="isSmartSkillRequired1"
                              className={'d-block'}
                              value={isSmartSkillRequired}
                              onChange={(event) => this.handleOnChaneRadioButton(event, 2)}
                           >
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="isSmartSkillRequired"
                                       component={renderRadioButtonField}
                                       label="Yes"
                                       radioValue="1"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="isSmartSkillRequired"
                                       component={renderRadioButtonField}
                                       label="No"
                                       defaultChecked
                                       radioValue="2"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                           </RadioGroup>
                        </div>
                     </div>

                     <div className="form__form-group col-sm-12">
                        <span className="form__form-group-label job__form-group-lable">
                           Job Level
                        </span>
                        <div>
                           <RadioGroup
                              aria-label="jobLevel"
                              name="jobLevel"
                              className={'d-block'}
                              defaultValue={"1"}
                              value={jobLevel}
                              onChange={(event) => this.handleOnChaneRadioButton(event, 4)}
                           >
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="jobLevel"
                                       component={renderRadioButtonField}
                                       label="Junior Level"
                                       defaultChecked
                                       radioValue="1"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="jobLevel"
                                       component={renderRadioButtonField}
                                       label="Middle Level"
                                       radioValue="2"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="jobLevel"
                                       component={renderRadioButtonField}
                                       label="Senior Level"
                                       radioValue="3"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>

                           </RadioGroup>
                        </div>
                     </div>
                        </Col>
                     <Col md={6}>
                     <div className="form__form-group col-sm-12">
                        <span className="form__form-group-label job__form-group-lable">
                           Job Type
                        </span>
                        <div>
                           <RadioGroup
                              aria-label="jobType"
                              name="jobType1"
                              className={'d-block'}
                              defaultValue={"1"}
                              value={jobType}
                              onChange={(event) => this.handleOnChaneRadioButton(event, 3)}
                           >
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="jobType"
                                       component={renderRadioButtonField}
                                       label="Full-Time"
                                       defaultChecked
                                       radioValue="1"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="jobType"
                                       component={renderRadioButtonField}
                                       label="Part-Time"
                                       radioValue="2"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="jobType"
                                       component={renderRadioButtonField}
                                       label="Contract"
                                       radioValue="3"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>
                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="jobType"
                                       component={renderRadioButtonField}
                                       label="Freelance"
                                       radioValue="4"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>



                              <div className="form__form-group job__form-group-radio">
                                 <div className="form__form-group-field">
                                    <Field
                                       name="jobType"
                                       component={renderRadioButtonField}
                                       label="Temporary"
                                       radioValue="5"
                                       className="colored-click"
                                    />
                                 </div>
                              </div>  <div className="form__form-group job__form-group-radio">
                              <div className="form__form-group-field">
                                 <Field
                                    name="jobType"
                                    component={renderRadioButtonField}
                                    label="Volunteer"
                                    radioValue="6"
                                    className="colored-click"
                                 />
                              </div>
                           </div>  <div className="form__form-group job__form-group-radio">
                              <div className="form__form-group-field">
                                 <Field
                                    name="jobType"
                                    component={renderRadioButtonField}
                                    label="Internship"
                                    radioValue="7"
                                    className="colored-click"
                                 />
                              </div>
                           </div>
                           </RadioGroup>
                        </div>
                     </div>
                     </Col>
                     </Row>

                     <div
                        className="form__form-group col-sm-12"
                        data-tut={'tour_post_job_required_skills'}
                     >
                        <span className="form__form-group-label job__form-group-lable">
                           Select Required Data Analytics &amp; Artificial
                           Intelligence Skills*
                        </span>
                        {selectedRequriedSkill.length >= 0 && specificSkills.length > 0 && <div
                           className="form__form-group"
                           style={{ display: 'table-cell' }}
                        >
                           <div className="form__form-group-field job__skills-one">
                              {console.log("specificSkills", specificSkills)}
                              <Field
                                 defaultChecked={selectedRequriedSkill}
                                 value={selectedRequriedSkill}
                                 onChange={this.handleOnChangeSkill}
                                 name={`requiredSkills`}
                                 component={CheckBoxGroupComponent}
                                 className="button"
                                 options={specificSkills}
                              />
                           </div>
                        </div>}
                     </div>
                     <div className="form__form-group col-sm-12">
                        <span
                           className="form__form-group-label job__form-group-lable"
                           style={{ width: 'max-content' }}
                        >
                           Other Skills{' '}
                           <>
                              <AlertCircleIcon
                                 title={
                                    'Please note, that any other skills added to the job description will not have an impact on skills calculation.'
                                 }
                                 className="panel__btn_alert-circle"
                                 id={"tooltipDisplayInfo"}
                              />
                              <Tooltip placement="right" isOpen={displayInformation} target={'tooltipDisplayInfo'} toggle={this.toggleDisplayInformation}>
                                 Please note, that any other skills added to the
                                 job description will not have an impact on
                                 skills calculation
                              </Tooltip>
                           </>
                        </span>
                        <div className="form__form-group">
                           {otherSkillsDisplay && (
                              <JobOtherSkills
                                 otherSkills={requiredOtherSkills}
                                 addOtherSkill={this.addOtherSkill}
                                 removeOtherSkill={this.removeOtherSkill}
                              // allOtherSkill = {this.allOtherSkill}
                              />
                           )}
                        </div>
                     </div>
                     <ButtonToolbar className="form__button-toolbar job__form-button">
                        <Button
                           color="primary"
                           type="submit"
                        >
                           {isEditForm ?
                              `Update ${pageTypeJobOrProject ==="Job"  ? "job" : "project"}`
                              :
                              `Post ${pageTypeJobOrProject}`
                           }

                           {/*{selectedData ? 'Update' : 'Post'} {pageType}*/}
                           {/* Post {pageType} */}
                        </Button>
                     </ButtonToolbar>
                  </form>
               </CardBody>
            </Card>
         </Col>
      );
   }
}

let InitializeFromStatePostJobForm = reduxForm({
   form: 'vertical_form_validation', // a unique identifier for this form
   validate,
   onSubmitFail: (errors) => errors && showNotification(errors[[Object.keys(errors)[0]]]),
   enableReinitialize: true,
})(withRouter(withTranslation('common')(PostJobProjectForm)));

InitializeFromStatePostJobForm = withRouter(connect((state, props) => {
   const data = props.location?.state?.selectedData;
   return {
      initialValues: {
         designation: data?.designation ?? '',
         description: data?.description ?? '',
         salaryBegin: data?.salaryBegin ?? undefined,
         salaryEnd: data?.salaryEnd ?? undefined,
         location: data?.location ?? '',
         locationRequirement: data?.locationRequirement ?? '1',
         isSmartSkillRequired: data?.isSmartSkillRequired ? '1' : '2',
         applicantSource: data?.applicantSource ?? '1',
         isSalaryNegotiable: data?.isSalaryNegotiable ?? false,
         jobStatus: data?.jobStatus ?? 2,
         requiredSkills: [],

      }
   }
})(InitializeFromStatePostJobForm));

export default InitializeFromStatePostJobForm;

