import React, { PureComponent } from "react";
import SearchIcon from "mdi-react/SearchIcon";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Input } from "reactstrap";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { hookAuth0 } from "../../../shared/components/auth/withAuth0";
import { trackEventMixpanel } from "../../../shared/helpers/Mixpanel";

class TopbarSearch extends PureComponent {
  constructor() {
    super();
    this.state = { inputOpen: false };
  }

  onInputOpen = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ inputOpen: !prevState.inputOpen }));
  };

  handleKeyPress = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.trim();
    if (e.which === 13 && searchValue) {
      this.setState((prevState) => ({ inputOpen: !prevState.inputOpen }));
      let { history } = this.props;
      trackEventMixpanel("search")
      let path = "";
      if(window.location.href.includes("/individual")) {
         path = '/individual/search'
      } else {
         path = '/organization/search'
      }
      history.push({
        pathname: path,
        state: { searchQuery: searchValue ? searchValue.trim() : "" }
      });
    }

  };

  render() {
    const { inputOpen } = this.state;
    const placeHolder = (window.location.href.includes("/individual")) ? "Search Assessments, Specialization, Courses, Opportunities & News." : "Search projects & opportunities."

    const searchClass = classNames({
      "topbar__search-field": true,
      "topbar__search-field--open": inputOpen
    });

    return (
      <div className="topbar__search d-none d-md-flex">
        {inputOpen && <div className="ml-auto w-100 mr-2 container__search-control">
          <Input
            style={{ height: "2rem", width: "18rem", borderRadius: "22px" }}
            type="text"
            autoFocus
            name={"searchQuery"}
            placeholder={placeHolder}
            onKeyPress={this.handleKeyPress}
            onBlur={() => {
              this.setState({
                inputOpen: false
              });
            }}
          />
          <MagnifyIcon style={{ left: "0.5rem", top: "0.3rem" }}/>
        </div>}
        {inputOpen ? null :
          <>
            <Input className={searchClass}/>
            <button
              className="topbar__btn topbar__search-btn"
              type="button"
              onClick={this.onInputOpen}>
              <SearchIcon/>
            </button>
          </>
        }
      </div>
    );
  }
}


export default connect((state) => ({ themeName: state.theme.className }))(
  withRouter(hookAuth0(TopbarSearch))
);
