import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import { setupAxios } from './shared/helpers/RequestWrapper';
// import { hotjar } from 'react-hotjar';
// hotjar.initialize(2119865, 6);

setupAxios(axios);

/**
 * embedding a chat box
 */
// var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
// (function() {
//   const s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
//   s1.async = true;
//   s1.src = "https://embed.tawk.to/5f28f52e2da87279037e3f17/default";
//   s1.charset = "UTF-8";
//   s1.setAttribute("crossorigin", "*");
//   s0.parentNode.insertBefore(s1, s0);
// })();

ReactDOM.render(<App />,document.getElementById('root'));

// ReactDOM.render(
//    < React.StrictMode >
//       <App />
//    < /React.StrictMode >,
//    document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
