import CloseIcon from "mdi-react/CloseIcon";
import React, { Component } from "react";
import {  Modal, ModalBody, ModalFooter } from 'reactstrap';
import AddProfileModalEmployeesList from "./AddProfileModalEmployeesList";
import {showErrorNotification} from "../../../../../../shared/components/toastMessage";
import { getListToCompare } from '../../../../../../api/Organization/compareProfile.api';
import LoadingIcon from 'mdi-react/LoadingIcon';
// import {getSpecificJobsSeekerDetails} from "../../../../api/Dashboard/charts.api";



export class AddProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state={
       selectedEmployees: [],
       addProfile: [],
       isLast: false,
       pageNumber: 0
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
     if(nextProps.isAddProfileModalOpen) {
        this.setState({
           addProfile:[],
           selectedEmployees: this.props.selectedEmployees,
        },() => {
           this.getEmployeeList(this.props.jobId,0)
        })
     }
  }

   getEmployeeList = (jobId,pageNo) => {
     this.setState({
        isLoading:true
     })
     getListToCompare(jobId,pageNo,10).then(response => {
        this.setState({
           addProfile: [...this.state.addProfile, ...response.content],
           isLoading:false,
           isLast: response.last,
           pageNumber: response.pageable.pageNumber
        },() => {
           let addProf =  this.state.addProfile;
           if(this.props.isAddProfile === false) {
              for(let i =0 ;i<this.props.selectedEmployees.length;i++) {
                 let val = this.props.selectedEmployees[i];
                 addProf = addProf.filter(item => item.jobSeekerId !== val);
              }
           }
           this.setState({
              addProfile: addProf,
           })
        })
     }).catch(err => {
        showErrorNotification(err)
        this.setState({
           isLoading:false
        })
     })
  }

  employeeRefresh=(id)=>{
     const items = this.state.selectedEmployees.filter(item => item !== id);
     this.props.compareJobSeekers(items);
     this.props.closeAddProfileModal();
  }

   saveEmployee = () => {
      this.props.compareJobSeekers(this.state.selectedEmployees);
      this.props.closeAddProfileModal();
   }

  handleEmployeesList = (id) => {
    const { selectedEmployees } = this.state;
    let tempSelectedEmployees = [...selectedEmployees];
    if (tempSelectedEmployees.includes(id)) {
      const index = tempSelectedEmployees.indexOf(id) ;
      tempSelectedEmployees.splice(index, 1);
    } else {
      tempSelectedEmployees.push(id);
    }
    this.setState({
       selectedEmployees: tempSelectedEmployees
    });
  };

  removeValueIfExist = (id,key) => {
     const { selectedEmployees } = this.state;
     let tempSelectedEmployees = [...selectedEmployees];
     const index = tempSelectedEmployees.indexOf(id) ;
     tempSelectedEmployees.splice(index, 1);
     this.setState({
        selectedEmployees:tempSelectedEmployees
     },() => {
        this.handleEmployeesList(key)
     })
  }

   loadMore = () => {
      let pageNo = this.state.pageNumber + 1;
      this.getEmployeeList(this.props.jobId,pageNo)
   }

  render() {
    const {
      isAddProfileModalOpen,
    } = this.props;

    const { selectedRadioBox,selectedEmployees} = this.state;
    return (
      <Modal
        isOpen={isAddProfileModalOpen}
        toggle={this.props.closeAddProfileModal}
        className="compare-employees-add-profile-modal"
      >
         <ModalBody>
            {this.state.isLoading ? (
                  <div className="panel__refresh"  style={{justifyContent: 'center', display: 'flex',width:' 95%'}}>
                     <LoadingIcon />
                  </div>
            ) :
            null}

           <div className="header">
             <div className="title-container">
                 {this.props.isAddProfile === true ?
                     <div className="title">Add Individual Profiles</div> :
                     <div className="title">Refersh Individual Profiles</div>
                 }

               <div className="subtitle">
                 You can add upto 3 profiles for comparison
               </div>
             </div>

             <div className="close-icon">
               <CloseIcon onClick={this.props.closeAddProfileModal} />
             </div>
           </div>

           {this.state.addProfile.length > 0 ? (
              <>
                 <AddProfileModalEmployeesList
                     jobSeekerId={this.props.jobSeekerId}
                     recommendationDetails={this.state.addProfile}
                     selectedRadioBox={selectedRadioBox}
                   selectedEmployees={selectedEmployees}
                   handleEmployeesList={this.handleEmployeesList}
                     removeValueIfExist={this.removeValueIfExist}
                     isLast={this.state.isLast}
                     loadMore={this.loadMore}
                 />
              </>
           ):(<div className="text-center mt-5 mb-5 pt-5 pb-5">
                 <h5>No Data Available</h5>
               </div>
           )}

            <ModalFooter>
              <div className="compare-employees-save-button-container">
                {this.props.isAddProfile === true ?
                <div className="compare-employees-save-button" onClick={this.saveEmployee}>Add Profile</div>:
                    <div className="compare-employees-save-button" onClick={()=>this.employeeRefresh(this.props.jobSeekerId)}>Add Profile</div>
                }
              </div>
            </ModalFooter>
         </ModalBody>
      </Modal>
    );
  }
}

export default AddProfileModal;
