/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */

import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { EmailProps } from '../../../shared/prop-types/EmailProps';
import { getLastChatMessage } from './ApiUtil';
import { Col,  Row } from 'reactstrap';

export default class EmailListItemMobileView extends PureComponent {
   static propTypes = {
      email: EmailProps.isRequired,
      onLetter: PropTypes.func.isRequired,
      onChangeSelect: PropTypes.func.isRequired,
      itemId: PropTypes.number.isRequired,
      settingActiveContact: PropTypes.func,
      currentUser: PropTypes.array,
      updateContactIdList: PropTypes.func,
   };

   static getDerivedStateFromProps(props, state) {
      const prevProps = state.prevProps || {};
      const isChecked =
         prevProps.isChecked !== props.isChecked
            ? props.isChecked
            : state.isChecked;
      return {
         prevProps: props,
         favorite: state.favorite,
         isChecked,
      };
   }

   constructor() {
      super();
      this.state = {
         favorite: false,
         isChecked: false,
         lastMessage: [],
         isRead: true,
      };
   }

   componentDidMount() {
      this.getLastChatMessage();
   }

   getLastChatMessage = () => {
      getLastChatMessage(this.props.email.id, this.props.currentUser.id)
         .then((response) => {
            if (response && response.status) {
               if (
                  this.props.currentUser.id ===
                  response.data.message.recipientId &&
                  response.data.message.read === false
               ) {
                  this.setState({
                     isRead: false,
                  });
               }
               if (!response.data.chatRead) {
                  this.setState({
                     isRead: false,
                  });
               }

               this.setState({
                  lastMessage: response.data.message.content.split(' '),
                  lastTimeStamp: response.data.message.timestamp,
               });
            }
         })
         .catch((error) => {
            console.error('error>>>', error);
         });
   };

   dateFormatter = (date) => {
      let today = new Date(date);
      return (
         today.getDate() +
         '/' +
         (today.getMonth() + 1) +
         '/' +
         today.getFullYear()
      );
   };

   onFavorite = (e) => {
      e.preventDefault();
      this.setState((prevState) => ({ favorite: !prevState.favorite }));
   };

   onChangeSelect = () => {
      this.setState({ isChecked: !this.state.isChecked });
      this.props.updateContactIdList(this.props.email.id);
   };

   handleClick = (value) => {
      this.setState({
         isRead: true,
      });
      this.props.settingActiveContact(value);
   };

   render() {
      const { email,  itemId } = this.props;
      const {  isChecked, lastMessage, lastTimeStamp } = this.state;
      const itemClass = classNames({
         'inbox__email-list-item': true,
      });

      return (
         <div className={'inbox__email-list-item mt-3 mb-3'}>
            {/*<Container fluid>*/}
            <Row>
               <Col sm={2} md={2} xs={2} xl={2}>
                   <span>
                     <label
                        htmlFor={itemId}
                        style={{marginLeft:"40%"}}
                        className="checkbox-btn checkbox-btn--colored-click inbox__email-list-item-checkbox"
                     >
                        <Checkbox
                           checked={isChecked}
                           onChange={this.onChangeSelect}
                           className="messages_list__checkbox mt-1"
                           color="#902027"
                           inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                     </label>
                  </span>
               </Col>
               <Col sm={7} md={7} xs={7} xl={7}>
                  {!this.state.isRead ? (
                     <span style={{fontWeight:"bold"}}
                           className="inbox__email-table-name"
                           onClick={(event) => {
                              this.props.onLetter(event);
                              this.handleClick(email);
                           }}
                     >
                        {email.name}
                     </span>
                  ) : (
                     <span style={{fontWeight:"bold"}}
                           className="inbox__email-table-name"
                           onClick={(event) => {
                              this.props.onLetter(event);
                              this.handleClick(email);
                           }}
                     >
                        {email.name}
                     </span>
                  )}
               </Col>
               <Col sm={3} md={3} xs={3} xl={3}>
                  {!this.state.isRead ? (
                     <span style={{fontWeight:"bold"}}
                           onClick={(event) => {
                              this.props.onLetter(event);
                              this.handleClick(email);
                           }}
                           className="inbox__email-table-date"
                     >
                  {lastTimeStamp ? this.dateFormatter(lastTimeStamp) : '-'}
                     </span>
                        ) : (
                           <span
                              onClick={(event) => {
                                 this.props.onLetter(event);
                                 this.handleClick(email);
                              }}
                              className="inbox__email-table-date"
                           >
                        {lastTimeStamp ? this.dateFormatter(lastTimeStamp) : '-'}
                     </span>
                  )}
               </Col>
            </Row>
            <Row>
                  <Col sm={2} md={2} xs={2} xl={2}>
                  </Col>
                  <Col sm={7} md={7} xs={7} xl={7}>
                     {lastMessage && (
                        <>
                           {!this.state.isRead ? (
                              <span style={{fontWeight:"bold"}}
                                    onClick={(event) => {
                                       this.props.onLetter(event);
                                       this.handleClick(email);
                                    }}
                                    className="inbox__email-table-preview"
                              >
                        {lastMessage.length > 0 &&
                        lastMessage.map((item) => {
                           if (
                              [
                                 'https://',
                                 'http://',
                                 'www.',
                                 '.com',
                                 '.in',
                                 '.co',
                              ].some((el) => item.includes(el))
                           ) {
                              return (
                                 <span style={{ color: '#951B1E' }}>
                                       <a
                                          href={item}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: '#951B1E' }}
                                       >
                                          {item}
                                       </a>{' '}
                                    </span>
                              );
                           } else {
                              return <span>{item} </span>;
                           }
                        })}
                     </span>
                           ) : (
                              <span
                                 onClick={(event) => {
                                    this.props.onLetter(event);
                                    this.handleClick(email);
                                 }}
                                 className="inbox__email-table-preview"
                              >
                        {lastMessage.length > 0 &&
                        lastMessage.map((item) => {
                           if (
                              [
                                 'https://',
                                 'http://',
                                 'www.',
                                 '.com',
                                 '.in',
                                 '.co',
                              ].some((el) => item.includes(el))
                           ) {
                              return (
                                 <span style={{ color: '#951B1E' }}>
                                       <a
                                          href={item}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: '#951B1E' }}
                                       >
                                          {item}
                                       </a>{' '}
                                    </span>
                              );
                           } else {
                              return <span>{item} </span>;
                           }
                        })}
                     </span>
                           )}
                        </>
                     )}
                  </Col>
               <Col sm={3} md={3} xs={3} xl={3}>
               </Col>
            </Row>

            {/*</Container>*/}

            {/*{!this.state.isRead ? (*/}
            {/*   <th className="inbox__email-table-subject">Subject</th>*/}
            {/*) : (*/}
            {/*   <td className="inbox__email-table-subject">Subject</td>*/}
            {/*)}*/}




         </div>
      );
   }
}
