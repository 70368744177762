import classnames from 'classnames';
import React, { PureComponent } from 'react';
import {
   Button,
   Card,
   Col,
   Container,
   Nav,
   NavItem,
   NavLink,
   Row,
   TabContent,
   TabPane,
} from 'reactstrap';
import Overview from './Overview';
import DetailedView from './DetailedView';
import { hookAuth0 } from '../../../../../../shared/components/auth/withAuth0';
import { getIndividualSmartSkillStatus } from '../../../../../../api/Individual/opportunities.api';
import { getSmartSkillAssessmentDetails } from '../../../../../../api/Individual/employee.opportunities.api';
import { withRouter } from 'react-router';
import {
   generateSmartSkillsReport,
   getSmartSkillsdata,
} from '../../../../../../api/PerkesoUser/mySmartSkills.api';
import appConfigs from '../../../../../../config/appConfigs';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { LinearProgress } from '@material-ui/core';

const ssTabs = [
   {
      name: 'Overview',
      value: 'overview',
   },
   {
      name: 'Detailed View',
      value: 'detailedView',
   },
];

class SmartSkillTabs extends PureComponent {
   constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = {
         activeTab: 'overview',
         // id: 147,
         smartSkill: [],
         employeeSubDomain: '',
         smartSkillReport: [],
         toggleIFrame: false,
         isReportDownload:true,
         loading: true,
      };
   }

   componentDidMount() {
      const { auth0 } = this.props;

      if (auth0.user.attributes?.variation?.[0]) {
         this.setState(
            {
               employeeSubDomain: auth0.user.attributes.variation[0],
            },
            () => {
               this.checkSmartSkillStatus();
            }
         );
      } else {
         this.setState(
            {
               employeeSubDomain: appConfigs.endpoint.TS_SUBDOMAIN,
            },
            () => {
               // this.getSmartSkillsData();
               this.checkSmartSkillStatus();
            }
         );
      }
   }

   checkSmartSkillStatus = () => {
      this.setState({loading: true})
      if (this.props.email) {
         this.getSmartSkillsData();
      } else {
         getIndividualSmartSkillStatus().then((response) => {
            if (response.status) {
               this.setState(
                  {
                     smartSkillCompleted: true,
                  },
                  () => {
                     this.getSmartSkillsData();
                  }
               );
            } else {
               this.setState({
                  smartSkillCompleted: false,
                  loading:false
               });
            }
         });
      }
      getSmartSkillAssessmentDetails().then((response) => {
         if (response.status) {
            this.setState({
               assessment: response.smartskills[0],
            });
         }
      });
   };

   getSmartSkillsData = () => {
      console.log("inside the API")
      getSmartSkillsdata(this.state.employeeSubDomain, this.props.email)
         .then((data) => {
            if (data) {
               this.setState(
                  {
                     smartSkillCompleted: true,
                     smartSkill: data,
                  }
               );
            }
         })
         .catch((err) => console.log('ERR::::>>>', err))
         .finally(()=>{
            console.log("inside the API FINALLY")
            this.setState({loading: false})
         });
   };

   getCurrentDate = () => {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      let date = new Date().getDate();
      return year + '-' + month + '-' + date;
   };

   generateSmartSkillsReport = () => {
      this.setState({
         isReportDownload:false
      })
      generateSmartSkillsReport(this.state.employeeSubDomain)
         .then((pdf) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(
               new Blob([pdf], { type: 'application/pdf' })
            );
            a.href = url;
            a.download = 'SmartSkills-Report_' + this.getCurrentDate() + '.pdf';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
         })
         .catch((err) => console.log('SmartSkills Report ERROR', err)).finally(()=>{
            this.setState({
               isReportDownload:true
            })
         });
   };

   toggle(tab) {
      const { activeTab } = this.state;
      if (activeTab !== tab) {
         let update = { activeTab: tab };
         this.setState({ ...update });
      }
   }

   startAssessment = (assessment, e) => {
      // if (this.props.activeTab !== "completed") {
      let { history } = this.props;
      // let path = `/b2cIndividual/learn/assessment`;
      // history.push({
      // 	pathname: path,
      // 	state: { assessment: assessment, currentActiveTab: activeTab },
      // });
      let path = `/individual/assessmentDetails`;
      history.push({
         pathname: '/individual/assessmentDetails',
         state: {
            assessment: assessment,
            backUri: '/individual/profile',
            backTab: 'mySmartSkills'
         },
      });
   };

   /**
    * This will be used to create a Profile Tab division.
    */

   render() {
      const {
         loading,
         activeTab,
         smartSkillCompleted,
         smartSkill,
         isReportDownload,
         progress=0,
         assessment
      } = this.state;

      return (
         <React.Fragment>
            <div className="profile__card tabs tabs--bordered-bottom">
               {smartSkillCompleted ? (
                  <div className="tabs__wrap smartskill-tabs">
                     <Nav
                        tabs
                        className={''}
                        style={{ padding: '0 100px', border: '0' }}
                     >
                        {ssTabs.map((profileTab, index) => {
                           return (
                              <NavItem key={index}>
                                 <NavLink
                                    className={classnames({
                                       active: activeTab === profileTab.value,
                                    })}
                                    onClick={() => {
                                       this.toggle(profileTab.value);
                                    }}
                                 >
                                    {profileTab.name}
                                 </NavLink>
                              </NavItem>
                           );
                        })}
                        
                        <div>
                        
                           <a
                              // onClick={() => this.routeToSavedData()}
                              className="assessments__end-collapse-btns"
                           >
                             {isReportDownload ?  <Button
                                 onClick={() =>
                                    this.generateSmartSkillsReport()
                                 }
                                 size={'sm'}
                                 color={'primary'}
                                 style={{ marginRight: '100px' }}
                              >
                                 Download Report
                              </Button> 
                              :
                              <LinearProgress color="secondary" style={{top:"12px",width:"100px",right:"100px"}} />
                              // <CircularProgressWithLabel value={progress} />
                              }
                           </a>
                        </div>
                     </Nav>
                  </div>
               ) : null}
            </div>
            <Container>
               <Row className={'mt-3'}>
                  <Col md={12} lg={12} xl={12}>
                     <Card>
                        {loading ? (
                           <div className="panel__refresh">
                              <LoadingIcon />
                           </div>
                        ) : null}
                        <div className="profile__card tabs tabs--bordered-bottom">
                           <div className="tabs__wrap">
                              {!smartSkillCompleted && !loading && (
                                 <div
                                    style={{
                                       textAlign: 'center',
                                       height: '100%',
                                       width: '100%',
                                       marginTop: '200px',
                                    }}
                                 >
                                    You don’t have any smart skills results
                                    &nbsp;&nbsp;
                                    <Button onClick={() => this.startAssessment(assessment)}
                                       size={'sm'}
                                       color={'primary'}
                                       style={{ marginTop: '20px' }}
                                    >
                                       Take Smart Skill Assessment
                                    </Button>
                                 </div>
                              )}
                              {smartSkill.jobSeekerId && (
                                 <TabContent
                                    className="p-4"
                                    activeTab={activeTab}
                                 >
                                    {smartSkillCompleted ? (
                                       smartSkill.jobSeekerId &&
                                       activeTab === 'overview' ? (
                                          <TabPane tabId="overview">
                                             <Overview
                                                smartSkillData={
                                                   smartSkill.factors
                                                }
                                             />
                                          </TabPane>
                                       ) : activeTab === 'detailedView' ? (
                                          <TabPane tabId="detailedView">
                                             <DetailedView
                                                smartSkillData={
                                                   smartSkill.factors
                                                }
                                             />
                                          </TabPane>
                                       ) : null
                                    ) : null}
                                 </TabContent>
                              )}
                           </div>
                        </div>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </React.Fragment>
      );
   }
}

export default hookAuth0(withRouter(SmartSkillTabs));
