import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";
import PostJobForm from "./components/PostJobProjectForm";

const PostJobProject = (props) => {

  let { rtl, crumbs,match } = props;

  let pathname = window.location.pathname;
  let title = "";
  let pageTitle = "";
  if(match?.params?.jobId || match?.params?.projectId){
    let ids = pathname.split('/');
     title = ids[ids.length - 2]
     pageTitle =  title === "postjob" ? "Update a Job" : "Update a Project";
  }else{
    title = pathname.substr(pathname.lastIndexOf("/") + 1);
    pageTitle =  title === "postjob" ? "Post a Job" : "Post a Project";
  }

 

  const routeChange = () => {
    let path = `/organization/opportunities`;
    let { history, location } = props;
    // history.push(path);
    if(location.state.prevPath){
      history.push({
        pathname: location.state.prevPath,
        state: { ...location.state }
      })
    } else {
      history.push({
        pathname: path,
        state: { pathname: title }
      });
    }
  };

  return (
    <Container className="dashboard">
      <Row className="dashboard__container">
        <Col md={12}>
          <div className="dashboard__info">
            <button className="dashboard__info-icon" onClick={routeChange}>
              <ArrowLeftIcon size={"30"} />
            </button>
            <div className="dashboard__info-title">
              <h3 className="page-title">{pageTitle}</h3>
              <Breadcrumbs crumbs={crumbs} />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="dashboard__body-form">
        <PostJobForm
          dir={rtl.direction}
          rtl={rtl}
          pageTypeJobOrProject={title === "postjob" ? "Job" : "Project"}
        />
      </Row>
    </Container>
  );
};
PostJobProject.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired
};

export default connect((state) => ({
  rtl: state.rtl
}))(withRouter(withTranslation("common")(PostJobProject)));
