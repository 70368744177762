import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '../../../shared/components/Loading';
import Layout from '../../Layout';
// import Opportunities from '../../Pages/Public/Opportunities';

const PublicProfile = lazy(() => import('../../Pages/Public/Profile'));
const Opportunities = lazy(() => import('../../Pages/Public/Opportunities'));
const PublicQandA = lazy(() => import('../../Pages/Public/QandA'));
const DataStar = lazy(() => import('../../B2CIndividual/DataStar'))
const DataAcceleratorDetail = lazy(() => import('../../B2CIndividual/DataStar/AcceleratorDetails'));
const PublicCompetition = lazy(() => import('../../Pages/Public/Competition'));
const Home = lazy(() => import('../../B2CIndividual/Home'));

const PublicRoutes = (props) => (
   <div>
      <Layout publicRoute />
      <div className={'container__wrap public'}>
         <Suspense fallback={<Loading />}>
            <Switch>
               <Route path={'/public/home/:id?'} component={Home} />
               <Route path={'/public/profile'} component={PublicProfile} />
               <Route path={'/public/QandA'} component={PublicQandA} />
               <Route path={'/public/QandA/:id'} component={PublicQandA} />
               <Route path={'/public/competition'} component={PublicCompetition} />
               <Route path={'/public/competition/:id'} component={PublicCompetition} />
               <Route exact path={'/public/dataStar'} component={DataStar} />
               <Route exact path={'/public/dataStar/:id'} render={(props) => <DataAcceleratorDetail {...props} />} />
               <Route
                  path={'/public/opportunities'}
                  component={Opportunities}
               />
            </Switch>
         </Suspense>
      </div>
   </div>
);

export default PublicRoutes;
