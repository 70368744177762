import {

   SET_PROFILE_BIO_DATA,

} from "../actions/ProfileProcessAction";

const initialState = {
   profileBio: {
      bioData:0,
      skills:0,
      otherSkill:0,
      experience:0,
      education:0,
      //assessmentVideo:0,
      //smartSkillVideo:0,
      certificate:0,
      selectSkillsLearn: 0,
      course:0,
   }
};

const profileProcessReducer = (state = initialState, action) => {
switch (action.type){
   case SET_PROFILE_BIO_DATA:
      return {...state,profileBio: action.profileBio};
   default:
      return state;
}

};

export default profileProcessReducer;
