import {request, requestPdf} from "../../shared/helpers/RequestWrapper";
import appConfigs from "../../config/appConfigs";

const API_BASE_SMAERTSKILLS = appConfigs.endpoint.SMARTSKILL_REPORT_ROOT;
const SMARTSKILL_DATA = API_BASE_SMAERTSKILLS + "/api/v1/analyse-people-skills";
const SMARTSKILL_REPORT = API_BASE_SMAERTSKILLS + "/api/v1/report-people-skills";

export async function getSmartSkillsdata(subDomain, email) {
    const data = await request({
        url: SMARTSKILL_DATA,
        method: "POST",
        body: JSON.stringify({
            "talentspySubdomain": subDomain,
            "jobSeekerEmail": email
        })
    });
    return data;
}

export async function generateSmartSkillsReport(subDomain) {
    const data = await requestPdf({
        url: SMARTSKILL_REPORT,
        method: "GET",
        params: {
            "talentspySubdomain": subDomain
        }
    });
    return data;
}
