import React, {Fragment} from "react";
import PlusIcon from 'mdi-react/PlusIcon';

const SkillComponent = ({ index, skill, toggleSkillLevel, style }) => {

  return (
    <Fragment>
      <div
         style={style}
        key={index}
        className={`skill-level-component ${skill.level === 1 || skill.level === 2 ? 'selected' : ''}`}
        onClick={() => {
           console.log("skil", skill, toggleSkillLevel)
          if (
            toggleSkillLevel &&
            (!skill.level || skill.level === 0 || skill.level === 1)
          ) {
            toggleSkillLevel(skill.skillId, skill.id, skill.name, index);
          }
        }}>
        {/*{skill.level === 4 && (*/}
        {/*  <span*/}
        {/*    className={*/}
        {/*      skill.badgeLevel === 1*/}
        {/*        ? `skill-badge__bronze`*/}
        {/*        : skill.badgeLevel === 2*/}
        {/*        ? `skill-badge__silver`*/}
        {/*        : skill.badgeLevel === 3*/}
        {/*        ? `skill-badge__gold`*/}
        {/*        : `d-none`*/}
        {/*    }>*/}
        {/*    <TrophyVariantOutlineIcon />*/}
        {/*  </span>*/}
        {/*)}*/}
         {skill.level === 3 && <span className={"skill-completed-badge"}>
            {/*<MedalOutlineIcon />*/}
         </span>}
         {skill.level === 4 && <span className={"skill-certified-badge"}>
            {/*<MedalOutlineIcon />*/}
         </span>}
        <span className={"skill-component__skill-name"}>{skill.name}</span>
        {/*{skill.level >= 1 && (*/}
        {/*  <span*/}
        {/*    className={*/}
        {/*      skill.level === 1*/}
        {/*        ? `level1`*/}
        {/*        : skill.level === 2*/}
        {/*        ? `level2`*/}
        {/*        : skill.level === 3*/}
        {/*        ? `level3`*/}
        {/*        : skill.level === 4*/}
        {/*        ? `level4`*/}
        {/*        : skill.level === 5*/}
        {/*        ? `level5`*/}
        {/*        : ``*/}
        {/*    }>*/}
        {/*    LVL {skill.level}*/}
        {/*  </span>*/}
        {/*)}*/}
         {skill.level === 0 && (
            <PlusIcon className={"add-skill"} size={"20"} />
         )}
      </div>
    </Fragment>
  );
};

export default SkillComponent;
