import React, { Suspense } from 'react';
import { Redirect,  Switch } from 'react-router-dom';
import { hookAuth0 } from '../../../../shared/components/auth/withAuth0';
import Loading from '../../../../shared/components/Loading';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import { roleEnum } from '../../../../shared/enums';
import Layout from '../../../Layout/index';
import Account from './Account';
import { connect } from 'react-redux';
import NotificationBoard from '../../../Dashboards/Notification/';
import Mail from '../../../Mail/index';
import IndividualRoutes from './IndividualRoutes';
import OrganizationRoutes from './OrganizationRoutes';

const WrappedRoutes = ({ auth0, redirectTo, currentProfile, ...rest }) => {
   return (
      <div>
         {auth0 && auth0.user && <Layout />}
         {auth0 && auth0.user && currentProfile && (
            <div className={'container__wrap'} data-tut={'tour_main_frame'}>
               <Suspense fallback={<Loading />}>
                  <Switch>
                     {/*   {last_profile ? (*/}
                     {/*   last_profile === 'rubiqe_organization' ? (*/}
                     {/*      <Redirect exact from="/" to="/organization" />*/}
                     {/*   ) : (*/}
                     {/*      <Redirect exact from="/" to="/individual" />*/}
                     {/*   )*/}
                     {/*) : (*/}
                     {/*   <Redirect exact from="/" to="/individual" />*/}
                     {/*)}*/}
                     {currentProfile.admin ? (
                        <Redirect exact from={'/'} to={'/organization'} />
                     ) : (
                        <Redirect exact from={'/'} to={'/individual'} />
                     )}
                     {/*{auth0 &&*/}
                     {/*auth0.user &&*/}
                     {/*auth0.user.attributes.user_type[0] ===*/}
                     {/*roleEnum.B2C_INDIVIDUAL &&*/}
                     {/*(cookie.get("firstVisit") ? (*/}
                     {/*    <Redirect exact from="/" to="/individual/profile" />*/}
                     {/*) : (*/}
                     {/*    <Redirect exact from="/" to="/individual" />*/}
                     {/*))}*/}

                     {/*{auth0.user &&*/}
                     {/*  auth0.user.attributes.user_type[0] ===*/}
                     {/*    roleEnum.B2C_ORGANIZATION &&*/}
                     {/*  (cookie.get("firstVisit") ? (*/}
                     {/*    <Redirect exact from="/" to="/organization/profile" />*/}
                     {/*  ) : (*/}
                     {/*    <Redirect exact from="/" to="/organization" />*/}
                     {/*  ))}*/}

                     <PrivateRoute
                        roles={[roleEnum.B2C_INDIVIDUAL]}
                        path="/individual"
                        component={IndividualRoutes}
                        currentProfile={currentProfile}
                     />

                     <PrivateRoute
                        roles={[roleEnum.B2C_ORGANIZATION]}
                        path="/organization"
                        component={OrganizationRoutes}
                        currentProfile={currentProfile}
                     />

                     <PrivateRoute path="/account" component={Account} />
                     <PrivateRoute
                        roles={[
                           roleEnum.B2C_ORGANIZATION,
                           roleEnum.B2C_INDIVIDUAL,
                           roleEnum.PERKESO_USER,
                        ]}
                        path="/notifications"
                        component={NotificationBoard}
                     />
                     {/*<PrivateRoute*/}
                     {/*   roles={[*/}
                     {/*      roleEnum.B2C_ORGANIZATION,*/}
                     {/*      roleEnum.B2C_INDIVIDUAL,*/}
                     {/*      roleEnum.PERKESO_USER,*/}
                     {/*   ]}*/}
                     {/*   path="/search"*/}
                     {/*   component={SearchBoard}*/}
                     {/*/>*/}

                     <PrivateRoute
                        roles={[
                           roleEnum.B2C_ORGANIZATION,
                           roleEnum.B2C_INDIVIDUAL,
                        ]}
                        path="/messages"
                        component={Mail}
                     />
                  </Switch>
               </Suspense>
            </div>
         )}
      </div>
   );
};

// export default hookAuth0(connect(WrappedRoutes));
export default connect((state) => ({
   currentProfile: state.accounts.currentProfile,
}))(hookAuth0(WrappedRoutes));
