import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RTLProps } from '../../../../../../../shared/prop-types/ReducerProps';
import { Col, Row } from 'reactstrap';
import DetailedTabs from './DetailedTabs';

class DetailedViewDescription extends React.Component {
   constructor(props) {
      super(props);

      this.state = {};
   }

   render() {
      const { color, currentDimension } = this.props;

      return (
         <Fragment>
            <Row >
               <Col md={'12'}>
                  <div style={{ color: color }}>
                     <b>{currentDimension.dimensionName}</b>
                     <b
                        style={{
                           backgroundColor: '#D5D5D5',
                           marginLeft: '20px',
                           borderRadius: '10px',
                        }}
                     >
                        &nbsp; You are &nbsp;{currentDimension.scoreTitle} on
                        &nbsp; {currentDimension.dimensionName} &nbsp;
                     </b>
                  </div>
                  <div style={{ color: color }}>
                     {currentDimension.dimensionDescription}
                  </div>
               </Col>
            </Row>
            <DetailedTabs currentDimension={currentDimension} />
         </Fragment>
      );
   }
}

DetailedViewDescription.propTypes = {
   t: PropTypes.func.isRequired,
   rtl: RTLProps.isRequired,
   currentDimension: PropTypes.any,
   color: PropTypes.any,
};

export default compose(
   withTranslation('common'),
   connect((state) => ({
      rtl: state.rtl,
   }))
)(DetailedViewDescription);
