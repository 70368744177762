import { getAllProfilesOfUser } from '../../api/profiles.api';

export const SET_ACCOUNT_PROFILES = "SET_ACCOUNT_PROFILES";
export const SET_CURRENT_PROFILE = "SET_CURRENT_PROFILE";

export function setAccountProfiles1(res) {
   return {
      type: SET_ACCOUNT_PROFILES,
      profiles: res,
   };
}

export function setAccountProfiles(profileData) {
   return {
      type: SET_ACCOUNT_PROFILES,
      profiles: profileData,
   };
}

export function setCurrentProfile(profile) {
   return {
      type: SET_CURRENT_PROFILE,
      profile: profile,
   };
}
