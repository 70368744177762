/* eslint-disable */
const validate = (values, props) => {
  const errors = {};
  const {
    pageType
  } = props;
  if (!values.designation) {
    errors.designation = `${pageType} Title field shouldn’t be empty`;
  }

  if (!values.description) {
    errors.description = `${pageType} Description field shouldn’t be empty`;
  } else {
    let parser = new DOMParser();
    let html = parser.parseFromString(values.description, "text/html");
    if (!html || !html.body || !html.body.innerText.toString().trim()) {
      errors.description = `${pageType} Description field shouldn’t be empty`;
    }
  }
  // if (!values.location) {
  //   errors.location = `City field shouldn’t be empty`;
  // }

  // if (!values.countryId || !values.countryId.label || !values.countryId.value) {

  //   errors.countryId = `Country field shouldn’t be empty`;
  // }

  //
  // if (!values.location) {
  //   errors.locationRequirement = `Location Requirement field shouldn’t be empty`;
  // }

  if (!values.applicantSource) {
    errors.applicantSource = `Applicant Source field shouldn’t be empty`;
  }

  if (!values.requiredSkills || !values.requiredSkills.length) {
    errors.requiredSkills = `Please select atleast one skill`;
  }

  return errors;
};

export default validate;
