import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import InboxCard from './components/InboxCard';

const Mail = ({ t }) => (
   <Fragment>
      <InboxCard />
   </Fragment>
);

Mail.propTypes = {
   t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Mail);
