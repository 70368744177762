import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RTLProps } from '../../../../../../../shared/prop-types/ReducerProps';
import DetailedViewDesign from './DetailedViewDesign';

const DetailedView = ({ t, rtl, userProfile, smartSkillData }) => (
   <DetailedViewDesign smartSkillData={smartSkillData} />
);

DetailedView.propTypes = {
   t: PropTypes.func.isRequired,
   rtl: RTLProps.isRequired,
   smartSkillData: PropTypes.any,
};

export default compose(
   withTranslation('common'),
   connect((state) => ({
      rtl: state.rtl,
   }))
)(DetailedView);
