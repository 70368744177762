import React, { Fragment } from 'react';

const ApplicantSkills = ({ index, skill }) => {
   return (
      <Fragment>
         <div
            key={index}
            className={`skill-level-component ${
               skill.level === 1 || skill.level === 2 ? 'selected' : ''
            }`}
         >
            {skill.level === 3 && (
               <span className={'skill-completed-badge'}></span>
            )}
            {skill.level === 4 && (
               <span className={'skill-certified-badge'}></span>
            )}
            <span className={'skill-component__skill-name'}>{skill.name}</span>

         </div>
      </Fragment>
   );
};

export default ApplicantSkills;
