import {
    FETCH_PROGRAM_DETAILS_BY_ID, SET_APPLICATION_STATUS_FLAG
} from '../actions/dataStarActions';

const initialState = {
    data: [],
    applicationStatusFlag: false,
};

export const acceleratorReducer = (state = initialState, action) => {

    switch (action.type) {

        case FETCH_PROGRAM_DETAILS_BY_ID:

            return {
                ...state,
                data: action.payload?.data,
            };
        case SET_APPLICATION_STATUS_FLAG:

            return {
                ...state,
                applicationStatusFlag: action.payload.data
            };
        default:
            return state;
    }
};
