import { request } from "../shared/helpers/RequestWrapper";
import appConfigs from "../config/appConfigs";

const API_HOST = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS_PUBLIC;

export const INDUSTRY_SUGGEST_URL = "suggestIndustry";

export function getSuggestIndustry(industryName) {
  return request({
    url: API_HOST + "/" + INDUSTRY_SUGGEST_URL + "/" + industryName,
    method: "get"
  });
}
