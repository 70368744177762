import React from 'react';

const SmartSkill = ({color, level, value, detail,isSelected}) => (
    detail ? (
        <div className={`smart-skill-circle ss-detailed-${level?.toLowerCase()} ${isSelected ? " selected-ss" : ""}`}
             style={{backgroundColor: color}}
        >
            <div className="score" style={{fontSize: "13px"}}>{value}</div>
            <div className="level" style={{fontSize: "10px"}}>{level}</div>
        </div>
    ) : (
        <div className={`smart-skill-circle ss-${level?.toLowerCase()} 
        ${isSelected ? " selected-ss" : ""}`}
             style={{backgroundColor: color}}
        >
            {console.log(">>>>>>> isSelected >>>> ",isSelected)}
            <div className="score">{value}</div>
            <div className="level">{level}</div>
        </div>
    )
);


export default SmartSkill;
