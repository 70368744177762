import {
  SET_ACTIVE_CONTACT,
  SET_CONTACTS,
  SET_CURRENT_USER,
  SET_MESSAGES,
  SET_STOMP_CLIENT, SET_UNREAD_MSG_COUNT
} from "../actions/messageAction";

const initialState = {
  contacts: [],
  stompClient: null,
  currentUser: {},
  messages: [],
  activeContact: null,
  unreadMessageCount: 0
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACTS:
      return { ...state, contacts: action.contacts };
    case SET_STOMP_CLIENT:
      return { ...state, stompClient: action.stompClient };
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.currentUser };
    case SET_MESSAGES:
      return { ...state, messages: action.messages };
    case SET_ACTIVE_CONTACT:
      return { ...state, activeContact: action.activeContact };
    case SET_UNREAD_MSG_COUNT:
      return { ...state, unreadMessageCount: action.unreadMessageCount };
    default:
      return state;
  }
};

export default messageReducer;
