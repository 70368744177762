import React, { Component, Fragment } from 'react';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import BriefcaseOutlineIcon from 'mdi-react/BriefcaseOutlineIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import CircleDoubleIcon from 'mdi-react/CircleDoubleIcon';
import SchoolIcon from 'mdi-react/SchoolIcon';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import { withRouter } from 'react-router';
import TrophyVariantOutlineIcon from 'mdi-react/TrophyVariantOutlineIcon';
import { Button } from 'reactstrap';
import BookmarkBorderIcon from 'mdi-react/BookmarkBorderIcon';
import BookmarkIcon from 'mdi-react/BookmarkIcon';
import ExclamationIcon from 'mdi-react/ExclamationIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import {
   applyForJobOrProject,
   saveJobOrProjectAPI,
} from '../../../../api/Individual/employee.opportunities.api';
import { trackEventMixpanel } from '../../../../shared/helpers/Mixpanel';
import {
   showErrorNotification,
   showNotification,
} from '../../../../shared/components/toastMessage';
import { hookAuth0 } from '../../../../shared/components/auth/withAuth0';

class JobProjectDetailsMobile extends Component {
   constructor(props) {
      super(props);

      this.state = {
         show: false,
      };

      if (
         !props.location ||
         !props.location.state ||
         !props.location.state.job
      ) {
         props.history.push('/individual/opportunities');
      }
   }

   componentDidMount() {
      const { location } = this.props;
      if (location && location.state && location.state.job) {
         this.setState({
            show: true,
            saved: location.state.job.isBookMarked,
            isApplied: location.state.isApplied,
         });
      }
   }

   goBack = () => {
      const { history } = this.props;

      history.goBack();
   };

   applyForJobOrProject = (sourceData) => {
      const { job, individualSmartSkillStatus } = this.props.location.state;
      const data = job;
      if (data.isSmartSkillRequired && !individualSmartSkillStatus) {
         this.toggleSmartSkillAssessmentAlert();
      } else {
         this.setState({
            loading: true,
         });
         const initTime = new Date();
         applyForJobOrProject(sourceData.id)
            .then((res) => {
               trackEventMixpanel('apply_job', { jobId: sourceData.id });
               const finalTime = new Date();
               const secTime = (finalTime - initTime) / 1000;

               trackEventMixpanel(
                  "Applied to '" + sourceData.designation + "' Job",
                  {
                     'Organization ID': sourceData.organizationId,
                     'Organization Name': sourceData.organizationName,
                     'Job ID': sourceData.id,
                     'Time elapsed(sec)': secTime,
                  }
               );
               trackEventMixpanel('[TE] Applying For Job', {
                  'Time elapsed(sec)': secTime,
               });

               // showNotification('Applied for job successfully.', 'primary');
               // this.props.refreshData();
               this.setState({
                  loading: false,
                  isApplied: true,
                  appliedDone: true,
               });
            })
            .catch((error) => {
               console.log('Error :: ', error);
               this.setState({
                  isJobRefresh: false,
                  isProjectRefresh: false,
               });
               // showErrorNotification('Error occurred while applying for job');
            });
      }
   };

   saveJobOrProject = (id, status) => {
      saveJobOrProjectAPI(id, status)
         .then((res) => {
            showNotification(
               `job ${status ? 'Saved' : 'Unsaved'} successfully.`,
               'primary'
            );
            if (status) trackEventMixpanel('save_job', { jobId: id });
            // this.props.refreshData();
            this.setState({
               saved: status,
            });
         })
         .catch((error) => {
            this.setState({
               isJobRefresh: false,
               isProjectRefresh: false,
            });
            showErrorNotification('Error occurred while applying for job');
         });
   };

   toggleSmartSkillAssessmentAlert = () => {
      this.setState({
         smartSkillAlert: !this.state.smartSkillAlert,
      });
   };

   takeSmartSkillAssessment = () => {
      const { history, location } = this.props;
      const state = location.state;
      const smartSkillAssessment = state.smartSkillAssessment.smartskills[0];

      let path = `/individual/assessmentDetails`;
      history.push({
         pathname: path,
         // state: { assessment: assessment.id, currentActiveTab: activeTab },
         state: { assessment: smartSkillAssessment },
      });
   };

   afterApplicationAction = () => {
      this.props.history.push('/individual/opportunities');
   };

   render() {
      const { location } = this.props;
      const { show, saved, isApplied, loading, smartSkillAlert, appliedDone } =
         this.state;
      const data = location?.state?.job;
      const isJobView = location?.state?.isJobView;

      return (
         <Fragment>
            {show && (
               <div className={'job-details-main__mobile'}>
                  <div className={'title'}>
                     <ArrowLeftIcon
                        className={'back'}
                        size={'30'}
                        onClick={this.goBack}
                     />
                     <div className={'page-title'}>{`${
                        isJobView ? 'Job' : 'project'
                     } Details`}</div>
                  </div>
                  <div className={'details'}>
                     <JobProjectField
                        name={'Job Title'}
                        value={data.designation}
                        icon={<BriefcaseOutlineIcon size={18} />}
                     />
                     <JobProjectField
                        name={'Company'}
                        value={data.organizationName}
                        icon={<DomainIcon size={18} />}
                     />
                     <JobProjectField
                        name={'Location'}
                        value={`${data.officeLocation}`}
                        icon={<MapMarkerOutlineIcon size={18} />}
                     />
                     <JobProjectField
                        name={'Your Match'}
                        value={`${
                           data?.jobMatch &&
                           data.jobMatch.hasOwnProperty('autoMatchedPercentage')
                              ? Math.round(
                                   (data.jobMatch.autoMatchedPercentage +
                                      Number.EPSILON) *
                                      100
                                ) / 100
                              : data?.jobMatch && data.jobMatch[0]
                              ? data.jobMatch[0]?.auto_matched_percentage
                              : ''
                        }%`}
                        icon={<CircleDoubleIcon size={18} />}
                     />
                     <JobProjectField
                        name={'Skill Match'}
                        value={`${
                           data?.jobMatch &&
                           data.jobMatch.hasOwnProperty('matchSkillCount')
                              ? data.jobMatch.matchSkillCount
                              : data?.jobMatch[0]
                              ? data.jobMatch[0]?.match_skill_count
                              : ''
                        } out of ${
                           data?.jobMatch.hasOwnProperty('requiresSkillCount')
                              ? data.jobMatch.requiresSkillCount
                              : data?.jobMatch && data.jobMatch[0]
                              ? data.jobMatch[0]?.requires_skill_count
                              : ''
                        }`}
                        icon={<SchoolIcon size={18} />}
                     />
                     <JobProjectField
                        name={'Salary Range'}
                        value={`${
                           data.salaryBegin && data.salaryEnd
                              ? data.salaryBegin +
                                ' - ' +
                                data.salaryEnd +
                                ' ' +
                                (data.currencySymbol || '')
                              : data.salaryBegin
                              ? data.salaryBegin + ' ' + data.currencySymbol
                              : '-'
                        }`}
                        icon={<CreditCardIcon size={18} />}
                     />
                  </div>
                  <div className="secondary-details">
                     <div className={'title-secondary'}>Required Skills</div>
                     <div className="job-skills-detail-secondary">
                        <div className={'skill-icon'}>
                           <TrophyVariantOutlineIcon size={14} />
                        </div>
                        <div className="jobs-skills-header-secondary">
                           Data Literacy Skills
                        </div>
                     </div>
                     <div>
                        {data.skillName &&
                           data.skillName
                              .split(',')
                              .map((requiredSkill, index) => {
                                 return (
                                    <Button
                                       key={index}
                                       color="black"
                                       className="btn-sm rounded"
                                    >
                                       {requiredSkill.trim()}
                                    </Button>
                                 );
                              })}
                     </div>
                     <div className={'title-secondary'}>Description</div>
                     <div
                        className="job-desc-p"
                        dangerouslySetInnerHTML={{
                           __html: data.description,
                        }}
                     />
                  </div>
                  <div className={'job-apply-save-section__mobile'}>
                     <div className={'apply-save-btns'}>
                        <Button
                           outline
                           color={'primary'}
                           className={'save-job-btn'}
                           // disabled={data.isBookMarked}
                           onClick={() =>
                              this.saveJobOrProject(data.id, !saved)
                           }
                        >
                           {saved ? (
                              <BookmarkIcon size={22} title={'Un save'} />
                           ) : (
                              <BookmarkBorderIcon size={22} title={'Save'} />
                           )}
                        </Button>
                        <Button
                           color={'primary'}
                           disabled={isApplied || loading}
                           className={'apply-job-btn'}
                           onClick={() => this.applyForJobOrProject(data)}
                        >
                           {loading
                              ? 'Applying..'
                              : isApplied
                              ? 'Applied'
                              : 'Apply'}
                        </Button>
                     </div>
                  </div>
               </div>
            )}
            {smartSkillAlert && (
               <div className={'custom-mobile-alert-overlay'}>
                  <div className={'mandatory-assessment-alert'}>
                     <div className={'text-center'}>
                        <div className={'assessment-required-icon'}>
                           <ExclamationIcon size={60} />
                        </div>
                        <div className={'assessment-required-title mt-3'}>
                           Assessment Required
                        </div>
                        <div className={'w-75 m-auto'}>
                           You are almost there! To apply for this job, a Smart
                           Skill Assessment is required.
                        </div>
                     </div>
                  </div>
                  <div className={'mandatory-assessment-actions'}>
                     <Button
                        color={'primary'}
                        outline
                        onClick={this.toggleSmartSkillAssessmentAlert}
                     >
                        Cancel
                     </Button>
                     <Button
                        color={'primary'}
                        onClick={this.takeSmartSkillAssessment}
                     >
                        Take Assessment
                     </Button>
                  </div>
               </div>
            )}

            {appliedDone && (
               <div className={'custom-mobile-alert-overlay'}>
                  <div className={'mandatory-assessment-alert'}>
                     <div className={'text-center'}>
                        <div className={'application-successfull-icon'}>
                           <CheckIcon size={60} />
                        </div>
                        <div className={'assessment-required-title mt-3'}>
                           Application Successfull
                        </div>
                        <div className={'w-75 m-auto'}>
                           Great, your application has been submitted
                           successfully.
                        </div>
                     </div>
                  </div>
                  <div className={'mandatory-assessment-actions'}>
                     <Button
                        color={'primary'}
                        onClick={this.afterApplicationAction}
                     >
                        OK
                     </Button>
                  </div>
               </div>
            )}
         </Fragment>
      );
   }
}

export default withRouter(hookAuth0(JobProjectDetailsMobile));

const JobProjectField = (data) => {
   return (
      <div className={'detail-item'}>
         <div className={'icon-basic'}>{data.icon}</div>
         <div className={'basic'}>
            <div className={'title-basic'}>{data.name}</div>
            <div className={'value-basic'}>{data.value}</div>
         </div>
      </div>
   );
};
