import LocalEnv from './envs/LocalEnv';

const endpointEnvVars = [
   'SKILLSTREETS_ROOT',
   'API_BASE_URL_SKILLSTREETS',
   'API_BASE_URL_SKILLSTREETS_PUBLIC',
   'PERKESO_ROOT',
   'API_BASE_URL_PERKESO',
   'SMARTSKILL_REPORT_ROOT',
   'APHRODITE_ROOT',
   'APHRODITE_ROOT_UI',
   'MIXPANEL_TOKEN',
   'ROLLBAR_KEY',
   'ROLLBAR_ENV',
   'HEBE_ROOT',
   'API_BASE_URL_CADS_HEBE',
   'TALENTSPY_DOMAIN',
   'API_BASE_TALENTSPY',
   'TS_ASSESSMENT_HOST',
   'BASE_COOKIE',
   'COOKIE_DOMAIN',
   'ASSESSMENT_SUBDOMAIN',
   'TS_SUBDOMAIN',
   'TALENT_SPY_ASSESSMENT_LINK',
   'HOMEPAGE',
   'CADS_BASE_API',
   'API_ROOT_CADS_BASE_API',
   'REDIRECTION_URL',
   'SKILLSTREET_REDIRECTION',
   'URL_LOGIN',
   'CLIENT_ID',
   'KEYCLOAK_URL',
   'KEYCLOAK_REALM',
   'PROFILE',
   'WOOCOMMERCE_URL',
   'ACCELTIC_ROOT',
   'APP_G_STREAM',
   'LOGIN_ROOT'
];

const config = {
	endpoint: {
		...(() => {
			const endpoint = {};
			endpointEnvVars.forEach(envVar => endpoint[envVar] = process.env["REACT_APP_" + envVar] ?? LocalEnv[envVar]);
			return endpoint;
		})()
	},
	keycloak: {
		CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? LocalEnv['CLIENT_ID'],
		URL: process.env.REACT_APP_KEYCLOAK_URL ?? LocalEnv['KEYCLOAK_URL'],
		REALM: process.env.REACT_APP_KEYCLOAK_REALM ?? LocalEnv['KEYCLOAK_REALM'],
		ONLOAD: 'login-required',
	},
	consts: {
		IMAGE_FILE_SIZE: 5 // in MB
	}
};
export default {
	APP_ENV: process.env.REACT_APP_PROFILE,
	MAX_ATTACHMENT_SIZE: 5000000,
	...config,
};
