import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import CloseIcon from "mdi-react/CloseIcon"

const icon = `${process.env.PUBLIC_URL}/img/burger-menu.svg`;

class TopbarSidebarButton extends PureComponent {
   static propTypes = {
      changeMobileSidebarVisibility: PropTypes.func.isRequired,
      changeSidebarVisibility: PropTypes.func.isRequired,
   };

   render() {
      const {
         changeMobileSidebarVisibility,
         changeSidebarVisibility,
         sidebar,
      } = this.props;
      return (
         <div className="topbar__left_corner d-md-none">
            <button
               className="topbar__button topbar__button--desktop"
               type="button"
               onClick={changeSidebarVisibility}
            >
               <img src={icon} alt="" className="topbar__button-icon" />
            </button>
            <button
               className="topbar__button topbar__button--mobile"
               type="button"
               onClick={changeMobileSidebarVisibility}
            >
               {sidebar.show ? (
                  <CloseIcon className={"topbar__button-icon"} />
               ) : (
                  <img src={icon} alt="" className="topbar__button-icon" />
               )}
            </button>
         </div>
      );
   }
}

// export default TopbarSidebarButton;

export default withRouter(
   connect((state) => ({
      sidebar: state.sidebar,
   }))(TopbarSidebarButton)
);
