import appConfigs from '../config/appConfigs';
import { request } from '../shared/helpers/RequestWrapper';
const API_HOST = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;
const GET_ALL_PROFILES_OF_USER = '/userDetails/getUserAllProfileDetails';
const SWITCH_ORG = '/userDetails/switch';
const GET_PROFILE_ACCESS = '/userDetails/getCurrentUserProfileAccess';


export function getAllProfilesOfUser() {
   return request({
      url: API_HOST + GET_ALL_PROFILES_OF_USER,
      method: 'GET'
   });
}

export function switchOrg(id) {
   return request({
      url: API_HOST + SWITCH_ORG + '/' + id,
      method: 'POST'
   });
}

export function getProfileAccess() {
   return request({
      url: API_HOST + GET_PROFILE_ACCESS,
      method: 'GET'
   });
}
