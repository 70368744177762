import {request} from "../../shared/helpers/RequestWrapper";
import appConfigs from "../../config/appConfigs";

const API_BASE_SKILLSTREETS = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;

const GET_EMPLOYEE_CERTIFICATES = "/jobseeker/certificate";
const GET_EMPLOYEE_CERTIFICATES_ACCSESSIBLE_LINK = "/jobseeker/certificate/accessibleLink";

export function getEmployeeCertificates(email) {
  let reqParam = "";
    if(email) {
      reqParam = "?userId=" + email;
    }
  return request({
    url: API_BASE_SKILLSTREETS + GET_EMPLOYEE_CERTIFICATES + reqParam,
    method: "GET"
  })
}

export function getCertificateLink(link, download) {
  let reqParam = "";
    if(link) {
      reqParam = "?certificateLink=" + link + '&download=' + download;
    }
  return request({
    url: API_BASE_SKILLSTREETS + GET_EMPLOYEE_CERTIFICATES_ACCSESSIBLE_LINK + reqParam,
    method: "GET"
  })
}
