import classnames from 'classnames';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Card, CardBody, CardHeader, Collapse} from 'reactstrap';
import {getAllApplicant, getRecommendedApplicant,} from '../../../../api/Organization/jobProjectPosted.api';
import {JobProjectBasic} from './JobProjectBasic';
import {JobProjectDetail} from "./JobProjectDetail";

class OpenJobsProjects extends PureComponent {
   constructor(props) {
      super(props);

      this.state = {
         collapse: -1,
         activeTab: 'recommendedApplicant',
         selectedOption: { value: 'INTERNAL', label: 'Internal' },
         selectedAllAppOption: { value: 'INTERNAL', label: 'Internal' },
         isMatchesRefresh: true,
         recommendations: [],
         applicants: [],
      };
   }

   componentDidMount() {}

   toogleCollapse = (job, e) => {
      if (this.state.collapse !== e) {
         this.getAllRecommendedApplicant(job);
      } else {
         this.setState({ activeTab: 'recommendedApplicant' });
      }
      this.setState({
         activeTab: 'recommendedApplicant',
         currentJob: null,
         collapse: this.state.collapse === Number(e) ? -1 : Number(e),
      });
   };

   getAllRecommendedApplicant(
      job,
      type = 'INTERNAL',
      pageNo = 0,
      pageSize = 20
   ) {
      this.setState({ isMatchesRefresh: true, hasMore: false });
      getRecommendedApplicant(job.id, type, { pageNo, pageSize })
         .then((res) => {
         	console.log(res)
            let update = {};
            	console.log(res.data)
               update.isMatchesRefresh = false;
               update.recommendations = res.data.sort(
                  (a, b) => b.autoMatchedPercentage - a.autoMatchedPercentage
               );
               update.currentJob = job;
               update.pageNo = pageNo;
               update.pageSize = pageSize;
               update.hasMore =
                  update.recommendations && update.recommendations.length === 20
                     ? true
                     : false;
            this.setState({ ...update });
         })
         .catch((error) => {
            console.log('getOrganizationDetails error :: ', error);
            this.setState({
               isMatchesRefresh: false,
            });
         });
   }

   getAllApplicants(job, type = 'INTERNAL', pageNo = 0, pageSize = 20) {
      this.setState({ isMatchesRefresh: true, hasMore: false });
      getAllApplicant(job.id, type, { pageNo, pageSize })
         .then((res) => {
            let update = {};
               update.isMatchesRefresh = false;
               update.applicants = res.data.sort(
                  (a, b) => b.autoMatchedPercentage - a.autoMatchedPercentage
               );
               update.currentJob = job;
               update.pageNo = pageNo;
               update.pageSize = pageSize;
               update.hasMore =
                  update.applicants && update.applicants.length === 20
                     ? true
                     : false;

            this.setState({ ...update });
         })
         .catch((error) => {
            console.log('getAllApplicant error :: ', error);
            this.setState({
               isMatchesRefresh: false,
            });
         });
   }

   toogleMatches = (job, tab) => {
      const { activeTab } = this.state;
      if (activeTab !== tab) {
         if (tab === 'allApplicant') {
            const selectedAllAppOption = {
               label: 'Internal',
               value: 'INTERNAL',
            };
            this.setState({ selectedAllAppOption });
            this.getAllApplicants(job);
         }
         if (tab === 'recommendedApplicant') {
            const selectedOption = { label: 'Internal', value: 'INTERNAL' };
            this.setState({ selectedOption });
            this.getAllRecommendedApplicant(job);
         }
         this.setState({
            activeTab: tab,
         });
      }
   };

   handleChange = (selectedOption, job) => {
      if (this.state.selectedOption.value !== selectedOption.value) {
         this.setState({ selectedOption });
         this.getAllRecommendedApplicant(job, selectedOption.value);
      }
   };
   handleAllApplicantChange = (selectedAllAppOption, job) => {
      if (
         this.state.selectedAllAppOption.value !== selectedAllAppOption.value
      ) {
         this.setState({ selectedAllAppOption });
         this.getAllApplicants(job, selectedAllAppOption.value);
      }
   };

   getMoreMatches = () => {
      const {
         activeTab,
         currentJob,
         pageNo,
         pageSize,
         selectedOption,
         selectedAllAppOption,
      } = this.state;
      if (activeTab === 'allApplicant') {
         this.getAllApplicants(
            currentJob,
            selectedAllAppOption.value,
            pageNo + 1,
            pageSize
         );
      }
      if (activeTab === 'recommendedApplicant') {
         this.getAllRecommendedApplicant(
            currentJob,
            selectedOption.value,
            pageNo + 1,
            pageSize
         );
      }
   };

   render() {
      const {
         collapse,
      } = this.state;
      const { jobs, isJobView } = this.props;

      return (
         <div className="container p-0">
            {jobs.map((job, index) => {
               return (
                  <Card key={index} className={'pt-2'}>
                     <CardHeader className="job-basic" >
                        <JobProjectBasic
                           data={job}
                           toogleCollapse={this.toogleCollapse}
                           index={index}
                           isJobView={isJobView}
                        />
                        <a href={"#a"}
                           onClick={() => this.toogleCollapse(job, index)}
                           data-event={index}
                           className="job__end-collapse-btns"
                        >
                           <button className="job-end-button">
                              <ChevronDownIcon
                                 size={'28'}
                                 className={classnames('job-icon', {
                                    'job-icon-collapse': collapse === index,
                                 })}
                              />
                           </button>
                        </a>
                     </CardHeader>

                     <Collapse isOpen={collapse === index}>
                        <CardBody className="jobs-description">
                           <div className="row job-desc-row">
                              <div className="col-md-12 job-desc-div-left">
                                 <JobProjectDetail
                                    data={job}
                                    isJobView={isJobView}
                                 />
                              </div>
                           </div>
                        </CardBody>
                     </Collapse>
                  </Card>
               );
            })}
         </div>
      );
   }
}

OpenJobsProjects.propTypes = {
   t: PropTypes.func.isRequired,
   dir: PropTypes.string.isRequired,
   themeName: PropTypes.string.isRequired,
   jobs: PropTypes.array,
};

export default connect((state) => ({ themeName: state.theme.className }))(
   withTranslation('common')(OpenJobsProjects)
);
