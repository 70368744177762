import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {withRouter} from "react-router";

class TopbarNavLink extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  isActiveLink = () => {
    const pathname = window.location.pathname;
    if(this.props.path === pathname) {
      return "active"
    }
    return "";
  };

  render() {
    const { title, id, path, onClick } = this.props;
    const pathname = window.location.pathname;
    const activeLink = pathname === path ? "topbar__link-nav-active" : "";
    return (
      <Link id={id} className={`topbar__link-nav ${activeLink} d-none d-md-block`} to={path} onClick={onClick} data-tut={`tour_${id}`}>
        <p className="topbar__link-nav-title">{title}</p>
      </Link>
    );
  }
}

export default withRouter(TopbarNavLink);
