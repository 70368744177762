import {
   SET_NOMINATION_OPEN,
   SET_NOMINATION_DONE,
   SET_SHOW_NOMINATION_BANNER,
} from '../actions/nominationAction';
import { campaignEnum } from '../../shared/enums/campaign.enum';

const initialState = {
   isOpen: false,
   showNominationBanner: true,
   isNominationDone: !!eval(localStorage.getItem(campaignEnum.TOP_25)),
};

const tourReducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_NOMINATION_OPEN:
         return {
            ...state,
            isOpen: action.isOpen,
         };
      case SET_SHOW_NOMINATION_BANNER:
         return {
            ...state,
            showNominationBanner: action.status,
         };
      case SET_NOMINATION_DONE:
         return {
            ...state,
            isNominationDone: action.isNominationDone,
         };
      default:
         return state;
   }
};

export default tourReducer;
