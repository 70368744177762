import { Col } from 'reactstrap';
import React, { Component } from 'react';
import { showErrorNotification } from '../../../../shared/components/toastMessage';
import { removeNotification } from '../../../../api/notification.api';
import * as PropTypes from 'prop-types';
import BellOutlineIcon from 'mdi-react/BellOutlineIcon';
import { withRouter } from 'react-router';

export const timeAgo = (date) => {
   const ms = new Date().getTime() - date.getTime();
   const seconds = Math.floor(ms / 1000);
   const minutes = Math.floor(seconds / 60);
   const hours = Math.floor(minutes / 60);
   const days = Math.floor(hours / 24);
   const months = Math.floor(days / 30);
   const years = Math.floor(months / 12);

   if (ms === 0) {
      return 'Just now';
   }
   if (seconds < 60) {
      return seconds + ' seconds Ago';
   }
   if (minutes < 60) {
      return minutes + ' minutes Ago';
   }
   if (hours < 24) {
      return hours + ' hours Ago';
   }
   if (days < 30) {
      return days + ' days Ago';
   }
   if (months < 12) {
      return months + ' months Ago';
   } else {
      return years + ' years Ago';
   }
};

class Notification extends Component {
   removeNotification = (notificationId, email, e) => {
      e.target.disabled = true;
      removeNotification(email, notificationId)
         .then((res) => {
            this.props.getNotifications(email);
         })
         .catch((err) => {
            showErrorNotification(err);
            console.error(
               'Error while removing notification in removeNotification()::::: ',
               err
            );
         });
   };

   redirectToNotification = (redirectionURL) => {
      if(redirectionURL && redirectionURL !== '') {
            this.props.history.push(redirectionURL);
      } else {

      }
   }

   render() {
      let { notification, email } = this.props;

      return (
         <Col md={12}>
            <div
               className={(notification?.action && notification?.action !== '') ? "topbar_notification_list topbar__collapse-item notification_pointer" : "topbar_notification_list topbar__collapse-item"}
               style={{ paddingLeft: '50px' }}
               onClick={() => this.redirectToNotification(notification.action)}
            >
               <div className="topbar_notification_icon_desktop topbar__collapse-img-wrap">
                  {/*<i className="notification-icon"/>*/}
                  <BellOutlineIcon className={'notification-icon'} size={18} />
               </div>
               <span
                  className="topbar__collapse-name notification_details_list"
                  style={{ color: '#525A60', fontSize: '14px' }}
               >
                  <div style={notification.isread ? { color: '#ababab' } : {}}>
                     {notification.title}
                  </div>
                  <p
                     className="topbar__collapse-message"
                     style={{ lineHeight: 2, color: '#525A60' }}
                  >
                     <div
                        style={notification.isread ? { color: '#ababab' } : {}}
                     >
                        {notification.subtitle}
                     </div>
                     <div className={"topbar_notification_icon_mobile"}>
                         {timeAgo(new Date(notification.notification_datetime * 1000))}
                     </div>
                  </p>
               </span>
               <p className="topbar__collapse-date">
                  <span style={{float:"left"}} className={"topbar_notification_icon_desktop"}>
                     {timeAgo(new Date(notification.notification_datetime * 1000))}
                  </span>
                  <button
                     onClick={(e) =>
                        this.removeNotification(notification.id, email, e)
                     }
                     className="topbar-button"
                     style={{ marginLeft: '12px' }}
                     type="button"
                  >
                     <i className="notification-close-icon" />
                  </button>
               </p>
            </div>
         </Col>
      );
   }
}

export default withRouter(Notification);

Notification.propTypes = {
   notification: PropTypes.any,
   email: PropTypes.any,
};
