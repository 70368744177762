import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";

class ApplicantOtherSkills extends Component {


    constructor(props) {
        super(props);

        this.state = {
            readOtherSkill: props.skill.otherSkillName
        }

    }

    render() {
        let {index} = this.props;
        let {readOtherSkill} = this.state;

        return (
            <Fragment>
                <div
                    key={index}
                    className={`skill-level-component`}>
                 <span className={"skill-component__skill-name"}>
                        {readOtherSkill}
                     </span>
                </div>
            </Fragment>
        );
    }
}

ApplicantOtherSkills.propTypes = {
    index: PropTypes.any,
    skill: PropTypes.any,
};

export default ApplicantOtherSkills;

