import React from "react";
import {BasicNotification} from "./Notification";
import NotificationSystem from "rc-notification";
import {apiResponseEnum} from "../enums";

let notification = null;
NotificationSystem.newInstance(
  { style: { top: 65 } },
  (n) => (notification = n)
);

export const showNotification = (
  message,
  color = "danger",
  title = "Error",
  rtl = "ltr"
) => {
  if (color === "primary" || color === "success") {
    title = "Success";
  }
  notification.notice({
    content: (
      <BasicNotification title={title} message={message} color={color} />
    ),
    duration: 5,
    closable: true,
    style: {
      top: 0,
      left: "calc(100vw - 100%)"
    },
    className: `right-up ${rtl}-support`
  });
};

export const showErrorNotification = (error) => {
  let message = error.message ? error.message : apiResponseEnum.SOMETHING_WRONG;
  showNotification(message);
};
