import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import BriefcaseOutlineIcon from 'mdi-react/BriefcaseOutlineIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import PropTypes from 'prop-types';
import React from 'react';

export const JobProjectBasic = (props) => {
	const { index, toogleCollapse, data, isJobView } = props;
	return (
		<ul className='list-inline'>
			<JobProjectBasicCol
				toogleCollapse={toogleCollapse}
				index={index}
				data={data}
				body={data.jobProjectTitle}
				classPrefix='col-md-6 col-lg-3 col-xl-3 '
				title={isJobView ? `Job Title` : `Project Title`}
				IconComponent={<BriefcaseOutlineIcon size={18} />}
			/>
			<JobProjectBasicCol
				toogleCollapse={toogleCollapse}
				index={index}
				data={data}
				body={`${data.location}, ${data.country}`}
				classPrefix={
					isJobView
						? 'col-md-6 col-lg-3 col-xl-3'
						: 'col-md-6 col-lg-3 col-xl-2'
				}
				title={`Office Location`}
				IconComponent={<MapMarkerOutlineIcon size={18} />}
			/>

			<JobProjectBasicCol
				toogleCollapse={toogleCollapse}
				index={index}
				data={data}
				body={
					Number(data.suitableMatched) > -1 ? Number(data.suitableMatched) : '-'
				}
				classPrefix='col-md-6 col-lg-2 col-xl-2'
				title={`Suitable Matches`}
				IconComponent={<AccountCircleOutlineIcon size={18} />}
			/>

			<JobProjectBasicCol
				toogleCollapse={toogleCollapse}
				index={index}
				data={data}
				body={
					Number(data.jobAppliedCount) > -1 ? Number(data.jobAppliedCount) : '-'
				}
				classPrefix={
					isJobView
						? 'col-md-6 col-lg-4 col-xl-4'
						: 'col-md-6 col-lg-2 col-xl-2'
				}
				title={`Applicants`}
				IconComponent={<AccountCircleOutlineIcon size={18} />}
			/>
			{!isJobView && (
				<JobProjectBasicCol
					toogleCollapse={toogleCollapse}
					index={index}
					data={data}
					body={formatDate(data.publishedDate)}
					classPrefix={'col-md-6 col-lg-2 col-xl-2'}
					title={`Date Posted`}
					IconComponent={<AccountCircleOutlineIcon size={18} />}
				/>
			)}
		</ul>
	);
};

JobProjectBasic.propTypes = {
	toogleCollapse: PropTypes.func.isRequired,
	index: PropTypes.number,
	isJobView: PropTypes.bool.isRequired,
	data: PropTypes.object.isRequired,
};

export const JobProjectBasicCol = ({
	toogleCollapse,
	index,
	data,
	body,
	title,
	IconComponent,
	classPrefix = '',
}) => {
	let className = 'list-inline-item job-basic-detail';

	return (
		<a onClick={() => toogleCollapse(data, index)}>
			<li className={`${classPrefix} ${className}`}>
				<button className='job-basic-icon'>{IconComponent}</button>
				<div className='jobs-basic-information'>
					<p className='jobs-basic-header'>{title}</p>
					<p className='jobs-basic-body'>{body}</p>
				</div>
			</li>
		</a>
	);
};

JobProjectBasicCol.propTypes = {
	toogleCollapse: PropTypes.func.isRequired,
	index: PropTypes.number,
	title: PropTypes.string.isRequired,
	body: PropTypes.any.isRequired,
	classPrefix: PropTypes.string,
};

function formatDate(publishedDate) {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	let dateObj = new Date(publishedDate);
	let date =
		dateObj.getUTCDate() < 10
			? '0' + dateObj.getUTCDate()
			: dateObj.getUTCDate();
	return (
		date + ' ' + months[dateObj.getUTCMonth()] + ' ' + dateObj.getUTCFullYear()
	);
}
