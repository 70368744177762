import React from 'react';
import Cookie from 'universal-cookie';
import { hookAuth0 } from '../../../shared/components/auth/withAuth0';
import TopbarNavLink from './TopbarNavLink';

const cookie = new Cookie();
const program = cookie.get("program");

const B2C_ORGANIZATION_MENU = [
   {
      id: 'org_dashboard_link',
      title: 'Dashboard',
      route: '/organization/dashboard',
   },
   {
      id: 'org_profile_link',
      title: 'Profile',
      route: '/organization/profile',
   },
   {
      id: 'org_opportunities_link',
      title: 'Opportunities',
      route: '/organization/opportunities',
   },
   {
      id: 'org_competition_link',
      title: 'Competition',
      route: '/organization/competition',
   },
];

const B2C_INDIVIDUAL_MENU = program === 'q3management' ? [
   {
      id: 'navbar_item',
   },
   {
      id: 'ind_home_link',
      title: 'Home',
      route: '/individual/home',
   },
   {
      id: 'ind_profile_link',
      title: 'My Profile',
      route: '/individual/profile',
   },
   {
      id: 'ind_QandA_link',
      title: 'Q&A', //Route to be displayed in topbar for q and a
      route: '/individual/QandA',
   },
   {
      id: 'ind_assessments_link',
      title: 'Skill Quiz',
      route: '/individual/assessments',
   },
   {
      id: 'ind_learn_link',
      title: 'Learn',
      route: '/individual/learn',
   },
   {
      id: 'ind_opportunities_link',
      title: 'Opportunities',
      route: '/individual/opportunities',
   },
   {
      id: 'ind_competition_link',
      title: 'Competition',
      route: '/individual/competition',
   },
   {
      id: 'ind_competition_link',
      title: 'Karisma',
      route: '/individual/karisma',
   },
   // {
   //    id: 'ind_data_star_link',
   //    title: 'Data Star',
   //    route: '/individual/dataStar',
   // },
] : [
   {
      id: 'navbar_item',
   },
   {
      id: 'ind_home_link',
      title: 'Home',
      route: '/individual/home',
   },
   {
      id: 'ind_profile_link',
      title: 'My Profile',
      route: '/individual/profile',
   },
   {
      id: 'ind_QandA_link',
      title: 'Q&A', //Route to be displayed in topbar for q and a
      route: '/individual/QandA',
   },
   {
      id: 'ind_assessments_link',
      title: 'Skill Quiz',
      route: '/individual/assessments',
   },
   {
      id: 'ind_learn_link',
      title: 'Learn',
      route: '/individual/learn',
   },
   {
      id: 'ind_opportunities_link',
      title: 'Opportunities',
      route: '/individual/opportunities',
   },
   {
      id: 'ind_competition_link',
      title: 'Competition',
      route: '/individual/competition',
   },
   // {
   //    id: 'ind_data_star_link',
   //    title: 'Data Star',
   //    route: '/individual/dataStar',
   // },
];

// const PERKESO_MENU = [
//    {
//       id: 'perkeso_home_link',
//       title: 'Home',
//       route: '/individual/home',
//    },
//    {
//       id: 'perkeso_profile_link',
//       title: 'My Profile',
//       route: '/individual/profile',
//    },
//    {
//       id: 'perkeso_assessments_link',
//       title: 'Assessments',
//       route: '/individual/assessments',
//    },
//    {
//       id: 'perkeso_learn_link',
//       title: 'Learn',
//       route: '/individual/learn',
//    },
//    {
//       id: 'perkeso_opportunities_link',
//       title: 'Opportunities',
//       route: '/individual/opportunities',
//    },
//
//    // TODO: Add topbar menu here for perkeso
// ];

const PUBLIC_MENU = [
   {
      id: 'public_home',
      title: 'Home',
      route: '/public/home',
   },
   {
      id: 'public_opportunities',
      title: 'Opportunities',
      route: '/public/opportunities',
   },
   {
       id: "public_qandA",
       title: "Q&A",
       route: "/public/QandA",
   },
   {
      id: "public_competition",
      title: "Competitions",
      route: "/public/competition",
   },
   // {
   //    id: "public_dataStar",
   //    title: "Data Star",
   //    route: "/public/dataStar",
   // }
];

class TopbarMenu extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         showOptions: false,
         users: [
            {
               id: 1,
               name: 'Andrew Krammer',
               des: 'Associate Data Scientist',
               role: 'Logged in as Rubique Individual',
            },
            {
               id: 2,
               name: 'Andrew Krammer',
               des: 'Associate Data Scientist',
               role: 'Access Rubique Organisation',
            },
            {
               id: 3,
               name: 'Andrew Krammer',
               des: 'Associate Data Scientist',
               role: 'Switch to Maybank Individual',
            },
         ],
      };
   }

   toggleShowOptions = () => {
      this.setState({
         showOptions: !this.state.showOptions,
      });
   };

   render() {
      const { auth0, authUser, publicRoute } = this.props;
      const { users, showOptions } = this.state;
      // const user_role = auth0.user ? auth0.user.user_type[0] : '';
      let userRoutes = window.location.href.includes('/individual')
         ? B2C_INDIVIDUAL_MENU
         : window.location.href.includes('/organization')
         ? B2C_ORGANIZATION_MENU
         : PUBLIC_MENU;
      return (
         <React.Fragment>
            {userRoutes.map((item, index) => {
               if (item.id === 'navbar_item') {
                  return (
                     <>
                        {/* Temporary code is commented of switch account UI*/}
                     </>
                  );
               } else {
                  return (
                     <TopbarNavLink
                        key={index}
                        onClick={() => {}}
                        title={item.title}
                        path={item.route}
                        id={item.id}
                     />
                  );
               }
            })}
         </React.Fragment>
      );
   }
}

export default hookAuth0(TopbarMenu);
