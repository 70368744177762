import React, { Fragment, useState, useEffect } from 'react';

//limit : upto how many characters to be displayed, story: description(string) containing html tags

const ReadMoreComponent = ({ story, limit, fontSize }) => {
	const [usableStory, setUsableStory] = useState('');
	const [contentClass, setContentClass] = useState('hidden');

	useEffect(() => {
		const setHiddenStory = (originalStory) => {
			//story without tags
			const storyWithoutTags = getStoryWithoutTags(originalStory);

			let usableStory;
			if (storyWithoutTags.length > limit) {
				usableStory = findUsableString(originalStory, limit);
			} else {
				usableStory = originalStory;
			}
			setUsableStory(usableStory);
		};
		setHiddenStory(story);
	});

	// const findLastWord = (storyWithoutTags, limit) => {
	// 	storyWithoutTags += ' ';
	// 	if (storyWithoutTags.charAt(limit) === ' ')
	// 		return storyWithoutTags.substring(
	// 			storyWithoutTags.lastIndexOf(' ', limit - 1),
	// 			limit,
	// 		);
	//
	// 	return storyWithoutTags
	// 		.substring(
	// 			storyWithoutTags.lastIndexOf(' ', limit - 1),
	// 			storyWithoutTags.indexOf(' ', limit),
	// 		)
	// 		.trim();
	// };

	const getStoryWithoutTags = (story) => {
		//story without html tags
		let div = document.createElement('div');
		div.innerHTML = story;
		return div.textContent || div.innerText || '';
	};


	const removeSpanAndSpaces = (originalStory) => {
		originalStory = originalStory.replace(/  +/g, ' ');
		let div = document.createElement('div');
		div.innerHTML = originalStory;
		let elemes = div.getElementsByTagName('span');
		let spanContent = '';
		while(elemes[0]){
			spanContent += elemes[0].innerHTML;
			elemes[0].parentNode.removeChild(elemes[0]);
		}
		return `<p>${div.innerHTML}${spanContent}</p>`
	};


	const findLastWordIndex = (originalStory, limit) => {
		let newLimit = limit - 3;
		let i = 0, count = 0;
		for(; i < originalStory.length ; i++){
			if(originalStory[i]==='<'){
				i++;
				while(originalStory[i]!=='>') i++;
			} else
				count++;

			if(newLimit===count) {
				break;
			}
		}
		return i;
	};

	const findUsableString = (originalStory, limit) => {

		originalStory = removeSpanAndSpaces(originalStory);
		const lastWordIndex = findLastWordIndex(originalStory, limit);
		let i,
			closingTagStart = originalStory.indexOf('<', lastWordIndex);

		for (i = closingTagStart; i < originalStory.length; i++) {
			//if encountered character is > then check if consecutive character is not < else move forward

			if (originalStory[i] === '<' && originalStory[i + 1] === '/') {
				i = originalStory.indexOf('>', i + 2);

				if (
					originalStory[i + 1] !== '<' ||
					(originalStory[i + 1] === '<' && originalStory[i + 2] !== '/')
				)
					break;
				if (originalStory.substring(i + 1, i + 5) === '</p>') break;
			}
		}
		return (
			originalStory.substring(0, lastWordIndex) +
			'...' +
			originalStory.substring(closingTagStart, i + 1)
		);
	};

	return (
		<Fragment>
			<div>
				{story !== '' && contentClass === 'hidden' ? (
					<>
						<div className={contentClass} style={{ 'font-size': fontSize }}>
							<p style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{ __html: usableStory }} />
						</div>
						{getStoryWithoutTags(story).length > limit && (
							<div
								className='read-more'
								style={{ 'font-size': fontSize }}
								onClick={() => setContentClass('show_content')}
							>
								<a>Read more</a>
							</div>
						)}
					</>
				) : (
					<>
						<div className={contentClass} style={{ 'font-size': fontSize }}>
							<p style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{ __html: story }} />
						</div>
						<div
							className='read-more'
							style={{ 'font-size': fontSize }}
							onClick={() => setContentClass('hidden')}
						>
							<a>Read less</a>
						</div>
					</>
				)}
			</div>
		</Fragment>
	);
};

export default ReadMoreComponent;
