import appConfigs from '../../config/appConfigs';
import { request } from '../../shared/helpers/RequestWrapper';

const API_BASE_SKILLSTREETS = appConfigs.endpoint.API_BASE_URL_SKILLSTREETS;

const GET_JOBSEEKER_DETAILS = '/organization/jobProjectPost/getSpecificJobsSeekerDetailsById';
const GET_JOBSEEKER_DETAILS_BYID = '/organization/jobProjectPost/getJobOrProjectById';
const PUT_JOB_ClOSE_BYID = '/organization/jobProjectPost/closeJobPorject';



export function getSpecificJobsSeekerDetailsById(jobSeekerId, jobId) {
    return request({
        url: API_BASE_SKILLSTREETS + GET_JOBSEEKER_DETAILS + '/' + jobSeekerId + '/' + jobId,
        method: 'GET',
    });
}
export function getJobsSeekerDetailsById(jobId) {
    return request({
        url: API_BASE_SKILLSTREETS + GET_JOBSEEKER_DETAILS_BYID + '/' + jobId,
        method: 'GET',
    });
}

export function jobCloseById(jobId) {
    return request({
        url: API_BASE_SKILLSTREETS + PUT_JOB_ClOSE_BYID + '/' + jobId,
        method: 'PUT',
    });
}