import React, { Component, Fragment } from 'react';
import {
   Button,
   Col,
   Input,
   Modal,
   ModalBody,
   ModalHeader,
   Row,
} from 'reactstrap';
import TinyUrl from 'tinyurl';
import {
   getCertificateLink,
   getEmployeeCertificates,
} from '../../../../api/Individual/employee.certificates.api';
import { showNotification } from '../../../../shared/components/toastMessage';
import ApplicantDisplayCertificateModal from './ApplicantDisplayCertificateModal';
import PropTypes from 'prop-types';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { hookAuth0 } from '../../../../shared/components/auth/withAuth0';

class ApplicantCertifications extends Component {
   constructor(props) {
      super(props);
      this.state = {
         skillCertificates: [],
         othersCertificates:[]
      };
   }

   componentWillReceiveProps(nextProps, nextContext) {
      this.setState(
         {
            email: nextProps.email,
         },
         () => this.getCertifications()
      );
   }

   getCertifications = () => {
      getEmployeeCertificates(this.state.email)
         .then((response) => {
            this.setState({
               skillCertificates: response.data.skillCertificates,
               othersCertificates: response.data.othersCertificates,
            });
         })
         .catch((err) =>
            console.error('getEmployeeCertificates error :: ', err)
         );
   };

   getCertificateLink = (link) => {
      getCertificateLink(link, false)
         .then((response) => {
            this.setState({
               fileUrl: response.data,
               selectedCertificateLink: link,
            });
            this.toggleModal();
         })
         .catch((err) => console.error('getCertificateLink error :: ', err));
   };

   toggleModal = () => {
      this.setState({
         modal: !this.state.modal,
      });
   };

   openCertificate = () => {
      this.toggleModal();
   };

   downloadPDF = (link) => {
      getCertificateLink(link, true)
         .then((response) => {
            window.open(response.data, '_blank');
         })
         .catch((err) => console.error('getCertificateLink error :: ', err));
   };

   sharePDF = (link) => {
      getCertificateLink(link, false)
         .then((response) => {
            TinyUrl.shorten(response.data, (res, err) => {
               this.setState(
                  {
                     tinyURL: res,
                  },
                  () => this.toggleTiny()
               );
            });
         })
         .catch((err) => console.error('getCertificateLink error :: ', err));
   };

   toggleTiny = () => {
      this.setState({
         tinyOpen: !this.state.tinyOpen,
      });
   };

   copyTinyURL = () => {
      let url = this.state.tinyURL;
      var textField = document.createElement('textarea');
      textField.innerText = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      showNotification('URL is copied.', 'primary');
   };

   render() {
      const { modal, skillCertificates, othersCertificates } = this.state;
      // certifications.push({});
      return (
         <Fragment>
            <Row className="container pr-0">
               <Col md={12} lg={12} sm={12} xs={12} className={'px-0'}>
                  <div className={'employee-certifications'}>
                     {skillCertificates.length === 0 && othersCertificates.length === 0 && (
                        <p className={'pl-3 mt-3'}>
                           You have not completed any data, analytics, or AI
                           skill certification by CADS.
                        </p>
                     )}
                     {skillCertificates.map((item) => (
                        <div
                           className={'employee-certifications__block'}
                           onClick={() =>
                              this.getCertificateLink(item.certificate)
                           }
                        >
                           <img
                              className={'employee-certifications__skill-small'}
                              src={item.skillsImage}
                              // src={
                              //   "https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/module-images/HBS_Competing_on_business_analytics_and_Bigdata.png"
                              // }
                              alt={'Skill Certification LOGO'}
                           />
                        </div>
                     ))}
                     {othersCertificates.map((item) => (
                        <div
                           className={'employee-certifications__block'}
                           onClick={() =>
                              this.getCertificateLink(item.certificate)
                           }
                        >
                           <img
                              className={'employee-certifications__skill-small'}
                              src={item.skillsImage}
                              // src={
                              //   "https://general-assets.sgp1.digitaloceanspaces.com/learning-path-images/module-images/HBS_Competing_on_business_analytics_and_Bigdata.png"
                              // }
                              alt={'Skill Certification LOGO'}
                           />
                        </div>
                     ))}
                  </div>
               </Col>
            </Row>
            {modal && (
               <ApplicantDisplayCertificateModal
                  isOpen={true}
                  toggle={this.toggleModal}
                  url={this.state.fileUrl}
                  downloadPdf={this.downloadPDF}
                  selectedCertificateLink={this.state.selectedCertificateLink}
               />
            )}
            <Modal isOpen={this.state.tinyOpen} toggle={this.toggleTiny}>
               <ModalHeader className={'p-0 m-0'} toggle={this.toggleTiny} />
               <ModalBody className={'pb-0'}>
                  <div className={'d-flex'}>
                     <Input
                        size={'sm'}
                        className={'w-75'}
                        value={this.state.tinyURL}
                     />
                     <Button
                        className={'ml-2'}
                        size={'sm'}
                        color={'primary'}
                        onClick={this.copyTinyURL}
                     >
                        Copy Link
                     </Button>
                  </div>
               </ModalBody>
            </Modal>
         </Fragment>
      );
   }
}
ApplicantCertifications.propTypes = {
   t: PropTypes.func.isRequired,
   rtl: RTLProps.isRequired,
};

export default compose(
   withTranslation('common'),
   connect((state) => ({
      rtl: state.rtl,
   }))
)(hookAuth0(ApplicantCertifications));
