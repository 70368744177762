import clsx from 'clsx';
import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import HomeOutlineIcon from 'mdi-react/HomeOutlineIcon';
import BookOutlineIcon from 'mdi-react/BookOutlineIcon';
// import TableLargeIcon from "mdi-react/TableLargeIcon";
import BriefcaseOutlineIcon from 'mdi-react/BriefcaseOutlineIcon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SidebarLink from './SidebarLink';

function getClassName(route, activeRoute) {
   return clsx({
      'sidebar__link-active': activeRoute.includes(route),
   });
}

const B2B_ORGANIZATION_SIDEBAR = [
   {
      title: 'Dashboard',
      mdiIcon: (route, activeRoute) => (
         <HomeOutlineIcon
            className={getClassName(route, activeRoute)}
            size={20}
         />
      ),
      route: '/b2borganization/dashboard',
   },
   {
      title: 'Employees',
      mdiIcon: (route, activeRoute) => (
         <AccountCircleOutlineIcon
            className={getClassName(route, activeRoute)}
            size={18}
         />
      ),
      route: '/b2borganization/employees',
   },
   {
      title: 'Assessments',
      mdiIcon: (route, activeRoute) => (
         <BookOutlineIcon
            className={getClassName(route, activeRoute)}
            size={18}
         />
      ),
      route: '/b2borganization/assessments',
   },
   {
      title: 'Opportunities',
      mdiIcon: (route, activeRoute) => (
         <BriefcaseOutlineIcon
            className={getClassName(route, activeRoute)}
            size={18}
         />
      ),
      route: '/b2borganization/opportunities',
   },
];
const B2C_INDIVIDUAL_SIDEBAR = [
   {
      title: 'Home',
      mdiIcon: (route, activeRoute) => (
         <HomeOutlineIcon
            className={getClassName(route, activeRoute)}
            size={24}
         />
      ),
      route: '/individual/home',
   },
   // {
   //   title: "Q&A",
   //   mdiIcon: (route, activeRoute) => (
   //     <BookOutlineIcon className={getClassName(route, activeRoute)} size={24} />
   //   ),
   //   route: "/individual/QandA",   //Route to be displayed in sidebar for q and a
   // },
   {
      title: 'Profile',
      mdiIcon: (route, activeRoute) => (
         <AccountCircleOutlineIcon
            className={getClassName(route, activeRoute)}
            size={24}
         />
      ),
      route: '/individual/profile',
   },
   {
      title: 'Assessments',
      mdiIcon: (route, activeRoute) => (
         <BookOutlineIcon
            className={getClassName(route, activeRoute)}
            size={24}
         />
      ),
      route: '/individual/assessments',
   },
   {
      title: 'Learn',
      mdiIcon: (route, activeRoute) => (
         <BookOutlineIcon
            className={getClassName(route, activeRoute)}
            size={24}
         />
      ),
      route: '/individual/learn',
   },
   {
      title: 'Opportunities',
      mdiIcon: (route, activeRoute) => (
         <BriefcaseOutlineIcon
            className={getClassName(route, activeRoute)}
            size={24}
         />
      ),
      route: '/individual/opportunities',
   },
   {
      title: 'Competition',
      mdiIcon: (route, activeRoute) => (
         <BookOutlineIcon
            className={getClassName(route, activeRoute)}
            size={24}
         />
      ),
      route: '/individual/competition',
   },
   {
      title: 'Q&A',
      mdiIcon: (route, activeRoute) => (
         <BriefcaseOutlineIcon
            className={getClassName(route, activeRoute)}
            size={24}
         />
      ),
      route: '/individual/QandA',
   },
];

class SidebarContent extends Component {
   static propTypes = {
      changeToDark: PropTypes.func.isRequired,
      changeToLight: PropTypes.func.isRequired,
      onClick: PropTypes.func.isRequired,
   };

   hideSidebar = (data) => {
      const { onClick } = this.props;
      onClick();
   };

   render() {
      // const { user_role } = this.props;
      const activeRoute = window.location.pathname;
      let userRoutes = window.location.href.includes('/individual')
         ? B2C_INDIVIDUAL_SIDEBAR
         : window.location.href.includes('/organization')
         ? B2B_ORGANIZATION_SIDEBAR
         : [];
      return (
         <div className="sidebar__content">
            <ul className="sidebar__block">
               {userRoutes.map((item, index) => (
                  <SidebarLink
                     key={index}
                     title={item.title}
                     mdiIcon={item.mdiIcon(item.route, activeRoute)}
                     route={item.route}
                     onClick={this.hideSidebar}
                     activeRoute={activeRoute}
                     mdiIcon2={item.mdiIcon2}
                  />
               ))}
            </ul>
         </div>
      );
   }
}

export default SidebarContent;
