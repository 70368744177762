import { SET_TOUR_CONFIG, SET_TOUR_OPEN, SET_EXP, SET_LEARN, SET_REDIRECT_URL } from '../actions/tourAction';


const initialState = {
    isTourOpen: false,
    tourConfig: null,
    tourExperienceAdded: false,
    tourLearnRedirect:false,
    toueRedirect:''
};

const tourReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOUR_OPEN:
            return {
                ...state,
                isTourOpen: action.isOpen
            };
        case SET_TOUR_CONFIG:
            return {
                ...state,
                tourConfig: action.tourConfig
            };
        case SET_EXP:
            return {
                ...state,
                tourExperienceAdded: action.status
            };

        case SET_LEARN:
            return {
                ...state,
                tourLearnRedirect: action.status
            };
        case SET_REDIRECT_URL:
            return {
                ...state,
                tourRedirectUrl: action.status
            };

        default:
            return state;
    }
};

export default tourReducer;
