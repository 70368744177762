import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { Card, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import BehavioralTab from './BehavioralTab';
import CompetencyTab from './CompetencyTab';
import PropTypes from 'prop-types';

const overviewTabs = [
   {
      name: 'Behavioral Implications',
      value: 'behavioral',
   },
   {
      name: 'Competency Analysis',
      value: 'competency',
   },
];

class OverviewTabs extends PureComponent {
   constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = {
         activeTab: 'behavioral',
      };
   }

   toggle(tab) {
      const { activeTab } = this.state;
      if (activeTab !== tab) {
         let update = { activeTab: tab };
         this.setState({ ...update });
      }
   }

   /**
    * This will be used to create a Profile Tab division.
    */

   render() {
      const { activeTab } = this.state;
      const { id, behavioralAnalysis, competenciesAnalysis } = this.props;

      return (
         <Row className={"mt-3"}>
            <Col md={12} lg={12} xl={12}>
               <Card>
                  <div className="profile__card tabs tabs--bordered-bottom">
                     <div className="tabs__wrap">
                        <Nav tabs className={"p-0"} style={{ border: '0px' }}>
                           {overviewTabs.map((profileTab, index) => {
                              return (
                                 <NavItem key={index}>
                                    <NavLink
                                       className={classnames({
                                          active:
                                             activeTab === profileTab.value,
                                       })}
                                       onClick={() => {
                                          this.toggle(profileTab.value);
                                       }}
                                    >
                                       {profileTab.name}
                                    </NavLink>
                                 </NavItem>
                              );
                           })}
                        </Nav>
                        {activeTab === 'behavioral' ? (
                           <BehavioralTab
                              id={id}
                              behavioralAnalysis={behavioralAnalysis}
                           />
                        ) : activeTab === 'competency' ? (
                           <CompetencyTab
                              id={id}
                              competenciesAnalysis={competenciesAnalysis}
                           />
                        ) : null}
                     </div>
                  </div>
               </Card>
            </Col>
         </Row>
      );
   }
}
OverviewTabs.propTypes = {
   id: PropTypes.any,
   behavioralAnalysis: PropTypes.string,
   competenciesAnalysis: PropTypes.string,
};
export default OverviewTabs;
