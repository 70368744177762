import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RTLProps } from '../../../../../../../shared/prop-types/ReducerProps';
import SmartSkillContainer from '../../../../../../../shared/components/SmartSkillContainer';
import {  Col, Row } from 'reactstrap';
import OverviewDescription from './OverviewDescription';

class OverviewDesign extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         currentCardId: 1,
         selectedCard: 1,
         smartSkillData: [],
         id: 147,
      };
   }

   // componentDidMount() {
   //     this.getSmartSkillsData(this.state.id);
   // }
   //
   // getSmartSkillsData = (id) => {
   //     getSmartSkillsdata(id)
   //         .then((data) => {
   //             console.log(">>> ++ ",data);
   //             this.setState({
   //                 smartSkillData: data.factors,
   //             });
   //         })
   //         .catch((err) => console.log("ERR::::", err));
   //     console.log(">>> ",this.state.smartSkillData);
   // };

   setSelectedCard = (id) => {
      this.setState({
         selectedCard: id,
      });
   };

   getSmartSkillFactor = (id) => {
      return this.props.smartSkillData.find((element) => {
         return element.factorId === id;
      });
   };

   render() {
      const { selectedCard } = this.state;
      const { smartSkillData } = this.props;
      return (
         <Fragment>
            <Row>
               <Col md={12}>
                  <div className="card-deck" >
                     {smartSkillData.map((smartSkill, index) => {
                        return (
                           <SmartSkillContainer
                              color={smartSkill.color}
                              level={smartSkill.scoreTitle}
                              value={smartSkill.score}
                              title={smartSkill.factorName}
                              description={smartSkill.factorAlias}
                              id={smartSkill.factorId}
                              setSelectedCard={this.setSelectedCard}
                              isSelected={
                                 selectedCard === smartSkill.factorId
                              }
                           />
                        );
                     })}
                  </div>
               </Col>
            </Row>
            <Row className={"p-5"}>
               <Col md={'12'}>
                  <OverviewDescription
                     id={selectedCard}
                     color={this.getSmartSkillFactor(selectedCard).color}
                     title={this.getSmartSkillFactor(selectedCard).factorName}
                     description={
                        this.getSmartSkillFactor(selectedCard).factorDescription
                     }
                     behavioralAnalysis={
                        this.getSmartSkillFactor(selectedCard)
                           .behavioralAnalysis
                     }
                     competenciesAnalysis={
                        this.getSmartSkillFactor(selectedCard)
                           .competenciesAnalysis
                     }
                  />
               </Col>
            </Row>
         </Fragment>
      );
   }
}

OverviewDesign.propTypes = {
   t: PropTypes.func.isRequired,
   rtl: RTLProps.isRequired,
   smartSkillData: PropTypes.any,
};

export default compose(
   withTranslation('common'),
   connect((state) => ({
      rtl: state.rtl,
   }))
)(OverviewDesign);
