import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const SidebarLink = ({
  title,
  icon,
  mdiIcon,
  newLink,
  route,
  onClick,
  activeRoute
}) => {
  return (
    <NavLink
      to={route}
      onClick={(e) => onClick(route)}
      activeClassName="sidebar__link-active">
      <li className="sidebar__link">
        {mdiIcon ? mdiIcon : null}
        {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ""}

        <p
          className={clsx("sidebar__link-title", {
            "sidebar__link-active": activeRoute.includes(route)
          })}>
          {title}
        </p>
      </li>
    </NavLink>
  );
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func
};

SidebarLink.defaultProps = {
  icon: "",
  newLink: false,
  route: "/",
  onClick: () => {}
};

export default SidebarLink;
