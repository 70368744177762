/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from "react";
import CheckIcon from "mdi-react/CheckIcon";
import PropTypes from "prop-types";
import classNames from "classnames";

class CheckBoxRoundedGroup extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    // value: PropTypes.oneOfType([PropTypes.any, PropTypes.bool]),
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string
  };

  static defaultProps = {
    label: "",
    defaultChecked: false,
    disabled: false,
    className: "",
    color: "",
    options: []
  };

  componentDidMount() {}

  render() {
    const { disabled, className, name, color, options, input } = this.props;
    return options.map((option, index) => {
      const CheckboxClass = classNames({
        "checkbox-btn-rounded checkbox-btn-rounded-group": true,
        "checkbox-btn-rounded--selected": input.value.indexOf(option.id) !== -1,
        disabled
      });

      return (
        <label
          className={`${CheckboxClass} ${
            className ? ` checkbox-btn-rounded--${className}` : ""
          }`}
          key={index}
          htmlFor={`${name}_checkbox_group_${option.id}`}>
          <input
            className="checkbox-btn-rounded__checkbox"
            type="checkbox"
            id={`${name}_checkbox_group_${option.id}`}
            name={`${name}_checkbox_group_${option.id}`}
            onChange={(event) => {
              const newValue = [...input.value];
              if (event.target.checked) {
                newValue.push(option.id);
              } else {
                newValue.splice(newValue.indexOf(option.id), 1);
              }

              return input.onChange(newValue);
            }}
            checked={input.value.indexOf(option.id) !== -1}
            disabled={disabled}
          />
          <span
            className="checkbox-btn-rounded__checkbox-custom"
            style={color ? { background: color, borderColor: color } : {}}>
            <CheckIcon />
          </span>
          <span
            className={classNames("checkbox-btn-rounded__label", {
              " checkbox-btn-rounded__label-selected":
                input.value.indexOf(option.id) !== -1
            })}>
            {option.name}
          </span>
        </label>
      );
    });
  }
}

const RenderCheckBoxRoundedGroupField = (props) => {
  const {
    input,
    label,
    defaultChecked,
    disabled,
    className,
    color,
    options,
  } = props;
  return (
    <div>
      <CheckBoxRoundedGroup
        input={input}
        label={label}
        defaultChecked={defaultChecked}
        disabled={disabled}
        className={className}
        color={color}
        options={options}
        name={input.name}
      />
    </div>
  );
};

RenderCheckBoxRoundedGroupField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string
    // value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  options: PropTypes.array
};

RenderCheckBoxRoundedGroupField.defaultProps = {
  label: "",
  defaultChecked: false,
  disabled: false,
  className: "",
  color: "",
  options: []
};

export default RenderCheckBoxRoundedGroupField;
