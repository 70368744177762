import React, {Component, Fragment} from 'react';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
// import Pdf from "react-to-pdf";
import html2PDF from 'jspdf-html2canvas';
import { getJobSeekerDetailsForSmartResume } from '../../api/Individual/employee.api';
import { showNotification } from './toastMessage';
import LoadingIcon from 'mdi-react/LoadingIcon';

const SkillsAndCertifications = {
   dataLiteracySkills: [
      'Data Analysis', 'Structured Query Language (SQL)', 'Javascript', 'Python',
      'Python-Statistical Programming', 'Machine Learning', 'Data Cleansing',
      'SPARK','Matlab', 'Computer Vision', 'Power BI', 'Dashboard', 'Computer Science',
      'Data Science', 'DMBS', 'Advance Analysis'
   ],
   otherSkills: [
      'Presentation', 'Video Production', 'public speaking'
   ],
   certifications: [
      'Supervised Machine Learning',
      'Relational Database Design',
      'Python for Analytics',
   ]
}

const Experience = {
   workExperience : [
      {
         position: 'Lead Data Engineer',
         company: 'Samsung Electronics',
         starting: 'June 2019',
         ending: 'Present',
         total: '1 Year',
         location: 'Austin, Texas',
         description : 'Collaborated with international volunteers from around the world and' +
            'studied Peru’s academic culture and language to provide sports, academic' +
            'classes and women’s workshops in Spanish. Managed classrooms between' +
            '10-30 students and created multi-disciplinary lessons. Adapted lessons to' +
            'students’ needs with limited local classroom resources.'
      },
      {
         position: 'Software Engineer',
         company: 'Samsung Electronics',
         starting: 'June 2019',
         ending: 'Present',
         total: '1 Year',
         location: 'Austin, Texas',
         description : 'Engineered, drafted and designed a wide variety of products: data center' +
            'racks, battery racks, test equipment, and solenoids.Researched and reported' +
            'on a wide variety topics.Performed inspections on the manufacturing floor'
      }
   ],
   education: [
      {
         field: 'Bachelor of Computer Science',
         school: 'Gujarat technological University',
         starting: 'June 2016',
         ending: '2020'
      },
      // {
      //    field: 'High School',
      //    school: 'The Warthon School',
      //    starting: 'February 2014',
      //    ending: '2016'
      // },
      // {
      //    field: 'Primary School',
      //    school: 'The Tulip School',
      //    starting: 'January 2004',
      //    ending: '2016'
      // }
   ]
}

const months = [
   'Jan',
   'Feb',
   'Mar',
   'Apr',
   'May',
   'Jun',
   'Jul',
   'Aug',
   'Sep',
   'Oct',
   'Nov',
   'Dec',
];

export default class PDFTemplate extends Component {
   constructor(props) {
      super(props);
      this.state = {
         dataLiteracySkills:[],
         otherSkills:[],
         certifications:[],
         jobSeekerInformation: [],
         workExperience: [],
         educationExperience:[],
         skillsAndCertifications: SkillsAndCertifications,
         experience: Experience,
         isLoading: false,
         jobSeekerPublicURL: '',
         individualDesignation:''
      }
      this.downloadPDF = React.createRef();
   }


   componentDidMount() {

   }

   componentWillReceiveProps(nextProps, nextContext) {
      if(nextProps && nextProps.modalFlagSmartResume) {
         this.getIndividualAllData();
      }
   }

   getIndividualAllData = () => {
      let emailId = null;
      if(this.props?.emailId && this.props?.emailId !== '' ) {
         emailId=this.props?.emailId
      }
      this.setState({
         isLoading:true
      })
      getJobSeekerDetailsForSmartResume(emailId).then(response => {
         this.setState({
            isLoading:false
         })
         let { data } = response;
         // let certifications = [...data.jobSeekerCertificates,...data.jobSeekerOtherCertificates];
         this.setState({
            dataLiteracySkills:data.dataLiteracySkills,
            otherSkills:data.otherSkills,
            individualDesignation:data?.designation || '',
            jobSeekerInformation: data.jobSeekerDTO,
            certifications:data.jobSeekerCertificates,
            workExperience:data.jobSeekerExperience.filter((data) => {
               return data.type !== 1;
            }),
            educationExperience:data.jobSeekerExperience.filter((data) => {
               return data.type !== 0;
            }),
            jobSeekerPublicURL : window.location.origin+"/public/profile/"+data.jobSeekerDTO?.userDetails?.userName
         })

      }).catch(err => {
         this.setState({
            isLoading:false
         })
         showNotification(err);
      })
   }

   downloadPDFMethod = () => {
      let public_url = document.getElementById('public_url');
      public_url.style.display = 'none';

      let page = document.getElementById('page');
      html2PDF(page, {
         jsPDF: {
            // unit: 'cm',
            format: 'a4'
         },
         html2canvas : {
            imageTimeout: 15000,
            useCORS: false,
            quality: 4,
            scale: 6
         },
         // imageQuality: 1,
         margin: {
            top: 35,
            right: 2,
            bottom: 40,
            left: 2,
         },
         imageType: 'image/jpeg',
         // output: 'cads_resume.pdf',
         init: (pdfObject) => {
         },
         success: (pdfObject) => {
            var pdf_pages = pdfObject.internal.pages
            for (var i = 1; i < pdf_pages.length; i++) {

               pdfObject.setPage(i)

               var pageSize = pdfObject.internal.pageSize;
               var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

               // a4 size width 631.4175 height 446.46

               pdfObject.setFillColor(149,27,30);
               pdfObject.rect(30, 0, 385, 15, 'F')

               if(i === 1) {

                  // pdfObject.setFillColor(255,255,255);
                  // pdfObject.rect(30, 90, 385, 20, 'F')

                  pdfObject.setFontSize(9);
                  pdfObject.setTextColor(46,72,92);
                  let userPublicURL = this.state.jobSeekerPublicURL;
                  pdfObject.text(userPublicURL,32,110, { align:'left' },null);
               }

               pdfObject.setFontSize(8);
               pdfObject.setTextColor(122,129,134);
               pdfObject.text('Page ' + String(i) + ' of ' + String(pdf_pages.length-1),pageSize.width-30,pageHeight-15,null,null,"right");

               // var img = new Image();
               // img.src = '/img/CadsLogoPDF.JPG';
               // pdfObject.addImage(img, 'png',30,pageHeight-32,100,23,null,'NORMAL',0)

            }
            pdfObject.save('cads_resume.pdf');
            public_url.style.display = 'block';
         },
      });
   }

   calculateStartDate = (startDate) => {
      let valMonth = '';
      let valYear = '';
      if(startDate) {
         let startMonth = new Date(startDate).getMonth();
         let startYear = new Date(startDate).getFullYear();
         if (startMonth) {
            valMonth = months[startMonth];
         } else {
            valMonth = ''
         }
         if (startYear) {
            valYear = startYear;
         } else {
            valYear = ''
         }
      }
      return valMonth + valYear + '-';
   }

   calculateEndDate = (endDate,isCurrent) => {

      let endMonth = '';
      let endYear = '';
      if(endDate) {
         endMonth = new Date(endDate).getMonth();
         endYear = new Date(endDate).getFullYear();
      }
      if (isCurrent) {
         let date = new Date();
         endYear = date.getFullYear();
         endMonth = date.getMonth();
      }
      let month = ''
      if(endMonth) {
         month = months[endMonth]
      } else {
         month = ''
      }
      let year = ''
      if(endYear) {
         year = endYear;
      } else {
         year = ''
      }
      return month + year;
   }

   calculateFinalYears = (startDate,endDate) => {
      let startMonth = new Date(startDate).getMonth();
      let startYear = new Date(startDate).getFullYear();
      let endMonth = '';
      let endYear = '';
      if(endDate) {
         endMonth = new Date(endDate).getMonth();
         endYear = new Date(endDate).getFullYear();
      }
      let totalMon = (endYear - startYear - 1) * 12 + (12 - startMonth) + endMonth;
      let finalYears = endYear === startYear ? 0 : Math.floor(totalMon / 12);
      let finalMon = endYear === startYear ? endMonth - startMonth : totalMon % 12;
      let val = ''
      if(finalYears && finalYears > 0) {
         val = finalYears+' Year';
      } else if(finalMon && finalMon > 0) {
         val = finalMon+' Month';
      }
      return val;
   }

   render() {

      const {jobSeekerInformation,dataLiteracySkills,otherSkills,certifications, workExperience, educationExperience ,individualDesignation} = this.state;

      return (
         <Fragment>

            <Modal style={{ maxWidth: '700px' }} isOpen={this.props.modalFlagSmartResume} centered>
               {this.state.isLoading && (
                  <div className="panel__refresh" style={{justifyContent: 'center', display: 'flex',width:' 95%'}}>
                     <LoadingIcon />
                  </div>
               )}
               <ModalHeader toggle={this.props.toogleModalForSmartResume} className={'p-0'}>

               </ModalHeader>
               <ModalBody className={'p-1 modal-question'}>
                  {/*<div style={{border:'2px solid #2E485C'}} className={'p-1'}>*/}
                  <div id={'page'} ref={this.downloadPDF}>
                     <Container style={{color:'#2e485c',width: '595px'}}>
                        <Row className={'mt-0'}>
                           {/*<Col xl={12}>*/}
                           {/*   <div style={{backgroundColor:'#951B1E',height:'15px'}} className={'mb-2'}>*/}
                           {/*   </div>*/}
                           {/*</Col>*/}
                           {/*<Col xl={12} className={'mt-0 mb-2'}>*/}
                           {/*   <img src={'/img/logo/rubiqe-primary.png'} style={{height:'20px',width:'auto',backfaceVisibility:'hidden'}}/>*/}
                           {/*</Col>*/}
                        </Row>
                        <Row>
                           <Col xl={12}>
                              <p style={{fontSize: '14px',fontWeight:'bold'}}>
                                 {jobSeekerInformation?.userDetails?.fname}&nbsp;{jobSeekerInformation?.userDetails?.lname}
                              </p>
                           </Col>
                           <Col xl={12} className={'mb-2'}>
                              <p style={{fontSize:'10px',color:'#575e5e',fontWeight:'600',minHeight:'16px'}}>
                                 {individualDesignation}
                              </p>
                           </Col>
                           <Col xl={12} className={'pt-2 pb-4'}>
                              <p style={{fontWeight:'600',fontSize: '9px',minHeight:'16px'}}>
                                 <span id={'public_url'} >{this.state.jobSeekerPublicURL}</span>
                              </p>
                           </Col>
                        </Row>
                        <Row style={{fontSize: '9px'}} className={'mb-1'}>
                           <Col xl={4}>
                              <p style={{color:'#951B1E',fontWeight:'600'}}>
                                 Contact
                              </p>
                              <p className={'mt-0'} >
                                 {jobSeekerInformation?.phoneNumber || '-' }
                              </p>
                              <p style={{color:'#951B1E',fontWeight:'600'}}>
                                 Email
                              </p>
                              <p className={'mt-0'} >
                                 {jobSeekerInformation?.userDetails?.userId}
                              </p>
                              <p style={{color:'#951B1E',fontWeight:'600'}}>
                                 Location
                              </p>
                              <p className={'mt-0'}>
                                 {jobSeekerInformation?.countryDetails?.name  || '-'},&nbsp;{jobSeekerInformation?.location  || '-'}
                              </p>
                           </Col>
                           <Col xl={8}>
                              <p style={{width:'90%'}}>
                                 {jobSeekerInformation?.bio  || '-'}
                              </p>
                           </Col>
                        </Row>
                        <hr className={'mt-2'}/>

                        <Row style={{fontSize: '9px'}} className={'mb-1'}>
                           <Col xl={4}>
                              <p style={{color:'#951B1E',fontWeight:'600'}}>
                                 Skills
                              </p>
                           </Col>
                           <Col xl={8}>
                              <div>
                                 <p style={{fontWeight:'600'}}>
                                    Data Literacy Skill
                                 </p>
                                 <p className={'mt-1 mb-3'} style={{width:'90%'}}>
                                    {dataLiteracySkills.map((data,key) => (
                                       <span>{data}{(dataLiteracySkills?.length-1) === key ? '' : ', '}</span>
                                    ))}
                                    {dataLiteracySkills && dataLiteracySkills?.length === 0 &&
                                       <span>No Skills</span>
                                    }
                                 </p>
                              </div>
                              <div className={'mt-2'}>
                                 <p style={{fontWeight:'600'}}>
                                    Other Skill
                                 </p>
                                 <p  className={'mt-1 mb-3'} style={{width:'90%'}}>
                                    {otherSkills.map((data,key) => (
                                       <span>{data}{(otherSkills.length-1) === key ? '' : ', '}</span>
                                    ))}
                                    {otherSkills && otherSkills?.length === 0 &&
                                       <span>No Skills</span>
                                    }
                                 </p>
                              </div>
                              <div className={'mt-2'} style={{width:'90%'}}>
                                 <p style={{fontWeight:'600'}}>
                                    Certifications
                                 </p>
                                 <p  className={'mt-1 mb-3'}>
                                    {certifications.map((data,key) => (
                                       <p className={'mt-0'}>{data?.skillName}{(certifications.length-1) === key ? '' : ', ' }</p>
                                    ))}
                                    {certifications && certifications.length === 0 &&
                                       <span>No Certifications</span>
                                    }
                                 </p>
                              </div>
                           </Col>
                        </Row>
                        <hr className={'mt-2'}/>


                        <Row style={{fontSize: '9px'}} className={'mb-3'}>
                           <Col xl={12}>
                              <p style={{color:'#951B1E',fontWeight:'600'}}>
                                 Working Experience
                              </p>
                           </Col>
                           {workExperience.map((data,key) => (
                              <Container className={'mt-3'}>
                                 <Row>
                                    <Col xl={4}>
                                       <p style={{fontSize: '11px',fontWeight:'700'}}>
                                          {data.designation}
                                       </p>
                                       <p className={'mt-2'} style={{color:'#575e5e',fontWeight:'600'}}>
                                          {data.organizationName}
                                       </p>
                                       <p className={'mt-0'} style={{color:'#575e5e',fontWeight:'400'}}>
                                          {this.calculateStartDate(data.startDate)}
                                          {data.isCurrent ? 'Present' :
                                             this.calculateEndDate(data.endDate,data.isCurrent)}
                                          &nbsp;{this.calculateFinalYears(data.startDate,data.endDate)}
                                       </p>
                                    </Col>
                                    <Col xl={8}>
                                       <p style={{width:'90%'}}>
                                          {data?.description ?
                                             <>{data?.description}</> :
                                             <>No description found</>
                                          }
                                       </p>
                                    </Col>
                                 </Row>
                              </Container>
                           ))}
                           {workExperience && workExperience.length === 0 &&
                           <Container className={'mt-3'}>
                              <Row>
                                 <Col xl={4}>
                                    <span>No Work Experience </span>
                                 </Col>
                              </Row>
                           </Container>
                           }
                        </Row>
                        <hr className={'mt-2'}/>

                        <Row style={{fontSize: '9px'}} className={'mb-2'}>
                           <Col xl={12}>
                              <p style={{color:'#951B1E',fontWeight:'600'}}>
                                 Education
                              </p>
                           </Col>
                           {educationExperience.map((data,key) => (
                              <Container className={'mt-3'}>
                                 <Row>
                                    <Col xl={4}>
                                       <p style={{fontSize: '11px',fontWeight:'700'}}>
                                          {data.degree}&nbsp;{data.fieldOfStudy}
                                       </p>
                                    </Col>
                                    <Col xl={8}>
                                       <p style={{color:'#575e5e',fontWeight:'600',width:'90%'}}>
                                          {data.organizationName}
                                       </p>
                                       <p className={'mt-0'} style={{color:'#575e5e',fontWeight:'400',width:'90%'}}>
                                          {this.calculateStartDate(data.startDate)}
                                          {data.isCurrent ? 'Present' :
                                             this.calculateEndDate(data.endDate,data.isCurrent)}
                                          &nbsp;{this.calculateFinalYears(data.startDate,data.endDate)}
                                       </p>
                                    </Col>
                                 </Row>
                              </Container>
                           ))}
                           {educationExperience && educationExperience.length === 0 &&
                           <Container className={'mt-3'}>
                              <Row>
                                 <Col xl={4}>
                                    <span>No Education Added </span>
                                 </Col>
                              </Row>
                           </Container>

                           }
                        </Row>
                     </Container>
                  </div>
                  {/*</div>*/}
                  <div className={'mt-2'}>
                     <Button
                        color="primary"
                        style={{
                           float:'right',
                           padding: '5px 25px',
                           marginLeft: '14px',
                        }}
                        onClick={this.downloadPDFMethod}
                     >
                        Download PDF
                     </Button>
                  </div>
               </ModalBody>
            </Modal>

         </Fragment>
      )
   }
}